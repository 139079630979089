import i18next from 'i18next';
import { Record, Map } from 'immutable';

const properties = {
  name: '',
  contact_name: '',
  facility_form: null,
  job_offer_existence: '',
  job_category_id: '',
  pagination: Map({
    page: 1,
    limit: 10,
  }),
};

const FacilitySearchLabelRecord = Record(properties);

export default class FacilitySearchLabel extends FacilitySearchLabelRecord {
  update(key, value) {
    return this.set(key, value);
  }

  isUnspecified() {
    return (
      !this.name
      &&
      !this.facility_form
      &&
      !this.job_category_id
      &&
      !this.isJobOfferExistanceSpecified()
      &&
      !this.contact_name
    );
  }

  isJobOfferExistanceSpecified() {
    return this.job_offer_existence && this.job_offer_existence !== i18next.t('common.unspecified');
  }
}
