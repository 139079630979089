import { Record, List } from 'immutable';

const MessagesRecord = Record({
  messages: List(),
  page: 1,
  total: 0,
});

export default class Messages extends MessagesRecord {
  update(key, value) {
    return this.set(key, value);
  }

  getApplicationId() {
    const applicationIds = this.messages.map(m => m.get('application_id'));

    return Math.max(...applicationIds);
  }

  getSelection() {
    const message = this.messages.filter(m => m.get('selection').id).first();

    if (!message) {
      return null;
    }

    return message.get('selection');
  }

  getMemberSelectedPreferredInterviewDatesOnLatestMessage() {
    return this.messages.last().member_selected_interview_dates;
  }
}
