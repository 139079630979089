import reducers from './reducers';
import * as manuscriptModificationOperations from './operations';
import * as manuscriptModificationTypes from './types';

export {
  manuscriptModificationOperations,
  manuscriptModificationTypes,
};

export default reducers;
