import { Record, fromJS } from 'immutable';
import { FACILITY_STATUS } from '@/models/ducks/facility/constants';
import { JOB_OFFER_EXISTENCE, JOB_OFFER_TYPE } from '@/models/ducks/job-offer/constants';

const properties = {
  pagination: fromJS({
    limit: 5,
    page: 1,
  }),
  count_only: false,
  status: FACILITY_STATUS.OPENED_STATUSES,
  job_offer_existence: JOB_OFFER_EXISTENCE.JOB_OFFER_EXISTENCE_UNSPECIFIED,
  name: '',
  job_category_id: '',
  job_offer_type: JOB_OFFER_TYPE.ALL,
};

const FacilitySearchConditionRecord = Record(properties);
export default class FacilitySearchCondition extends FacilitySearchConditionRecord {
  update(key, val) {
    if (key === 'page' || key === 'limit') {
      return this.setIn(['pagination', 'page'], val);
    }
    return this.set(key, val);
  }

  limit() {
    return this.pagination.get('limit');
  }

  page() {
    return this.pagination.get('page');
  }

  from() {
    const limit = this.limit();

    return ((this.pagination.get('page') * limit) + 1) - limit;
  }

  to(total) {
    const toNum = ((this.pagination.get('page') * this.pagination.get('limit')));

    return total > toNum ? toNum : total;
  }
}
