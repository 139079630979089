
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "Identifiable": [
      "Application",
      "AppliedMemberCareer",
      "AppliedMemberEducationBackground",
      "City",
      "Facility",
      "FavoriteJobOffer",
      "JobCategory",
      "JobOfferSalary",
      "MemberDesiredCondition",
      "MemberDesiredJobCategory",
      "MemberDesiredWorkplace",
      "MemberQualification",
      "MessageTemplate",
      "Prefecture",
      "Qualification",
      "UnAppliedMemberCareer",
      "UnAppliedMemberEducationBackground"
    ],
    "JobOffer": [
      "JmJobOffer"
    ],
    "Member": [
      "AppliedMember",
      "DeletedMember",
      "ScoutUnnecessaryMember",
      "StoppedMember",
      "UnAppliedMember"
    ]
  }
};
      export default result;
    