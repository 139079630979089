import * as types from './types';

const get = (facilityForms) => {
  return {
    type: types.GET,
    payload: {
      facilityForms,
    },
  };
};

const init = () => {
  return {
    type: types.INIT,
  };
};

export {
  get,
  init,
};
