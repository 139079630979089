import * as types from './types';

const init = () => {
  return {
    type: types.INIT,
  };
};

const changeField = (key, value) => {
  return {
    type: types.CHANGE_FIELD,
    payload: {
      key,
      value,
    },
  };
};

const changeJobOfferField = (key, value) => {
  return {
    type: types.CHANGE_JOB_OFFER_FIELD,
    payload: {
      key,
      value,
    },
  };
};

const initJobOfferField = () => {
  return {
    type: types.INIT_JOB_OFFER_FIELD,
  };
};

const changeMemberIdsField = (memberIds) => {
  return {
    type: types.CHANGE_MEMBER_IDS_FIELD,
    payload: {
      memberIds,
    },
  };
};

export {
  init,
  changeField,
  changeJobOfferField,
  initJobOfferField,
  changeMemberIdsField,
};
