import { createAction, ActionType } from 'typesafe-actions';
import * as validation from './validation';

export const INIT = 'RESET_FORM/INIT';
export const HANDLE_SUBMIT = 'RESET_FORM/HANDLE_SUBMIT';
export const SHOW_MESSAGE = 'RESET_FORM/SHOW_MESSAGE';

export const init = createAction(INIT)();
export const handleSubmit = createAction(HANDLE_SUBMIT, ((values: validation.Values) => ({
  values,
})))();
export const showMessage = createAction(SHOW_MESSAGE, (message => ({
  message,
})))();

export type ResetFormActionTypes =
  | ActionType<typeof init>
  | ActionType<typeof handleSubmit>
  | ActionType<typeof showMessage>
