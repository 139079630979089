import * as types from './types';

const create = () => {
  return {
    type: types.CREATE,
  };
};

const restore = (cache) => {
  return {
    type: types.RESTORE,
    payload: {
      cache,
    },
  };
};

const update = (key, value) => {
  return {
    type: types.UPDATE,
    payload: {
      key,
      value,
    },
  };
};

const updateMessageType = (key, value) => {
  return {
    type: types.UPDATE_MESSAGE_TYPE,
    payload: {
      key,
      value,
    },
  };
};

export {
  create,
  restore,
  update,
  updateMessageType,
};
