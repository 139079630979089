import * as types from './types';

const init = () => {
  return {
    type: types.INIT,
  };
};

const save = (date) => {
  return {
    type: types.SAVE,
    payload: {
      date,
    },
  };
};

export {
  init,
  save,
};
