export default {
  isIE11() {
    /* eslint-disable */
    return !!navigator.userAgent.match(/Trident.*rv\:11\./);
    /* eslint-enable */
  },

  isIE10() {
    return navigator.appVersion.indexOf('MSIE 10') !== -1;
  },

  isIE9() {
    return navigator.appVersion.indexOf('MSIE 9') !== -1;
  },

  isLessThanIE8() {
    return !!window.attachEvent && !document.addEventListener;
  },

  isFirefox() {
    return navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
  },

  isSafari() {
    const ua = navigator.userAgent.toLowerCase();

    return (
      ua.indexOf('safari') > -1
      &&
      ua.indexOf('chrome') < 0
    );
  },
};
