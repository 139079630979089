import * as types from './types';

const init = () => {
  return {
    type: types.INIT,
  };
};

const syncSize = (areas) => {
  return {
    type: types.SYNC_SIZE,
    payload: {
      areas,
    },
  };
};

const sync = (areas) => {
  return {
    type: types.SYNC,
    payload: {
      areas,
    },
  };
};

const toggle = (id) => {
  return {
    type: types.TOGGLE,
    payload: {
      id,
    },
  };
};

const updateId = (newId, index) => {
  return {
    type: types.UPDATE_ID,
    payload: {
      newId,
      index,
    },
  };
};

export {
  init,
  syncSize,
  sync,
  toggle,
  updateId,
};
