import i18next from 'i18next';
import { SALARY_TYPES, JOB_OFFER_STATUS } from './constants';

const getSalaryTypeName = (salaryTypeId) => {
  const salary = SALARY_TYPES.find((item) => {
    return item.id === salaryTypeId;
  });
  if (!salary) {
    return '';
  }
  return i18next.t(salary.name);
};

const getLabels = (mode) => {
  return JOB_OFFER_STATUS.STATUS_LABELS.filter((item) => {
    return item.mode === mode && item.disapproved === false;
  });
};

const getJobOfferLabels = (jobOfferStatus) => {
  return JOB_OFFER_STATUS.STATUS_LABELS.find((item) => {
    return item.id === jobOfferStatus;
  });
};

export {
  getSalaryTypeName,
  getLabels,
  getJobOfferLabels,
};
