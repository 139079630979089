/* eslint-disable import/first */
import '../scss/customers.scss';
import '@babel/polyfill';

import { ApolloProvider } from '@apollo/client';
import React from 'react';
import { render } from 'react-dom';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';
import * as Sentry from '@sentry/browser';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from '@emotion/react';
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import store, { persistor } from '@/models/store';
import AppRouter from '@/router/index.tsx';

import SETTINGS from '@/models/ducks/shared/constants/settings';
import SENTRY from '@/models/ducks/shared/constants/sentry';

import supportHelper from '@/utils/support';
import { client } from './apollo/client';
import { theme } from '@/theme';
import enJson from '@/locales/en';
import { TimezoneCookie } from '@/utils/timezone-cookie';

if (SETTINGS.GOOGLE_ANALYTICS.trackable) {
  ReactGA.initialize(SETTINGS.GOOGLE_ANALYTICS.code);
}

if (SETTINGS.GOOGLE_TAG_MANAGER.trackable) {
  const tagManagerArgs = {
    gtmId: SETTINGS.GOOGLE_TAG_MANAGER.gtm_id,
    auth: SETTINGS.GOOGLE_TAG_MANAGER.auth,
    preview: SETTINGS.GOOGLE_TAG_MANAGER.preview,
  };
  TagManager.initialize(tagManagerArgs);
}

Sentry.init({
  dsn: SETTINGS.SENTRY_DSN,
  // 環境名(development,sb,qa,production)取得の機構が存在しないため明示的にNoneを設定しenvironmentタグを設定しない
  // @sentry/browser6.15.0ではデフォルトenvironmentタグがproductionで設定される挙動
  environment: 'None',
  allowUrls: [
    /us\.job-medley\.com/,
    /jobleyhealth\.asia/,
  ],
  ignoreErrors: SENTRY.IGNORE_ERRORS,
  beforeSend: (event) => {
    if (supportHelper.isRecommendedEnv()) {
      return event;
    }
    return null;
  },
  integrations: [new Sentry.Integrations.GlobalHandlers({
    onunhandledrejection: true,
  })],
});

Sentry.setExtras({
  devicePixelRatio: window.devicePixelRatio,
});

i18next.use(initReactI18next).init({
  lng: 'en',
  resources: {
    en: { translation: enJson },
  },
});

new TimezoneCookie().init();

render(
  <Provider store={ store }>
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <PersistGate loading={ null } persistor={ persistor }>
          <AppRouter />
        </PersistGate>
      </ThemeProvider>
    </ApolloProvider>
  </Provider>,
  document.getElementById('js-app'),
);

export { store };
