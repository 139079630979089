import * as actions from './actions';

const init = () => {
  return dispatch => dispatch(actions.init());
};

const toggle = (ID) => {
  return dispatch => dispatch(actions.toggle(ID));
};

const show = (ID) => {
  return dispatch => dispatch(actions.show(ID));
};

const hide = (ID) => {
  return dispatch => dispatch(actions.hide(ID));
};

export {
  init,
  toggle,
  show,
  hide,
};
