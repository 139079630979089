import reducers from './reducers';
import * as pageOperations from './operations';
import * as pageTypes from './types';

export {
  pageOperations,
  pageTypes,
};

export default reducers;
