import * as types from './types';
import * as timezone from '@/utils/timezone';

const init = () => {
  return {
    type: types.INIT,
  };
};

const save = () => {
  return {
    type: types.SAVE,
    payload: {
      date: timezone.newDate(),
    },
  };
};

const savePermanently = (isPermanent) => {
  return {
    type: types.SAVE_PERMANENTLY,
    payload: {
      isPermanent,
    },
  };
};

export {
  init,
  save,
  savePermanently,
};
