import reducers from './reducers';
import * as customerOperations from './operations';
import * as customerTypes from './types';

export {
  customerOperations,
  customerTypes,
};

export default reducers;
