export const NOTIFICATION_TIMEOUT = 3000;

export const JOB_OFFER_TYPE_JOB_MEDLEY = 'JMJobOffer';
export const JOB_OFFER_TYPE_ALL = 'ALL';

export type JobOfferTypes =
  | typeof JOB_OFFER_TYPE_JOB_MEDLEY
  | typeof JOB_OFFER_TYPE_ALL

export const JOB_OFFER_TYPE = {
  LABEL: {
    JOB_MEDLEY: JOB_OFFER_TYPE_JOB_MEDLEY,
  },
};

export type JobOfferStatusLabel = {
  id: number;
  mode: string;
  disapproved: boolean;
  name: string;
  labels: {
    name: string;
    color: string;
  }[];
};

// Localized const
export const UNQUALIFIED = 'constants.unqualified';

// TODO: Need localization
export const MOVE_PAGE_ALERT_MESSAGE = 'Are you sure you want to leave this page? Changes that you made may not be saved.';

