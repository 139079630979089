import QualificationApi from './api';
import * as actions from './actions';

const get = () => {
  return dispatch => QualificationApi.all()
    .then((response) => {
      return dispatch(actions.get(response.data.qualifications));
    });
};

const getWithJobCategorySearchCondition = (id) => {
  return dispatch => QualificationApi.jobCategorySearchCondition(id)
    .then((response) => {
      return dispatch(actions.get(response.data.qualifications));
    });
};

export {
  get,
  getWithJobCategorySearchCondition,
};
