import * as types from './types';

const init = () => {
  return {
    type: types.INIT,
  };
};

const change = (key, value) => {
  return {
    type: types.CHANGE,
    payload: {
      key,
      value,
    },
  };
};

export {
  init,
  change,
};
