import { createAction, ActionType } from 'typesafe-actions';

export const INIT = 'EMAIL_EDIT/INIT';
export const HANDLE_UPDATE_COMPLETION = 'EMAIL_EDIT/HANDLE_UPDATE_COMPLETION';
export const SHOW_NOTIFICATION = 'EMAIL_EDIT/SHOW_NOTIFICATION';
export const HIDE_NOTIFICATION = 'EMAIL_EDIT/HIDE_NOTIFICATION';

export const init = createAction(INIT)();
export const handleUpdateCompletion = createAction(HANDLE_UPDATE_COMPLETION)();
export const showNotification = createAction(SHOW_NOTIFICATION, (({ message }: { message: string }) => ({ message })))();
export const hideNotification = createAction(HIDE_NOTIFICATION)();

export type EmailEditActionTypes =
  | ActionType<typeof init>
  | ActionType<typeof handleUpdateCompletion>
  | ActionType<typeof showNotification>
  | ActionType<typeof hideNotification>
