import * as types from './types';

const init = () => {
  return {
    type: types.INIT,
  };
};

const get = (response) => {
  return {
    type: types.GET,
    payload: {
      response,
    },
  };
};

const updateFacility = (facility) => {
  return {
    type: types.UPDATE_FACILITY,
    payload: {
      facility,
    },
  };
};

const updateJobOffer = (facilityIndex, jobOfferIndex, facilityJobOffer) => {
  return {
    type: types.UPDATE_JOB_OFFER,
    payload: {
      facilityIndex,
      jobOfferIndex,
      facilityJobOffer,
    },
  };
};

const deleteJobOffer = (facilityIndex, jobOfferIndex) => {
  return {
    type: types.DELETE_JOB_OFFER,
    payload: {
      facilityIndex,
      jobOfferIndex,
    },
  };
};

const deleteFacility = (facilityId) => {
  return {
    type: types.DELETE_FACILITY,
    payload: {
      facilityId,
    },
  };
};

export {
  init,
  get,
  updateFacility,
  updateJobOffer,
  deleteJobOffer,
  deleteFacility,
};
