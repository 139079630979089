import axios from 'axios';

export default {
  recommendMembers(limit) {
    return axios.get('/api/customers/home/v2/recommend_members/', {
      params: {
        limit,
      },
    });
  },
};
