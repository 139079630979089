import { Record } from 'immutable';

const OperatorRecord = Record({
  operator: false,
  email: null,
  eligible_switch: false,
  admin: false,
});

export default class Operator extends OperatorRecord {
  isAdmin() {
    return this.admin;
  }
}
