import * as actions from './actions';
import JobOfferSuggestApi from '@/models/ducks/shared/api/job-offer-suggest';
import { JOB_OFFER_TYPE } from '@/models/ducks/job-offer/constants';

const init = (query, jobOfferType = JOB_OFFER_TYPE.ALL) => {
  return dispatch => JobOfferSuggestApi.getSuggestions(query, jobOfferType, 1)
    .then((response) => {
      return dispatch(actions.init({
        suggest: response.data.suggest,
      }));
    });
};

const get = (query, jobOfferType = JOB_OFFER_TYPE.ALL) => {
  return dispatch => JobOfferSuggestApi.getSuggestions(query, jobOfferType, 1)
    .then((response) => {
      return dispatch(actions.get({
        suggest: response.data.suggest,
      }));
    });
};

const getMore = (query, jobOfferType = JOB_OFFER_TYPE.ALL, page) => {
  return dispatch => JobOfferSuggestApi.getSuggestions(query, jobOfferType, page)
    .then((response) => {
      return dispatch(actions.getMore({
        suggest: response.data.suggest,
      }));
    });
};

const initForScout = (query) => {
  return dispatch => JobOfferSuggestApi.getSuggestionsForScout(query, 1)
    .then((response) => {
      return dispatch(actions.initForScout({
        suggest: response.data.suggest,
      }));
    });
};

const getForScout = (query) => {
  return dispatch => JobOfferSuggestApi.getSuggestionsForScout(query, 1)
    .then((response) => {
      return dispatch(actions.getForScout({
        suggest: response.data.suggest,
      }));
    });
};

const getMoreForScout = (query, page) => {
  return dispatch => JobOfferSuggestApi.getSuggestionsForScout(query, page)
    .then((response) => {
      return dispatch(actions.getMoreForScout({
        suggest: response.data.suggest,
      }));
    });
};

const clear = () => {
  return dispatch => dispatch(actions.clear());
};

const initWithFacility = (query, facilitySuffix) => {
  return dispatch => JobOfferSuggestApi.getFacilityJobOfferSuggestions(query, facilitySuffix, 1).then((response) => {
    return dispatch(actions.initWithFacility({
      suggest: response.data.suggest,
      total: response.data.suggest.total,
    }));
  });
};

const getWithFacility = (query, facilitySuffix) => {
  return dispatch => JobOfferSuggestApi.getFacilityJobOfferSuggestions(query, facilitySuffix, 1).then((response) => {
    return dispatch(actions.getWithFacility({
      suggest: response.data.suggest,
    }));
  });
};

const getMoreWithFacility = (query, facilitySuffix, page) => {
  return dispatch => JobOfferSuggestApi.getFacilityJobOfferSuggestions(query, facilitySuffix, page).then((response) => {
    return dispatch(actions.getMoreWithFacility({
      suggest: response.data.suggest,
    }));
  });
};

export {
  init,
  get,
  getMore,
  initForScout,
  getForScout,
  getMoreForScout,
  clear,
  initWithFacility,
  getWithFacility,
  getMoreWithFacility,
};
