import * as actions from './actions';
import * as selectors from './selectors';

const save = () => {
  return dispatch => dispatch(actions.save());
};

const savePermanently = (isPermanent) => {
  return dispatch => dispatch(actions.savePermanently(isPermanent));
};

const check = () => {
  return (dispatch, getState) => {
    const { promoted } = getState();

    if (selectors.isCurrentMonth(promoted) || selectors.isPermanent(promoted)) {
      return dispatch(actions.save());
    }
    return dispatch(actions.init());
  };
};

export {
  save,
  savePermanently,
  check,
};
