import reducers from './reducers';
import * as toggleOperations from './operations';
import * as toggleTypes from './types';

export {
  toggleOperations,
  toggleTypes,
};

export default reducers;
