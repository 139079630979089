import axios from 'axios';

export default {
  searchGeo(prefectureId, cityId, addressEtc) {
    return axios.get('/api/customers/geocoders/search/',
      {
        params: {
          condition: {
            prefecture_id: prefectureId,
            city_id: cityId,
            address_etc: addressEtc,
          },
        },
      });
  },
};
