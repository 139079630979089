import * as types from './types';
import MessageGroupSearchLabel from './model';

export const initialState = new MessageGroupSearchLabel();

export default (state = initialState, action) => {
  switch (action.type) {
    case types.CREATE:
      return new MessageGroupSearchLabel(action.payload.label);
    default:
      return state;
  }
};
