import { Record, List } from 'immutable';
import String from '@/utils/string';

const properties = {
  id: 0,
  name: '',
  postal_code: '',
  prefecture_id: '',
  city_id: '',
  address_etc: '',
  in_charge_family_name: '',
  in_charge_first_name: '',
  in_charge_post: '',
  in_charge_position: '',
  tel: '',
  mobile_tel: '',
  same_as_tel: false,
  email: '',
  password: '',
  password_confirmation: '',
  approval: false,
  claim_postal_code: '',
  claim_prefecture_id: '',
  claim_city_id: '',
  claim_address_etc: '',
  claim_in_charge_family_name: '',
  claim_in_charge_first_name: '',
  claim_in_charge_post: '',
  claim_in_charge_position: '',
  claim_tel: '',
  notification_setting: { ignore_emails: [] },
  publishing_mode: '',
  eligible_switch: false,
  lead_id: '',
  hiring_page: false,
  images: new List(),
  init_email: '',
};

const CustomerRecord = Record(properties);
export default class Customer extends CustomerRecord {
  update(key, value) {
    return this.set(key, String.convertNullValue(value));
  }

  hasClaim() {
    if (this.claim_prefecture_id || this.claim_city_id || this.claim_address_etc
      || this.claim_in_charge_post || this.claim_in_charge_position
      || this.claim_in_charge_family_name || this.claim_in_charge_first_name
      || this.claim_tel) {
      return true;
    }
    return false;
  }

  clearClaim() {
    return this.update('claim_postal_code', '')
      .update('claim_prefecture_id', '')
      .update('claim_city_id', '')
      .update('claim_address_etc', '')
      .update('claim_in_charge_family_name', '')
      .update('claim_in_charge_first_name', '')
      .update('claim_in_charge_post', '')
      .update('claim_in_charge_position', '')
      .update('claim_tel', '');
  }

  getClaimKeys() {
    return ['claim_postal_code', 'claim_prefecture_id', 'claim_city_id', 'claim_address_etc', 'claim_in_charge_family_name', 'claim_in_charge_first_name', 'claim_in_charge_post', 'claim_in_charge_position', 'claim_tel'];
  }

  // メール配信希望するかどうか
  isDesireEmail(key) {
    if (Array.isArray(this.notification_setting.ignore_emails) === false) {
      return true;
    }

    return !this.notification_setting.ignore_emails.includes(key);
  }

  claimSameWithBasic() {
    return this.postal_code === this.claim_postal_code
      && this.prefecture_id === this.claim_prefecture_id
      && this.city_id === this.claim_city_id
      && this.address_etc === this.claim_address_etc
      && this.in_charge_family_name === this.claim_in_charge_family_name
      && this.in_charge_first_name === this.claim_in_charge_first_name;
  }
}
