const CONTACT_TYPE = {
  BASIC: 1,
  CLAIM: 2,
};

const ESTABLISHED_MONTH_TYPE = {
  DEFAULT: 0,
  MONTH: 1,
};

const FACILITY_STATUS = {
  OPENED_STATUSES: [3, 5],

  STATUS: {
    DRAFT: 0, // 下書き
    PENDING: 1, // 承認待ち
    CLOSED: 2, // 承認済み(応募受付終了)
    OPENED: 3, // 承認済み(掲載中)
    DELETED: 4, // 削除済み
    OPENED_WITH_PENDING: 5, // 掲載中の情報を編集し申請した状態
  },
};

export {
  CONTACT_TYPE,
  ESTABLISHED_MONTH_TYPE,
  FACILITY_STATUS,
};
