const INIT = 'state/ducks/job-offer/INIT';
const CREATE = 'state/ducks/job-offer/CREATE';
const SET = 'state/ducks/job-offer/SET';
const GET = 'state/ducks/job-offer/GET';
const CHANGE_FIELD = 'state/ducks/job-offer/CHANGE_FIELD';
const CHANGE_CLOSED_REASONS = 'state/ducks/job-offer/CHANGE_CLOSED_REASONS';
const COPY = 'state/ducks/job-offer/COPY';
const CHANGE_SALARY_FIELD = 'state/ducks/job-offer/CHANGE_SALARY_FIELD';
const CHANGE_SALARY_STATUS = 'state/ducks/job-offer/CHANGE_SALARY_STATUS';
const CHANGE_SALARY_STATUS_CHECK = 'state/ducks/job-offer/CHANGE_SALARY_STATUS_CHECK';
const HOLD_CURRENT_FEATURES = 'state/ducks/job-offer/HOLD_CURRENT_FEATURES';
const UPDATE_UNIT_PRICES = 'state/ducks/job-offer/UPDATE_UNIT_PRICES';
const CHANGE_FEATURE = 'state/ducks/job-offer/CHANGE_FEATURE';
const ADD_IMAGES = 'state/ducks/job-offer/ADD_IMAGES';

export {
  INIT,
  CREATE,
  SET,
  GET,
  CHANGE_FIELD,
  CHANGE_CLOSED_REASONS,
  COPY,
  CHANGE_SALARY_FIELD,
  CHANGE_SALARY_STATUS,
  CHANGE_SALARY_STATUS_CHECK,
  HOLD_CURRENT_FEATURES,
  UPDATE_UNIT_PRICES,
  CHANGE_FEATURE,
  ADD_IMAGES,
};
