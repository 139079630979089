import * as types from './types';

const show = () => {
  return {
    type: types.SHOW,
  };
};

const hide = () => {
  return {
    type: types.HIDE,
  };
};

export {
  show,
  hide,
};
