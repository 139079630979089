import i18next from 'i18next';
import * as actions from './actions';
import browser from '@/utils/browser';

const handleUnloadEvent = (event, leavingPageAlert) => {
  // firefoxかつsetRouteLeaveHookの場合、確認ダイアログでのキャンセルがhistoryの更新を止められていない
  // ので、その場合はleave alertを表示しない
  if (event.type !== 'beforeunload' && browser.isFirefox()) {
    return;
  }

  /* eslint-disable consistent-return */
  if (leavingPageAlert) {
    const newEvent = event;
    newEvent.returnValue = i18next.t('models.operations.leaving_page_alert.confirm_leaving');
    return newEvent.returnValue;
  }
  /* eslint-enable consistent-return */
};

const attachEvent = () => {
  return (dispatch, getState) => {
    window.addEventListener('beforeunload', e => handleUnloadEvent(e, getState().leavingPageAlert));
    return dispatch(actions.noop());
  };
};

const activate = () => {
  return dispatch => dispatch(actions.activate());
};

const inactivate = () => {
  return (dispatch, getState) => {
    dispatch(actions.inactivate());
    window.removeEventListener('beforeunload', e => handleUnloadEvent(e, getState().leavingPageAlert));

    return dispatch(actions.noop());
  };
};

export {
  attachEvent,
  activate,
  inactivate,
};
