const INIT = 'state/ducks/selection-list/INIT';
const GET = 'state/ducks/selection-list/GET';
const GET_MORE = 'state/ducks/selection-list/GET_MORE';
const TOGGLE = 'state/ducks/selection-list/TOGGLE';
const SYNC_STATUS_WITH_MESSAGE_TYPE = 'state/ducks/selection-list/SYNC_STATUS_WITH_MESSAGE_TYPE';
const UPDATE = 'state/ducks/selection-list/UPDATE';
const UPDATE_STATUS = 'state/ducks/selection-list/UPDATE_STATUS';
const REQUEST_APPLICATION = 'state/ducks/selection-list/REQUEST_APPLICATION';
const NOOP = 'state/ducks/selection-list/NOOP';

export {
  INIT,
  GET,
  GET_MORE,
  TOGGLE,
  SYNC_STATUS_WITH_MESSAGE_TYPE,
  UPDATE,
  UPDATE_STATUS,
  REQUEST_APPLICATION,
  NOOP,
};
