import * as actions from './actions';

const init = (page, total) => {
  return (dispatch, getState) => {
    const { guide } = getState();

    // すでに保存済みのlocalStorageがある場合は、途中からのガイド表示 or ガイドの表示不要になるため、初期化しない
    if (guide && guide[page] && guide[page].length) {
      return dispatch(actions.noop());
    }
    return dispatch(actions.init(page, total));
  };
};

const check = () => {
  return dispatch => dispatch(actions.check());
};

const save = (page) => {
  return dispatch => dispatch(actions.save(page));
};

const saveIndex = (page, index) => {
  return dispatch => dispatch(actions.saveIndex(page, index));
};

const reset = (page) => {
  return dispatch => dispatch(actions.reset(page));
};

export {
  init,
  check,
  save,
  saveIndex,
  reset,
};
