import CityApi from '@/models/ducks/shared/api/city';
import * as actions from './actions';

const get = (prefectureId) => {
  if (!prefectureId) {
    return dispatch => dispatch(actions.get([]));
  }

  return dispatch => CityApi.search(prefectureId)
    .then((response) => {
      return dispatch(actions.get(response.data.cities));
    });
};

export {
  get,
};
