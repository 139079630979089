import * as actions from './actions';

const init = () => {
  return dispatch => dispatch(actions.init());
};

const change = (key, value) => {
  return dispatch => dispatch(actions.change(key, value));
};

export {
  init,
  change,
};
