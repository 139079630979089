const INIT = 'state/ducks/job-offer-list-for-suggest/INIT';
const GET = 'state/ducks/job-offer-list-for-suggest/GET';
const GET_MORE = 'state/ducks/job-offer-list-for-suggest/GET_MORE';
const INIT_FOR_SCOUT = 'state/ducks/job-offer-list-for-suggest/INIT_FOR_SCOUT';
const GET_FOR_SCOUT = 'state/ducks/job-offer-list-for-suggest/GET_FOR_SCOUT';
const GET_MORE_FOR_SCOUT = 'state/ducks/job-offer-list-for-suggest/GET_MORE_FOR_SCOUT';
const CLEAR = 'state/ducks/job-offer-list-for-suggest/CLEAR';
const INIT_WITH_FACILITY = 'state/ducks/job-offer-list-for-suggest/INIT_WITH_FACILITY';
const GET_WITH_FACILITY = 'state/ducks/job-offer-list-for-suggest/GET_WITH_FACILITY';
const GET_MORE_WITH_FACILITY = 'state/ducks/job-offer-list-for-suggest/GET_MORE_WITH_FACILITY';

export {
  INIT,
  GET,
  GET_MORE,
  INIT_FOR_SCOUT,
  GET_FOR_SCOUT,
  GET_MORE_FOR_SCOUT,
  CLEAR,
  INIT_WITH_FACILITY,
  GET_WITH_FACILITY,
  GET_MORE_WITH_FACILITY,
};
