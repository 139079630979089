import * as types from './types';

const get = ({ facilities, count, total }) => {
  return {
    type: types.GET,
    payload: {
      facilities,
      count,
      total,
    },
  };
};

const getMore = ({ facilities }) => {
  return {
    type: types.GET_MORE,
    payload: {
      facilities,
    },
  };
};

const noop = () => {
  return {
    type: types.NOOP,
  };
};

export {
  get,
  getMore,
  noop,
};
