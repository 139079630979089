import FacilityApi from '@/models/ducks/shared/api/facility';
import api from './api';
import TownApi from '@/models/ducks/shared/api/town';
import * as actions from './actions';

const init = () => {
  return dispatch => dispatch(actions.init());
};

const set = (facility) => {
  return dispatch => dispatch(actions.set(facility));
};

const create = (facility) => {
  return dispatch => FacilityApi.create(facility)
    .then((response) => {
      return dispatch(actions.create(response.data.facility));
    });
};

const getFacilityById = (id) => {
  return dispatch => FacilityApi.show(id)
    .then((response) => {
      return dispatch(actions.setWithConvertedFacility(response.data.facility));
    });
};

const initUnnecessaryFieldOnCopy = () => {
  return dispatch => dispatch(actions.initUnnecessaryFieldOnCopy());
};

const copyCustomerInfo = (customer) => {
  return dispatch => dispatch(actions.copyCustomerInfo(customer));
};

const updateWithCopiedCustomerInfo = () => {
  return (dispatch, getState) => {
    const { facility } = getState();

    return api.searchGeo(facility.prefecture_id, facility.city_id, facility.address_etc)
      .then((response) => {
        const { lat, lon } = response.data.cordinates;
        return dispatch(actions.changeGeoLatLon({
          lat,
          lon,
        }));
      });
  };
};

const changeField = (key, value) => {
  return dispatch => dispatch(actions.changeField(key, value));
};

const updateLatLon = (prefId, cityId, value) => {
  return dispatch => api.searchGeo(prefId, cityId, value).then((response) => {
    const { lat, lon } = response.data.cordinates;
    return dispatch(actions.changeGeoLatLon({
      lat,
      lon,
    }));
  });
};

const changeFeature = (feature, isChecked) => {
  return dispatch => dispatch(actions.changeFeature(feature, isChecked));
};

const fillAddress = () => {
  return (dispatch, getState) => {
    const { facility } = getState();

    return TownApi.search(facility.postal_code).then((response) => {
      const town = response.data.towns.shift();
      if (!town) {
        return dispatch(actions.noop());
      }

      return dispatch(actions.fillAddress(town));
    });
  };
};

const addImages = (images) => {
  return dispatch => dispatch(actions.addImages(images));
};

export {
  init,
  set,
  create,
  getFacilityById,
  initUnnecessaryFieldOnCopy,
  copyCustomerInfo,
  updateWithCopiedCustomerInfo,
  changeField,
  updateLatLon,
  changeFeature,
  fillAddress,
  addImages,
};
