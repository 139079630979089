import * as types from './types';

const check = () => {
  return {
    type: types.CHECK,
  };
};

const save = (page) => {
  return {
    type: types.SAVE,
    payload: {
      page,
    },
  };
};

export {
  check,
  save,
};
