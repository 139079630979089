import { List, Map } from 'immutable';
import * as types from './types';
import CustomerSearchCondition from '@/models/ducks/shared/models/customer-search-condition';

export const initialState = new CustomerSearchCondition();

const changeConditionSelective = (state, action) => {
  const { key, value, prop } = action.payload;
  const list = value ?
    state.getIn(['condition', prop]).push(key)
    :
    state.getIn(['condition', prop]).delete(state.getIn(['condition', prop]).findIndex((index) => {
      return index === key;
    }));

  return state.setIn(['condition', prop], list);
};

const addConditionDesiredArea = (state) => {
  const list = state.getIn(['condition', 'desired_areas']);
  const newList = list.push(Map({
    prefecture_id: '',
    cities: List(),
    city_id: '',
    city_ids: List(),
  }));

  return state.setIn(['condition', 'desired_areas'], newList);
};

const removeConditionDesiredArea = (state, action) => {
  const list = state.getIn(['condition', 'desired_areas']);
  const newList = list.filter((item, idx) => {
    return idx !== action.payload.index;
  });

  return state.setIn(['condition', 'desired_areas'], newList);
};

const addConditionDesiredJobCategory = (state) => {
  const list = state.getIn(['condition', 'desired_job_categories']);
  const newList = list.push(Map({
    job_category_group_id: '',
    job_category_id: '',
    career_year: '',
  }));

  return state.setIn(['condition', 'desired_job_categories'], newList);
};

const removeConditionDesiredJobCategory = (state, action) => {
  const list = state.getIn(['condition', 'desired_job_categories']);
  const newList = list.filter((item, idx) => {
    return idx !== action.payload.index;
  });

  return state.setIn(['condition', 'desired_job_categories'], newList);
};

const changeConditionDesiredJobCategory = (state, action) => {
  const list = state.getIn(['condition', 'desired_job_categories']);
  const newList = list.update(action.payload.index, (item) => {
    return item.set(action.payload.key, action.payload.value);
  });

  return state.setIn(['condition', 'desired_job_categories'], newList);
};

const changeConditionDesiredAreaPrefectures = (state, action) => {
  const list = state.getIn(['condition', 'desired_areas']);
  const newList = list.update(action.payload.index, (item) => {
    const cities = action.payload.cities.map(city => city.id);
    const cityId = Number(item.get('city_id'));
    const cityIds = item.get('city_ids');
    const cityIdList = cityIds.toArray().concat(cityId).filter(v => v);
    const isCurrentPrefecture = cityIdList.every(id => cities.includes(id));

    return item
      .set('cities', List(action.payload.cities))
      .set('prefecture_id', action.payload.prefectureId)
      .set('city_ids', isCurrentPrefecture ? List(cityIdList) : List());
  });

  return state.setIn(['condition', 'desired_areas'], newList);
};

const toggleConditionDesiredAreaCityGroupsCheckAll = (state, action) => {
  const { allCityIds, isCheckedAll, index } = action.payload;
  const currentCityIds = state.getIn(['condition', 'desired_areas', index, 'city_ids']);
  const otherCityGroupIds = currentCityIds.filter(v => !allCityIds.includes(v));
  const newCityIds = isCheckedAll ? otherCityGroupIds : allCityIds.push(otherCityGroupIds).flatten();

  return state.setIn(['condition', 'desired_areas', index, 'city_ids'], newCityIds);
};

const changeConditionDesiredAreaCity = (state, action) => {
  const { value, index } = action.payload;
  const list = state.getIn(['condition', 'desired_areas']);
  const cities = list.getIn([index, 'city_ids']);
  const isAdd = !cities.toArray().includes(value);
  const newList = list.update(index, (item) => {
    return item.set('city_ids', isAdd ? cities.push(value) : cities.filter(city => (city !== value)));
  });

  return state.setIn(['condition', 'desired_areas'], newList);
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.INIT:
      return initialState;
    case types.COPY:
      return action.payload.form;
    case types.CHANGE:
      return state.update(action.payload.key, action.payload.value);
    case types.CHANGE_CONDITION_SELECTIVE:
      return changeConditionSelective(state, action);
    case types.ADD_CONDITION_DESIRED_AREA:
      return addConditionDesiredArea(state);
    case types.REMOVE_CONDITION_DESIRED_AREA:
      return removeConditionDesiredArea(state, action);
    case types.ADD_CONDITION_DESIRED_JOB_CATEGORY:
      return addConditionDesiredJobCategory(state);
    case types.REMOVE_CONDITION_DESIRED_JOB_CATEGORY:
      return removeConditionDesiredJobCategory(state, action);
    case types.CHANGE_CONDITION_DESIRED_JOB_CATEGORY:
      return changeConditionDesiredJobCategory(state, action);
    case types.CHANGE_CONDITION_DESIRED_AREA_PREFECTURES:
      return changeConditionDesiredAreaPrefectures(state, action);
    case types.TOGGLE_CONDITION_DESIRED_AREA_CITY_GROUPS_CHECK_ALL:
      return toggleConditionDesiredAreaCityGroupsCheckAll(state, action);
    case types.CHANGE_CONDITION_DESIRED_AREA_CITY:
      return changeConditionDesiredAreaCity(state, action);
    case types.CORRECT_CONDITION_AGE_RANGE:
      return state.equalizeAge(action.payload.type);
    case types.CHANGE_FROM_URL_QUERY:
      return state.updateFromUrlQuery(action.payload.condition);
    case types.CHANGE_TO_SCOUTED:
      return state.updateToScouted(action.payload.scoutedMember);
    case types.CHANGE_TO_RECOMMENDED:
      return state.updateToRecommend(action.payload.condition);
    case types.CHANGE_TO_FAVORITE:
      return state.updateToFavorite(action.payload.favorite);
    case types.NOOP:
    default:
      return state;
  }
};
