import MinimumWageApi from './api';
import * as actions from './actions';

const get = () => {
  return dispatch => MinimumWageApi.all()
    .then((response) => {
      return dispatch(actions.get(response.data.minimum_wages));
    });
};

export {
  get,
};
