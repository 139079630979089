import { ValueOf } from '@/types';

export const INTERVIEW_METHOD = {
  OFFLINE_INTERVIEW: 1,
} as const;

export type InterviewMethodKey = ValueOf<typeof INTERVIEW_METHOD>;

export const INTERVIEW_METHOD_LABEL = {
  [INTERVIEW_METHOD.OFFLINE_INTERVIEW]: 'models.constants.shared.interview.in_person_interview',
} as const;
