import * as types from './types';

const show = ({ targetEl, targetEllipsis, content }) => {
  return {
    type: types.SHOW,
    payload: {
      targetEl,
      targetEllipsis,
      content,
    },
  };
};

const hide = () => {
  return {
    type: types.HIDE,
  };
};

export {
  show,
  hide,
};
