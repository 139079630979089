import reducers from './reducers';
import * as prefectureListOperations from './operations';
import * as prefectureListTypes from './types';
import * as prefectureListSelectors from './selectors';

export {
  prefectureListOperations,
  prefectureListTypes,
  prefectureListSelectors,
};

export default reducers;
