import * as actions from './actions';

const update = (status) => {
  return dispatch => dispatch(actions.update(status));
};

const check = () => {
  return dispatch => dispatch(actions.check());
};

export {
  update,
  check,
};
