import { List } from 'immutable';
import * as types from './types';

export const initialState = {
  receivedFavorites: new List(),
  total: 0,
  count: 0,
  limit: 10,
  total_unread_member_count: 0,
};

const get = (action) => {
  const { response } = action.payload;
  const { total, count, limit } = response.data;
  const receivedFavorites = List(response.data.received_favorites);

  return {
    total,
    count,
    limit,
    total_match_count: response.data.total_match_count,
    total_unread_member_count: response.data.total_unread_member_count,
    receivedFavorites,
  };
};

const getMore = (state, action) => {
  const { response } = action.payload;
  const { total, count, limit } = response.data;
  const receivedFavorites = List(response.data.received_favorites);

  return {
    total,
    count,
    limit,
    total_match_count: response.data.total_match_count,
    total_unread_member_count: response.data.total_unread_member_count,
    receivedFavorites: state.receivedFavorites.concat(receivedFavorites),
  };
};

const updateUnreadMemberCount = (state, action) => {
  const { total, count, limit } = state;
  const { jobOfferId, unreadMemberCount } = action.payload;
  const index = state.receivedFavorites.findIndex(item => item.job_offer_id === jobOfferId);

  return {
    total,
    count,
    limit,
    total_unread_member_count: state.total_unread_member_count,
    receivedFavorites: state.receivedFavorites.setIn([index], {
      ...state.receivedFavorites.getIn([index]),
      unread_member_count: unreadMemberCount,
    }),
  };
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET:
      return get(action);
    case types.GET_MORE:
      return getMore(state, action);
    case types.UPDATE_UNREAD_MEMBER_COUNT:
      return updateUnreadMemberCount(state, action);
    default:
      return state;
  }
};
