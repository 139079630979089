import { Record, Map, List } from 'immutable';
import SELECTION_STATUS from '@/models/ducks/selection-list/constants';
import FACILITY_JOB_OFFER_SUGGEST_TYPE from '@/models/ducks/shared/constants/facility-job-offer-suggest-type';

const properties = {
  job_offer_id: '',
  job_offer_name: '',
  facility_id: '',
  facility_name: '',
  suggest: new Map({
    prefecture_id: 0,
    job_category_id: 0,
    keyword: '',
  }),
  member_name_or_id: '',
  pagination: Map({
    limit: 20,
    page: 1,
  }),
  selection_status_ids: List([
    SELECTION_STATUS.STATUS_MAP.NOT_DOING.id,
    SELECTION_STATUS.STATUS_MAP.DOCUMENT_SCREENING.id,
    SELECTION_STATUS.STATUS_MAP.INTERVIEW_SET_DONE.id,
    SELECTION_STATUS.STATUS_MAP.INTERVIEW_DONE.id,
    SELECTION_STATUS.STATUS_MAP.OFFER.id,
    SELECTION_STATUS.STATUS_MAP.ACCEPTANCE.id,
    SELECTION_STATUS.STATUS_MAP.HIRING_SET_DONE.id,
  ]),
  note: '',

  scheduled_interview_only: 0,

  cached: null,
};

const SelectionSearchFormRecord = Record(properties);

export default class SelectionSearchForm extends SelectionSearchFormRecord {
  update(key, value) {
    if (Array.isArray(key)) {
      return this.setIn(key, value);
    }
    return this.set(key, value);
  }

  clearSuggest() {
    return this.set('suggest', properties.suggest);
  }

  updateSuggest(suggestion) {
    const model = this.clearSuggest();

    if (suggestion.type === FACILITY_JOB_OFFER_SUGGEST_TYPE.FACILITY) {
      return model
        .update('facility_id', suggestion.id)
        .update('facility_name', suggestion.name);
    }

    return model
      .update('job_offer_id', suggestion.id)
      .update('job_offer_name', suggestion.name);
  }

  hasSelectedFacilityOrJobOffer() {
    return this.get('facility_id') > 0 || this.get('job_offer_id') > 0;
  }

  getSelectedFacilityOrJobOffer() {
    return this.get('facility_name') || this.get('job_offer_name');
  }

  clearFacilityOrJobOffer() {
    return this.merge({
      job_offer_id: properties.job_offer_id,
      job_offer_name: properties.job_offer_name,
      facility_id: properties.facility_id,
      facility_name: properties.facility_name,
    });
  }

  isFacilityOrJobOfferSpecified() {
    return [
      this.getIn(['job_offer_id']) > 0,
      this.getIn(['facility_id']) > 0,
      this.getIn(['suggest', 'keyword']),
      this.getIn(['suggest', 'prefecture_id']) > 0,
      this.getIn(['suggest', 'job_category_id']) > 0,
    ].some(v => v);
  }

  isEdited() {
    return [
      this.isFacilityOrJobOfferSpecified(),
      this.note,
      this.facility_name,
      this.job_offer_name,
      this.member_name_or_id,
      !this.selection_status_ids.equals(properties.selection_status_ids),
      this.scheduled_interview_only,
    ].some(v => v);
  }

  createCache() {
    return this.set('cached', this);
  }

  restoreFromCache() {
    return this.merge({
      job_offer_id: this.cached.job_offer_id,
      job_offer_name: this.cached.job_offer_name,
      facility_id: this.cached.facility_id,
      facility_name: this.cached.facility_name,
      suggest: this.cached.suggest,
      member_name_or_id: this.cached.member_name_or_id,
      note: this.cached.note,
      scheduled_interview_only: this.cached.scheduled_interview_only,
      cached: properties.cached,
    });
  }
}
