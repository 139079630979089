import React, { FC, memo, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useRouteNavigate } from '../../../hooks/useRouter';
import interceptor from '@/utils/interceptor';

const ApiClientInterceptor: FC = memo(() => {
  const router = useRouteNavigate();
  // 子コンポーネントを読み込んだ際にAPIリクエストが実行されるため、interceptorを設定してから子コンポーネントを読み込む
  const [isAddInterceptor, setIsAddSetInterceptor] = useState(false);

  useEffect(() => {
    interceptor.addResponseErrorInterceptor(router);
    interceptor.addRequestInterceptor();
    setIsAddSetInterceptor(true);
  }, []);

  if (!isAddInterceptor) {
    return null;
  }

  return <Outlet />;
});


export default ApiClientInterceptor;
