import * as types from './types';

export const initialState = {
  promoted: false,
  permanent: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.INIT:
      return initialState;
    case types.SAVE:
      return {
        ...state,
        promoted: action.payload.date,
      };
    case types.SAVE_PERMANENTLY:
      return {
        ...state,
        permanent: action.payload.isPermanent,
      };
    default:
      return state;
  }
};
