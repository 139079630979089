const INIT = 'state/ducks/facility-job-offer-search-form/INIT';
const CHANGE_FIELD = 'state/ducks/facility-job-offer-search-form/CHANGE_FIELD';
const CHANGE_FROM_URL_QUERY = 'state/ducks/facility-job-offer-search-form/CHANGE_FROM_URL_QUERY';
const CHANGE_POSTING_STATUSES = 'state/ducks/facility-job-offer-search-form/CHANGE_POSTING_STATUSES';
const CHANGE_PAGE = 'state/ducks/facility-job-offer-search-form/CHANGE_PAGE';
const OVERWRITE_WITH_FACILITY_SEARCH_FORM = 'state/ducks/facility-job-offer-search-form/OVERWRITE_WITH_FACILITY_SEARCH_FORM';

export {
  INIT,
  CHANGE_FIELD,
  CHANGE_FROM_URL_QUERY,
  CHANGE_POSTING_STATUSES,
  CHANGE_PAGE,
  OVERWRITE_WITH_FACILITY_SEARCH_FORM,
};
