import { List } from 'immutable';
import { JOB_OFFER_STATUS } from '@/models/ducks/job-offer/constants';
import BaseJobOfferRecord from '@/models/ducks/shared/models/base-job-offer';

const properties = {
  id: undefined,
  facility_id: undefined,
  facility: undefined,
  job_category_id: undefined,
  status: JOB_OFFER_STATUS.NEW,
  salary_etc: '',
  appeal_title: '',
  appeal_body: '',
  job_content: '',
  required: '',
  preferred: '',
  office_hours_conditions: '',
  welfare_programs: '',
  process: '',
  video_url: '',
  job_title: '',
  indoorview_url: '',
  customer_images: new List(),
  features: new List(),
  job_offer_salaries: new List(),
  job_offer_unit_prices: new List(),
  next_status_key: '',
  closed_job_offer_reason: new List(),
  disapproved: false,
};

export default class JobOffer extends BaseJobOfferRecord(properties) {
  static convert(raw) {
    let jobOffer = new JobOffer(raw);

    jobOffer = jobOffer.update('features', raw.features);
    jobOffer = jobOffer.update('job_offer_unit_prices', raw.job_offer_unit_prices);
    jobOffer = jobOffer.update('customer_images', raw.customer_images);
    jobOffer = jobOffer.update('job_offer_salaries', raw.job_offer_salaries);

    return jobOffer;
  }
}
