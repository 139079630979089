import * as types from './types';
import SelectedImageList from './model';

export const initialState = new SelectedImageList();

const update = (state, action) => {
  const { image } = action.payload;
  const selected = state.images.toArray().map(item => item.id).includes(image.id);

  return !selected ? state.set('images', state.images.push(image)) : state.set('images', state.images.filter(item => item.id !== image.id));
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.INIT:
      return initialState;
    case types.UPDATE:
      return update(state, action);
    case types.REFRESH:
      return state.set('images', action.payload.imageList);
    default:
      return state;
  }
};
