import { Record, List } from 'immutable';

const properties = {
  id: '',
  facility_id: '',
  appeal_title: '',
  appeal_body: '',
  status: '',
  job_category_id: '',
  job_category_name: '',
  image_url: '',
  employment_types: new List(),
  job_offer_type: '',
  job_title: '',
  created_at: '',
  updated_at: '',
  disapproved: false,
};

const FacilityJobOfferRecord = Record(properties);
export default class FacilityJobOffer extends FacilityJobOfferRecord {
  update(key, value) {
    return this.set(key, value);
  }
}
