export const theme = {
  colors: {
    blue: {
      g50: '#F8FBFF',
      g100: '#EDF5FF',
      g200: '#CDE2FA',
      g300: '#D6DDE6',
      g400: '#92C1EB',
      g500: '#65A7E1',
      g600: '#2F88D7',
      g700: '#1772C2',
      g800: '#3C78C3',
      g900: '#3163A2',
    },
    blueGrey: {
      0: '#879195',
      50: '#F8FAFA',
      100: '#ECEFF1',
      200: '#CFD8DC',
      300: '#B0BEC5',
      400: '#78909C',
      500: '#455A64',
      600: '#37474F',
      700: '#28383F',
      800: '#1D2B33',
      900: '#14252E',
    },
    red: {
      g900: '#9E001A',
      g800: '#B4415A',
      g700: '#D12133',
      g600: '#E52538',
      g500: '#CD4B64',
      g400: '#F5495B',
      g300: '#F06573',
      g200: '#F58994',
      g150: '#FFD7D8',
      g100: '#FFC1C7',
      g75: '#FFD7D8',
      g50: '#FFEBEC',
    },
    grey: {
      g900: '#222222',
      g800: '#444444',
      g700: '#666666',
      g600: '#888888',
      g500: '#9E9E9E',
      g400: '#BDBDBD',
      g300: '#E0E0E0',
      g200: '#EEEEEE',
      g100: '#F5F5F5',
      g50: '#FAFAFA',
    },
    link: '#0F68D0',
  },
  font: {
    sizes: {
      xs: '10px',
      s: '12px',
      m: '14px',
      l: '16px',
      xl: '18px',
      xxl: '22px',
      inherit: 'inherit',
    },
    weight: {
      normal: 400,
      bold: 700,
      inherit: 'inherit',
    },
  },
  lineHeights: {
    xxxSmall: '12px',
    xxSmall: '15px',
    xSmall: '18px',
    small: '20px',
    medium: '22px',
    large: '24px',
    xLarge: '26px',
    xxLarge: '32px',
  },
  spaces: {
    five: '5px',
    ten: '10px',
    fifteen: '15px',
    twentyFive: '25px',
  },
};

export type AppTheme = typeof theme;
export type ThemeProps = {
  theme: AppTheme;
};

export type FontSize = keyof AppTheme['font']['sizes'];
export type FontWeight = keyof AppTheme['font']['weight'];
