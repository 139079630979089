import * as types from './types';
import MessageGroupSearchForm from '@/models/ducks/shared/models/message-group-search-form';

export const initialState = new MessageGroupSearchForm();

const updateMessageType = (state, action) => {
  const messageTypes = action.payload.value ?
    state.message_type.push(action.payload.key)
    :
    state.message_type.delete(state.message_type.findIndex((index) => {
      return index === action.payload.key;
    }));

  return state.update('message_type', messageTypes);
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.CREATE:
      return initialState;
    case types.RESTORE:
      return action.payload.cache;
    case types.UPDATE:
      return state.update(action.payload.key, action.payload.value);
    case types.UPDATE_MESSAGE_TYPE:
      return updateMessageType(state, action);
    default:
      return state;
  }
};
