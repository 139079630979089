import * as types from './types';
import Customer from '@/models/ducks/shared/models/customer';
import ImmutableHelper from '@/utils/immutable-helper';

export const initialState = new Customer();

const loadLeadCustomer = (state, action) => {
  const { lead } = action.payload;

  return state.merge({
    name: lead.lead_name || '',
    postal_code: lead.lead_zip_code || '',
    address_etc: lead.lead_address || '',
    tel: lead.lead_tel || '',
    email: lead.lead_email || '',
    in_charge_post: lead.lead_staff_post || '',
    in_charge_family_name: lead.lead_staff_name || '',
    lead_id: lead.id,
  });
};

const fillCustomerAddresses = (state, action) => {
  const { town } = action.payload;

  return state.merge({
    prefecture_id: town.prefecture_id,
    city_id: town.city_id,
  });
};

const fillCustomerInvoiceAddresses = (state, action) => {
  const { town } = action.payload;

  return state.merge({
    claim_prefecture_id: town.prefecture_id,
    claim_city_id: town.city_id,
  });
};

const addImages = (state, action) => {
  const { images } = action.payload;

  return state.merge({
    images: state.get('images').concat(images),
  });
};

const deleteImage = (state, action) => {
  const targetImage = action.payload.image;
  const images = state.get('images').filter(image => image.id !== targetImage.id);

  return state.update('images', images);
};

const updateImage = (state, action) => {
  const targetImage = action.payload.image;
  const newImages = state.get('images').map((image) => {
    if (image.id === targetImage.id) {
      return image.update('caption', targetImage.caption);
    }
    return image;
  });

  return state.update('images', newImages);
};

const setImageSize = (state, action) => {
  const { id, width, height } = action.payload;
  const newImages = state.get('images').map((image) => {
    if (image.id === id) {
      return image.merge({ width, height });
    }
    return image;
  });

  return state.update('images', newImages);
};

const sortImages = (state, action) => {
  const { oldIndex, newIndex } = action.payload;
  const newImages = ImmutableHelper.moveListItem(state.images, oldIndex, newIndex);

  return state.update('images', newImages);
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.CLEAR:
      return initialState;
    case types.UPDATE_FIELD:
      return state.update(action.payload.key, action.payload.value);
    case types.SAVE:
      return action.payload.customer;
    case types.CREATE:
      return new Customer(action.payload.customer);
    case types.LOAD_LEAD:
      return loadLeadCustomer(state, action);
    case types.FILL_ADDRESSES:
      return fillCustomerAddresses(state, action);
    case types.FILL_INVOICE_ADDRESSES:
      return fillCustomerInvoiceAddresses(state, action);
    case types.ADD_IMAGES:
      return addImages(state, action);
    case types.DELETE_IMAGE:
      return deleteImage(state, action);
    case types.UPDATE_IMAGE:
      return updateImage(state, action);
    case types.SET_IMAGE_SIZE:
      return setImageSize(state, action);
    case types.SORT_IMAGES:
      return sortImages(state, action);
    case types.CLEAR_INVOICE:
      return state.clearClaim();
    case types.NOOP:
    default:
      return state;
  }
};
