import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { RootState } from '@/state';
import { VersionState } from '@/models/ducks';
import { versionOperations } from '@/models/ducks/version';
import { withRouter } from '@/router/hocs/withRouter';
import OnUpdateRouter from '.';

export type StateProps = {
  version: VersionState;
}

export const mapStateToProps = (state: RootState): StateProps => ({
  version: state.version,
});

export const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => ({
  reloadIfNewVersionDetected: async () => {
    await dispatch(versionOperations.reloadIfNewVersionDetected());
  },
});

export type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OnUpdateRouter));
