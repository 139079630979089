import { Record, List } from 'immutable';
import FacilityJobOfferFacility from '@/models/ducks/shared/models/facility-job-offer-facility';

const properties = {
  count: 0,
  total: 0,
  limit: 0,
  page: 0,
  job_offer_total: 0,
  facilities: List([
  ]),
};

const FacilityJobOffersRecord = Record(properties);
export default class FacilityJobOffers extends FacilityJobOffersRecord {
  static convert(response) {
    const raw = response.data;
    const facilities = List(raw.facilities.map((facility) => {
      return FacilityJobOfferFacility.convert(facility);
    }));
    return new FacilityJobOffers({
      facilities,
      count: raw.count,
      total: raw.total,
      limit: raw.limit,
      page: raw.page,
      job_offer_total: raw.job_offer_total,
    });
  }

  update(key, value) {
    return this.set(key, value);
  }

  updateJobOffers(facilityIndex, newJobOffers) {
    return this.setIn(['facilities', facilityIndex, 'job_offers'], newJobOffers);
  }

  getOffset() {
    const {
      page,
      limit,
      facilities,
    } = this;

    return {
      start: (page * limit) - (limit - 1),
      end: (page * limit) - (limit - facilities.size),
    };
  }
}
