import { connect } from 'react-redux';

export const mapStateToProps = state => ({
  customer: state.customer,
  sideNavBadge: state.sideNavBadge,
  isOperator: state.operator.operator,
  isLoading: state.loader,
  deviceMode: state.deviceMode,
});

export const mapDispatchToProps = () => ({
});

export default connect(mapStateToProps, mapDispatchToProps);
