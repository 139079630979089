/*
これはオペレータ代理ログイン時にタイムゾーンを変更して表示するためのタイムゾーン変更を考慮した関数のモジュールです。
日時を扱う場合は原則こちらの関数を利用するようにしてください。
*/
import * as dateFns from 'date-fns';
import * as dateFnsTZ from 'date-fns-tz';
import i18next from 'i18next';

// 「代理ログインtimezone設定」
// オペレータ代理ログイン時のタイムゾーンの設定（PST以外に変えたい時はこの2つの定数を変える）
const PST_TIMEZONE = 'Etc/GMT+8';
const PST_ISO_TIMEZONE_FORMAT = '-08:00';


/**
 * オペレーターログイン時かどうかを判定する関数
 *
 * @return {boolean}
*/
const isOperatorLogin = (): boolean => {
  // storeのデータを利用すると複数の既存のreduxのテストが通らなくなるので、localStorageを利用する
  // （根本原因がわからないため暫定対応でこうした。読み込み順序の問題かも？）
  return localStorage.getItem('isOperatorLogin') === 'true';
};

/**
 * オペレーターログイン時にタイムゾーンをPSTに変更した現在時刻を返す関数
 *
 * @return {Date} クライアントのタイムゾーンの現在時刻
*/
const getClientCurrentDateTime = (): Date => {
  const now = new Date();
  if (isOperatorLogin()) {
    return dateFnsTZ.utcToZonedTime(now, PST_TIMEZONE);
  }
  return now;
};

/**
 * 渡されたDateと同一日で時刻が00:00:00.000のDateを返す
 * utc
 *
 * @param date
 * @returns {Date}
 */
const truncateTime = (date: Date) => {
  if (isOperatorLogin()) {
    const zonedDate = dateFnsTZ.utcToZonedTime(date, PST_TIMEZONE);
    const zonedDateISOString = dateFnsTZ.format(zonedDate, "yyyy-MM-dd'T'00:00:00.000xxx", { timeZone: PST_TIMEZONE });
    return new Date(zonedDateISOString);
  }
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
};

/**
 * オペレーターログイン時にタイムゾーンをPSTに変更してフォーマットする関数
 *
 *  - ブラウザで表示される時刻（Dateオブジェクトの表示）は、自動でブラウザのタイムゾーンに合わせた表示になる
 *  - そのため違うタイムゾーンで表示する場合は、タイムゾーンを変換する処理が必要になり、この関数がその役割を担う (代理ログイン時はPSTで表示する必要があるため、この関数を使用する)
 *
 * @param {string | Date} date
 *   string型の場合: ISO8601形式の日付文字列 ex) '2023-08-18T18:29:11.000-08:00'
 *                  RailsAPIからの日付のレスポンスはこの形式なので、created_atなどはこのdateの引数にそのまま渡せばOK
 *   Date型の場合: Dateオブジェクト ex) new Date()
 * @param {string} template
 *   date-fnsのformat関数の第2引数と同じ形式の文字列 ex) 'yyyy/MM/dd HH:mm:ss'
 * @return {string}
 *   フォーマットされたPST時刻での日付文字列 ex) 2023/08/18 18:29:11
*/
const format = (date: string | Date, template: string): string => {
  // DateオブジェクトをISO8601形式の日付文字列に変換する
  const dateString = typeof date === 'object' ? date.toISOString() : date;

  // 代理ログイン時は日付をPSTで表示する
  if (isOperatorLogin()) {
    const utcDate = dateFns.parseISO(dateString);
    const zonedDate = dateFnsTZ.utcToZonedTime(utcDate, PST_TIMEZONE);
    return dateFns.format(zonedDate, template);
  }

  // Employer自身のアクセス時はローカルタイムで表示する（date-fnsのライブラリは自動でローカルタイムゾーンに合わせた変換になるので追加の処理は不要）
  return dateFns.format(dateFns.parseISO(dateString), template);
};

/**
 * オペレータログイン時でもタイムゾーンに関係なくDateオブジェクトをフォーマットする関数
 * 用途としては、時間に関係なく日付のみをフォーマットしたいケース
 *
 * ex) '2023-01-01'の日付文字列を、'Jan 01, 2023'など別の表現に変えたい
 *      この時にformatの方の関数を使うと、タイムゾーンによっては日付がずれる（バグる）可能性があるのでこの関数を使う
 *     https://github.com/medley-inc/jobley-health/pull/2454#discussion_r1409130786
 * @param {string | Date} date
 *   string型の場合: ISO8601形式の日付文字列 ex) '2023-08-18' or '08/18/2023' のような日付の入力を想定
 *   Date型の場合: react-day-pickerのライブラリが使われている1箇所だけDate型として入ってきています。これは例外的な感じなので普段使用する際は上記のstring型だけ考えるので良さそうです。
 *                使用箇所: client/customers/js/containers/shared/desktop/containers/message-view/MessageFormItem/InterviewDatetimeList/InterviewDatetimeModal/Calendar.tsx の monthLabel関数
 * @param {string} template
*/
const formatWithoutChangingTimezone = (date: Date | string, template: string): string => {
  const targetDate = typeof date === 'string' ? new Date(date) : date;
  return dateFns.format(targetDate, template);
};

/**
 * タイムゾーンに合わせたDateオブジェクトを返す関数
 * new Date() との使い分けは、
 * - 引数がない場合は、timezone.newDate() を使う
 * - 引数があるときに時差によって日付が変わって適切な場合は timezone.newDate() を使う
 * - 引数があるときに時差によって日付や時間が変わるとおかしい場合は new Date() を使う
 *   ex) '2023-01-01'の日付をDateオブジェクトにしたいときは new Date('2023-01-01')
 *       後続でその月の日数を取得したい（getDate()）などの処理が続く場合が想定される
 *
 * ※ただしオペレーターログイン時のタイムゾーン表記（GMT+9:00の部分）はクライアントのタイムゾーンのままになっているので注意が必要。具体的にはこのnewDateで取得したDateオブジェクトでtoISOString()を使うと、時間がずれるバグとなるので使わないこと。
 * toISOString()を使う場合は、newDateISOString()を使うようにする。
 *
 * @param {string} dateISOString
 * @return {Date} PSTタイムゾーンのDateオブジェクト
*/
const newDate = (dateISOString: string | null = null): Date => {
  const targetDate = dateISOString === null ? new Date() : new Date(dateISOString);
  // 代理ログイン時はPST時刻のDateオブジェクトを返す
  if (isOperatorLogin()) {
    const utcDate = dateFns.parseISO(targetDate.toISOString());
    const zonedDate = dateFnsTZ.utcToZonedTime(utcDate, PST_TIMEZONE);
    return zonedDate;
  }
  // Employer自身のアクセス時はローカル時刻のDateオブジェクトを返す
  return targetDate;
};

/**
 * タイムゾーンに合わせた現在時刻のISO形式文字列を返す関数
 *
 * @return {string} クライアントのタイムゾーンのISO形式文字列 ex)'2023-08-18T18:29:11.000-08:00'
*/
const newDateISOString = (): string => {
  if (isOperatorLogin()) {
    const now = new Date();
    const zonedDate = dateFnsTZ.utcToZonedTime(now, PST_TIMEZONE);
    return dateFnsTZ.format(zonedDate, "yyyy-MM-dd'T'HH:mm:ss.000xxx", { timeZone: PST_TIMEZONE });
  }
  return new Date().toISOString();
};

/**
 *  タイムゾーンに合わせた本日の日付を返す
 *
 * @return {string} クライアントのタイムゾーンの日付文字列 ex)'2023-08-18'
 */
const getToday = (): string => {
  return format(newDateISOString(), 'yyyy-MM-dd');
};

/**
 * タイムゾーンに合わせた本日の日付かどうかの判定
 *
 * @param {Date} date
 * @returns {boolean}
 */
const isToday = (date: Date): boolean => {
  const zonedNow = getClientCurrentDateTime();
  return zonedNow.toDateString() === date.toDateString();
};

/**
 * タイムゾーンに合わせた時間の基準で、未来の時刻かどうかの判定
 *
 * @param {string | Date} date
 *   string型の場合: 日付文字列 ex) '2023-08-18 18:29'
 *   Date型の場合: Dateオブジェクト ex) new Date()
 * @returns {boolean}
 */
const isFuture = (date: Date | string): boolean => {
  const dateObj = typeof date === 'string' ? new Date(date) : date;
  // クライアントの現在時刻を取得
  const zonedNow = getClientCurrentDateTime();

  return zonedNow.getTime() < dateObj.getTime();
};

/**
 * タイムゾーンに合わせた時間の基準で、過去の時刻かどうかの判定
 *
 * @param {string | Date} date
 *   string型の場合: 日付文字列 ex) '2023-08-18 18:29'
 *   Date型の場合: Dateオブジェクト ex) new Date()
 * @returns {boolean}
 */
const isPast = (date: Date | string): boolean => {
  const dateObj = typeof date === 'string' ? new Date(date) : date;
  // クライアントの現在時刻を取得
  const zonedNow = getClientCurrentDateTime();

  return zonedNow.getTime() > dateObj.getTime();
};

/**
 * 過去 or 今日の日付かどうかを判定して返す
 *
 * @param {String} dateValue ハイフン区切りの日付
 * @return {Boolean}
 */
const isPastDay = (dateValue: string) => {
  const parsedDate = dateValue.split('-');
  const year = Number(parsedDate[0]);
  const month = Number(parsedDate[1]);
  const date = Number(parsedDate[2]);

  // クライアントの現在時刻を取得
  const zonedNow = getClientCurrentDateTime();

  const currentYear = zonedNow.getFullYear();
  const currentMonth = zonedNow.getMonth() + 1;
  const currentDate = zonedNow.getDate();

  if (year < currentYear) {
    return true;
  }

  if (year === currentYear) {
    if (month < currentMonth) {
      return true;
    }

    if (month === currentMonth) {
      if (date <= currentDate) {
        return true;
      }
    }
  }

  return false;
};

/**
 * タイムゾーンに合わせた月の基準で、今月かどうかの判定
 *
 * @param {string | Date} date 比較対象の時刻
 *   string型の場合: 日付文字列 ex) '2023-08-18 18:29'
 *   Date型の場合: Dateオブジェクト ex) new Date()
 * @returns {boolean}
 */
const isThisMonth = (date: Date | string): boolean => {
  const dateObj = typeof date === 'string' ? new Date(date) : date;
  const year = dateObj.getFullYear();
  const month = dateObj.getMonth() + 1;

  const zonedNow = getClientCurrentDateTime();
  const currentYear = zonedNow.getFullYear();
  const currentMonth = zonedNow.getMonth() + 1;

  return currentYear === year && currentMonth === month;
};

const isThisMonthByNum = (month: number): boolean => {
  const zonedNow = getClientCurrentDateTime();
  const currentMonth = zonedNow.getMonth() + 1;

  return currentMonth === month;
};

const isThisYearByNum = (year: number): boolean => {
  const zonedNow = getClientCurrentDateTime();
  const currentYear = zonedNow.getFullYear();

  return currentYear === year;
};

/**
 * タイムゾーンに合わせたISO8601形式の日時文字列を作成する
 *
 * @param {string} dateString 'YYYY-MM-DD'の形式の文字列
 * @param {number} hour 0-23の数値
 * @param {number} minutes 0-59の数値
 * @return {string} ISO8601形式の日付文字列 ex) '2023-08-18T18:29:11.000-08:00'
 */
const convertToISOStringByDateAndHourAndMinutes = (dateString: string, hour: number, minutes: number): string => {
  if (isOperatorLogin()) {
    const dateISOString = `${dateString}T${hour.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:00.000${PST_ISO_TIMEZONE_FORMAT}`; // 'Etc/GMT+8'が使えないので'-08:00'を直接入れている
    return dateISOString;
  }
  const datetime = new Date(`${dateString} 00:00:00`); // Date(dateString) だと Date('YYYY-MM-DD 00:00:00(UTC)') となるので、クライアントのTZにおけるDateになるように 00:00:00 を入れている
  datetime.setHours(hour, minutes);
  return datetime.toISOString();
};

/**
 * タイムゾーンに合わせたISO8601形式の日時文字列を作成する
 * convertToISOStringByDateAndHourAndMinutes関数とは引数が違うのみ
 *
 * @param {string} day 'YYYY-MM-DD'の形式の文字列
 * @param {string} time 'HH:mm:ss'の形式の文字列
 * @return {string} ISO8601形式の日付文字列 ex) '2023-08-18T18:29:11.000-08:00'
 */
const convertToISOStringByDayAndTime = (day: string, time: string): string => {
  if (isOperatorLogin()) {
    const dateISOString = `${day}T${time}.000${PST_ISO_TIMEZONE_FORMAT}`; // 'Etc/GMT+8'が使えないので'-08:00'を直接入れている
    return dateISOString;
  }
  const datetime = new Date(`${day} ${time}`);
  return datetime.toISOString();
};

/**
   * {n}ヶ月前か否かを判定して返す
   *
   * @param {Number} month 何ヶ月前かの値
   * @param {String} compared いつからかを指定する日時のISO8601形式の文字列
   * @return {Boolean}
   */
const isMonthsAgo = (month: number, compared: string) => {
  const now = newDate();
  const monthAgo = now.setMonth(now.getMonth() - month);
  const comparedDate = new Date(compared);
  const monthAgoDate = new Date(monthAgo);

  return monthAgoDate > comparedDate;
};

/**
 * 現在時刻からの差に応じてフォーマットした文字列を返す
 *
 * - 過去24時間以内の場合：{1..24}時間以内
 * - 24時間より過去 かつ 過去30日以内の場合：{1..30}日前
 * - 上記以外：MM/DD/YYYY
 * @param dateTimeVal 比較対象
 * @returns フォーマットした文字列
 */
const formatDistanceToNow = (dateTimeVal: string) => {
  const oneHour = 1000 * 60 * 60;
  const oneDay = oneHour * 24;

  // 相対時間を求めているので、timezone.newDate()を使わなくても問題ない（オペレータログインでも同じ結果になる）
  const now = new Date();
  const target = new Date(dateTimeVal);
  const distance = now.getTime() - target.getTime();

  if (distance < 0) {
    return format(dateTimeVal, 'MM/dd/yyyy');
  }

  // 24時間以内かどうかを求める
  let nHoursAgo = Math.ceil(distance / oneHour);
  if (nHoursAgo <= 24) {
    // 同一日時だと0になってしまうのでその場合は+1する
    nHoursAgo = nHoursAgo > 0 ? nHoursAgo : nHoursAgo + 1;
    return `${ i18next.t('date.hours_ago', { count: nHoursAgo, value: nHoursAgo }) }`;
  }

  // 30日以内かどうかを求める
  const daysDistance = truncateTime(now).getTime() - truncateTime(target).getTime();
  const nDaysAgo = Math.ceil(daysDistance / oneDay);
  if (nDaysAgo <= 30) {
    return `${ i18next.t('date.days_ago', { count: nDaysAgo, value: nDaysAgo }) }`;
  }

  return format(dateTimeVal, 'MM/dd/yyyy');
};


export {
  format,
  formatWithoutChangingTimezone,
  newDate,
  isToday,
  isFuture,
  isThisMonth,
  isThisMonthByNum,
  isThisYearByNum,
  isPast,
  isPastDay,
  convertToISOStringByDateAndHourAndMinutes,
  newDateISOString,
  getToday,
  convertToISOStringByDayAndTime,
  isOperatorLogin,
  isMonthsAgo,
  formatDistanceToNow,
};
