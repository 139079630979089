import * as types from './types';

export const initialState = {
  items: [],
  total: 0,
  page: 1,
  count: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.INIT:
      return {
        items: action.payload.suggest.items,
        count: action.payload.suggest.total,
        page: action.payload.suggest.page,
        total: action.payload.suggest.total,
      };
    case types.GET:
      return {
        items: action.payload.suggest.items,
        count: action.payload.suggest.total,
        page: action.payload.suggest.page,
        total: state.total,
      };
    case types.GET_MORE:
      return {
        items: state.items.concat(action.payload.suggest.items),
        count: action.payload.suggest.total,
        page: action.payload.suggest.page,
        total: state.total,
      };
    case types.INIT_FOR_SCOUT:
      return {
        items: action.payload.suggest.items,
        count: action.payload.suggest.total,
        page: action.payload.suggest.page,
        total: action.payload.suggest.total,
      };
    case types.GET_FOR_SCOUT:
      return {
        items: action.payload.suggest.items,
        count: action.payload.suggest.total,
        page: action.payload.suggest.page,
        total: state.total,
      };
    case types.GET_MORE_FOR_SCOUT:
      return {
        items: state.items.concat(action.payload.suggest.items),
        count: action.payload.suggest.total,
        page: action.payload.suggest.page,
        total: state.total,
      };
    case types.CLEAR:
      return initialState;
    case types.INIT_WITH_FACILITY:
      return {
        items: action.payload.suggest.items,
        count: action.payload.suggest.total,
        page: action.payload.suggest.page,
        total: action.payload.total,
      };
    case types.GET_WITH_FACILITY:
      return {
        items: action.payload.suggest.items,
        count: action.payload.suggest.total,
        page: action.payload.suggest.page,
        total: state.total,
      };
    case types.GET_MORE_WITH_FACILITY:
      return {
        items: state.items.concat(action.payload.suggest.items),
        count: action.payload.suggest.total,
        page: action.payload.suggest.page,
        total: state.total,
      };
    default:
      return state;
  }
};
