import * as types from './types';

const show = () => {
  return {
    type: types.SHOW,
  };
};

const hide = () => {
  return {
    type: types.HIDE,
  };
};

const updateContent = (title, body) => {
  return {
    type: types.UPDATE_CONTENT,
    payload: {
      title,
      body,
    },
  };
};

export {
  show,
  hide,
  updateContent,
};
