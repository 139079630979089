import * as actions from './actions';
import MessageApi from '@/models/ducks/shared/api/message';
import JobOfferApi from '@/models/ducks/shared/api/job-offer';

const show = () => {
  return dispatch => Promise.all([
    MessageApi.scoutSent(),
    JobOfferApi.published(),
  ]).then((responses) => {
    const isScoutSent = responses[0].data.scout_sent;
    const isJobOfferPublished = responses[1].data.data.job_offers.length > 0;
    const isPromotionNeeded = !isScoutSent && isJobOfferPublished;

    if (isPromotionNeeded) {
      return dispatch(actions.show());
    }
    return dispatch(actions.hide());
  });
};

const hide = () => {
  return dispatch => dispatch(actions.hide());
};

export {
  show,
  hide,
};
