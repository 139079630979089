import * as types from './types';
import Version from './model';

export const initialState = new Version();

export default (state = initialState, action) => {
  switch (action.type) {
    case types.CHECK:
    case types.SAVE:
      return action.payload.version;
    default:
      return state;
  }
};
