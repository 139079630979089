import * as actions from './actions';
import ScoutedMembersApi from './api';

const get = (pagination) => {
  return dispatch => ScoutedMembersApi.search(pagination)
    .then((response) => {
      return dispatch(actions.get(response));
    });
};

const getMore = (pagination) => {
  return dispatch => ScoutedMembersApi.search(pagination)
    .then((response) => {
      return dispatch(actions.getMore(response));
    });
};

export {
  get,
  getMore,
};
