import * as actions from './actions';

const show = () => {
  return dispatch => dispatch(actions.show());
};

const hide = () => {
  return dispatch => dispatch(actions.hide());
};

const updateContent = (title, body) => {
  return dispatch => dispatch(actions.updateContent(title, body));
};

export {
  show,
  hide,
  updateContent,
};
