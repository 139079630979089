import { format } from 'date-fns';
import * as timezone from '@/utils/timezone';
import { UpdatePreferredInterviewDatetimeListAction } from '@/models/ducks/message-send-form/actions-typed';
import { DucksState } from '@/models/ducks';

const convertToISOFormatDatetimeList = (datetimeList: UpdatePreferredInterviewDatetimeListAction['payload']['datetimeList']): string[] => {
  return datetimeList.reduce((accum, { date, times }) => {
    if (times.length === 0) {
      return accum;
    }

    const formattedDate = timezone.formatWithoutChangingTimezone(date, 'yyyy-MM-dd');
    const formatted = times.map(({ hour, minutes }) => {
      return timezone.convertToISOStringByDateAndHourAndMinutes(formattedDate, hour, minutes);
    });

    return accum.concat(formatted);
  }, [] as string[]);
};

export const updatePreferredInterviewDatetimeList = (state: DucksState['messageSendForm'], payload: UpdatePreferredInterviewDatetimeListAction['payload']): DucksState['messageSendForm'] => {
  const newErrors = { ...state.errors };
  delete newErrors.preferred_interview_datetime_list;
  const datetimeList = convertToISOFormatDatetimeList(payload.datetimeList);

  return state.update('errors', newErrors).update('preferred_interview_datetime_list', datetimeList);
};
