import React, { FC } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import loadable from 'loadable-components';
import { connect } from 'react-redux';
import { trackPromise } from 'react-promise-tracker';
import { versionOperations } from '@/models/ducks/version';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const loadPage = (page: () => Promise<any>) => loadable(() => trackPromise(page()));

const Signin = loadPage(() => import('../containers/signin' /* webpackChunkName: './containers/signin' */));
const Signout = loadPage(() => import('../containers/signout' /* webpackChunkName: './containers/signout' */));
const Home = loadPage(() => import('../containers/home' /* webpackChunkName: './containers/home' */));
const Facility = loadPage(() => import('../containers/facility/container' /* webpackChunkName: './containers/facility' */));
const PostJob = loadPage(() => import('../containers/post-job/container' /* webpackChunkName: './containers/post-job' */));
const MessageTemplates = loadPage(() => import('../containers/message-templates/container' /* webpackChunkName: './containers/message-templates' */));
const Selections = loadPage(() => import('../containers/selections' /* webpackChunkName: './containers/selections' */));
const Messages = loadPage(() => import('../containers/messages' /* webpackChunkName: './containers/messages' */));
const CustomerNew = loadPage(() => import('../containers/customer/new' /* webpackChunkName: './containers/customer/new' */));
const CustomerEdit = loadPage(() => import('../containers/customer/edit/container' /* webpackChunkName: './containers/customer/edit' */));
const FacilityList = loadPage(() => import('../containers/facility-list/container' /* webpackChunkName: './containers/facility-list' */));
const ImagesManagers = loadPage(() => import('../containers/images-managers/container' /* webpackChunkName: './containers/images-managers' */));
const Searches = loadPage(() => import('../containers/searches' /* webpackChunkName: './containers/searches' */));
const JobOffer = loadPage(() => import('../containers/job-offer/job-medley/container' /* webpackChunkName: './containers/job-offer/job-medley' */));
const ResetForm = loadPage(() => import('../pages/ResetForm' /* webpackChunkName: './pages/ResetForm' */));
const EmailEdit = loadPage(() => import('../pages/EmailEdit' /* webpackChunkName: './pages/EmailEdit' */));
const NotificationEdit = loadPage(() => import('../containers/notification-edit/container' /* webpackChunkName: './containers/notification-edit' */));
const PasswordEdit = loadPage(() => import('../containers/password-edit' /* webpackChunkName: './containers/password-edit' */));
const ServerError = loadPage(() => import('../pages/500' /* webpackChunkName: './pages/500' */));
const NotFoundError = loadPage(() => import('../pages/404' /* webpackChunkName: './pages/404' */));
const Password = loadPage(() => import('../containers/password/container' /* webpackChunkName: './containers/password' */));
const Announcements = loadPage(() => import('../pages/Announcements' /* webpackChunkName: './pages/Announcements' */));
const CustomerInvitation = loadPage(() => import('../containers/customer/invitation' /* webpackChunkName: './containers/customer/invitation' */));

import ApiClientInterceptor from '@/router/components/api-client-interceptor';
import OnUpdateRouter from './components/on-update-router/container';
import AuthenticatedRoute from './components/authenticated-route/container';
import CheckLogin from './components/check-login/container';

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  return {
    saveCurrentVersion: () => {
      dispatch(versionOperations.save());
    },
  };
};

export type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = DispatchProps

const AppRouter: FC<Props> = ({ saveCurrentVersion }) => {
  saveCurrentVersion();

  return (
    <BrowserRouter>
      <Routes>
        <Route element={ <ApiClientInterceptor /> }>
          <Route element={ <AuthenticatedRoute /> }>
            <Route element={ <OnUpdateRouter /> }>

              <Route path="/" element={ <Home /> } />

              <Route path="customers">
                <Route path="sign_in" element={ <Signin /> } />
                <Route path="sign_out" element={ <Signout /> } />
                <Route element={ <CheckLogin /> }>
                  <Route path="new" element={ <CustomerNew /> } />
                </Route>
                <Route path="edit" element={ <CustomerEdit /> } />
                <Route path="reset_form" element={ <ResetForm /> } />
                <Route path="announcements" element={ <Announcements /> } />
                <Route path="invitations" element={ <CustomerInvitation /> } />

                <Route path="post_job">
                  <Route index element={ <PostJob /> } />
                  <Route path="register_facility" element={ <Facility /> } />
                  <Route path="facility/:facility_id" element={ <JobOffer /> } />
                </Route>

                <Route path="facilities">
                  <Route index element={ <FacilityList /> } />
                  <Route path="new" element={ <Facility /> } />
                  <Route path=":id/edit" element={ <Facility /> } />
                </Route>

                <Route path="job_offers">
                  <Route path="new" element={ <JobOffer /> } />
                  <Route path=":id/edit" element={ <JobOffer /> } />
                </Route>

                <Route path="images_managers" element={ <ImagesManagers /> } />
                <Route path="searches" element={ <Searches />} />
                <Route path="selections" element={ <Selections /> } />
                <Route path="messages" element={ <Messages /> } />
                <Route path="messages/detail" element={ <Messages /> } />
                <Route path="email_edit" element={ <EmailEdit /> } />
                <Route path="notification_edit" element={ <NotificationEdit />} />
                <Route path="password" element={ <Password /> } />
                <Route path="message_templates" element={ <MessageTemplates /> } />

                <Route path="500" element={ <ServerError /> } />
                <Route path="404" element={ <NotFoundError /> } />
              </Route>

              <Route path="api/customers/auth/password/edit" element={ <PasswordEdit /> } />
            </Route>
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default connect(() => ({}), mapDispatchToProps)(AppRouter);
