import * as types from './types';

export const initialState = 0;

export default (state = initialState, action) => {
  switch (action.type) {
    case types.INIT:
      return initialState;
    case types.UPDATE:
      return action.payload.index;
    default:
      return state;
  }
};
