import * as types from './types';

const init = () => {
  return {
    type: types.INIT,
  };
};

const get = (response) => {
  return {
    type: types.GET,
    payload: {
      response,
    },
  };
};

const getMore = (response) => {
  return {
    type: types.GET_MORE,
    payload: {
      response,
    },
  };
};

const markAsNew = (unreadMemberIds) => {
  return {
    type: types.MARK_AS_NEW,
    payload: {
      unreadMemberIds,
    },
  };
};

const markAsScouted = (scoutedMemberIds) => {
  return {
    type: types.MARK_AS_SCOUTED,
    payload: {
      scoutedMemberIds,
    },
  };
};

export {
  init,
  get,
  getMore,
  markAsNew,
  markAsScouted,
};
