import { Record } from 'immutable';

const properties = {
  current_password: '',
  password: '',
  password_confirmation: '',
};

const PasswordFormRecord = Record(properties);
export default class PasswordForm extends PasswordFormRecord {
  update(key, value) {
    return this.set(key, value);
  }
}
