import SETTINGS from '@/models/ducks/shared/constants/settings';

const initialStatusMap = {
  user: '',
  mode: '',
  current_status: 0,
  preview_visible: false,
  save_button_visible: false,
  save_button_text: '',
  save_dialog_title: '',
  save_dialog_content: '',
  save_api_entry: '',
  save_after_status: 0,
  confirm_button_visible: false,
  confirm_button_text: '',
  confirm_dialog_title: '',
  confirm_dialog_content: '',
  confirm_api_entry: '',
  confirm_after_status: 0,
  confirm_notice_dialog: '',
  confirm_notice_dialog_title: '',
  confirm_notice_dialog_content: '',
  edit_button_visible: false,
  close_button_visible: false,
  close_after_status: '',
  delete_button_visible: false,
  delete_after_status: '',
  apply_publish_button_visible: false,
  after_apply_publish_status: '',
  apply_publish_button_visible_after_save_text: '',
  publish_button_visible: false,
  after_publish_status: '',
  desired_revise_button_visible: false,
  desired_revise_button_after_save_text: '',
  confirmation_button_type: '',
  back_to_draft_visible: false,
  back_to_revise_visible: false,
  after_revise_status: '',
};

const getJobOfferStatusMap = (currentJobOfferStatus) => {
  if (currentJobOfferStatus === undefined) {
    return initialStatusMap;
  }

  return SETTINGS.JOB_OFFER_STATUS_MAP.find((item) => {
    return [item.current_status === currentJobOfferStatus].every(v => v);
  });
};

const getInitialJobOfferStatusMap = () => {
  return initialStatusMap;
};

export {
  getJobOfferStatusMap,
  getInitialJobOfferStatusMap,
};
