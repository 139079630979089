import reducers from './reducers';
import * as messageTemplateOperations from './operations';
import * as messageTemplateTypes from './types';

export {
  messageTemplateOperations,
  messageTemplateTypes,
};

export default reducers;
