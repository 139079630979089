import { List, Map } from 'immutable';
import * as types from './types';

export const initialState = List();

const syncSize = (areas) => {
  return areas.map((v) => {
    const prefId = Number(v.get('prefecture_id'));

    return Map({
      id: prefId,
      isHidden: true,
    });
  });
};

const sync = (state, areas) => {
  return areas.map((v) => {
    const prefId = Number(v.get('prefecture_id'));
    const index = state.findIndex(item => Number(item.get('id')) === prefId);
    const isChecked = v.get('city_ids').size > 0;
    const isHidden = () => {
      if (!state.getIn([index, 'isHidden'])) {
        return false;
      }
      return index < 0 || state.getIn([index, 'isHidden']) || isChecked;
    };

    return Map({
      id: prefId,
      isHidden: isHidden(),
    });
  });
};

const toggle = (state, id) => {
  return state.map((item) => {
    if (Number(item.get('id')) === Number(id)) {
      return Map({
        id,
        isHidden: !item.get('isHidden'),
      });
    }

    return item;
  });
};

const updateId = (state, action) => {
  return state
    .setIn([action.payload.index, 'id'], action.payload.newId)
    .setIn([action.payload.index, 'isHidden'], true);
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.INIT:
      return initialState;
    case types.SYNC_SIZE:
      return syncSize(action.payload.areas);
    case types.SYNC:
      return sync(state, action.payload.areas);
    case types.TOGGLE:
      return toggle(state, action.payload.id);
    case types.UPDATE_ID:
      return updateId(state, action);
    default:
      return state;
  }
};
