import { getType } from 'typesafe-actions';
import * as actions from './actions';
import { reduceWithImmer } from '@/reducer';

export const initialState = {
  isAnnouncementModalHidden: true,
  announcementIds: [] as number[],
  selectedAnnouncementId: 0,
};

export type AnnouncementsState = typeof initialState;

export const reducer = (state: AnnouncementsState = initialState, action: actions.AnnouncementsActionTypes): AnnouncementsState => {
  return reduceWithImmer(state, (draft) => {
    switch (action.type) {
      case getType(actions.init):
        return initialState;
      case getType(actions.fetchAnnouncements):
        draft.announcementIds = action.payload.announcementIds;
        break;
      case getType(actions.showAnnouncementDetail):
        draft.selectedAnnouncementId = action.payload.id;
        draft.isAnnouncementModalHidden = false;
        break;
      case getType(actions.hideAnnouncementDetail):
        draft.selectedAnnouncementId = initialState.selectedAnnouncementId;
        draft.isAnnouncementModalHidden = initialState.isAnnouncementModalHidden;
        break;
      default:
        return draft;
    }
    return draft;
  });
};
