import { ThunkDispatch } from '@reduxjs/toolkit';
import { PreferredInterviewDatetime } from '@/models/ducks/message-send-form/actions-typed';
import * as actions from './actions';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Dispatch = ThunkDispatch<any, any, any>;

export const updatePreferredInterviewDatetimeList = (datetimeList: PreferredInterviewDatetime[]) => {
  return (dispatch: Dispatch) => dispatch(actions.updatePreferredInterviewDatetimeList({ datetimeList }));
};
