import reducers from './reducers';
import * as receivedFavoriteListOperations from './operations';
import * as receivedFavoriteListTypes from './types';

export {
  receivedFavoriteListOperations,
  receivedFavoriteListTypes,
};

export default reducers;
