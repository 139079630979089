import { List } from 'immutable';
import Facility from '@/models/ducks/shared/models/facility';
import * as types from './types';

export const initialState = {
  facilities: new List(),
  total: 0,
  count: 0,
  limit: 10,
};

const get = (state, action) => {
  const { facilities, total, count } = action.payload;

  return {
    facilities: List(facilities.map((facility) => {
      return new Facility(facility);
    })),
    total,
    count,
    limit: state.limit,
  };
};

const getMore = (state, action) => {
  const facilities = List(action.payload.facilities.map((faclity) => {
    return new Facility(faclity);
  }));

  return {
    ...state,
    facilities: state.facilities.concat(facilities),
  };
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET:
      return get(state, action);
    case types.GET_MORE:
      return getMore(state, action);
    case types.NOOP:
    default:
      return state;
  }
};
