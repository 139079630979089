// react-routerのバージョンアップにより、hooksの名前が替わった際にこのファイルを修正するだけで
// 済むようにreact-routerのhooksをラップする
// react-router v3 v4 v5 v6と1上がるごとに破壊的変更が入っており、使用方法やhooks名が変更されている

import { useLocation, useNavigate, useParams } from 'react-router-dom';

const useRouteNavigate = () => {
  const router = useNavigate();
  return router;
};

const useRouteLocation = () => {
  const location = useLocation();
  return location;
};

const useRouteParams = () => {
  const params = useParams();
  return params;
};

export {
  useRouteNavigate,
  useRouteLocation,
  useRouteParams,
};
