import reducers from './reducers';
import * as searchesMenuOperations from './operations';
import * as searchesMenuTypes from './types';

export {
  searchesMenuOperations,
  searchesMenuTypes,
};

export default reducers;
