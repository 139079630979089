import reducers from './reducers';
import * as sideNavBadgeOperations from './operations';
import * as sideNavBadgeTypes from './types';

export {
  sideNavBadgeOperations,
  sideNavBadgeTypes,
};

export default reducers;
