import reducers from './reducers';
import * as facilityOperations from './operations';
import * as facilityTypes from './types';

export {
  facilityOperations,
  facilityTypes,
};

export default reducers;
