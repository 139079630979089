const TEXT = {
  DATE_YEAR_AD: 'models.constants.shared.text.date_year_ad',
  DATE_MONTH: 'models.constants.shared.text.date_month',
  DATE_DATE: 'models.constants.shared.text.date_date',
  EMPLOYMENT_STATUS: 'models.constants.shared.text.employment_status',
  EMPLOYMENT_STATUS_AND_SALARY: 'models.constants.shared.text.employment_status_and_salary',
  EMPLOYMENT_STATUS_SELECTION: 'models.constants.shared.text.employment_status_selection',
  MESSAGE_TEMPLATES_HELP_TITLE: 'models.constants.shared.text.message_templates_help_title',
  MESSAGE_TEMPLATES_HELP_BODY: 'models.constants.shared.text.message_templates_help_body',
  PROFILE_JOB_DESCRIPTION: 'models.constants.shared.text.profile_job_description',
  PASSWORD_RESET_SUCCESS: 'models.constants.shared.text.password_reset_success',
  PASSWORD_RESET_FAILURE: 'models.constants.shared.text.password_reset_failure',
  PROFILE_BLANK: 'common.blank',
} as const;

export default TEXT;
