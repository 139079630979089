import { all, fork } from 'redux-saga/effects';
import { watchAnnouncements } from '@/pages/Announcements/saga';
import { watchEmailEdit } from '@/pages/EmailEdit/saga';
import { watchResetForm } from '@/pages/ResetForm/saga';

function* rootSaga(): Generator {
  yield all([
    fork(watchAnnouncements),
    fork(watchEmailEdit),
    fork(watchResetForm),
  ]);
}

export {
  rootSaga,
};
