import * as types from './types';

const search = (response) => {
  return {
    type: types.SEARCH,
    payload: {
      messages: response.data.messages,
      page: response.data.page,
      total: response.data.total,
    },
  };
};

const searchMore = (response) => {
  return {
    type: types.SEARCH_MORE,
    payload: {
      messages: response.data.messages,
      page: response.data.page,
      total: response.data.total,
    },
  };
};

const updateMessage = (messages, jobOfferId, memberId) => {
  return {
    type: types.UPDATE_ITEM,
    payload: {
      messages,
      jobOfferId,
      memberId,
    },
  };
};

const markAsRead = (response) => {
  return {
    type: types.MARK_AS_READ,
    payload: {
      id: response.data.message.id,
    },
  };
};

const noop = () => {
  return {
    type: types.NOOP,
  };
};

export {
  search,
  searchMore,
  updateMessage,
  markAsRead,
  noop,
};
