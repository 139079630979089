import axios from 'axios';

export default {
  getSelections(condition) {
    return axios
      .post('/api/customers/selections/search', {
        selection_status_ids: condition.selection_status_ids.toJS(),
        member_name_or_id: condition.member_name_or_id,
        note: condition.note,
        job_offer_id: condition.job_offer_id * 1,
        facility_id: condition.facility_id * 1,
        scheduled_interview_only: condition.scheduled_interview_only,
        pagination: {
          limit: condition.getIn(['pagination', 'limit']) || 10,
          page: condition.getIn(['pagination', 'page']) || 1,
        },
      });
  },

  update(params, currentSelectionStatus) {
    return axios
      .post('/api/customers/selections/v2/update/', {
        selection: params,
        current_selection_status: currentSelectionStatus,
      });
  },

  markRead(selectionId) {
    return axios.put(`/api/customers/selections/mark_read/${selectionId}/`);
  },

  markReadByApplication(applicationId) {
    return axios.post('/api/customers/selections/mark_read_by_application/', { application_id: applicationId });
  },

  createLabel(condition) {
    return axios.post('/api/customers/selections/label', {
      note: condition.note,
      member_name_or_id: condition.member_name_or_id,
      job_offer_name: condition.job_offer_name,
      facility_name: condition.facility_name,
    });
  },

  show(id) {
    return axios.get(`/api/customers/selections/${id}`);
  },
};
