import axios from 'axios';

export default {
  all() {
    return axios.get('/api/customers/features/');
  },

  search(condition) {
    return axios.get('/api/customers/features/search/', { params: condition });
  },
};
