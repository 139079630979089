import ImageApi from '@/models/ducks/shared/api/image';
import * as actions from './actions';

const get = (condition) => {
  return dispatch => ImageApi.search(condition)
    .then((response) => {
      return dispatch(actions.get(response.data.images));
    });
};

const changeCaption = (caption, imageId) => {
  return dispatch => dispatch(actions.changeCaption(caption, imageId));
};

const update = (image) => {
  return dispatch => ImageApi.update(image).then((response) => {
    return dispatch(actions.update(response));
  });
};

export {
  get,
  changeCaption,
  update,
};
