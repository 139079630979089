import reducers from './reducers';
import * as promotedOperations from './operations';
import * as promotedSelectors from './selectors';
import * as promotedTypes from './types';

export {
  promotedOperations,
  promotedSelectors,
  promotedTypes,
};

export default reducers;
