import * as types from './types';
import JobOffer from '@/models/ducks/shared/models/job-offer';

export const initialState = new JobOffer().appeal_body;

export default (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE:
      return action.payload.value;
    default:
      return state;
  }
};
