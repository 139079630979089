import React from 'react';
import PropTypes from 'prop-types';
import SVGInline from 'react-svg-inline';
import { Link } from 'react-router-dom';
import routerHelper from '@/utils/router';

const logo = require('raw-loader!../../../../img/logo.svg');

export default class Logo extends React.PureComponent {

  static propTypes = {
    href: PropTypes.string.isRequired,
    query: PropTypes.object,
  }

  static defaultProps = {
    href: '',
  }

  render() {
    // TODO 動いていない。HOME画面を表示している時にロゴをクリックした場合、HOME画面のURLは/であり、isActiveLinkの中で/を取り除いてしまうため、
    // isActiveLinkはfalseとなってしまう
    const isActiveLink = routerHelper.isActiveLink(this.props);
    const query = isActiveLink
      ? 'activelink=true'
      : this.props.query;

    return (
      <div className="c-logo">
        <Link
          to={
            {
              pathname: this.props.href,
              search: query,
            }
          }
          className="c-logo__image"
        >
          <SVGInline svg={ logo } width="126" height="31" />
        </Link>
      </div>
    );
  }
}
