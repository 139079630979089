import * as types from './types';

const update = (badge) => {
  return {
    type: types.UPDATE,
    payload: {
      badge,
    },
  };
};

export {
  update,
};
