import axios from 'axios';

export default {
  messages(params) {
    return axios.post('/api/customers/badge/messages/', {
      keyword: params.keyword,
      ignore_deleted_member: params.ignore_deleted_member,
      unread: params.unread,
      not_replied: params.unreplied,
      message_type: params.message_type.map(item => item * 1),
      job_offer_id: params.job_offer_id,
      member: params.member,
      member_id: params.member_id,
    });
  },
};
