import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isMobileOnly } from 'react-device-detect';

export default class Main extends React.Component {

  static propTypes = {
    singleColumn: PropTypes.bool,
    className: PropTypes.string,
    children: PropTypes.node,
  }

  static defaultProps = {
    singleColumn: false,
  }

  render() {
    const mainClass = classNames({
      'o-main': true,
      'o-main--with-sidebar': !this.props.singleColumn,
      'o-main--single-column': this.props.singleColumn,
      'o-main--with-mobile': isMobileOnly,
      [`${ this.props.className }`]: this.props.className,
    });

    return (
      <main className={ mainClass }>
        { this.props.children }
      </main>
    );
  }
}
