import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { StickyContainer } from 'react-sticky';
import { usePromiseTracker } from 'react-promise-tracker';
import connect from './container';
import LoaderView from '@/components/shared/loader-view';
import Desktop from './desktop';
import { useRouteLocation, useRouteNavigate } from '@/hooks/useRouter';

const usePreventContinuousRequest = ({ isLoading }) => {
  // ボタンやリンクの連打によってサーバ負荷が高い処理が連続して行われるケースがあるので、サーバ通信中とみなせる状態のときは一度アクティブな要素からfocusを外すようにしておく
  useEffect(() => {
    const activeEl = document.activeElement;
    if (!activeEl) {
      return;
    }
    if (typeof activeEl.blur !== 'function') {
      return;
    }
    if (isLoading && activeEl.nodeName !== 'BODY') {
      activeEl.blur();
    }
  }, [isLoading]);
};

const AppShell = ({
  children, isLoading, onInit,
}) => {
  // ローディング画面でちらつきが起こるため、ローディング画面を表示するタイミングを遅らせる。
  // see https://lemoncode.github.io/react-promise-tracker/docs/api#:~:text=of%20the%20screen).-,delay,-%3A
  const { promiseInProgress } = usePromiseTracker({ delay: 500 });

  const router = useRouteNavigate();
  const location = useRouteLocation();

  usePreventContinuousRequest({ isLoading: !!isLoading || promiseInProgress });

  useEffect(() => {
    onInit({ router, location });
  }, []);

  return (
    <StickyContainer>
      <Desktop
        location={ location }
        router={ router }
      >
        { children }
      </Desktop>
      {
        // TODO
        //  - 各画面の状態管理整理が完了したら不要になったisLoadingは削除する
        (promiseInProgress || isLoading) ? <LoaderView isLoading={ 1 } /> : null
      }
    </StickyContainer>
  );
};

AppShell.propTypes = {
  children: PropTypes.node,
  isLoading: PropTypes.number,
  onInit: PropTypes.func,
};

export default connect(AppShell);
