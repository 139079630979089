import * as types from './types';

const init = () => {
  return {
    type: types.INIT,
  };
};

const create = (selection) => {
  return {
    type: types.CREATE,
    payload: {
      selection,
    },
  };
};

const change = (key, value) => {
  return {
    type: types.CHANGE,
    payload: {
      key,
      value,
    },
  };
};

const updateToHiringDone = () => {
  return {
    type: types.UPDATE_TO_HIRING_DONE,
  };
};

export {
  init,
  create,
  change,
  updateToHiringDone,
};
