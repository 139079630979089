import * as types from './types';
import ImagesSearchForm from './model';

export const initialState = new ImagesSearchForm();

export default (state = initialState, action) => {
  switch (action.type) {
    case types.INIT:
      return initialState;
    case types.CHANGE_FIELD:
      return state.update(action.payload.key, action.payload.value);
    case types.CHANGE_PAGE:
      return state.setIn(['pagination', 'page'], action.payload.page);
    case types.SET_SUGGESTION_FACILITY_ID:
      return state.merge({
        facility_id: action.payload.suggestion.id,
        job_offer_id: '',
      });
    case types.SET_SUGGESTION_JOB_OFFER_ID:
      return state.merge({
        job_offer_id: action.payload.suggestion.id,
        facility_id: '',
      });
    case types.INIT_SUGGESTION:
      return state.merge({
        facility_id: null,
        job_offer_id: null,
        job_offer_name: '',
      });
    default:
      return state;
  }
};
