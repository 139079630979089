import { List } from 'immutable';
import UnreadMember from './models/members';
import UnreadMemberList from './model';
import * as types from './types';

export const initialState = new UnreadMemberList();

const add = (state, action) => {
  const { type, id, memberIds } = action.payload;
  const itemIndex = state.items.findIndex(condition => condition.type === type && condition.id === id);

  if (itemIndex === -1) {
    return state.addItem(new UnreadMember({
      type,
      id,
      memberIds: new List(memberIds),
    }));
  }
  return state;
};

const removeMemberIds = (state, action) => {
  const { type, id, memberIds } = action.payload;
  const itemIndex = state.items.findIndex(condition => condition.type === type && condition.id === id);

  if (itemIndex > -1) {
    const newItem = state.items.get(itemIndex).removeMemberIds(memberIds);
    return state.updateItem(itemIndex, newItem);
  }
  return state;
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.INIT:
      return initialState;
    case types.ADD:
      return add(state, action);
    case types.REMOVE_MEMBER_IDS:
      return removeMemberIds(state, action);
    default:
      return state;
  }
};
