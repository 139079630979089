import * as actions from './actions';
import MessageApi from '@/models/ducks/shared/api/message';

const create = (params) => {
  return dispatch => MessageApi.createLabel(params)
    .then((response) => {
      return dispatch(actions.create(response.data.label));
    });
};

export {
  create,
};
