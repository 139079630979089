import browserHelper from '@/utils/browser';
import deviceHelper from '@/utils/device';

// JMにおいてサポート対象外 or 推奨されてない環境(ブラウザ、デバイス)かどうかを判定するメソッド群
//  `isNotSupported`: サポート対象外 => 顧客に対して利用することを推奨しない、かつ、動作保証しない
//  `isNotRecommended`: 推奨されてない => 顧客に対して利用することを推奨はしない
export default {

  isNotSupportedBrowser() {
    return browserHelper.isIE9() || browserHelper.isLessThanIE8() || browserHelper.isIE10();
  },

  isNotSupportedDevice() {
    return false;
  },

  isNotSupportedEnv() {
    return this.isNotSupportedBrowser() || this.isNotSupportedDevice();
  },

  isSupportedEnv() {
    return !this.isNotSupportedEnv();
  },

  isNotRecommendedDevice() {
    return deviceHelper.isMobile();
  },

  isNotRecommendedEnv() {
    return this.isNotSupportedBrowser();
  },

  isRecommendedEnv() {
    return !this.isNotRecommendedEnv();
  },
};
