import * as types from './types';
import Messages from './model';
import Message from '@/models/ducks/shared/models/message';
import { List } from 'immutable';

export const initialState = new Messages();

const show = (action) => {
  const { messages, page, total } = action.payload;

  return new Messages({
    messages: List(messages.map((message) => {
      return new Message(message);
    })),
    page,
    total,
  });
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SHOW:
      return show(action);
    case types.ADD:
      return new Messages({
        messages: state.messages.push(action.payload.item),
        total: state.total,
        page: state.page,
        title: state.title,
      });
    case types.DESTROY:
      return initialState;
    case types.NOOP:
      return state;
    default:
      return state;
  }
};
