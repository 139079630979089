const TO_SELECTION = 'state/ducks/transition-link/TO_SELECTION';
const TO_FACILITIES = 'state/ducks/transition-link/TO_FACILITIES';
const TO_INQUIRY = 'state/ducks/transition-link/TO_INQUIRY';
const INIT = 'state/ducks/transition-link/INIT';

export {
  TO_SELECTION,
  TO_FACILITIES,
  TO_INQUIRY,
  INIT,
};
