import { Record, List } from 'immutable';
import NAV_TYPE from '@/models/ducks/shared/constants/member-search-nav-type';

const properties = {
  items: new List(),
};

const UnreadMembersListRecord = Record(properties);
export default
class UnreadMembersList extends UnreadMembersListRecord {
  addItem(item) {
    const newItems = this.items.push(item);
    return this.set('items', newItems);
  }

  updateItem(index, item) {
    return this.setIn(['items', index], item);
  }

  getRecommendConditionUnreadMemberCount(id) {
    return this.getUnreadMemberCount(NAV_TYPE.RECOMMEND_CONDITION, id);
  }

  getManualConditionUnreadMemberCount(id) {
    return this.getUnreadMemberCount(NAV_TYPE.MANUAL_CONDITION, id);
  }

  getFavoriteJobOfferUnreadMemberCount(id) {
    return this.getUnreadMemberCount(NAV_TYPE.FAVORITE_JOB_OFFER, id);
  }

  getUnreadMemberCount(type, id) {
    return this.getUnreadMemberIds(type, id).size;
  }

  getRecommendConditionUnreadMemberIds(id) {
    return this.getUnreadMemberIds(NAV_TYPE.RECOMMEND_CONDITION, id);
  }

  getManualConditionUnreadMemberIds(id) {
    return this.getUnreadMemberIds(NAV_TYPE.MANUAL_CONDITION, id);
  }

  getFavoriteJobOfferUnreadMemberIds(id) {
    return this.getUnreadMemberIds(NAV_TYPE.FAVORITE_JOB_OFFER, id);
  }

  getUnreadMemberIds(type, id) {
    const item = this.items.find((it) => {
      return it.type === type && it.id === id;
    });
    if (item === undefined) {
      return new List();
    }
    return item.memberIds;
  }
}
