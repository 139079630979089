import { Record } from 'immutable';
import TOGGLE from '@/models/ducks/toggle/constants';

const properties = Object.assign(...Object.keys(TOGGLE).map((item) => {
  return {
    [`${ item }`]: false,
  };
}));

const ToggleRecord = Record(properties);

export default class Toggle extends ToggleRecord {
  update(key, value) {
    return this.set(key, value);
  }

  isVisible(ID) {
    return this.get(ID);
  }

  isHidden(ID) {
    return !this.isVisible(ID);
  }
}
