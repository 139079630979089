import i18next from 'i18next';
import SELECTION_STATUS from '@/models/ducks/selection-list/constants';
import isEqualArray from '@/utils/is-equal-array';

const STATUS_PROGRESS_IDS = [
  SELECTION_STATUS.STATUS_MAP.NOT_DOING.id,
  SELECTION_STATUS.STATUS_MAP.DOCUMENT_SCREENING.id,
  SELECTION_STATUS.STATUS_MAP.INTERVIEW_SET_DONE.id,
  SELECTION_STATUS.STATUS_MAP.INTERVIEW_DONE.id,
  SELECTION_STATUS.STATUS_MAP.OFFER.id,
  SELECTION_STATUS.STATUS_MAP.ACCEPTANCE.id,
  SELECTION_STATUS.STATUS_MAP.HIRING_SET_DONE.id,
];
const STATUS_FINISHED_IDS = [
  SELECTION_STATUS.STATUS_MAP.DECLINE_OFFER.id,
  SELECTION_STATUS.STATUS_MAP.REJECTION.id,
  SELECTION_STATUS.STATUS_MAP.HIRING_DONE.id,
  SELECTION_STATUS.STATUS_MAP.SELECTION_END.id,
];
const STATUS_INTERVIEW_ENABLED_IDS = [
  SELECTION_STATUS.STATUS_MAP.NOT_DOING.id,
  SELECTION_STATUS.STATUS_MAP.DOCUMENT_SCREENING.id,
  SELECTION_STATUS.STATUS_MAP.INTERVIEW_SET_DONE.id,
  SELECTION_STATUS.STATUS_MAP.INTERVIEW_DONE.id,
  SELECTION_STATUS.STATUS_MAP.OFFER.id,
  SELECTION_STATUS.STATUS_MAP.ACCEPTANCE.id,
];

export default {

  /**
   * 選考状況の全ステータス(id、ラベル)を配列で返す
   *
   * @return {Array} 選考状況の全ステータスの配列
   */
  getStatusItems() {
    const statusItems = [];

    Object.keys(SELECTION_STATUS.STATUS_MAP).forEach((key) => {
      statusItems.push({
        id: SELECTION_STATUS.STATUS_MAP[key].id,
        label: i18next.t(SELECTION_STATUS.STATUS_MAP[key].label),
      });
    });

    return statusItems;
  },

  /**
   *
   * 引数で受け取った選考状況ステータス値のラベルを返す
   *
   * @param {Number} 選考状況ステータスの値
   * @return {String} 対象の選考状況ステータスのラベル文字列
   */
  getStatusLabel(status) {
    const items = this.getStatusItems();

    for (let i = 0, max = items.length; i < max; i += 1) {
      if (items[i].id === status) {
        return items[i].label;
      }
    }
    return i18next.t('common.unselected');
  },

  getStatus(statusItems, currentStatusIds) {
    return statusItems.find(item => isEqualArray(item.status.sort(), currentStatusIds.sort()));
  },

  getStatusCategory(statusIds) {
    return statusIds.some(id => STATUS_PROGRESS_IDS.includes(id)) ? i18next.t('utils.selection.awaiting_review') : i18next.t('utils.selection.reviewed');
  },

  /**
   *
   * 引数で受け取った配列の選考状況ステータス値のラベルを返す
   *
   * @param {Array} statusIds 選考状況ステータスの値
   * @return {String} 対象の選考状況ステータスのラベル文字列
   */
  getStatusLabelByIds(statusIds) {
    if (this.isStatusProgressAll(statusIds) || this.isStatusAllFinished(statusIds)) {
      return i18next.t('common.all');
    }

    return this.getStatusLabel(...statusIds);
  },

  /**
   *
   * 引数で受け取った配列の選考状況ステータス値が「選考中すべて」かを判定して返す
   *
   * @param {Array} statusIds 選考状況ステータスの値
   * @return {Boolean} 「選考中すべて」か否か
   */
  isStatusProgressAll(statusIds) {
    return isEqualArray(STATUS_PROGRESS_IDS.sort(), statusIds.sort());
  },

  /**
   *
   * 引数で受け取った配列の選考状況ステータス値が「選考終了すべて」かを判定して返す
   *
   * @param {Array} statusIds 選考状況ステータスの値
   * @return {Boolean} 「選考中終了」か否か
   */
  isStatusAllFinished(statusIds) {
    return isEqualArray(STATUS_FINISHED_IDS.sort(), statusIds.sort());
  },

  /**
   *
   * 入職状況の申請が可能な選考状況ステータスか否かを判定して返す
   *
   * @param status {Number} 選考状況ステータス
   * @return {Boolean}
   */
  isApplicationStatusAppliable(status) {
    return (
      this.isStatusAcceptance(status) || this.isStatusHiringSetDone(status)
    );
  },

  /**
   *
   * 内定承諾済かどうかを判定して返す
   *
   * @param status {Number} 選考状況ステータス
   * @return {Boolean}
   */
  isStatusAcceptance(status) {
    return Number(status) === SELECTION_STATUS.STATUS_MAP.ACCEPTANCE.id;
  },

  /**
   *
   * 入職日決定済かどうかを判定して返す
   *
   * @param status {Number} 選考状況ステータス
   * @return {Boolean}
   */
  isStatusHiringSetDone(status) {
    return Number(status) === SELECTION_STATUS.STATUS_MAP.HIRING_SET_DONE.id;
  },

  /**
   *
   * 入職済かどうかを判定して返す
   *
   * @param status {Number} 選考状況ステータス
   * @return {Boolean}
   */
  isStatusHiringDone(status) {
    return Number(status) === SELECTION_STATUS.STATUS_MAP.HIRING_DONE.id;
  },

  /**
   *
   * 不採用かどうかを判定して返す
   *
   * @param status {Number} 選考状況ステータス
   * @return {Boolean}
   */
  isStatusRejection(status) {
    return Number(status) === SELECTION_STATUS.STATUS_MAP.REJECTION.id;
  },

  /**
   * 選考中か否かを返す
   *
   * @param status {Number} 選考状況ステータス
   * @return {Boolean}
   */
  isStatusInProgress(status) {
    return STATUS_PROGRESS_IDS.includes(Number(status));
  },

  /**
   * web面接 or 対面面接設定可能 もしくは 面接日程調整可能か否かを返す
   *
   * @param status {Number} 選考状況ステータス
   * @return {Boolean}
   */
  isInterviewEnabled(status) {
    return STATUS_INTERVIEW_ENABLED_IDS.includes(Number(status));
  },

  /**
   * 面接日設定済か否かを返す
   *
   * @param status {Number} 選考状況ステータス
   * @return {Boolean}
   */
  isInterviewSetDone(status) {
    return Number(status) === SELECTION_STATUS.STATUS_MAP.INTERVIEW_SET_DONE.id;
  },
};
