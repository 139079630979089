const SEARCH = 'state/ducks/message-group/SEARCH';
const SEARCH_MORE = 'state/ducks/message-group/SEARCH_MORE';
const UPDATE_ITEM = 'state/ducks/message-group/UPDATE_ITEM';
const MARK_AS_READ = 'state/ducks/message-group/MARK_AS_READ';
const NOOP = 'state/ducks/message-group/NOOP';

export {
  SEARCH,
  SEARCH_MORE,
  UPDATE_ITEM,
  MARK_AS_READ,
  NOOP,
};
