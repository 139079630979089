const getNameById = (facilityFormList, id) => {
  const facilityForm = facilityFormList.find(item => item.id === id);

  if (!facilityForm) {
    return '';
  }

  return facilityForm.name;
};

export {
  getNameById,
};
