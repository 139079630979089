const INIT = 'state/ducks/customer-search-condition-form/INIT';
const COPY = 'state/ducks/customer-search-condition-form/COPY';
const CHANGE = 'state/ducks/customer-search-condition-form/CHANGE';
const CHANGE_CONDITION_SELECTIVE = 'state/ducks/customer-search-condition-form/CHANGE_CONDITION_SELECTIVE';
const ADD_CONDITION_DESIRED_AREA = 'state/ducks/customer-search-condition-form/ADD_CONDITION_DESIRED_AREA';
const REMOVE_CONDITION_DESIRED_AREA = 'state/ducks/customer-search-condition-form/REMOVE_CONDITION_DESIRED_AREA';
const ADD_CONDITION_DESIRED_JOB_CATEGORY = 'state/ducks/customer-search-condition-form/ADD_CONDITION_DESIRED_JOB_CATEGORY';
const REMOVE_CONDITION_DESIRED_JOB_CATEGORY = 'state/ducks/customer-search-condition-form/REMOVE_CONDITION_DESIRED_JOB_CATEGORY';
const CHANGE_CONDITION_DESIRED_JOB_CATEGORY = 'state/ducks/customer-search-condition-form/CHANGE_CONDITION_DESIRED_JOB_CATEGORY';
const CHANGE_CONDITION_DESIRED_AREA_PREFECTURES = 'state/ducks/customer-search-condition-form/CHANGE_CONDITION_DESIRED_AREA_PREFECTURES';
const TOGGLE_CONDITION_DESIRED_AREA_CITY_GROUPS_CHECK_ALL = 'state/ducks/customer-search-condition-form/TOGGLE_CONDITION_DESIRED_AREA_CITY_GROUPS_CHECK_ALL';
const CHANGE_CONDITION_DESIRED_AREA_CITY = 'state/ducks/customer-search-condition-form/CHANGE_CONDITION_DESIRED_AREA_CITY';
const CORRECT_CONDITION_AGE_RANGE = 'state/ducks/customer-search-condition-form/CORRECT_CONDITION_AGE_RANGE';
const NOOP = 'state/ducks/customer-search-condition-form/NOOP';
const CHANGE_FROM_URL_QUERY = 'state/ducks/customer-search-condition-form/CHANGE_FROM_URL_QUERY';
const CHANGE_TO_SCOUTED = 'state/ducks/customer-search-condition-form/CHANGE_TO_SCOUTED';
const CHANGE_TO_RECOMMENDED = 'state/ducks/customer-search-condition-form/CHANGE_TO_RECOMMENDED';
const CHANGE_TO_FAVORITE = 'state/ducks/customer-search-condition-form/CHANGE_TO_FAVORITE';

export {
  INIT,
  COPY,
  CHANGE,
  CHANGE_CONDITION_SELECTIVE,
  ADD_CONDITION_DESIRED_AREA,
  REMOVE_CONDITION_DESIRED_AREA,
  ADD_CONDITION_DESIRED_JOB_CATEGORY,
  REMOVE_CONDITION_DESIRED_JOB_CATEGORY,
  CHANGE_CONDITION_DESIRED_JOB_CATEGORY,
  CHANGE_CONDITION_DESIRED_AREA_PREFECTURES,
  TOGGLE_CONDITION_DESIRED_AREA_CITY_GROUPS_CHECK_ALL,
  CHANGE_CONDITION_DESIRED_AREA_CITY,
  CORRECT_CONDITION_AGE_RANGE,
  NOOP,
  CHANGE_FROM_URL_QUERY,
  CHANGE_TO_SCOUTED,
  CHANGE_TO_RECOMMENDED,
  CHANGE_TO_FAVORITE,
};
