import * as types from './types';

export const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET:
      return {
        ...action.payload.announcement,
      };
    case types.INIT:
      return initialState;
    default:
      return state;
  }
};
