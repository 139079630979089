import { List } from 'immutable';
import * as types from './types';
import Image from '@/models/ducks/shared/models/image';

export const initialState = {
  images: List(),
  pagination: {},
};

const get = (action) => {
  const { images, pagination } = action.payload.images;

  return {
    images: List(images.map((image) => {
      return new Image(image);
    })),
    pagination,
  };
};

const changeCaption = (state, action) => {
  const { caption, id } = action.payload;
  const images = state.images.update(state.images.findIndex((item) => {
    return item.get('id') === id;
  }), (item) => {
    return item.set('caption', caption);
  });

  return {
    ...state,
    images,
  };
};

const update = (state, action) => {
  const image = new Image(action.payload.image);
  const images = state.images.update(state.images.findIndex((item) => {
    return item.id === image.id;
  }), (item) => {
    return item.merge(image);
  });

  return {
    ...state,
    images,
  };
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET:
      return get(action);
    case types.CHANGE_CAPTION:
      return changeCaption(state, action);
    case types.UPDATE:
      return update(state, action);
    default:
      return state;
  }
};
