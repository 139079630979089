import * as types from './types';

interface PreferredInterviewTime {
  hour: number;
  minutes: number;
}

export interface PreferredInterviewDatetime {
  date: Date;
  times: PreferredInterviewTime[];
}

export interface UpdatePreferredInterviewDatetimeListAction {
  type: string;
  payload: {
    datetimeList: PreferredInterviewDatetime[];
  };
}

export const updatePreferredInterviewDatetimeList = ({ datetimeList }: UpdatePreferredInterviewDatetimeListAction['payload']): UpdatePreferredInterviewDatetimeListAction => {
  return {
    type: types.UPDATE_PREFERRED_INTERVIEW_LIST,
    payload: {
      datetimeList,
    },
  };
};
