import i18next from 'i18next';
import { List, Record } from 'immutable';
import { EMPLOYMENT_TYPE, EMPLOYMENT_TYPES, JOB_OFFER_STATUS, SALARY_STATUS, SALARY_TYPES } from '@/models/ducks/job-offer/constants';
import { getSalaryTypeName } from '@/models/ducks/job-offer/utils';
import Image from '@/models/ducks/shared/models/image';
import JobOfferSalary from '@/models/ducks/shared/models/job-offer-salary';
import JobOfferUnitPrice from '@/models/ducks/shared/models/job-offer-unit-price';

const defaultProperties = {
  id: undefined,
  facility_id: undefined,
  facility: undefined,
  job_category_id: undefined,
  status: JOB_OFFER_STATUS.NEW,
  salary_etc: '',
  appeal_title: '',
  appeal_body: '',
  job_content: '',
  required: '',
  preferred: '',
  office_hours_conditions: '',
  welfare_programs: '',
  process: '',
  video_url: '',
  job_title: '',
  customer_images: new List(),
  features: new List(),
  job_offer_salaries: new List(),
  job_offer_unit_prices: new List(),
  next_status_key: '',
  closed_job_offer_reason: new List(),
  disapproved: false,
  resume_required: false,
};

const BaseJobOfferRecord = props => class extends Record({
  ...defaultProperties,
  ...props,
}) {
  update(key, value) {
    let val = value;

    if (key.includes('_id')) {
      val *= 1;
    }
    if (key === 'resume_required') {
      val = value === 'true';
    }
    if (key === 'features') {
      return this.setFeatures(key, val);
    }
    if (key === 'job_offer_unit_prices') {
      return this.setUnitPrices(key, val);
    }
    if (key === 'customer_images') {
      return this.setImages(key, val);
    }
    if (key === 'job_offer_salaries') {
      return this.setSalaries(key, val);
    }

    return this.set(key, val);
  }

  validate() {
    const errors = {};
    if (!this.appeal_title) {
      errors.appeal_title = [i18next.t('common.required')];
    }
    return errors;
  }

  setFeatures(key, features) {
    const immutableFeatures = features instanceof List ? features : new List(features);
    immutableFeatures.forEach((feature) => {
      if (!Object.prototype.hasOwnProperty.call(feature, 'checked')) {
        Object.assign(feature, { checked: false });
      }
    });
    return this.set(key, immutableFeatures);
  }

  setUnitPrices(key, unitPrices) {
    if (unitPrices instanceof List) {
      return this.set(key, unitPrices);
    }

    const immutableUnitPrices = new List(unitPrices.map((unitPrice) => {
      return new JobOfferUnitPrice(unitPrice);
    }));

    return this.set('job_offer_unit_prices', immutableUnitPrices);
  }

  setImages(key, images) {
    let newImages = null;

    // Listの中にImageではない場合は、Imageへ変換する
    if (images instanceof List) {
      if (images.get(0) === undefined || images.get(0) instanceof Image) {
        return this.set(key, images);
      }
    }

    newImages = new List();
    images.forEach((image) => {
      newImages = newImages.push(new Image(image));
    });
    return this.set(key, newImages);
  }

  setSalaries(key, salaries) {
    if (salaries instanceof List) {
      return this.set(key, salaries);
    }

    const immutableSalaries = new List(salaries.map((salary) => {
      const jobOfferSalary = new JobOfferSalary(salary);
      return jobOfferSalary.update('salary_type_label', getSalaryTypeName(salary.salary_type));
    }));

    let newJobOffer = this.set('job_offer_salaries', immutableSalaries);

    // 選択なしの場合はデフォルトで正職員を入れる
    const noSalary = immutableSalaries.filter((salary) => {
      return salary.status === SALARY_STATUS.ON;
    }).size === 0;
    if (noSalary) {
      const regularSalaryIndex = immutableSalaries.findIndex((salary) => {
        return salary.employment_type === EMPLOYMENT_TYPE.REGULAR;
      });
      newJobOffer = newJobOffer.setIn(['job_offer_salaries', regularSalaryIndex, 'status'], SALARY_STATUS.ON);
    }
    return newJobOffer;
  }

  setDefaultJobOffer() {
    return this.set('job_offer_salaries', this.getDefaultJobOfferSalaries())
                .set('process', i18next.t('models.constants.shared.models.job_offer_process'));
  }

  getDefaultJobOfferSalaries() {
    const salaries = [];
    EMPLOYMENT_TYPES.forEach((employmentType, index) => {
      SALARY_TYPES.forEach((salaryType) => {
        salaries.push(new JobOfferSalary({
          id: null,
          employment_type: employmentType.id,
          employment_type_label: i18next.t(employmentType.name),
          salary_bottom: '',
          salary_top: '',
          salary_type: salaryType.id,
          salary_type_label: getSalaryTypeName(salaryType.id),
          status: index === 0 ? SALARY_STATUS.ON : SALARY_STATUS.OFF,
        }));
      });
    });

    return new List(salaries);
  }

  checkedFeatures() {
    const features = this.features.filter((feature) => {
      return feature.checked === true;
    });
    return this.set('features', features);
  }

  // list中の特定のitemを更新して、新しいlistを返す
  updateItemInList(toUpdateItem, list, compare) {
    const index = list.findIndex((item) => {
      return compare(item, toUpdateItem);
    });
    return list.setIn([index], toUpdateItem);
  }

  getSalaries(employmentType = null) {
    if (employmentType === null) {
      return this.job_offer_salaries;
    }
    return this.job_offer_salaries.filter((salary) => {
      return salary.employment_type === employmentType;
    });
  }

  getSalary(employmentType, salaryType = null) {
    return this.job_offer_salaries.find((salary) => {
      return salary.employment_type === employmentType && (salaryType === null || salary.salary_type === salaryType);
    });
  }

  getSalaryTop(employmentType, salaryType) {
    const salary = this.getSalary(employmentType, salaryType);

    if (!salary) {
      return null;
    }
    return salary.salary_top;
  }

  getSalaryBottom(employmentType, salaryType) {
    const salary = this.getSalary(employmentType, salaryType);

    if (!salary) {
      return null;
    }
    return salary.salary_bottom;
  }

  getFirstSalary() {
    return this.job_offer_salaries.find((salary) => {
      return salary.status === SALARY_STATUS.ON;
    });
  }

  getUnitPrices() {
    return this.job_offer_unit_prices.groupBy(item => item.qualification_id).valueSeq().toArray()
      .map((unitPrices) => {
        return {
          regular: unitPrices.find(item => item.employment_type === EMPLOYMENT_TYPE.REGULAR),
          part: unitPrices.find(item => item.employment_type === EMPLOYMENT_TYPE.PART),
        };
      });
  }
};

export default BaseJobOfferRecord;
