import axios from 'axios';

export default {
  save(jobOffer) {
    return axios.post('/api/customers/job_offers/save', { job_offer: jobOffer });
  },
  revise(jobOffer, nextStatus) {
    return axios.post('/api/customers/job_offers/revise', { job_offer: jobOffer, next_status: nextStatus });
  },
  changeStatus(model, nextStatusKey, message) {
    const jobOffer = model.toJS();

    return axios.post('/api/customers/job_offers/change_status',
      {
        change_status: {
          job_offer_id: jobOffer.id,
          next_status_key: nextStatusKey,
          message,
          closed_job_offer_reason: jobOffer.closed_job_offer_reason.map((reason) => {
            return reason * 1;
          }),
        },
      },
    );
  },
  show(id) {
    return axios.get(`/api/customers/job_offers/${id}/`);
  },

  appealTitleExist(appealTitle, facilityId, jobOfferId, jobOfferType) {
    return axios.get('/api/customers/job_offers/exist_by_appeal_title/', {
      params: {
        appeal_title: appealTitle,
        id: jobOfferId,
        facility_id: facilityId,
        job_offer_type: jobOfferType,
      },
    });
  },
  appealBodyExist(appealBody, facilityId, jobOfferId, jobOfferType) {
    return axios.post('/api/customers/job_offers/exist_by_appeal_body/', {
      appeal_body: appealBody,
      id: jobOfferId,
      facility_id: facilityId,
      job_offer_type: jobOfferType,
    });
  },

  published() {
    return axios.get('/api/customers/job_offers/published/', {
      params: {
        limit: 1,
      },
    });
  },

  validateSalary(jobOffer) {
    return axios.post('/api/customers/job_offers/salary_validate/', jobOffer);
  },

  exist(jobOffer) {
    return axios.post('/api/customers/job_offers/exist/', jobOffer);
  },
};
