import * as types from './types';

export const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET:
      return action.payload.minimumWages;
    default:
      return state;
  }
};
