import * as actions from './actions';
import MessageApi from '@/models/ducks/shared/api/message';
import MessageGroupSearchForm from '@/models/ducks/shared/models/message-group-search-form';

const search = (params, page) => {
  return dispatch => MessageApi.search(params, page)
    .then((response) => {
      return dispatch(actions.search(response));
    });
};

const searchMore = (params, page) => {
  return dispatch => MessageApi.search(params, page)
    .then((response) => {
      return dispatch(actions.searchMore(response));
    });
};

const markAsRead = (messageId) => {
  return dispatch => MessageApi.markRead(messageId)
    .then((response) => {
      return dispatch(actions.markAsRead(response));
    });
};

// /customers/selections では messageGroupのstateが空なので、API通信のみ行ってstateの更新は行わない
const markAsReadOnSelection = (messageId) => {
  return dispatch => MessageApi.markRead(messageId)
    .then(() => {
      return dispatch(actions.noop());
    });
};

const updateMessage = (jobOfferId, memberId) => {
  const searchForm = new MessageGroupSearchForm({
    job_offer_id: jobOfferId,
    member_id: memberId,
  });

  return dispatch => MessageApi.search(searchForm)
    .then((response) => {
      return dispatch(actions.updateMessage(response.data.messages, jobOfferId, memberId));
    });
};

export {
  search,
  searchMore,
  markAsRead,
  markAsReadOnSelection,
  updateMessage,
};
