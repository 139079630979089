import * as actions from './actions';

const init = () => {
  return dispatch => dispatch(actions.init());
};

const changeField = (key, value) => {
  return dispatch => dispatch(actions.changeField(key, value));
};

const changeJobOfferField = (key, value) => {
  return dispatch => dispatch(actions.changeJobOfferField(key, value));
};

const initJobOfferField = () => {
  return dispatch => dispatch(actions.initJobOfferField());
};

const changeMemberIdsField = (memberIds) => {
  return dispatch => dispatch(actions.changeMemberIdsField(memberIds));
};

export {
  init,
  changeField,
  changeJobOfferField,
  initJobOfferField,
  changeMemberIdsField,
};
