import * as types from './types';
import MessageTemplateList from './model';

export const initialState = new MessageTemplateList();

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET:
      return MessageTemplateList.convert(action.payload.templates);
    default:
      return state;
  }
};
