import { Record } from 'immutable';

const properties = {
  id: '',
  caption: '',
  customer_id: '',
  height: '',
  height_now: '',
  width: '',
  width_now: '',
  image: {
    converted: {
      url: '',
    },
    original: {
      url: '',
    },
    url: '',
  },
  sort: '',
  selected: false,
};

const ImageRecord = Record(properties);

export default class Image extends ImageRecord {
  update(key, value) {
    return this.set(key, value);
  }
}
