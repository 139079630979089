import * as types from './types';
import { List } from 'immutable';

export const initialState = List();

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET:
      return List(action.payload.groups);
    default:
      return state;
  }
};
