const INIT = 'state/ducks/editing-selection/INIT';
const CREATE = 'state/ducks/editing-selection/CREATE';
const CHANGE = 'state/ducks/editing-selection/CHANGE';
const UPDATE_TO_HIRING_DONE = 'state/ducks/editing-selection/UPDATE_TO_HIRING_DONE';

export {
  INIT,
  CREATE,
  CHANGE,
  UPDATE_TO_HIRING_DONE,
};
