import * as types from './types';

const get = (images) => {
  return {
    type: types.GET,
    payload: {
      images,
    },
  };
};

const changeCaption = (caption, id) => {
  return {
    type: types.CHANGE_CAPTION,
    payload: {
      caption,
      id,
    },
  };
};

const update = (response) => {
  return {
    type: types.UPDATE,
    payload: {
      image: response.data.customer_image,
    },
  };
};

export {
  get,
  changeCaption,
  update,
};
