import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import ReactGA from 'react-ga4';

import IconText from '@/components/desktop/icon-text';
import Icon from '@/components/desktop/icon';
import Badge from '@/components/desktop/badge';

import routerHelper from '@/utils/router';
import GOOGLE_ANALYTICS from '@/models/ducks/shared/constants/google-analytics';

class SideNav extends React.Component {

  static propTypes = {
    children: PropTypes.node,
    small: PropTypes.bool,
  }

  static defaultProps = {
    small: false,
  }

  render() {
    const SideNavClass = classNames({
      'c-side-nav__item': true,
      'c-side-nav__item--small': this.props.small,
    });

    const navigations = React.Children.map(this.props.children, (child, index) => {
      return (
        <li className={ SideNavClass } key={ index }>
          { child }
        </li>
      );
    });

    return (
      <ul className="c-side-nav">
        { navigations }
      </ul>
    );
  }
}

class SideNavItem extends React.Component {

  static propTypes = {
    text: PropTypes.string,
    icon: PropTypes.string,
    count: PropTypes.number,
    href: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    new: PropTypes.bool,
    className: PropTypes.string,
    state: PropTypes.object,
    disabled: PropTypes.bool,
  }

  static defaultProps = {
    text: '',
    icon: '',
    count: 0,
    href: '',
    new: false,
    state: null,
    disabled: false,
  }
  onClick = (e) => {
    if (this.props.disabled) {
      e.preventDefault();
      return;
    }

    ReactGA.event({
      category: GOOGLE_ANALYTICS.CATEGORY.SIDE_NAV,
      action: `Change page to ${ this.props.href }`,
      label: window.location.pathname,
    });
  }

  render() {
    const isActiveLink = routerHelper.isActiveLink(this.props.href);
    const isActiveCategory = routerHelper.isActiveCategory(this.props.href);
    const { disabled } = this.props;
    const navClass = classNames({
      'c-side-nav__link': true,
      'c-side-nav__link--active': isActiveLink || isActiveCategory,
      'c-side-nav__link--disabled': disabled,
      [`${ this.props.className }`]: this.props.className,
    });
    const query = routerHelper.generateLinkProps(this.props.href);

    return (
      <NavLink
        to={
          {
            pathname: this.props.href,
            search: query,
          }
        }
        state={ this.props.state }
        className={ navClass }
        onClick={ this.onClick }
        style={{ pointerEvents: disabled ? 'none' : 'auto' }}
      >
        <div className={ `c-side-nav__link-inner${ this.props.new ? ' c-side-nav__link-inner--new' : '' }` }>
          <div className="c-side-nav__link-text">
            <IconText
              text={
                <div>
                  {
                    this.props.new
                    &&
                    <span className="c-side-nav__new u-ff-helvetica-arial">NEW!!</span>
                  }
                  <span>{ this.props.text }</span>
                </div>
              }
              textBottom={ true }
            >
              <Icon
                name={ this.props.icon }
                width={ this.props.width }
                height={ this.props.height }
              />
            </IconText>
          </div>
          {
            this.props.count > 0 &&
            (
              <div className="c-side-nav__link-count">
                <Badge alert={ true } value={ this.props.count } className="u-fl-right" />
              </div>
            )
          }
        </div>
      </NavLink>
    );
  }
}

export {
  SideNav,
  SideNavItem,
};
