import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default class IconText extends React.Component {
  static propTypes = {
    text: PropTypes.oneOfType(
      [
        PropTypes.string,
        PropTypes.node,
      ],
    ).isRequired,
    rightIcon: PropTypes.bool,
    textBottom: PropTypes.bool,
    rotate: PropTypes.number,
    align: PropTypes.oneOf(['baseline']),
    className: PropTypes.string,
    children: PropTypes.node,
  }

  static defaultProps = {
    text: '',
    rightIcon: false,
    textBottom: false,
  }

  render() {
    const iconTextClass = classNames({
      'c-icon-text': true,
      [`c-icon-text--align-${ this.props.align }`]: !!this.props.align,
      [`${ this.props.className }`]: !!this.props.className,
    });

    const iconClass = classNames({
      'c-icon-text__icon-inner': true,
      [`c-icon-text__icon-inner--rotate${ this.props.rotate }`]: !!this.props.rotate,
    });

    const TextClass = classNames({
      'c-icon-text__text': true,
      'c-icon-text__text--bottom': this.props.textBottom,
    });

    const Icon = () => {
      return (
        <div className="c-icon-text__icon">
          <div className={ iconClass }>{ this.props.children }</div>
        </div>
      );
    };

    return (
      <div className={ iconTextClass }>
        {
          !this.props.rightIcon && <Icon />
        }
        <div className={ TextClass }>{ this.props.text }</div>
        {
          this.props.rightIcon && <Icon />
        }
      </div>
    );
  }
}
