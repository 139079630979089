import * as types from './types';

export const initialState = {
  doing: 0,
  not_doing_unread: 0,
  not_doing: 0,
  document_screening: 0,
  interview_set_done: 0,
  interview_done: 0,
  offer: 0,
  acceptance: 0,
  hiring_set_done: 0,
  selection_end_all: 0,
  hiring_done: 0,
  rejection: 0,
  decline_offer: 0,
  selection_end: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET:
      return action.payload.badge;
    default:
      return state;
  }
};
