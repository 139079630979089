import * as types from './types';

const init = () => {
  return {
    type: types.INIT,
  };
};

const get = ({ selections, count, total, limit }) => {
  return {
    type: types.GET,
    payload: {
      selections,
      count,
      total,
      limit,
    },
  };
};

const getMore = ({ selections, count, total, limit }) => {
  return {
    type: types.GET_MORE,
    payload: {
      selections,
      count,
      total,
      limit,
    },
  };
};

const toggle = (key, id) => {
  return {
    type: types.TOGGLE,
    payload: {
      key,
      id,
    },
  };
};

const syncStatusWithMessageType = (selectedSelection, messageType) => {
  return {
    type: types.SYNC_STATUS_WITH_MESSAGE_TYPE,
    payload: {
      selectedSelection,
      messageType,
    },
  };
};

const update = (selection) => {
  return {
    type: types.UPDATE,
    payload: {
      selection,
    },
  };
};

const updateStatus = (selection) => {
  return {
    type: types.UPDATE_STATUS,
    payload: {
      selection,
    },
  };
};

const requestApplication = (selection) => {
  return {
    type: types.REQUEST_APPLICATION,
    payload: {
      selection,
    },
  };
};

const noop = () => {
  return {
    type: types.NOOP,
  };
};

export {
  init,
  get,
  getMore,
  toggle,
  syncStatusWithMessageType,
  update,
  updateStatus,
  requestApplication,
  noop,
};
