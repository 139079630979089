const CREATE = 'state/ducks/message-group-search-form/CREATE';
const RESTORE = 'state/ducks/message-group-search-form/RESTORE';
const UPDATE = 'state/ducks/message-group-search-form/UPDATE';
const UPDATE_MESSAGE_TYPE = 'state/ducks/message-group-search-form/UPDATE_MESSAGE_TYPE';

export {
  CREATE,
  RESTORE,
  UPDATE,
  UPDATE_MESSAGE_TYPE,
};
