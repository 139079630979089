import { useState, useEffect } from 'react';
import { Location } from 'react-router-dom';

type checkAuth = ({ toPath, query, loginRedirectUrl }: {
  toPath: string;
  query: string;
  loginRedirectUrl: string;
}) => Promise<{
  isAuthenticated: boolean;
  redirectPath: string;
  isReload: boolean;
  canShowWithoutAuthenticated: boolean;
  loginRedirectUrl: string;
}>

export const useCheckAuth = (location: Location, checkAuth: checkAuth) => {
  const [path, setPath] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [redirectPath, setRedirectPath] = useState('');
  const [canShowWithoutAuthenticated, setCanShowWithoutAuthenticated] = useState(false);
  const [loginRedirectUrl, setLoginRedirectUrl] = useState('');
  // @see https://github.com/medley-inc/job-medley/issues/25987
  // ここで初期化しないとlocation.pathnameが変わった瞬間はisAuthenticatedがtrueとなっており、
  // 一瞬子コンポーネントがマウント → useEffect内の初期化により子コンポーネントがアンマウント → checkAuthが通り子コンポーネントがマウント
  // という手順を踏み、2回マウントされる。ここに初期化を入れることにより、子コンポーネントがマウントされる前にisAuthenticatedが初期化され、2回マウントされるのを防ぐ。
  if (path !== location.pathname) {
    setPath(location.pathname);
    setIsAuthenticated(false);
    setRedirectPath('');
    setCanShowWithoutAuthenticated(false);
    setLoginRedirectUrl('');
  }

  useEffect(() => {
    const onCheckAuth = async () => {
      setIsAuthenticated(false);
      setRedirectPath('');
      setCanShowWithoutAuthenticated(false);

      const checkAuthResult = await checkAuth({ toPath: location.pathname, query: location.search, loginRedirectUrl });
      if (checkAuthResult.isReload) {
        window.location.href = checkAuthResult.redirectPath;
        return;
      }
      setLoginRedirectUrl(checkAuthResult.loginRedirectUrl);
      setIsAuthenticated(checkAuthResult.isAuthenticated);
      setRedirectPath(checkAuthResult.redirectPath);
      setCanShowWithoutAuthenticated(checkAuthResult.canShowWithoutAuthenticated);
    };

    onCheckAuth();

    // @see https://github.com/medley-inc/job-medley/issues/25987
    // 初期化の意図はstate宣言直下の初期化と同じ。
    // 子コンポーネントの不要なマウント、アンマウントを防ぐため、AuthenticatedRouteがアンマウントされる時にも確実に初期化しておく。
    return () => {
      setLoginRedirectUrl('');
      setIsAuthenticated(false);
      setRedirectPath('');
      setCanShowWithoutAuthenticated(false);
    };
  }, [location.pathname]);

  return {
    isAuthenticated,
    redirectPath,
    canShowWithoutAuthenticated,
  };
};
