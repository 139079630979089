import { AxiosPromise } from 'axios';
import { apiClient } from '@/utils/ApiClient';

export const resetPassword = (email: string): AxiosPromise => apiClient.post('/api/customers/auth/password', {
  customer: {
    email,
  },
});

export const existByEmail = (email: string): AxiosPromise => apiClient.get('/api/customers/customers/exist_by_email', {
  params: {
    email,
  },
}, { isLoaderHidden: true });

export const updateEmail = (email: string): AxiosPromise => apiClient.put('/api/customers/customers/update_email', { email });

export interface Customer {
  id: string;
  name: string;
  postal_code: string;
  prefecture_id: string;
  city_id: string;
  address_etc: string;
  in_charge_family_name: string;
  in_charge_first_name: string;
  in_charge_post: string;
  in_charge_position: string;
  tel: string;
  email: string;
  password: string;
  password_confirmation: string;
  approval: boolean;
  claim_postal_code: string;
  claim_prefecture_id: string;
  claim_city_id: string;
  claim_address_etc: string;
  claim_in_charge_family_name: string;
  claim_in_charge_first_name: string;
  claim_in_charge_post: string;
  claim_in_charge_position: string;
  claim_tel: string;
  notification_setting: {
    ignore_emails: string[];
  };
  publishing_mode: string;
  eligible_switch: boolean;
  lead_id: string;

  // TODO:
  //  - そもそも必要かどうか不明なので、AppShell移行時に適宜型定義 or 削除する
  images: any[];
}
