import * as actions from './actions';
import CustomerApi from '@/models/ducks/shared/api/customer';
import api from './api';
import TownApi from '@/models/ducks/shared/api/town';
import browser from '@/utils/browser';

const updateCustomerFields = (key, value) => {
  return dispatch => dispatch(actions.updateCustomerFields(key, value));
};

const createCustomer = (customer) => {
  return dispatch => dispatch(actions.createCustomer(customer));
};

const clearCustomer = () => {
  return dispatch => dispatch(actions.clearCustomer());
};

const updateCustomerAuth = ({ email, password }) => {
  return dispatch => Promise.resolve()
    .then(() => {
      return dispatch(actions.updateCustomerFields('email', email));
    })
    .then(() => {
      return dispatch(actions.updateCustomerFields('password', password));
    });
};

const signinCustomer = (router, location) => {
  return (dispatch, getState) => {
    const { customer } = getState();

    return CustomerApi.signIn({ customer })
      .then((response) => {
        dispatch(actions.createCustomer(response.data.customer));
        dispatch(updateCustomerAuth({ email: customer.email, password: customer.password }));

        if (response.data.next_url) {
          window.location.href = response.data.next_url;
        }

        const isRedirectingPageSpecified = (location.state && location.state.nextPathname);

        if (!browser.isIE9() && isRedirectingPageSpecified) {
          router(location.state.nextPathname);
        }

        router('/');
      });
  };
};

const signoutCustomer = () => {
  return dispatch => CustomerApi.signOut()
    .then(() => {
      return dispatch(actions.clearCustomer());
    });
};

const getCurrentCustomer = () => {
  return dispatch => CustomerApi.show()
    .then((response) => {
      return dispatch(actions.createCustomer(response.data.customer));
    });
};

const updateCustomerEmail = (email) => {
  return dispatch => CustomerApi.updateEmail(email)
    .then((response) => {
      return dispatch(actions.createCustomer(response.data.customer));
    });
};

const loadLeadCustomer = (id) => {
  return dispatch => api.showLead(id).then((response) => {
    return dispatch(actions.loadLeadCustomer(response.data.lead));
  });
};

const getCustomerByInvitationCode = (code) => {
  return dispatch => CustomerApi.showByInvitationCode(code).then((response) => {
    return dispatch(actions.createCustomer(response.data.customer));
  });
};

const fillCustomerAddresses = () => {
  return (dispatch, getState) => {
    const { customer } = getState();

    return TownApi.search(customer.postal_code).then((response) => {
      const town = response.data.towns.shift();
      if (!town) {
        return dispatch(actions.noop());
      }

      return dispatch(actions.fillCustomerAddresses(town));
    });
  };
};

const fillCustomerInvoiceAddresses = () => {
  return (dispatch, getState) => {
    const { customer } = getState();

    return TownApi.search(customer.claim_postal_code).then((response) => {
      const town = response.data.towns.shift();
      if (!town) {
        return dispatch(actions.noop());
      }

      return dispatch(actions.fillCustomerInvoiceAddresses(town));
    });
  };
};

const createNewCustomerAccount = () => {
  return (dispatch, getState) => {
    const { customer } = getState();

    return CustomerApi.create(customer)
      .then((response) => {
        return dispatch(createCustomer(response.data.customer));
      }).catch((err) => {
        throw err;
      });
  };
};

const addImages = (addedImages) => {
  return dispatch => dispatch(actions.addImages(addedImages));
};

const deleteImage = (image) => {
  return dispatch => dispatch(actions.deleteImage(image));
};

const updateImage = (image) => {
  return dispatch => dispatch(actions.updateImage(image));
};

const setImageSize = ({ id, width, height }) => {
  return dispatch => dispatch(actions.setImageSize({ id, width, height }));
};

const sortImages = (oldIndex, newIndex) => {
  return dispatch => dispatch(actions.sortImages(oldIndex, newIndex));
};

const updateWithInvitationCode = (code) => {
  return (dispatch, getState) => {
    const { customer } = getState();

    return CustomerApi.updateWithInvitationCode(code, customer)
      .then(() => {
        return dispatch(actions.createCustomer(customer));
      }).catch((err) => {
        return Promise.reject(err);
      });
  };
};

const save = (customer) => {
  return dispatch => CustomerApi.update(customer)
    .then(() => {
      return dispatch(actions.save(customer));
    }).catch((err) => {
      return Promise.reject(err);
    });
};

const clearInvoice = () => {
  return dispatch => dispatch(actions.clearInvoice());
};

export {
  updateCustomerFields,
  createCustomer,
  clearCustomer,
  updateCustomerAuth,
  signinCustomer,
  signoutCustomer,
  getCurrentCustomer,
  updateCustomerEmail,
  loadLeadCustomer,
  fillCustomerAddresses,
  fillCustomerInvoiceAddresses,
  createNewCustomerAccount,
  addImages,
  deleteImage,
  updateImage,
  setImageSize,
  sortImages,
  updateWithInvitationCode,
  save,
  clearInvoice,
  getCustomerByInvitationCode,
};
