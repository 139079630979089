import * as actions from './actions';

const set = (announcement) => {
  return dispatch => dispatch(actions.set(announcement));
};

const init = () => {
  return dispatch => dispatch(actions.init());
};

 export {
   set,
   init,
 };
