const INIT = 'state/ducks/facility/INIT';
const SET = 'state/ducks/facility/SET';
const CREATE = 'state/ducks/facility/CREATE';
const INIT_UNNECESSARY_FIELD_ON_COPY = 'state/ducks/facility/INIT_UNNECESSARY_FIELD_ON_COPY';
const SET_WITH_CONVERTED_FACILITY = 'state/ducks/facility/SET_WITH_CONVERTED_FACILITY';
const COPY_CUSTOMER_INFO = 'state/ducks/facility/COPY_CUSTOMER_INFO';
const CHANGE_GEO_LAT_LON = 'state/ducks/facility/CHANGE_GEO_LAT_LON';
const CHANGE_ACCESS = 'state/ducks/facility/CHANGE_ACCESS';
const CHANGE_FIELD = 'state/ducks/facility/CHANGE_FIELD';
const CHANGE_FEATURE = 'state/ducks/facility/CHANGE_FEATURE';
const FILL_ADDRESS = 'state/ducks/facility/FILL_ADDRESS';
const ADD_IMAGES = 'state/ducks/facility/ADD_IMAGES';
const NOOP = 'state/ducks/facility/NOOP';

export {
  INIT,
  SET,
  CREATE,
  INIT_UNNECESSARY_FIELD_ON_COPY,
  SET_WITH_CONVERTED_FACILITY,
  COPY_CUSTOMER_INFO,
  CHANGE_GEO_LAT_LON,
  CHANGE_ACCESS,
  CHANGE_FIELD,
  CHANGE_FEATURE,
  FILL_ADDRESS,
  ADD_IMAGES,
  NOOP,
};
