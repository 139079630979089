import axios from 'axios';
import * as timezone from '@/utils/timezone';
import { INTERVIEW_METHOD } from '../constants/interview';

export default {
  search(params, page = 1) {
    return axios
      .post('/api/customers/messages/search', {
        keyword: params.keyword,
        ignore_deleted_member: params.ignore_deleted_member,
        unread: params.unread,
        not_replied: params.unreplied,
        message_type: params.message_type.map(item => item * 1),
        job_offer_id: params.job_offer_id,
        member: params.member,
        member_id: params.member_id,
        pagination: {
          limit: 10,
          page,
        },
      });
  },

  createLabel(params, page = 1) {
    return axios
      .post('/api/customers/messages/label', {
        keyword: params.keyword,
        ignore_deleted_member: params.ignore_deleted_member,
        unread: params.unread,
        not_replied: params.unreplied,
        message_type: params.message_type.map(item => item * 1),
        job_offer_id: params.job_offer_id,
        job_offer_name: params.job_offer_name,
        member: params.member,
        pagination: {
          limit: 10,
          page,
        },
      });
  },

  messages(id) {
    return axios
      .get(`/api/customers/messages/${ id }/messages`);
  },

  create(params) {
    const onlineInterviewSchedule = params.isInterview() ? timezone.convertToISOStringByDayAndTime(params.interview_schedule.day, params.interview_schedule.time) : undefined;
    const location = params.isOfflineInterview() ? `${params.interview_location}` : undefined;

    const data = {
      body: params.body,
      application_id: params.application_id,
      job_offer_id: params.job_offer_id,
      customer_id: params.customer_id,
      member_id: params.member_id,
      operator_id: params.operator_id,
      scout_member_id: params.scout_member_id,
      from: params.from,
      to: params.to,
      title: params.title,
      message_type: params.message_type,
      replied_status: params.replied_status,
      customer_read_status: params.customer_read_status,
      member_read_status: params.member_read_status,
      start_datetime: onlineInterviewSchedule,
      interview_method: params.isOfflineInterview() ? INTERVIEW_METHOD.OFFLINE_INTERVIEW : undefined,
      preferred_interview_datetime_list: params.preferred_interview_datetime_list,
      preferred_interview_methods: params.isArrangementInterview() ? [INTERVIEW_METHOD.OFFLINE_INTERVIEW] : params.preferred_interview_methods,
      preferred_interview_location: params.preferred_interview_location,
      location,
    };

    if (params.message_files.length > 0) {
      const formData = new FormData();
      params.message_files.forEach((file, index) => {
        formData.append(`files[${index}]`, file);
      });

      // formDataにdataの値を追加してpostする必要があるため、ここで追加する
      Object.entries(data).forEach(([key, value]) => {
        if (value) {
          formData.append(key, value);
        }
      });

      return axios.post('/api/customers/messages/', formData);
    }
    return axios.post('/api/customers/messages/', data);
  },

  bulkCreateScout(condition) {
    const message = condition.getIn(['messages', 0]);
    const messages = condition.member_ids.map((id) => {
      return {
        body: message.get('body'),
        facility_id: message.get('facility_id'),
        job_offer_id: message.get('job_offer_id'),
        job_offer_salary_id: message.get('job_offer_salary_id'),
        member_id: id,
        message_type: message.get('message_type'),
      };
    });

    return axios.post('/api/customers/messages/bulk_create_scout', {
      messages,
    });
  },

  markRead(messageId) {
    return axios
      .post('/api/customers/messages/mark_read', {
        message_id: messageId,
      });
  },

  countRemainingScout() {
    return axios.get('/api/customers/messages/scout_count');
  },

  scoutSent() {
    return axios.get('/api/customers/messages/scout_sent');
  },

  showScoutSendCountResetNotice() {
    return axios.get('/api/customers/messages/show_scout_send_count_reset_notice');
  },
};
