import reducers from './reducers';
import * as scoutPromotionModalOperations from './operations';
import * as scoutPromotionModalTypes from './types';

export {
  scoutPromotionModalOperations,
  scoutPromotionModalTypes,
};

export default reducers;
