import axios from 'axios';

export default {
  all() {
    return axios.get('/api/prefectures/');
  },

  getWithJobOffers() {
    return axios.get('/api/customers/prefectures/with_job_offers');
  },
};
