import * as types from './types';
import SEARCHES_MENU from '@/models/ducks/searches-menu/constants';

export const initialState = SEARCHES_MENU.MENU_ITEMS[0];

export default (state = initialState, action) => {
  switch (action.type) {
    case types.INIT:
      return initialState;
    case types.CHANGE:
      return SEARCHES_MENU.MENU_ITEMS.find(item => item.id === action.payload.id);
    default:
      return state;
  }
};
