import reducers from './reducers';
import * as facilitySearchLabelOperations from './operations';
import * as facilitySearchLabelTypes from './types';

export {
  facilitySearchLabelOperations,
  facilitySearchLabelTypes,
};

export default reducers;
