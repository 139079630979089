const INIT = 'state/ducks/job-offer-status-map/INIT';
const SET = 'state/ducks/job-offer-status-map/SET';
const GET = 'state/ducks/job-offer-status-map/GET';
const NOOP = 'state/ducks/job-offer-status-map/NOOP';

export {
  INIT,
  SET,
  GET,
  NOOP,
};
