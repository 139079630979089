import * as types from './types';

const create = (label) => {
  return {
    type: types.CREATE,
    payload: {
      label,
    },
  };
};

export {
  create,
};
