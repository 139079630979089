import i18next from 'i18next';
import SELECTION_STATUS from '@/models/ducks/selection-list/constants';

function getSelectionStatusProgress(selectionBadge) {
  return [
    {
      name: i18next.t('common.all'),
      status: [
        SELECTION_STATUS.STATUS_MAP.NOT_DOING.id,
        SELECTION_STATUS.STATUS_MAP.DOCUMENT_SCREENING.id,
        SELECTION_STATUS.STATUS_MAP.INTERVIEW_SET_DONE.id,
        SELECTION_STATUS.STATUS_MAP.INTERVIEW_DONE.id,
        SELECTION_STATUS.STATUS_MAP.OFFER.id,
        SELECTION_STATUS.STATUS_MAP.ACCEPTANCE.id,
        SELECTION_STATUS.STATUS_MAP.HIRING_SET_DONE.id,
      ],
      unread: 0,
      total: selectionBadge.doing,
    },
    {
      name: i18next.t('models.constants.selection_badge.status.applied'),
      status: [
        SELECTION_STATUS.STATUS_MAP.NOT_DOING.id,
      ],
      unread: selectionBadge.not_doing_unread,
      total: selectionBadge.not_doing,
    },
    {
      name: i18next.t('models.constants.selection_badge.status.reviewing'),
      status: [
        SELECTION_STATUS.STATUS_MAP.DOCUMENT_SCREENING.id,
      ],
      unread: selectionBadge.document_screening_unread,
      total: selectionBadge.document_screening,
    },
    {
      name: i18next.t('models.constants.selection_badge.status.scheduled_interviews'),
      status: [
        SELECTION_STATUS.STATUS_MAP.INTERVIEW_SET_DONE.id,
      ],
      unread: selectionBadge.interview_set_done_unread,
      total: selectionBadge.interview_set_done,
    },
    {
      name: i18next.t('models.constants.selection_badge.status.interviewing'),
      status: [
        SELECTION_STATUS.STATUS_MAP.INTERVIEW_DONE.id,
      ],
      unread: selectionBadge.interview_done_unread,
      total: selectionBadge.interview_done,
    },
    {
      name: i18next.t('models.constants.selection_badge.status.received_offer'),
      status: [
        SELECTION_STATUS.STATUS_MAP.OFFER.id,
      ],
      unread: selectionBadge.offer_unread,
      total: selectionBadge.offer,
    },
    {
      name: i18next.t('models.constants.selection_badge.status.accepted_offer'),
      status: [
        SELECTION_STATUS.STATUS_MAP.ACCEPTANCE.id,
      ],
      unread: selectionBadge.acceptance_unread,
      total: selectionBadge.acceptance,
    },
    {
      name: i18next.t('models.constants.selection_badge.status.join_date_confirmed'),
      status: [
        SELECTION_STATUS.STATUS_MAP.HIRING_SET_DONE.id,
      ],
      unread: selectionBadge.hiring_set_done_unread,
      total: selectionBadge.hiring_set_done,
    },
  ];
}

function getSelectionStatusFinished(selectionBadge) {
  return [
    {
      name: i18next.t('common.all'),
      status: [
        SELECTION_STATUS.STATUS_MAP.DECLINE_OFFER.id,
        SELECTION_STATUS.STATUS_MAP.REJECTION.id,
        SELECTION_STATUS.STATUS_MAP.HIRING_DONE.id,
        SELECTION_STATUS.STATUS_MAP.SELECTION_END.id,
      ],
      unread: 0,
      total: selectionBadge.selection_end_all,
    },
    {
      name: i18next.t('models.constants.selection_badge.status.hired'),
      status: [
        SELECTION_STATUS.STATUS_MAP.HIRING_DONE.id,
      ],
      unread: selectionBadge.hiring_done_unread,
      total: selectionBadge.hiring_done,
    },
    {
      name: i18next.t('models.constants.selection_badge.status.rejected'),
      status: [
        SELECTION_STATUS.STATUS_MAP.REJECTION.id,
      ],
      unread: selectionBadge.rejection_unread,
      total: selectionBadge.rejection,
    },
    {
      name: i18next.t('models.constants.selection_badge.status.declined_offer'),
      status: [
        SELECTION_STATUS.STATUS_MAP.DECLINE_OFFER.id,
      ],
      unread: selectionBadge.decline_offer_unread,
      total: selectionBadge.decline_offer,
    },
    {
      name: i18next.t('models.constants.selection_badge.status.completed_or_withdrawn'),
      status: [
        SELECTION_STATUS.STATUS_MAP.SELECTION_END.id,
      ],
      unread: selectionBadge.selection_end_unread,
      total: selectionBadge.selection_end,
    },
  ];
}

export {
  getSelectionStatusProgress,
  getSelectionStatusFinished,
};
