import { List } from 'immutable';
import * as types from './types';
import JobOffer from '@/models/ducks/shared/models/job-offer';
import Image from '@/models/ducks/shared/models/image';
import { SALARY_STATUS } from '@/models/ducks/job-offer/constants';

export const initialState = new JobOffer();

const changeClosedReasons = (state, action) => {
  const { key, value } = action.payload;
  const list = value
    ? state.getIn(['closed_job_offer_reason']).push(key)
    : state.getIn(['closed_job_offer_reason']).delete(state.getIn(['closed_job_offer_reason']).findIndex((index) => {
      return index === key;
    }));

  return state.setIn(['closed_job_offer_reason'], list);
};

const copy = (state, action) => {
  const copyingJobOffer = JobOffer.convert(action.payload.copyingJobOffer);

  const jobOfferSalaries = copyingJobOffer.job_offer_salaries.map((salary) => {
    return salary.merge({
      id: undefined,
      job_offer_id: undefined,
    });
  });
  const jobOfferUnitPrices = copyingJobOffer.job_offer_unit_prices.map((unitPrice) => {
    return unitPrice.merge({
      id: undefined,
      job_offer_id: undefined,
    });
  });
  return copyingJobOffer.merge({
    id: state.id,
    facility_id: state.facility_id,
    status: state.status,
    closed_job_offer_reason: state.closed_job_offer_reason,
    job_offer_salaries: jobOfferSalaries,
    job_offer_unit_prices: jobOfferUnitPrices,
  });
};

const changeSalaryField = (state, action) => {
  const { salary, key, value } = action.payload;
  const index = state.job_offer_salaries.findIndex(item => item.employment_type === salary.employment_type && item.salary_type === salary.salary_type);
  const target_salary = state.job_offer_salaries.find(item => item.employment_type === salary.employment_type && item.salary_type === salary.salary_type);

  return state.setIn(['job_offer_salaries', index], target_salary.set(key, value).set('status', SALARY_STATUS.ON));
};

const changeSalaryStatus = (state, action) => {
  const { salary } = action.payload;
  const salaries = state.job_offer_salaries.map((item) => {
    const status = (item.employment_type === salary.employment_type) ? SALARY_STATUS.ON : SALARY_STATUS.OFF;

    return item.set('status', status);
  });
  return state.update('job_offer_salaries', new List(salaries));
};

const changeSalaryStatusCheck = (state, action) => {
  const { salary } = action.payload;
  const index = state.job_offer_salaries.findIndex(item => item.employment_type === salary.employment_type);
  const status = (salary.status === SALARY_STATUS.OFF) ? SALARY_STATUS.ON : SALARY_STATUS.OFF;

  return state.setIn(['job_offer_salaries', index, 'status'], status);
};

const holdCurrentFeatures = (state, action) => {
  const { features } = action.payload;
  const checkedFeatureIds = state.features.filter(item => item.checked).map(item => item.id);

  return state.update('features', features.map((feature) => {
    return {
      ...feature,
      checked: checkedFeatureIds.includes(feature.id),
    };
  }));
};

const changeFeature = (state, action) => {
  const features = state.updateItemInList(action.payload.feature, state.features, (item, toUpdateItem) => {
    return item.id === toUpdateItem.id;
  });

  return state.update('features', features);
};

const addImages = (state, action) => {
  const { images } = action.payload;
  const newImages = images.reduce((accumulator, current) => {
    return accumulator.push(new Image(current));
  }, state.customer_images);

  return state.update('customer_images', newImages);
};

export default (state = initialState, action) => {
  // TODO:
  //  - SET, CREATE, GET あたりが別である必要がなければ統合
  switch (action.type) {
    case types.INIT:
      return initialState;
    case types.CREATE:
      return new JobOffer(action.payload.jobOffer);
    case types.SET:
      return action.payload.jobOffer;
    case types.GET:
      return JobOffer.convert(action.payload.jobOffer);
    case types.COPY:
      return copy(state, action);
    case types.CHANGE_FIELD:
      return state.update(action.payload.key, action.payload.value);
    case types.CHANGE_CLOSED_REASONS:
      return changeClosedReasons(state, action);
    case types.CHANGE_SALARY_FIELD:
      return changeSalaryField(state, action);
    case types.CHANGE_SALARY_STATUS:
      return changeSalaryStatus(state, action);
    case types.CHANGE_SALARY_STATUS_CHECK:
      // 古い求人は給与形態をcheckboxで複数選択できてたので、給与形態を複数選択している求人を考慮
      return changeSalaryStatusCheck(state, action);
    case types.HOLD_CURRENT_FEATURES:
      return holdCurrentFeatures(state, action);
    case types.UPDATE_UNIT_PRICES:
      return state.update('job_offer_unit_prices', action.payload.unitPrices);
    case types.CHANGE_FEATURE:
      return changeFeature(state, action);
    case types.ADD_IMAGES:
      return addImages(state, action);
    default:
      return state;
  }
};
