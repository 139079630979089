import reducers from './reducers';
import * as passwordFormOperations from './operations';
import * as passwordFormTypes from './types';

export {
  passwordFormOperations,
  passwordFormTypes,
};

export default reducers;
