import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Badge = (props) => {
  const badgeClass = classNames({
    'c-badge': true,
    'c-badge--alert': props.alert,
    'c-badge--inline': props.inline,
    'c-badge--icon': props.icon,
    'c-badge--large': props.large,
    [`${ props.className }`]: props.className,
  });

  if (!props.value && !props.isDisplayZero) {
    return null;
  }

  // isDisplayZero 時には 0 を通すため、falsy チェックのやり方を変える
  if (props.value == null && props.isDisplayZero) {
    return null;
  }

  const num = props.value <= props.limit ? props.value : `${ props.limit }+`;

  return (
    <div className={ badgeClass }>
      { num }
    </div>
  );
};

Badge.propTypes = {
  alert: PropTypes.bool,
  inline: PropTypes.bool,
  icon: PropTypes.bool,
  large: PropTypes.bool,
  value: PropTypes.number,
  limit: PropTypes.number,
  className: PropTypes.string,
  isDisplayZero: PropTypes.bool,
};

Badge.defaultProps = {
  alert: false,
  inline: false,
  icon: false,
  large: false,
  limit: 999,
  value: 0,
  isDisplayZero: false,
};

export default Badge;
