import { Record, Map, List } from 'immutable';

const properties = {
  caption: '',
  filter_type: 0,
  job_offer_name: '',
  job_offer_id: '',
  facility_id: '',
  ignore_ids: new List(),
  pagination: Map({
    limit: 20,
    page: 1,
  }),
};

const ImagesSearchFormRecord = Record(properties);

export default class ImagesSearchForm extends ImagesSearchFormRecord {
  update(key, value) {
    if (key === 'ignore_ids') {
      return this.setIgnoreIds(key, value);
    }

    return this.set(key, value);
  }

  setIgnoreIds(key, ignoreIds) {
    return this.set(key, ignoreIds instanceof List ? ignoreIds : new List(ignoreIds));
  }
}
