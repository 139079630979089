import * as timezone from '@/utils/timezone';
import * as actions from './actions';
import MessageApi from '@/models/ducks/shared/api/message';

const check = () => {
  return (dispatch, getState) => {
    const { scoutSendingCounterInitialized } = getState();

    return MessageApi.showScoutSendCountResetNotice()
      .then((response) => {
        const date = response.data.show_scout_send_count_reset_notice ? scoutSendingCounterInitialized.initialized : timezone.newDateISOString();

        if (timezone.isThisMonth(date)) {
          return dispatch(actions.save(date));
        }
        return dispatch(actions.init());
      });
  };
};

const save = () => {
  return dispatch => dispatch(actions.save(timezone.newDateISOString()));
};

export {
  check,
  save,
};
