import { Record, List } from 'immutable';
import SETTINGS from '@/models/ducks/shared/constants/settings';
import { ESTABLISHED_MONTH_TYPE, CONTACT_TYPE, FACILITY_STATUS } from '@/models/ducks/facility/constants';
import Contact from '@/models/ducks/shared/models/contact';
import Image from '@/models/ducks/shared/models/image';
import DateHelper from '@/utils/date';

const properties = {
  id: undefined,
  customer_id: undefined,
  name: '',
  facility_form_id: undefined,
  facility_form_name: '',
  establish_year: undefined,
  establish_month: undefined,
  establish_day: undefined,
  postal_code: '',
  prefecture_id: undefined,
  prefecture_name: '',
  city_id: undefined,
  address_etc: '',
  access: '-',
  address: '',
  status: FACILITY_STATUS.STATUS.OPENED,
  lat: 47.6062095,
  lon: -122.31921,
  zoom: 15,
  number_of_beds: '',
  emergency_designation: '',
  open_time: '',
  holiday: '',
  average_patients: '',
  ambulance_per_year: '',
  staff_info: '',
  fulltime_doctor_info: '',
  parttime_doctor_info: '',
  equip_info: '',
  director_name: '',
  director_history: '',
  has_dormitory: undefined, // 社宅・寮
  has_nursery: undefined, // 託児所
  prescription_kind: '',
  avarage_prescription_number: '',
  visiting_area: '',
  target_age: '',
  capacity: '',
  brand: '',
  offer_menu: '',
  price_per_customer: '',
  customer_base: '',
  claim_postal_code: '',
  claim_prefecture_id: undefined,
  claim_city_id: undefined,
  claim_address_etc: '',
  claim_in_charge_post: '',
  claim_in_charge_position: '',
  claim_in_charge_family_name: '',
  claim_in_charge_first_name: '',
  claim_tel: '',
  customer_images: new List(),
  features: new List(),
  contacts: new List(),
  job_offers: new List(),
  created_at: '',
  updated_at: '',
  image_url: '',
  transportation: '',
  corresponding_disease_nursing: '',
  additional_reimbursement: '',
  policy: '',
  program: '',
  children_per_staff: '',
  event_schedule: '',
  patient_data: '',
  infection_control_measures: '',
};

const FacilityRecord = Record(properties);
export default class Facility extends FacilityRecord {
  static convert(raw) {
    const facility = new Facility(raw)
      .update('features', raw.features)
      .update('contacts', Contact.convertMultiple(raw.contacts))
      .update('customer_images', raw.customer_images);
    return facility;
  }

  update(key, value) {
    let val = value;

    if (key.includes('_id') || key.includes('has_')) {
      val *= 1;
    }
    if (key === 'features') {
      return this.setFeatures(val);
    }
    if (key === 'contacts') {
      return this.setContacts(val);
    }
    if (key === 'customer_images') {
      return this.setImages(key, val);
    }
    const updated = this.updateEstablish(key, val);
    if (updated) {
      return updated;
    }
    return this.set(key, val);
  }

  hasFacilityForm() {
    return Object.keys(SETTINGS.FACILITY_FORMS).map((form) => {
      return SETTINGS.FACILITY_FORMS[form];
    }).includes(this.facility_form_id);
  }

  isHospital() {
    return this.facility_form_id === SETTINGS.FACILITY_FORMS.hospital;
  }

  isClinic() {
    return this.facility_form_id === SETTINGS.FACILITY_FORMS.clinic;
  }

  isDentistry() {
    return this.facility_form_id === SETTINGS.FACILITY_FORMS.dentistry;
  }

  isRelaxation() {
    return this.facility_form_id === SETTINGS.FACILITY_FORMS.relaxation;
  }

  isWelfare() {
    return this.facility_form_id === SETTINGS.FACILITY_FORMS.welfare;
  }

  isPharmacy() {
    return this.facility_form_id === SETTINGS.FACILITY_FORMS.pharmacy;
  }

  isNursingStation() {
    return this.facility_form_id === SETTINGS.FACILITY_FORMS.nursing_station;
  }

  isChildCenter() {
    return this.facility_form_id === SETTINGS.FACILITY_FORMS.child_center;
  }

  isOther() {
    return this.facility_form_id === SETTINGS.FACILITY_FORMS.other;
  }

  isSalon() {
    return this.facility_form_id === SETTINGS.FACILITY_FORMS.salon;
  }

  setFeatures(features) {
    const immutableFeatures = features instanceof List ? features : new List(features);
    immutableFeatures.forEach((feature) => {
      if (!Object.prototype.hasOwnProperty.call(feature, 'checked')) {
        Object.assign(feature, { checked: false });
      }
    });
    return this.set('features', immutableFeatures);
  }

  setContacts(contacts) {
    const immutableContacts = contacts instanceof List ? contacts : new List(contacts);
    return this.set('contacts', immutableContacts);
  }

  addContact(contact = new Contact({ contact_type: CONTACT_TYPE.BASIC })) {
    const immutableContact = contact instanceof Contact ? contact : new Contact(contact);
    const contacts = this.contacts.push(immutableContact);
    return this.update('contacts', contacts);
  }

  removeContact(index) {
    const contacts = this.contacts.remove(index);
    return this.update('contacts', contacts);
  }

  setImages(key, images) {
    let newImages = null;

    // Listの中にImageではない場合は、Imageへ変換する
    if (images instanceof List) {
      if (images.get(0) === undefined || images.get(0) instanceof Image) {
        return this.set(key, images);
      }
    }

    newImages = new List();
    images.forEach((image) => {
      newImages = newImages.push(new Image(image));
    });
    return this.set(key, newImages);
  }

  hasDormitory() {
    return this.has_dormitory === 1;
  }

  hasNursery() {
    return this.has_nursery === 1;
  }

  updateEstablish(key, val) {
    const emptyValue = '';
    if (key === 'establish_year' && !val) {
      return this.set(key, val)
        .set('establish_month', emptyValue)
        .set('establish_day', emptyValue);
    }
    if (key === 'establish_month') {
      const monthType = DateHelper.getMonthType(val);
      if (monthType === ESTABLISHED_MONTH_TYPE.MONTH) {
        return this.set('establish_month', val);
      }

      return this.set('establish_month', emptyValue)
        .set('establish_day', emptyValue);
    }
    return false;
  }

  isNew() {
    return this.id === undefined;
  }

  // list中の特定のitemを更新して、新しいlistを返す
  updateItemInList(toUpdateItem, list, compare) {
    const index = list.findIndex((item) => {
      return compare(item, toUpdateItem);
    });
    return list.setIn([index], toUpdateItem);
  }
}
