import * as types from './types';

const show = (text, isAlert) => {
  return {
    type: types.SHOW,
    payload: {
      text,
      isAlert,
    },
  };
};

const hide = () => {
  return {
    type: types.HIDE,
  };
};

export {
  show,
  hide,
};
