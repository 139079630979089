import * as types from './types';

export const initialState = {
  counter: 0,
  displayCounter: 0,
  total: 200,
};

const countRemaining = (action) => {
  const { data } = action.payload.response;

  return {
    counter: data.remaining_count,
    displayCounter: data.remaining_count,
    total: data.total_count,
  };
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.COUNT_REMAINING:
      return countRemaining(action);
    case types.SUBTRACT:
      return {
        ...state,
        displayCounter: state.counter - action.payload.subtractionNum,
      };
    default:
      return state;
  }
};
