import * as types from './types';

export const initialState = {
  targetEl: null,
  targetEllipsis: null,
  content: '',
  isVisible: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SHOW:
      return {
        targetEl: action.payload.targetEl,
        targetEllipsis: action.payload.targetEllipsis,
        content: action.payload.content,
        isVisible: true,
      };
    case types.HIDE:
      return initialState;
    default:
      return state;
  }
};
