import { SagaIterator } from 'redux-saga';
import i18next from 'i18next';
import { put, takeLatest, delay } from 'redux-saga/effects';
import * as actions from './actions';
import * as CONSTANTS from './constants';
import * as APP_CONSTANTS from '@/constants';

export function* handleUpdateCompletion(): SagaIterator {
  yield put(actions.showNotification({
    message: i18next.t(CONSTANTS.MESSAGE.UPDATE_COMPLETION),
  }));
  yield delay(APP_CONSTANTS.NOTIFICATION_TIMEOUT);
  yield put(actions.hideNotification());
}

export function* watchEmailEdit(): SagaIterator {
  yield takeLatest(actions.HANDLE_UPDATE_COMPLETION, handleUpdateCompletion);
}
