import axios, { AxiosInstance, AxiosPromise } from 'axios';
import { trackPromise } from 'react-promise-tracker';

type PromiseTrackerOption = Partial<{
  area: string;
  isLoaderHidden: boolean;
}>;

class ApiClient {
  client: AxiosInstance

  constructor() {
    this.client = axios;
  }

  createRequest = ({
    method,
    path,
    payload,
    promiseTrackerConfig,
  }: {
    method: AxiosInstance['post'] & AxiosInstance['get'] & AxiosInstance['put'] & AxiosInstance['delete'];
    path: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    payload?: any;
    promiseTrackerConfig?: PromiseTrackerOption;
  }): AxiosPromise => {
    if (promiseTrackerConfig?.isLoaderHidden) {
      return method(path, {
        ...payload,
      });
    }
    return trackPromise(method(path, {
      ...payload,
    }), promiseTrackerConfig?.area);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get = (path: string, payload?: any, promiseTrackerConfig?: PromiseTrackerOption): AxiosPromise => {
    return this.createRequest({
      method: this.client.get,
      path,
      payload,
      promiseTrackerConfig,
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  post = (path: string, payload?: any, promiseTrackerConfig?: PromiseTrackerOption): AxiosPromise => {
    return this.createRequest({
      method: this.client.post,
      path,
      payload,
      promiseTrackerConfig,
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  put = (path: string, payload?: any, promiseTrackerConfig?: PromiseTrackerOption): AxiosPromise => {
    return this.createRequest({
      method: this.client.put,
      path,
      payload,
      promiseTrackerConfig,
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  delete = (path: string, payload?: any, promiseTrackerConfig?: PromiseTrackerOption): AxiosPromise => {
    return this.createRequest({
      method: this.client.delete,
      path,
      payload,
      promiseTrackerConfig,
    });
  }
}

const apiClient = new ApiClient();

export {
  ApiClient,
  apiClient,
};
