import reducers from './reducers';
import * as scoutedJobOfferHistoryOperations from './operations';
import * as scoutedJobOfferHistoryTypes from './types';

export {
  scoutedJobOfferHistoryOperations,
  scoutedJobOfferHistoryTypes,
};

export default reducers;
