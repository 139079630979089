import axios from 'axios';

export default {
  getUnitPrices(jobCategoryId) {
    return axios.get('/api/customers/job_offer_unit_prices/', {
      params: {
        job_category_id: jobCategoryId,
      },
    });
  },
};
