import qs from 'qs';
import * as actions from './actions';

const init = () => {
  return dispatch => dispatch(actions.init());
};

const restoreFromCache = (cache) => {
  return dispatch => dispatch(actions.set(cache));
};

const changeField = (key, value) => {
  return dispatch => dispatch(actions.changeField(key, value));
};

const updateFromUrlQuery = (params) => {
  if (!params) {
    return dispatch => dispatch(actions.noop());
  }
  const query = params.split('?')[1];
  const parsed = qs.parse(query);

  return dispatch => dispatch(actions.updateFromUrlQuery(parsed));
};

const changePage = (page) => {
  return dispatch => dispatch(actions.changePage(page));
};

const resetPage = () => {
  return dispatch => dispatch(actions.resetPage());
};

export {
  init,
  restoreFromCache,
  changeField,
  updateFromUrlQuery,
  changePage,
  resetPage,
};
