import Cookie from 'js-cookie';
import * as timezone from '@/utils/timezone';

const isSecure = () => /^https/.test(document.location.protocol);

// NOTE:
// ・EmployerページでUsers2016Controllerを使用時にタイムゾーンが利用されるようにした（現在求人のプレビュー表示のみUsers2016Controllerが使われている。）
// ・Users側にある`client/users/js/timezone.js`をコピーしてきて、オペレータログイン時はタイムゾーンの変更をする分岐を追加した。
// ・名前はわかりやすいようにTimezone → TimezoneCookieへと変えた
//
class TimezoneCookie {
  constructor() {
    this.init();
  }

  init = () => {
    const now = timezone.newDate();
    const cookieOffset = this.currentTimezoneOffset();
    const jsOffset = now.getTimezoneOffset() * -1;
    const jsOffsetString = jsOffset.toString();
    if (!cookieOffset || jsOffsetString !== cookieOffset) {
      this.setOffsetCookie(jsOffsetString);
    }
    if (typeof Intl === 'object' && typeof Intl.DateTimeFormat === 'function' && typeof Intl.DateTimeFormat().resolvedOptions === 'function') {
      const timeZoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
      if (timeZoneName) {
        this.setTimezoneCookie(timeZoneName);
      }
    }
  };

  currentTimezoneOffset = () => {
    const cookieOffset = Cookie.get('tz_offset');
    if (cookieOffset) {
      return cookieOffset;
    }
    return null;
  };

  setTimezoneCookie = (timeZoneName: string) => {
    Cookie.set('tz_name', timeZoneName, { expires: 365, secure: isSecure() });
  }

  setOffsetCookie = (offset: string) => {
    Cookie.set('tz_offset', offset, { expires: 365, secure: isSecure() });
  }
}
export { TimezoneCookie };
