import reducers from './reducers';
import * as profileTabOperations from './operations';
import * as profileTabTypes from './types';

export {
  profileTabOperations,
  profileTabTypes,
};

export default reducers;
