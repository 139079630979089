import * as actions from './actions';

const scrollToTop = () => {
  setTimeout(() => {
    window.scrollTo(0, 1);
  }, 0);
};

const init = () => {
  return dispatch => dispatch(actions.init());
};

const sync = (ID, pageNum, isStayOnCurrentScrollPosition) => {
  return (dispatch) => {
    if (!isStayOnCurrentScrollPosition) {
      scrollToTop();
    }

    return dispatch(actions.update(ID, pageNum));
  };
};

export {
  init,
  sync,
};
