export default {
  STATUS_MAP: {
    NOT_DOING: {
      id: 1,
      label: 'models.constants.selection_list.status.applied',
    },
    DOCUMENT_SCREENING: {
      id: 2,
      label: 'models.constants.selection_list.status.reviewing',
    },
    INTERVIEW_SET_DONE: {
      id: 11,
      label: 'models.constants.selection_list.status.scheduled_interviews',
    },
    INTERVIEW_DONE: {
      id: 3,
      label: 'models.constants.selection_list.status.interviewing',
    },
    OFFER: {
      id: 7,
      label: 'models.constants.selection_list.status.received_offer',
    },
    ACCEPTANCE: {
      id: 8,
      label: 'models.constants.selection_list.status.accepted_offer',
    },
    HIRING_SET_DONE: {
      id: 12,
      label: 'models.constants.selection_list.status.join_date_confirmed',
      helpText: 'models.constants.selection_list.status.join_date_confirmed_helper_text',
    },
    HIRING_DONE: {
      id: 13,
      label: 'models.constants.selection_list.status.hired',
      helpText: 'models.constants.selection_list.status.hired_helper_text',
    },
    REJECTION: {
      id: 10,
      label: 'models.constants.selection_list.status.rejected',
    },
    DECLINE_OFFER: {
      id: 9,
      label: 'models.constants.selection_list.status.declined_offer',
    },
    SELECTION_END: {
      id: 14,
      label: 'models.constants.selection_list.status.completed_or_withdrawn',
    },
  },
};
