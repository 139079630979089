import * as types from './types';
import Page from './model';

export const initialState = new Page();

export default (state = initialState, action) => {
  switch (action.type) {
    case types.INIT:
      return initialState;
    case types.UPDATE:
      return state.update(action.payload.ID, action.payload.pageNum);
    default:
      return state;
  }
};
