import { Record, List } from 'immutable';

const properties = {
  members: new List(),
  total: 0,
  count: 0,
  limit: 20,
  unread_member_ids: new List(),
};

const MembersRecord = Record(properties);
export default class Members extends MembersRecord {
  getTargetMembers(ids) {
    return this.members.filter(m => ids.includes(m.id));
  }
}
