import { Record, List } from 'immutable';
import { FACILITY_STATUS } from '@/models/ducks/facility/constants';
import FacilityJobOffer from '@/models/ducks/shared/models/facility-job-offer-job-offer';

const properties = {
  id: undefined,
  customer_id: undefined,
  name: '',
  facility_form_id: undefined,
  facility_form_name: '',
  establish_year: undefined,
  establish_month: undefined,
  establish_day: undefined,
  postal_code: '',
  prefecture_id: undefined,
  prefecture_name: '',
  city_id: undefined,
  address_etc: '',
  access: '',
  status: FACILITY_STATUS.STATUS.OPENED,
  lat: 47.6062095,
  lon: -122.31921,
  zoom: 15,
  number_of_beds: '',
  emergency_designation: '',
  open_time: '',
  holiday: '',
  average_patients: '',
  ambulance_per_year: '',
  staff_info: '',
  fulltime_doctor_info: '',
  parttime_doctor_info: '',
  equip_info: '',
  director_name: '',
  director_history: '',
  has_dormitory: undefined, // 社宅・寮
  has_nursery: undefined, // 託児所
  prescription_kind: '',
  avarage_prescription_number: '',
  visiting_area: '',
  target_age: '',
  capacity: '',
  claim_postal_code: '',
  claim_prefecture_id: undefined,
  claim_city_id: undefined,
  claim_address_etc: '',
  claim_in_charge_post: '',
  claim_in_charge_position: '',
  claim_in_charge_family_name: '',
  claim_in_charge_first_name: '',
  claim_tel: '',
  features: new List(),
  contacts: new List(),
  job_offers: new List(),
  created_at: '',
  updated_at: '',
  image_url: '',
};

const FacilityJobOfferFacilityRecord = Record(properties);
export default class FacilityJobOfferFacility extends FacilityJobOfferFacilityRecord {
  static convert(raw) {
    return new FacilityJobOfferFacility(raw)
      .update('features', raw.features)
      .update('contacts', raw.contacts)
      .update('job_offers', raw.job_offers);
  }

  update(key, value) {
    const val = value;

    if (key === 'features') {
      return this.setFeatures(val);
    }
    if (key === 'contacts') {
      return this.setContacts(val);
    }
    if (key === 'job_offers') {
      return this.setJobOffers(val);
    }
    return this.set(key, val);
  }

  setFeatures(features) {
    const immutableFeatures = features instanceof List ? features : new List(features);
    return this.set('features', immutableFeatures);
  }

  setContacts(contacts) {
    const immutableContacts = contacts instanceof List ? contacts : new List(contacts);
    return this.set('contacts', immutableContacts);
  }

  setJobOffers(jobOffers) {
    let newJobOffers = null;
    if (jobOffers === null || jobOffers === undefined) {
      newJobOffers = new List();
    } else if (jobOffers instanceof List) {
      newJobOffers = jobOffers;
    } else {
      newJobOffers = new List();
      jobOffers.forEach((jobOffer) => {
        newJobOffers = newJobOffers.push(new FacilityJobOffer(jobOffer));
      });
    }
    return this.set('job_offers', newJobOffers);
  }
}
