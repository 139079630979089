const MENU_ITEMS = [
  {
    id: 0,
    name: 'models.constants.searches_menu.menu_items.menu_1',
  },
  {
    id: 1,
    name: 'models.constants.searches_menu.menu_items.menu_2',
  },
  {
    id: 2,
    name: 'models.constants.searches_menu.menu_items.menu_3',
  },
  {
    id: 3,
    name: 'models.constants.searches_menu.menu_items.menu_4',
  },
  {
    id: 4,
    name: 'models.constants.searches_menu.menu_items.menu_5',
  },
];

const MENU_LABEL = {
  DEFAULT: 0,
  MANUAL: 1,
  RECOMMENDED: 2,
  RECEIVED_FAVORITES: 3,
  SCOUTED_MEMBERS: 4,
};

export default {
  MENU_ITEMS,
  MENU_LABEL,
};
