import { Record, List } from 'immutable';

const MessageGroupsRecord = Record({
  messageGroups: List(),
  page: 1,
  total: 0,
});

export default class MessageGroups extends MessageGroupsRecord {
  update(key, value) {
    return this.set(key, value);
  }
}
