import * as types from './types';

const get = (templates) => {
  return {
    type: types.GET,
    payload: {
      templates,
    },
  };
};

export {
  get,
};
