import React from 'react';
import { withTranslation, Trans } from 'react-i18next';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Icon from '@/components/desktop/icon';
import IconText from '@/components/desktop/icon-text';
import { StaticHoc } from '@/components/desktop/static-component';
import SETTINGS from '@/models/ducks/shared/constants/settings';

class SiteInfo extends React.Component {
  static propTypes = {
    t: PropTypes.func,
  }

  static defaultProps = {
  }

  shouldComponentUpdate() {
    return false;
  }

  render() {
    const { t } = this.props;
    return (
      <div className="c-site-info">
        <div className="c-site-info__contact">
          <div className="c-site-info__contact-item">
            <span className="c-site-info__contact-title">
              <Trans i18nKey="components.site_info.customer_support_html" />
            </span>
            <br />
          </div>

          <div className="c-site-info__contact-item u-mt-5">
            <div className="c-site-info__contact-time">
              Mon-Fri, 9:00 AM - 6:00 PM
            </div>
            <IconText text={ SETTINGS.SUPPORT_TEL } className="c-site-info__tel">
              <Icon
                name="phone"
                color="bluegrey"
                width="12"
                height="12"
              />
            </IconText>
            <IconText text={ SETTINGS.SUPPORT_MAIL } className="c-site-info__mail">
              <Icon
                name="mail"
                color="bluegrey"
                width="12"
                height="12"
              />
            </IconText>
          </div>

          <div className="c-site-info__contact-item u-mt-5">
            <Link to="/rule/" className="c-inline-group__item-s" target="_blank">
              <p className="c-link u-fs-lh-x-small-short">Terms of Use</p>
            </Link>
            <Link to="/rule#privacyPolicy" className="c-inline-group__item-s" target="_blank">
              <p className="c-link u-fs-lh-x-small-short">Privacy Policy</p>
            </Link>
          </div>
        </div>

        <div className="c-site-info__rule">
          <small className="c-site-info__copyright">©2022 MEDLEY US, INC.</small>
        </div>
      </div>
    );
  }
}

export default withTranslation()(StaticHoc(SiteInfo));
