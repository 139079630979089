import * as types from './types';
import Selection from '@/models/ducks/shared/models/selection';

export const initialState = new Selection();

export default (state = initialState, action) => {
  switch (action.type) {
    case types.INIT:
      return initialState;
    case types.SET:
      return action.payload.selection;
    default:
      return state;
  }
};
