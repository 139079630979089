import * as types from './types';
import { List } from 'immutable';

export const initialState = List([]);

const update = (state, action) => {
  const { index } = action.payload;
  const isOpened = state.get(index);

  return state.set(index, !isOpened);
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.INIT:
      return List(Array(action.payload.size).fill(false));
    case types.UPDATE:
      return update(state, action);
    default:
      return state;
  }
};
