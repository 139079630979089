import * as actions from './actions';
import JobOfferSuggestApi from '@/models/ducks/shared/api/job-offer-suggest';
import { JOB_OFFER_TYPE } from '@/models/ducks/job-offer/constants';

const init = (query) => {
  return dispatch => JobOfferSuggestApi.getSuggestions(query, JOB_OFFER_TYPE.JOB_MEDLEY, 1)
    .then((response) => {
      return dispatch(actions.init({
        suggest: response.data.suggest,
        total: response.data.total,
      }));
    });
};

const get = (query) => {
  return dispatch => JobOfferSuggestApi.getSuggestions(query, JOB_OFFER_TYPE.JOB_MEDLEY, 1)
    .then((response) => {
      return dispatch(actions.get({
        suggest: response.data.suggest,
      }));
    });
};

const getMore = (query, page) => {
  return dispatch => JobOfferSuggestApi.getSuggestions(query, JOB_OFFER_TYPE.JOB_MEDLEY, page)
    .then((response) => {
      return dispatch(actions.getMore({
        suggest: response.data.suggest,
      }));
    });
};

export {
  init,
  get,
  getMore,
};
