import * as types from './types';

const init = () => {
  return {
    type: types.INIT,
  };
};

const toggle = (ID) => {
  return {
    type: types.TOGGLE,
    payload: {
      ID,
    },
  };
};

const show = (ID) => {
  return {
    type: types.SHOW,
    payload: {
      ID,
    },
  };
};

const hide = (ID) => {
  return {
    type: types.HIDE,
    payload: {
      ID,
    },
  };
};

export {
  init,
  toggle,
  show,
  hide,
};
