import * as actions from './actions';

const change = (status) => {
  return dispatch => dispatch(actions.change(status));
};

const init = () => {
  return dispatch => dispatch(actions.init());
};

export {
  change,
  init,
};
