import reducers from './reducers';
import * as selectionSearchLabelOperations from './operations';
import * as selectionSearchLabelTypes from './types';

export {
  selectionSearchLabelOperations,
  selectionSearchLabelTypes,
};

export default reducers;
