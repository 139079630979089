import * as actions from './actions';

const init = () => {
  return dispatch => dispatch(actions.init());
};

const set = (jobOfferStatusMap) => {
  return dispatch => dispatch(actions.set(jobOfferStatusMap));
};

const get = (currentJobOfferStatus) => {
  if (currentJobOfferStatus === undefined) {
    return dispatch => dispatch(actions.noop());
  }
  return dispatch => dispatch(actions.get(currentJobOfferStatus));
};

export {
  init,
  set,
  get,
};
