import * as actions from './actions';
import BadgeApi from './api';

const get = (params) => {
  return dispatch => BadgeApi.messages(params)
    .then((response) => {
      return dispatch(actions.get(response.data.badge));
    });
};

export {
  get,
};
