import axios from 'axios';

const MAX_LENGTH = 5;

export default {
  search(postalCode) {
    if (postalCode.length > MAX_LENGTH) {
      return Promise.reject();
    }

    return axios.get('/api/towns/', { params: { postal_code: postalCode } });
  },
};
