import * as actions from './actions';
import CustomerSearchConditionsApi from '@/models/ducks/shared/api/customer-search-conditions';

const init = () => {
  return dispatch => dispatch(actions.init());
};

const set = (condition) => {
  return dispatch => dispatch(actions.set(condition));
};

const update = (condition) => {
  const formated = condition.format();
  return dispatch => CustomerSearchConditionsApi.update(formated)
    .then(() => {
      return dispatch(actions.set(condition));
    });
};

export {
  init,
  set,
  update,
};
