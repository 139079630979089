import * as actions from './actions';
import MessageApi from '@/models/ducks/shared/api/message';
import SelectionApi from '@/models/ducks/shared/api/selection';

const show = (messageThreadId) => {
  return dispatch => MessageApi.messages(messageThreadId)
    .then((response) => {
      return dispatch(actions.show(response.data));
    });
};

const destroy = () => {
  return dispatch => dispatch(actions.destroy());
};

const add = (message) => {
  return dispatch => dispatch(actions.add(message));
};

const markReadSelectionByApplicationId = (applicationId) => {
  return dispatch => SelectionApi.markReadByApplication(applicationId)
    .then(() => {
      return dispatch(actions.noop());
    });
};

export {
  show,
  destroy,
  add,
  markReadSelectionByApplicationId,
};
