import { createAction, ActionType } from 'typesafe-actions';
import { NormalizedResultEntities } from './reducers';

export const MERGE = 'ENTITIES/MERGE';
export const ADD_ITEM = 'ENTITIES/ADD_ITEM';
export const UPDATE_ITEM = 'ENTITIES/UPDATE_ITEM';

export const merge = createAction(MERGE, (entities: NormalizedResultEntities) => ({
  entities,
}))();
export const addItem = createAction(ADD_ITEM, (entities: NormalizedResultEntities, name) => ({
  entities,
  name,
}))();
export const updateItem = createAction(UPDATE_ITEM, (entities: NormalizedResultEntities, name) => ({
  entities,
  name,
}))();

export type EntitiesActionTypes =
  ActionType<typeof merge> |
  ActionType<typeof addItem> |
  ActionType<typeof updateItem>;
