import { ESTABLISHED_MONTH_TYPE } from '@/models/ducks/facility/constants';
import stringHelper from '@/utils/string';
import * as timezone from '@/utils/timezone';

// timezone.tsと違ってこのdate.tsはタイムゾーンに原則関係がない日付に関する処理のモジュール

export default {

  /**
   * JMで扱う年の配列を返却する
   *
   * @return {Array} 1838..{current_year + 2}
   */
  getYears(sort = 'desc') {
    const years = [];
    let year = 1838;
    const endYear = timezone.newDate().getFullYear() + 2;
    for (year; year <= endYear; year += 1) {
      years.push({ id: year, name: year });
    }
    return sort === 'desc' ? years.reverse() : years;
  },

  /**
   * JMで扱う月の配列を返却する
   *
   * @return {Array} 01..12
   */
  getMonths(year?: string) {
    const months: { id: number | string; name: string }[] = [];
    if (!year) {
      return months;
    }
    for (let i = 1; i <= 12; i += 1) {
      months.push({ id: i, name: this.applyZeroPadding(i) });
    }
    return months;
  },

  getAllMonths() {
    const months: { id: number | string; name: string }[] = [];
    for (let i = 1; i <= 12; i += 1) {
      months.push({ id: i, name: this.applyZeroPadding(i) });
    }
    return months;
  },

  getDays(year: number, month: string) {
    const days = [];
    const date = new Date(year, parseInt(month, 10), 0);
    const lastDate = date.getDate();

    for (let i = 1; i <= lastDate; i += 1) {
      days.push({ id: i, name: this.applyZeroPadding(i) });
    }
    return days;
  },

  get31Days() {
    const days: { id: number | string; name: string }[] = [];
    for (let i = 1; i <= 31; i += 1) {
      days.push({ id: i, name: this.applyZeroPadding(i) });
    }
    return days;
  },

  getRangeYears(startYear: number, end: number, isDesc?: boolean) {
    const years = [];
    let start = startYear;

    while (start <= end) {
      years.push(start);
      start += 1;
    }
    if (isDesc) {
      years.sort((a, b) => {
        return b - a;
      });
    }
    return years;
  },

  parseDashedDate(date?: string) {
    const dates = (date && date.length) ? date.split('-') : [];

    return {
      year: Number(dates[0]),
      month: Number(dates[1]),
      date: Number(dates[2]),
    };
  },

  getMonthType(value: string) {
    if (stringHelper.toInteger(value) >= 1 && stringHelper.toInteger(value) <= 12) {
      return ESTABLISHED_MONTH_TYPE.MONTH;
    }
    return ESTABLISHED_MONTH_TYPE.DEFAULT;
  },

  excludePastMonths(months: { id: number }[], to: number) {
    return months.filter((month) => {
      return month.id >= to;
    });
  },

  excludePastDates(dates: { id: number }[], to: number) {
    return dates.filter((date) => {
      return date.id >= to;
    });
  },

  excludeFutureMonths(months: { id: number }[], from: number) {
    return months.filter((month) => {
      return month.id <= from;
    });
  },

  excludeFutureDates(dates: { id: number }[], from: number) {
    return dates.filter((date) => {
      return date.id <= from;
    });
  },

  formatDate(dateVal: string, delimiter = '/') {
    const { year, month, date } = this.parseDashedDate(dateVal);

    return [year, this.applyZeroPadding(month), this.applyZeroPadding(date)].join(delimiter);
  },

  applyZeroPadding(num: number) {
    return (`0${ num }`).slice(-2);
  },
};
