import * as types from './types';

const init = () => {
  return {
    type: types.INIT,
  };
};

const set = (form) => {
  return {
    type: types.SET,
    payload: {
      form,
    },
  };
};

const changeField = (key, value) => {
  return {
    type: types.CHANGE_FIELD,
    payload: {
      key,
      value,
    },
  };
};

const updateFromUrlQuery = (parsedQuery) => {
  return {
    type: types.UPDATE_FROM_URL_QUERY,
    payload: {
      parsedQuery,
    },
  };
};

const noop = () => {
  return {
    type: types.NOOP,
  };
};

const changePage = (page) => {
  return {
    type: types.CHANGE_PAGE,
    payload: {
      page,
    },
  };
};

const resetPage = () => {
  return {
    type: types.RESET_PAGE,
  };
};

export {
  init,
  set,
  changeField,
  updateFromUrlQuery,
  noop,
  changePage,
  resetPage,
};
