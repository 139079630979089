import * as types from './types';
import * as timezone from '@/utils/timezone';

const init = (page, total) => {
  return {
    type: types.INIT,
    payload: {
      page,
      total,
    },
  };
};

const save = (page) => {
  return {
    type: types.SAVE,
    payload: {
      page,
    },
  };
};

const saveIndex = (page, index) => {
  return {
    type: types.SAVE_INDEX,
    payload: {
      page,
      index,
      date: timezone.newDate(),
    },
  };
};

const check = () => {
  return {
    type: types.CHECK,
  };
};

const reset = (page) => {
  return {
    type: types.RESET,
    payload: {
      page,
    },
  };
};

const noop = () => {
  return {
    type: types.NOOP,
  };
};

export {
  init,
  save,
  saveIndex,
  check,
  reset,
  noop,
};
