import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import * as Sentry from '@sentry/browser';
import 'unfetch/polyfill';
import introspection from '../__generated__/introspection';

const httpLink = new HttpLink({
  credentials: 'include',
  uri: ({ operationName }) => `/api/customers/graphql/${operationName}`,
});

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors && graphQLErrors.length > 0) {
    // TODO: RESTでいう400系も500系も全て200を返すので、Sentryに送るべきものとそうでないものを選り分ける必要がある。が現状全て送ってOKなので一旦この実装
    Sentry.withScope(scope => {
      scope.setExtra('operationName', operation.operationName);
      scope.setExtra('messages', graphQLErrors.map(e => e.message));
      Sentry.captureMessage(`GraphQL:${operation.operationName} がエラーを返しました`);
    });
    return;
  }
  if (networkError) {
    Sentry.withScope(scope => {
      scope.setExtra('operationName', operation.operationName);
      Sentry.captureException(networkError);
    });
  }
});

export const client = new ApolloClient({
  link: ApolloLink.from([errorLink, httpLink]),
  defaultOptions: {
    query: {
      errorPolicy: 'all',
    },
    mutate: {
      errorPolicy: 'all',
    },
  },
  cache: new InMemoryCache({
    possibleTypes: introspection.possibleTypes,
  }),
});
