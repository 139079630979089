import * as types from './types';
import { List } from 'immutable';
import Members from './model';

export const initialState = new Members();

const get = (action) => {
  const { members, count, total, limit } = action.payload.response.data;

  return new Members({
    members: List(members),
    count,
    total,
    limit,
    unread_member_ids: action.payload.response.data.unread_member_ids,
  });
};

const getMore = (state, action) => {
  const { members, count, total, limit } = action.payload.response.data;

  return new Members({
    members: state.members.concat(List(members)),
    count,
    total,
    limit,
    unread_member_ids: state.unread_member_ids.concat(state.unread_member_ids),
  });
};

const markAsNew = (state, action) => {
  const { unreadMemberIds } = action.payload;
  const members = state.members.map((member) => {
    return {
      ...member,
      new_arrival: unreadMemberIds.includes(member.id),
    };
  });

  return state.set('members', members);
};

const markAsScouted = (state, action) => {
  const { scoutedMemberIds } = action.payload;
  const members = state.members.map((member) => {
    return {
      ...member,
      scouted: member.scouted || scoutedMemberIds.includes(member.id),
    };
  });

  return state.set('members', members);
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.INIT:
      return initialState;
    case types.GET:
      return get(action);
    case types.GET_MORE:
      return getMore(state, action);
    case types.MARK_AS_NEW:
      return markAsNew(state, action);
    case types.MARK_AS_SCOUTED:
      return markAsScouted(state, action);
    default:
      return state;
  }
};
