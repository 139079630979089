import { Record, Map } from 'immutable';
import { isValid } from 'date-fns';
import * as timezone from '@/utils/timezone';
import dateHelper from '@/utils/date';
import { INTERVIEW_METHOD } from '../constants/interview';
import selectionHelper from '@/utils/selection';

const SelectionRecord = Record({
  id: '',
  read: '',
  status: 0,
  note: '',
  member: new Map(),
  facility: new Map(),
  job_offer: new Map(),
  application: new Map(),
  application_id: '',
  hired_at: '',
  hiring_note: '',
  scheduling_hired_at: '',
  job_offer_salary: '',
  qualification_id: '',
  message_id: '',
  message_title: '',
  updated_at: '',
  reason: '',
  note_visible: false,
  detail_visible: false,
  interview: {
    id: 0,
    room_id: '',
    interview_method: 0,
    start_datetime: '',
  },
});

export default class Selection extends SelectionRecord {
  update(key, value) {
    return this.set(key, value);
  }

  getYearsAgoFromApplication() {
    const applicationCreatedDate = timezone.newDate(this.application.created_at);
    return applicationCreatedDate.getFullYear() - timezone.newDate().getFullYear();
  }

  getValidSchedulingHiredAtMonths(selectedDate) {
    const year = dateHelper.parseDashedDate(selectedDate).year;
    const applicationDate = timezone.newDate(this.application.created_at);
    const isAppliiedYearSelected = year === applicationDate.getFullYear();

    return isAppliiedYearSelected ? dateHelper.excludePastMonths(dateHelper.getMonths(year), applicationDate.getMonth() + 1) : dateHelper.getMonths(year, false);
  }

  getValidSchedulingHiredAtDates(selectedDate) {
    const year = dateHelper.parseDashedDate(selectedDate).year;
    const month = dateHelper.parseDashedDate(selectedDate).month;
    const applicationDate = timezone.newDate(this.application.created_at);
    const isAppliedYear = year === applicationDate.getFullYear();
    const isThisMonthSelected = month === applicationDate.getMonth() + 1;
    const isAppliedMonthAndYearSelected = isAppliedYear && isThisMonthSelected;

    return isAppliedMonthAndYearSelected ? dateHelper.excludePastDates(dateHelper.getDays(year, month), applicationDate.getDate()) : dateHelper.getDays(year, month);
  }

  getValidHiredAtMonths(selectedDate) {
    const { year } = dateHelper.parseDashedDate(selectedDate);
    const thisMonth = timezone.newDate().getMonth() + 1;
    const applicationDate = timezone.newDate(this.application.created_at);
    const isAppliedYearSelected = year === applicationDate.getFullYear();

    if (timezone.isThisYearByNum(year)) {
      return dateHelper.excludeFutureMonths(dateHelper.getMonths(year), thisMonth);
    } if (isAppliedYearSelected) {
      return dateHelper.excludePastMonths(dateHelper.getMonths(year), applicationDate.getMonth() + 1);
    }
    return dateHelper.getMonths(year);
  }

  isHiredAtMonthAvailable() {
    const { month } = dateHelper.parseDashedDate(this.hired_at);
    const months = this.getValidHiredAtMonths(this.hired_at);

    return months.map(v => Number(v.id)).includes(month);
  }

  getValidHiredAtDates(selectedDate) {
    const { year, month } = dateHelper.parseDashedDate(selectedDate);
    const applicationDate = timezone.newDate(this.application.created_at);
    const isAppliedYear = year === applicationDate.getFullYear();
    const isAppliedMonthSelected = month === applicationDate.getMonth() + 1;
    const isAppliedMonthAndYearSelected = isAppliedYear && isAppliedMonthSelected;
    const today = timezone.newDate().getDate();

    if ((timezone.isThisYearByNum(year) && timezone.isThisMonthByNum(month))) {
      return dateHelper.excludeFutureDates(dateHelper.getDays(year, month), today);
    } if (isAppliedMonthAndYearSelected) {
      return dateHelper.excludePastDates(dateHelper.getDays(year, month), applicationDate.getDate());
    }
    return dateHelper.getDays(year, month);
  }

  isHiredAtDateAvailable() {
    const { date } = dateHelper.parseDashedDate(this.hired_at);
    const dates = this.getValidHiredAtDates(this.hired_at);

    return dates.map(v => Number(v.id)).includes(date);
  }

  isValidHiredAtDate() {
    const date = dateHelper.parseDashedDate(this.hired_at);

    const dateObj = new Date(date.year, date.month - 1, date.date);
    return isValid(dateObj)
      && dateObj.getFullYear() === date.year
      && dateObj.getMonth() === (date.month - 1)
      && dateObj.getDate() === date.date;
  }

  isValidSchedulingHiredAtDate() {
    const { year, month, date } = dateHelper.parseDashedDate(this.scheduling_hired_at);

    if (year === 0 || month === 0 || date === 0) {
      return false;
    }
    const schedulingHiredAtDate = new Date(year, month - 1, date);
    const applicationCreatedDate = timezone.newDate(this.application.created_at);
    const oneDayBeforeApplicationCreatedDate = new Date(
      applicationCreatedDate.getFullYear(),
      applicationCreatedDate.getMonth(),
      applicationCreatedDate.getDate() - 1,
    );

    if (schedulingHiredAtDate < oneDayBeforeApplicationCreatedDate) {
      return false;
    }

    const dateObj = new Date(year, month - 1, date);
    return isValid(dateObj)
      && dateObj.getFullYear() === year
      && dateObj.getMonth() === (month - 1)
      && dateObj.getDate() === date;
  }

  isUnchangedHiringApplicationForm(selectedSelection) {
    return (
      (
        dateHelper.formatDate(this.scheduling_hired_at) === dateHelper.formatDate(selectedSelection.scheduling_hired_at)
        || dateHelper.formatDate(this.scheduling_hired_at) === dateHelper.formatDate(selectedSelection.hired_at)
      )
      && (this.hiring_note || '') === (selectedSelection.hiring_note || '')
      && Number(this.job_offer_salary) === Number(selectedSelection.job_offer_salary)
      && Number(this.qualification_id) === Number(selectedSelection.qualification_id)
    );
  }

  generateSchedulingHiredAtMonthVal() {
    return dateHelper.parseDashedDate(this.scheduling_hired_at).month;
  }

  generateSchedulingHiredAtDateVal() {
    return dateHelper.parseDashedDate(this.scheduling_hired_at).date;
  }

  generateHiredAtMonthVal() {
    return dateHelper.parseDashedDate(this.hired_at).month;
  }

  generateHiredAtDateVal() {
    return dateHelper.parseDashedDate(this.hired_at).date;
  }

  isOfflineInterviewTimePassed() {
    return this.interview && this.interview.interview_method === INTERVIEW_METHOD.OFFLINE_INTERVIEW && timezone.isPast(timezone.newDate(`${this.interview.start_datetime}`));
  }

  isOfflineInterviewValid() {
    return [
      this.interview,
      this.interview.interview_method === INTERVIEW_METHOD.OFFLINE_INTERVIEW,
      !this.isOfflineInterviewTimePassed(),
      !this.interview.canceled,
    ].every(v => v);
  }

  isInterviewValid() {
    return [
      (this.isOfflineInterviewValid()),
      !this.isMemberUnavailable(),
    ].every(v => v);
  }

  isMemberUnavailable() {
    return [
      this.member.deleted,
      this.member.stopped,
    ].some(v => v);
  }

  isAbleToSendInterviewGuideMessage() {
    return [
      selectionHelper.isInterviewSetDone(this.status),
      !this.isMemberUnavailable(),
    ].every(v => v);
  }
}
