const GET = 'state/ducks/saved-customer-search-condition-list/GET';
const GET_MORE = 'state/ducks/saved-customer-search-condition-list/GET_MORE';
const UPDATE = 'state/ducks/saved-customer-search-condition-list/UPDATE';
const DESTROY = 'state/ducks/saved-customer-search-condition-list/DESTROY';

export {
  GET,
  GET_MORE,
  UPDATE,
  DESTROY,
};
