import * as types from './types';
import Toggle from './model';

export const initialState = new Toggle();

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SHOW:
      return state.update(action.payload.ID, true);
    case types.HIDE:
      return state.update(action.payload.ID, false);
    case types.TOGGLE:
      return state.update(action.payload.ID, !state.get(action.payload.ID));
    case types.INIT:
      return initialState;
    default:
      return state;
  }
};
