import SelectionApi from '@/models/ducks/shared/api/selection';
import * as actions from './actions';

const init = () => {
  return dispatch => dispatch(actions.init());
};

const search = (condition) => {
  return dispatch => SelectionApi.getSelections(condition)
    .then((response) => {
      const { selections, count, total, limit } = response.data;

      return dispatch(actions.get({ selections, count, total, limit }));
    });
};

const searchMore = (condition) => {
  return dispatch => SelectionApi.getSelections(condition)
    .then((response) => {
      const { selections, count, total, limit } = response.data;

      return dispatch(actions.getMore({ selections, count, total, limit }));
    });
};

const toggle = (key, id) => {
  return dispatch => dispatch(actions.toggle(key, id));
};

const syncStatusWithMessageType = (selectedSelection, messageType) => {
  return dispatch => dispatch(actions.syncStatusWithMessageType(selectedSelection, messageType));
};

const syncSelectionAfterMessageSend = (id) => {
  return dispatch => SelectionApi.show(id)
    .then((response) => {
      return dispatch(actions.update(response.data.selection));
    });
};

const update = (edited) => {
  return dispatch => SelectionApi.update(edited)
    .then((response) => {
      return dispatch(actions.update(response.data.selection));
    });
};

const markAsRead = (edited) => {
  return dispatch => SelectionApi.markRead(edited.id)
    .then((response) => {
      return dispatch(actions.update(response.data.selection));
    });
};

const markAsReadByApplication = (applicationId) => {
  return dispatch => SelectionApi.markReadByApplication(applicationId)
    .then(() => {
      return dispatch(actions.noop());
    });
};

const updateNote = (edited) => {
  return dispatch => SelectionApi.update(edited)
    .then((response) => {
      return dispatch(actions.update(response.data.selection));
    });
};

const updateStatus = (edited, currentSelectionStatus) => {
  return dispatch => SelectionApi.update(edited, currentSelectionStatus)
    .then((response) => {
      return dispatch(actions.updateStatus(response.data.selection));
    })
    .catch((err) => {
      const message = err?.response?.data?.message || 'エラーが発生しました';
      // eslint-disable-next-line no-alert
      window.alert(message);
      window.location.reload();
    });
};

const requestApplication = (edited) => {
  return dispatch => SelectionApi.update(edited)
    .then((response) => {
      return dispatch(actions.requestApplication(response.data.selection));
    });
};

export {
  init,
  search,
  searchMore,
  toggle,
  syncStatusWithMessageType,
  syncSelectionAfterMessageSend,
  update,
  markAsRead,
  markAsReadByApplication,
  updateNote,
  updateStatus,
  requestApplication,
};
