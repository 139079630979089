import CustomerSearchConditionsApi from '@/models/ducks/shared/api/customer-search-conditions';
import * as actions from './actions';

const get = (pagination) => {
  return dispatch => CustomerSearchConditionsApi.searchRecommend(pagination)
    .then((response) => {
      return dispatch(actions.get(response));
    });
};

const getMore = (pagination) => {
  return dispatch => CustomerSearchConditionsApi.searchRecommend(pagination)
    .then((response) => {
      return dispatch(actions.getMore(response));
    });
};

const update = (condition) => {
  return dispatch => dispatch(actions.update(condition));
};

const updateWithoutCondition = (condition) => {
  return dispatch => CustomerSearchConditionsApi.updateWithoutCondition(condition)
    .then(() => {
      return dispatch(actions.update(condition));
    });
};

export {
  get,
  getMore,
  update,
  updateWithoutCondition,
};
