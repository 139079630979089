import OperatorApi from './api';
import * as actions from './actions';

const get = () => {
  return dispatch => OperatorApi.show()
    .then((response) => {
      localStorage.setItem('isOperatorLogin', response.data.operator);
      return dispatch(actions.get(response.data));
    });
};

export {
  get,
};
