/* eslint-disable no-multi-spaces */
const URL_PATH = {
  SIGN_IN: '/customers/sign_in',
  SIGN_OUT: '/customers/sign_out',
  CUSTOMERS_NEW: '/customers/new',
  CUSTOMERS_USER_NEW: '/customers/customer_users/new',
  INVITATIONS: '/customers/invitations',
  HOME: '/',
  PROVISIONAL: '/provisional',
  CONFIRM_RULE: '/customers/confirm_rule',
  PASSWORD_RESET_FORM: '/customers/reset_form',
  PASSWORD_EDIT_FROM_EMAIL: '/api/customers/auth/password/edit',
  POST_JOB: '/customers/post_job',
  POST_JOB_REGISTER_FACILITY: '/customers/post_job/register_facility',
  POST_JOB_FOR_FACILITY: '/customers/post_job/facility',
  JOB_OFFERS_NEW: '/customers/job_offers/new',
  SERVER_ERROR: '/customers/500',
  FORBIDDEN_ERROR: '/customers/403',
  NOT_FOUND_ERROR: '/customers/404',
  FACILITIES: '/customers/facilities',
  JOB_OFFERS: '/customers/job_offers',
};

const PDF_PATH = {
  PRICE_LIST: '/files/price_list.pdf',
  PRICE_LIST_OLD: '/files/price_list_old.pdf',
  USERS_MANUAL_PC: '/files/users_manual.pdf',
  USERS_MANUAL_SP: '/files/users_manual_sp.pdf',
};

const REPLACE_KEY = 'id';

const SIDE_NAV_UNNECESSARY_PATH = {
  SIGN_IN: '/customers/sign_in',                                                                                                     // サインイン画面
  CUSTOMERS_EDIT: '/customers/edit',                                                                                                 // 契約情報画面
  CUSTOMERS_EMAIL_EDIT: '/customers/email_edit',                                                                                     // メールアドレス変更画面
  CUSTOMERS_NOTIFICATION_EDIT: '/customers/notification_edit',                                                                       // お知らせメール画面
  CUSTOMERS_INVITATIONS: '/customers/invitations',                                                                                   // 招待からのサインアップページ
  CUSTOMERS_PASSWORD: '/customers/password',                                                                                         // パスワード変更画面
  CUSTOMERS_MODE_SWITCH: '/customers/mode_switch',                                                                                   // 自己作成モード切り替え画面
  CUSTOMERS_MESSAGE_TEMPLATES: '/customers/message_templates',                                                                       // メッセージテンプレート一覧画面
  CUSTOMERS_AUTO_REPLY_MESSAGES: '/customers/auto_reply_messages',                                                                   // 自動返信一覧画面
  CUSTOMERS_AUTO_REPLY_MESSAGES_NEW: '/customers/auto_reply_messages/new',                                                           // 自動返信新規作成画面
  CUSTOMERS_AUTO_REPLY_MESSAGES_EDIT: `/customers/auto_reply_messages/${REPLACE_KEY}/edit`,                                          // 自動返信編集画面
  CUSTOMERS_AUTO_REPLY_MESSAGES_JOB_OFFERS: `/customers/auto_reply_messages/${REPLACE_KEY}/job_offers`,                              // 自動返信紐付け画面
  CUSTOMERS_VIDEO_INTERVIEWS: '/customers/video_interviews',                                                                         // 動画選考一覧画面
  CUSTOMERS_VIDEO_INTERVIEWS_NEW: '/customers/video_interviews/new',                                                                 // 動画選考新規作成画面
  CUSTOMERS_VIDEO_INTERVIEWS_EDIT: `/customers/video_interviews/${REPLACE_KEY}`,                                                     // 動画選考編集画面
  CUSTOMERS_OFFICIAL_HIRING_PAGES: '/customers/official_hiring_pages',                                                               // 採用サイト一覧画面
  CUSTOMERS_OFFICIAL_HIRING_PAGES_NEW: '/customers/official_hiring_pages/new',                                                       // 採用サイト新規作成画面
  CUSTOMERS_OFFICIAL_HIRING_PAGES_EDIT: `/customers/official_hiring_pages/${REPLACE_KEY}/edit`,                                      // 採用サイト編集画面
  CUSTOMERS_OFFICIAL_HIRING_PAGES_JOB_OFFERS_NEW: `/customers/official_hiring_pages/${REPLACE_KEY}/job_offers/new`,                  // 採用サイト求人作成画面
  CUSTOMERS_OFFICIAL_HIRING_PAGES_JOB_OFFERS_EDIT: `/customers/official_hiring_pages/${REPLACE_KEY}/job_offers/${REPLACE_KEY}/edit`, // 採用サイト求人編集画面
  CUSTOMERS_CUSTOMER_USERS: '/customers/customer_users',                                                                             // ユーザー管理
  CUSTOMERS_CUSTOMER_USERS_EDIT: `/customers/customer_users/${REPLACE_KEY}`,                                                         // ユーザー管理＞ユーザー情報・通知設定
  CUSTOMERS_CUSTOMER_USERS_EDIT_ME: '/customers/customer_users/edit',                                                                // 個人設定＞ユーザー情報・通知設定
  CUSTOMERS_CUSTOMER_USERS_NOTIFICATION_FACILITIES: `/customers/customer_users/${REPLACE_KEY}/notifications/facilities`,             // ユーザー管理＞通知を受け取る施設
  CUSTOMERS_NOTIFICATION_FACILITIES_ME: '/customers/notification_facilities',                                                        // 個人設定＞通知を受け取る施設
  CUSTOMERS_CUSTOMER_USERS_INVITE: '/customers/customer_users/invite',                                                               // ユーザーを一括招待・再招待
  PRICE_LIST: '/files/price_list.pdf',                                                                                               // 新採用単価下限表
  PRICE_LIST_OLD: '/files/price_list_old.pdf',                                                                                       // 旧採用単価下限表
  USERS_MANUAL_PC: '/files/users_manual.pdf',                                                                                        // ご利用ガイドPC
  USERS_MANUAL_SP: '/files/users_manual_sp.pdf',                                                                                     // ご利用ガイドSP
};

const SIDE_NAV_BLACK_LIST = [
  SIDE_NAV_UNNECESSARY_PATH.SIGN_IN,
  SIDE_NAV_UNNECESSARY_PATH.CUSTOMERS_EDIT,
  SIDE_NAV_UNNECESSARY_PATH.CUSTOMERS_EMAIL_EDIT,
  SIDE_NAV_UNNECESSARY_PATH.CUSTOMERS_NOTIFICATION_EDIT,
  SIDE_NAV_UNNECESSARY_PATH.CUSTOMERS_INVITATIONS,
  SIDE_NAV_UNNECESSARY_PATH.CUSTOMERS_PASSWORD,
  SIDE_NAV_UNNECESSARY_PATH.CUSTOMERS_MODE_SWITCH,
  SIDE_NAV_UNNECESSARY_PATH.CUSTOMERS_MESSAGE_TEMPLATES,
  SIDE_NAV_UNNECESSARY_PATH.CUSTOMERS_AUTO_REPLY_MESSAGES,
  SIDE_NAV_UNNECESSARY_PATH.CUSTOMERS_AUTO_REPLY_MESSAGES_NEW,
  SIDE_NAV_UNNECESSARY_PATH.CUSTOMERS_AUTO_REPLY_MESSAGES_EDIT,
  SIDE_NAV_UNNECESSARY_PATH.CUSTOMERS_AUTO_REPLY_MESSAGES_JOB_OFFERS,
  SIDE_NAV_UNNECESSARY_PATH.CUSTOMERS_VIDEO_INTERVIEWS,
  SIDE_NAV_UNNECESSARY_PATH.CUSTOMERS_VIDEO_INTERVIEWS_NEW,
  SIDE_NAV_UNNECESSARY_PATH.CUSTOMERS_VIDEO_INTERVIEWS_EDIT,
  SIDE_NAV_UNNECESSARY_PATH.CUSTOMERS_OFFICIAL_HIRING_PAGES,
  SIDE_NAV_UNNECESSARY_PATH.CUSTOMERS_OFFICIAL_HIRING_PAGES_NEW,
  SIDE_NAV_UNNECESSARY_PATH.CUSTOMERS_OFFICIAL_HIRING_PAGES_EDIT,
  SIDE_NAV_UNNECESSARY_PATH.CUSTOMERS_OFFICIAL_HIRING_PAGES_JOB_OFFERS_NEW,
  SIDE_NAV_UNNECESSARY_PATH.CUSTOMERS_OFFICIAL_HIRING_PAGES_JOB_OFFERS_EDIT,
  SIDE_NAV_UNNECESSARY_PATH.CUSTOMERS_CUSTOMER_USERS,
  SIDE_NAV_UNNECESSARY_PATH.CUSTOMERS_CUSTOMER_USERS_EDIT,
  SIDE_NAV_UNNECESSARY_PATH.CUSTOMERS_CUSTOMER_USERS_EDIT_ME,
  SIDE_NAV_UNNECESSARY_PATH.CUSTOMERS_CUSTOMER_USERS_NOTIFICATION_FACILITIES,
  SIDE_NAV_UNNECESSARY_PATH.CUSTOMERS_NOTIFICATION_FACILITIES_ME,
  SIDE_NAV_UNNECESSARY_PATH.CUSTOMERS_CUSTOMER_USERS_INVITE,
  SIDE_NAV_UNNECESSARY_PATH.PRICE_LIST,
  SIDE_NAV_UNNECESSARY_PATH.PRICE_LIST_OLD,
  SIDE_NAV_UNNECESSARY_PATH.USERS_MANUAL_PC,
  SIDE_NAV_UNNECESSARY_PATH.USERS_MANUAL_SP,
];

const CAN_SHOW_PAGE_WITHOUT_AUTHENTICATED_PATH_LIST = [
  URL_PATH.SIGN_IN,
  URL_PATH.SIGN_OUT,
  URL_PATH.CUSTOMERS_NEW,
  URL_PATH.CUSTOMERS_USER_NEW,
  URL_PATH.INVITATIONS,
  URL_PATH.PASSWORD_RESET_FORM,
  URL_PATH.PASSWORD_EDIT_FROM_EMAIL,
  URL_PATH.SERVER_ERROR,
  URL_PATH.FORBIDDEN_ERROR,
  URL_PATH.NOT_FOUND_ERROR,
];

const CAN_SHOW_PAGE_WITHOUT_APPROVAL_PATH_LIST = [
  ...CAN_SHOW_PAGE_WITHOUT_AUTHENTICATED_PATH_LIST,
  URL_PATH.POST_JOB,
  URL_PATH.POST_JOB_REGISTER_FACILITY,
  URL_PATH.FACILITIES,
  URL_PATH.JOB_OFFERS,
];

export {
  URL_PATH,
  PDF_PATH,
  SIDE_NAV_BLACK_LIST,
  CAN_SHOW_PAGE_WITHOUT_AUTHENTICATED_PATH_LIST,
  CAN_SHOW_PAGE_WITHOUT_APPROVAL_PATH_LIST,
};
