// Localized const
const CLOSED_JOB_OFFER_REASONS = [
  {
    id: 1,
    name: 'models.constants.job_offer.closed_job_offer_reasons.sufficient_candidates',
  },
  {
    id: 2,
    name: 'models.constants.job_offer.closed_job_offer_reasons.want_to_edit_post_contents',
  },
  {
    id: 3,
    name: 'models.constants.job_offer.closed_job_offer_reasons.not_satisfy_with_feature_support',
  },
  {
    id: 4,
    name: 'models.constants.job_offer.closed_job_offer_reasons.using_other_services',
  },
  {
    id: 5,
    name: 'models.constants.job_offer.closed_job_offer_reasons.others',
  },
];

const SALARY_TYPE = {
  SALARY_TYPE_HOURLY: 1,
  SALARY_TYPE_MONTHLY: 2,
  SALARY_TYPE_YEARLY: 3,
};

const EMPLOYMENT_TYPE = {
  REGULAR: 1,
  PART: 2,
};

const EMPLOYMENT_TYPES = [
  {
    id: 1,
    name: 'models.constants.job_offer.employment_types.full_time',
    salary_type: SALARY_TYPE.SALARY_TYPE_MONTHLY,
  },
  {
    id: 2,
    name: 'models.constants.job_offer.employment_types.part_time',
    salary_type: SALARY_TYPE.SALARY_TYPE_HOURLY,
  },
];

const SALARY_TYPES = [
  {
    id: SALARY_TYPE.SALARY_TYPE_HOURLY,
    name: 'models.constants.job_offer.salary_types.hourly',
  },
  {
    id: SALARY_TYPE.SALARY_TYPE_MONTHLY,
    name: 'models.constants.job_offer.salary_types.monthly',
  },
  {
    id: SALARY_TYPE.SALARY_TYPE_YEARLY,
    name: 'models.constants.job_offer.salary_types.yearly',
  },
];

const JOB_OFFER_EXISTENCE = {
  JOB_OFFER_EXISTENCE_TYPES: [
    {
      id: 0,
      name: 'models.constants.job_offer.job_offer_existence.unspecified',
    },
    {
      id: 1,
      name: 'models.constants.job_offer.job_offer_existence.has_post',
    },
    {
      id: 2,
      name: 'models.constants.job_offer.job_offer_existence.no_post',
    },
  ],
  JOB_OFFER_EXISTENCE_UNSPECIFIED: 0,
  JOB_OFFER_EXISTENCE_YES: 4,
};

const SALARY_STATUS = {
  OFF: 0,
  ON: 1,
};

const JOB_OFFER_STATUS = {
  ALL_STATUSES: [0, 1, 2, 3, 4, 5, 6, 7, 8],
  OPENED_STATUSES: [3, 5, 7],
  CLOSED_STATUSES: [2, 6, 8],
  REVISING_STATUSES: [7, 8],
  PENDING_STATUSES: [1, 5, 6],
  CLOSED: 2,
  OPENED: 3,
  DELETED: 4,
  DRAFT: 0,
  NEW: -1,

  STATUS: {
    DRAFT: 0, // 下書き
    PENDING: 1, // 承認待ち
    CLOSED: 2, // 承認済み(応募受付終了)
    OPENED: 3, // 承認済み(掲載中)
    DELETED: 4, // 削除済み
    OPENED_WITH_PENDING: 5, // 掲載中の情報を編集し申請した状態
    CLOSED_WITH_PENDING: 6, // 応募受付終了の情報を編集し申請した状態
    OPENED_WITH_REVISING: 7, // 掲載中の情報を修正した状態
    CLOSED_WITH_REVISING: 8, // 応募受付終了の情報を修正した状態
  },

  POSTING_STATUS: [
    {
      id: 0,
      name: 'models.constants.job_offer.job_offer_status.posting_status.draft',
      key: 'draft',
    },
    {
      id: 1,
      name: 'models.constants.job_offer.job_offer_status.posting_status.pending',
      key: 'pending',
    },
    {
      id: 2,
      name: 'models.constants.job_offer.job_offer_status.posting_status.closed',
      key: 'closed',
    },
    {
      id: 3,
      name: 'models.constants.job_offer.job_offer_status.posting_status.opened',
      key: 'opened',
    },
    {
      id: 4,
      name: 'models.constants.job_offer.job_offer_status.posting_status.deleted',
      key: 'deleted',
    },
    {
      id: 5,
      name: 'models.constants.job_offer.job_offer_status.posting_status.opened_with_pending',
      key: 'opened_with_pending',
    },
    {
      id: 6,
      name: 'models.constants.job_offer.job_offer_status.posting_status.closed_with_pending',
      key: 'closed_with_pending',
    },
    {
      id: 7,
      name: 'models.constants.job_offer.job_offer_status.posting_status.opened_with_revising',
      key: 'opened_with_revising',
    },
    {
      id: 8,
      name: 'models.constants.job_offer.job_offer_status.posting_status.closed_with_revising',
      key: 'closed_with_revising',
    },
  ],

  STATUS_LABELS: [
    {
      id: 0,
      mode: 'self_publishing',
      disapproved: false,
      name: 'models.constants.job_offer.job_offer_status.status_labels.draft',
      labels: [
        { name: 'models.constants.job_offer.job_offer_status.status_labels.draft', color: 'semicircleGreen' },
      ],
    },
    {
      id: 2,
      mode: 'self_publishing',
      disapproved: false,
      name: 'models.constants.job_offer.job_offer_status.status_labels.closed',
      labels: [
        { name: 'models.constants.job_offer.job_offer_status.status_labels.closed', color: 'semicircleBlueGrey' },
      ],
    },
    {
      id: 3,
      mode: 'self_publishing',
      disapproved: false,
      name: 'models.constants.job_offer.job_offer_status.status_labels.opened',
      labels: [
        { name: 'models.constants.job_offer.job_offer_status.status_labels.opened', color: 'semicircleBlue' },
      ],
    },
  ],
};

const JOB_OFFER_TYPE = {
  ALL: 'ALL',
  JOB_MEDLEY: 'JMJobOffer',

  LABEL: {
    JOB_MEDLEY: 'JMJobOffer',
  },
} as const;

export {
  CLOSED_JOB_OFFER_REASONS,
  EMPLOYMENT_TYPE,
  EMPLOYMENT_TYPES,
  SALARY_TYPE,
  SALARY_TYPES,
  JOB_OFFER_EXISTENCE,
  SALARY_STATUS,
  JOB_OFFER_STATUS,
  JOB_OFFER_TYPE,
};
