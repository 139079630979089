import axios from 'axios';
import { JOB_OFFER_TYPE } from '@/models/ducks/job-offer/constants';

export default {
  getSuggestions(query, jobOfferType, page) {
    return axios
      .get('/api/customers/job_offers/v2/suggest', {
        params: {
          limit: 20,
          query,
          page: page || 1,
          job_offer_type: jobOfferType,
        },
      });
  },

  getSuggestionsForScout(query, page) {
    return axios
      .get('/api/customers/job_offers/v2/suggest_for_scout', {
        params: {
          limit: 20,
          page: page || 1,
          query,
          job_offer_type: JOB_OFFER_TYPE.JOB_MEDLEY,
        },
      });
  },

  getFacilityJobOfferSuggestions(query, facilitySuffix, page) {
    return axios
      .get('/api/customers/facilities_job_offers/v2/suggest', {
        params: {
          limit: 20,
          page: page || 1,
          query,
          facility_suffix: facilitySuffix,
        },
      });
  },
};
