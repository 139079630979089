import * as actions from './actions';
import JobCategoryGroupsApi from './api';

const get = () => {
  return dispatch => JobCategoryGroupsApi.all()
    .then((response) => {
      return dispatch(actions.get(response.data.job_category_groups));
    });
};

export {
  get,
};
