import Interceptor from './interceptor.json';
import Member from './member.json';
import Selection from './selection.json';

const Data = {};
Data.interceptor = Interceptor;
Data.member = Member;
Data.selection = Selection;

export default Data;
