import loadImage from 'blueimp-load-image';
import 'blueimp-canvas-to-blob';

export default {
  createFormData({ img, file, blobItems }) {
    return new Promise((resolve) => {
      const formData = new FormData();

      // 以下のpolyfillライブラリでie11以下対応
      // https://github.com/blueimp/JavaScript-Canvas-to-Blob
      img.canvas.toBlob((blob) => {
        formData.append('file', blob, file.name);
        blobItems.forEach((item, index) => {
          formData.append(item.name, item.value);

          if (index === blobItems.length - 1) {
            return resolve(formData);
          }

          return formData;
        });
      });
    });
  },

  createImgData(formFile) {
    const file = formFile;

    return new Promise((resolve) => {
      loadImage(file, (canvas) => {
        const imageData = canvas.toDataURL(file.type);

        resolve({
          canvas,
          file: imageData,
          width: canvas.width,
          height: canvas.height,
        });
      }, {
        canvas: true,
        orientation: true,
      });
    });
  },
};
