import * as types from './types';
import CustomerSearchCondition from '@/models/ducks/shared/models/customer-search-condition';

export const initialState = new CustomerSearchCondition();

export default (state = initialState, action) => {
  switch (action.type) {
    case types.INIT:
      return initialState;
    case types.SET:
      return action.payload.condition;
    case types.CHANGE_FIELD:
      return state.set(action.payload.key, action.payload.value);
    default:
      return state;
  }
};
