import * as types from './types';

const init = () => {
  return {
    type: types.INIT,
  };
};

const update = (ID, pageNum) => {
  return {
    type: types.UPDATE,
    payload: {
      ID,
      pageNum,
    },
  };
};

export {
  init,
  update,
};
