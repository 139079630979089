import * as types from './types';

export const initialState = {
  home: false,
  facilities: false,
  customerImages: false,
  searches: false,
  selections: false,
  messages: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.CHECK:
      return state;
    case types.SAVE:
      return {
        ...state,
        [action.payload.page]: true,
      };
    default:
      return state;
  }
};
