import * as types from './types';

const init = ({ suggest, total }) => {
  return {
    type: types.INIT,
    payload: {
      suggest,
      total,
    },
  };
};

const get = ({ suggest }) => {
  return {
    type: types.GET,
    payload: {
      suggest,
    },
  };
};

const getMore = ({ suggest }) => {
  return {
    type: types.GET_MORE,
    payload: {
      suggest,
    },
  };
};

export {
  init,
  get,
  getMore,
};
