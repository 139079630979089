import * as actions from './actions';

const init = () => {
  return dispatch => dispatch(actions.init());
};

const syncSize = (areas) => {
  return dispatch => dispatch(actions.syncSize(areas));
};

const sync = (areas) => {
  return dispatch => dispatch(actions.sync(areas));
};

const toggle = (id) => {
  return dispatch => dispatch(actions.toggle(id));
};

const updateId = (newId, index) => {
  return dispatch => dispatch(actions.updateId(newId, index));
};

export {
  init,
  syncSize,
  sync,
  toggle,
  updateId,
};
