import { Record, List, Map } from 'immutable';

const properties = {
  keyword: '',
  ignore_deleted_member: true,
  unread: false,
  unreplied: false,
  message_type: List(),
  job_offer_id: '',
  job_offer_name: '',
  job_offer: Map({
    prefecture_id: 0,
    job_category_id: 0,
    keyword: '',
  }),
  member: '',
  member_id: '',
};

const MessageGroupSearchFormRecord = Record(properties);

export default class MessageGroupSearchForm extends MessageGroupSearchFormRecord {
  update(key, value) {
    if (Array.isArray(key)) {
      return this.setIn(key, value);
    }
    return this.set(key, value);
  }

  isSpecified() {
    if (
      this.keyword === properties.keyword
      &&
      this.ignore_deleted_member === properties.ignore_deleted_member
      &&
      this.unread === properties.unread
      &&
      this.unreplied === properties.unreplied
      &&
      this.message_type.equals(properties.message_type)
      &&
      this.job_offer_id === properties.job_offer_id
      &&
      this.member === properties.member
      &&
      this.member_id === properties.member_id
    ) {
      return false;
    }
    return true;
  }

  isJobOfferSpecified() {
    if (
      this.getIn(['job_offer_id']) > 0
      ||
      this.getIn(['job_offer', 'keyword'])
      ||
      this.getIn(['job_offer', 'prefecture_id']) > 0
      ||
      this.getIn(['job_offer', 'job_category_id']) > 0
    ) {
      return true;
    }

    return false;
  }
}
