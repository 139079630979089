import * as types from './types';

const clearCustomer = () => {
  return {
    type: types.CLEAR,
  };
};

const createCustomer = (customer) => {
  return {
    type: types.CREATE,
    payload: {
      customer,
    },
  };
};

const updateCustomerFields = (key, value) => {
  return {
    type: types.UPDATE_FIELD,
    payload: {
      key,
      value,
    },
  };
};

const loadLeadCustomer = (lead) => {
  return {
    type: types.LOAD_LEAD,
    payload: {
      lead,
    },
  };
};

const noop = () => {
  return {
    type: types.NOOP,
  };
};

const fillCustomerAddresses = (town) => {
  return {
    type: types.FILL_ADDRESSES,
    payload: {
      town,
    },
  };
};

const fillCustomerInvoiceAddresses = (town) => {
  return {
    type: types.FILL_INVOICE_ADDRESSES,
    payload: {
      town,
    },
  };
};

const addImages = (images) => {
  return {
    type: types.ADD_IMAGES,
    payload: {
      images,
    },
  };
};

const deleteImage = (image) => {
  return {
    type: types.DELETE_IMAGE,
    payload: {
      image,
    },
  };
};

const updateImage = (image) => {
  return {
    type: types.UPDATE_IMAGE,
    payload: {
      image,
    },
  };
};

const setImageSize = ({ id, width, height }) => {
  return {
    type: types.SET_IMAGE_SIZE,
    payload: {
      id,
      width,
      height,
    },
  };
};

const sortImages = (oldIndex, newIndex) => {
  return {
    type: types.SORT_IMAGES,
    payload: {
      oldIndex,
      newIndex,
    },
  };
};

const save = (customer) => {
  return {
    type: types.SAVE,
    payload: {
      customer,
    },
  };
};

const clearInvoice = () => {
  return {
    type: types.CLEAR_INVOICE,
  };
};

export {
  clearCustomer,
  createCustomer,
  updateCustomerFields,
  loadLeadCustomer,
  fillCustomerAddresses,
  fillCustomerInvoiceAddresses,
  addImages,
  deleteImage,
  updateImage,
  setImageSize,
  sortImages,
  save,
  noop,
  clearInvoice,
};
