import * as types from './types';

const countRemaining = (response) => {
  return {
    type: types.COUNT_REMAINING,
    payload: {
      response,
    },
  };
};

const subtract = (subtractionNum) => {
  return {
    type: types.SUBTRACT,
    payload: {
      subtractionNum,
    },
  };
};

export {
  countRemaining,
  subtract,
};
