import { getType } from 'typesafe-actions';
import * as actions from './actions';
import { reduceWithImmer } from '@/reducer';
import { Announcement } from '@/api/announcements';
import { Customer } from '@/api/customers';
import { JobCategory } from '@/api/jobCategories';

export interface Normalized<T> {
  [key: string]: T;
}

export interface NormalizedResult {
  result: number[];
  entities: {
    announcements: Normalized<Announcement>;
    customers: Normalized<Customer>;
    jobCategories: Normalized<JobCategory>;
  };
}

export type NormalizedResultEntities = NormalizedResult['entities'] & {
  [key: string]: any;
};

export const initialState = {
  announcements: {},
  customers: {},
  jobCategories: {},
};

const entities = (state: NormalizedResultEntities = initialState, action: actions.EntitiesActionTypes): NormalizedResultEntities => {
  return reduceWithImmer(state, (draft) => {
    switch (action.type) {
      case getType(actions.merge):
        return {
          ...draft,
          ...action.payload.entities,
        };
      case getType(actions.addItem):
        draft[`${ action.payload.name }`].push(action.payload.entities[action.payload.name]);
        break;
      case getType(actions.updateItem):
        draft[`${ action.payload.name }`] = {
          ...draft[`${ action.payload.name }`],
          ...action.payload.entities[`${ action.payload.name }`],
        };
        break;
      default:
        return draft;
    }
    return draft;
  });
};

export {
  entities,
};
