import * as types from './types';

const check = (version) => {
  return {
    type: types.CHECK,
    payload: {
      version,
    },
  };
};

const save = (version) => {
  return {
    type: types.SAVE,
    payload: {
      version,
    },
  };
};

export {
  check,
  save,
};
