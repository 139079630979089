import { ValueOf } from '@/types';

const PAGINATION = {
  FACILITY_COPY: 'FACILITY_COPY',
  JOB_OFFER_COPY: 'JOB_OFFER_COPY',
  SEARCHES_MANUAL_CONDITIONS: 'SEARCHES_MANUAL_CONDITIONS',
  SEARCHES_RECOMMENDED_CONDITIONS: 'SEARCHES_RECOMMENDED_CONDITIONS',
  SEARCHES_SCOUTED_MEMBERS: 'SEARCHES_SCOUTED_MEMBERS',
  SEARCHES_RECEIVED_FAVORITES: 'SEARCHES_RECEIVED_FAVORITES',
  SEARCHES_RESULT_LIST: 'SEARCHES_RESULT_LIST',
  FACILITIES: 'FACILITIES',
  SELECTIONS: 'SELECTIONS',
  IMAGES_MANAGERS: 'IMAGES_MANAGERS',
  MESSAGE_TEMPLATES: 'MESSAGE_TEMPLATES',
} as const;

export type PaginationKey = ValueOf<typeof PAGINATION>;

export {
  PAGINATION,
};
