import { AxiosPromise } from 'axios';
import { apiClient } from '@/utils/ApiClient';

export const getAll = (): AxiosPromise => apiClient.get('/api/customers/news');

export type Announcement = {
  description: string;
  id: number;
  publish_datetime: string;
  title: string;
}
