import i18next from 'i18next';

const UNDEFINED = 0;
const NOT_SCOUT = 1;
const SCOUTED = 2;

class Scout {
  constructor() {
    this.UNDEFINED = UNDEFINED;
    this.NOT_SCOUT = NOT_SCOUT;
    this.SCOUTED = SCOUTED;
  }

  get labels() {
    return [
      {
        id: this.UNDEFINED,
        name: i18next.t('common.unspecified'),
        checked: (value) => {
          return value * 1 === this.UNDEFINED || value === undefined;
        },
      },
      {
        id: this.NOT_SCOUT,
        name: i18next.t('models.constants.customer_search_condition.scouting.not_scouted'),
        checked: (value) => {
          return value * 1 === this.NOT_SCOUT;
        },
      },
      {
        id: this.SCOUTED,
        name: i18next.t('models.constants.customer_search_condition.scouting.scouted'),
        checked: (value) => {
          return value * 1 === this.SCOUTED;
        },
      },
    ];
  }
}

const scoutUtils = new Scout();

export {
  scoutUtils,
};
