import Common from './common.json';
import Constants from './constants.json';
import Date from './date.json';
import Time from './time.json';

import Components from './components.json';
import Containers from './containers';
import Pages from './pages';
import Validations from './validations';
import Models from './models';
import Domains from './domains';
import Utils from './utils';

const Data = {};

Data.common = Common;
Data.constants = Constants;
Data.date = Date;
Data.time = Time;

Data.components = Components;
Data.containers = Containers;
Data.pages = Pages;
Data.validations = Validations;
Data.models = Models;
Data.domains = Domains;
Data.utils = Utils;

export default Data;
