import * as actions from './actions';

const init = () => {
  return dispatch => dispatch(actions.init());
};

const createFromCustomer = (customer) => {
  return dispatch => dispatch(actions.createFromCustomer(customer));
};

const changeField = (key, value) => {
  return dispatch => dispatch(actions.changeField(key, value));
};

export {
  init,
  createFromCustomer,
  changeField,
};
