import * as actions from './actions';
import NAV_TYPE from '@/models/ducks/shared/constants/member-search-nav-type';

const init = () => {
  return dispatch => dispatch(actions.init());
};

const addFromManual = (condition, memberIds) => {
  return dispatch => dispatch(actions.add({
    type: NAV_TYPE.MANUAL_CONDITION,
    id: condition.id,
    memberIds,
  }));
};

const addFromRecommended = (condition, memberIds) => {
  return dispatch => dispatch(actions.add({
    type: NAV_TYPE.RECOMMEND_CONDITION,
    id: condition.id,
    memberIds,
  }));
};

const addFromFavoriteJobOffer = (condition, memberIds) => {
  return dispatch => dispatch(actions.add({
    type: NAV_TYPE.FAVORITE_JOB_OFFER,
    id: condition.job_offer_id,
    memberIds,
  }));
};

const removeMemberIdsFromManual = (condition, memberIds) => {
  return dispatch => dispatch(actions.removeMemberIds({
    type: NAV_TYPE.MANUAL_CONDITION,
    id: condition.id,
    memberIds,
  }));
};

const removeMemberIdsFromRecommended = (condition, memberIds) => {
  return dispatch => dispatch(actions.removeMemberIds({
    type: NAV_TYPE.RECOMMEND_CONDITION,
    id: condition.id,
    memberIds,
  }));
};

const removeMemberIdsFromFavoriteJobOffer = (condition, memberIds) => {
  return dispatch => dispatch(actions.removeMemberIds({
    type: NAV_TYPE.FAVORITE_JOB_OFFER,
    id: condition.job_offer_id,
    memberIds,
  }));
};

export {
  init,
  addFromManual,
  addFromRecommended,
  addFromFavoriteJobOffer,
  removeMemberIdsFromManual,
  removeMemberIdsFromRecommended,
  removeMemberIdsFromFavoriteJobOffer,
};
