import * as actions from './actions';

const init = () => {
  return dispatch => dispatch(actions.init());
};

const create = (selection) => {
  return dispatch => dispatch(actions.create(selection));
};

const change = (key, value) => {
  return dispatch => dispatch(actions.change(key, value));
};

const updateToHiringDone = () => {
  return dispatch => dispatch(actions.updateToHiringDone());
};

export {
  init,
  create,
  change,
  updateToHiringDone,
};
