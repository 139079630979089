import reducers from './reducers';
import * as versionOperations from './operations';
import * as versionTypes from './types';

export {
  versionOperations,
  versionTypes,
};

export default reducers;
