import * as types from './types';

const init = () => {
  return {
    type: types.INIT,
  };
};

const add = ({ type, id, memberIds }) => {
  return {
    type: types.ADD,
    payload: {
      type,
      id,
      memberIds,
    },
  };
};

const removeMemberIds = ({ type, id, memberIds }) => {
  return {
    type: types.REMOVE_MEMBER_IDS,
    payload: {
      type,
      id,
      memberIds,
    },
  };
};

export {
  init,
  add,
  removeMemberIds,
};
