import * as actions from './actions';

const set = (image) => {
  return dispatch => dispatch(actions.set(image));
};

const changeField = (key, value) => {
  return dispatch => dispatch(actions.changeField(key, value));
};

export {
  set,
  changeField,
};
