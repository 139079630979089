import { Record } from 'immutable';

const properties = {
  id: undefined,
  job_offer_id: undefined,
  employment_type: '',
  employment_type_label: '',
  salary_type: '',
  salary_type_label: '',
  salary_bottom: '',
  salary_top: '',
  status: '',
};

const JobOfferSalaryRecord = Record(properties);
export default class JobOfferSalary extends JobOfferSalaryRecord {
  update(key, value) {
    return this.set(key, value);
  }
}

