import * as actions from './actions';

const INTERVAL = 5000;
let timer;

const hide = () => {
  return (dispatch) => {
    clearTimeout(timer);
    timer = null;
    return dispatch(actions.hide());
  };
};

const send = (text, isAlert) => {
  if (timer) {
    clearTimeout(timer);
    timer = null;
  }
  return (dispatch) => {
    dispatch(actions.show(text, isAlert));
    timer = setTimeout(() => {
      dispatch(hide());
    }, INTERVAL);
  };
};

export {
  send,
  hide,
};
