import { getType } from 'typesafe-actions';
import * as actions from './actions';
import { reduceWithImmer } from '@/reducer';

export const initialState = {
  email: '',
  notice: '',
};

export type ResetFormState = typeof initialState;

export const reducer = (state: ResetFormState = initialState, action: actions.ResetFormActionTypes): ResetFormState => {
  return reduceWithImmer(state, (draft) => {
    switch (action.type) {
      case getType(actions.init):
        return initialState;
      case getType(actions.showMessage):
        draft.notice = action.payload.message;
        break;
      default:
        return draft;
    }
    return draft;
  });
};
