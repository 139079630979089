import i18next from 'i18next';
import { Map, Record } from 'immutable';
import * as timezone from '@/utils/timezone';
import MESSAGE_TYPE from '@/models/ducks/shared/constants/message-type';
import { CUSTOMER_MESSAGE_TEMPLATE_TYPE } from '@/models/ducks/message-template/constants';
import { INTERVIEW_METHOD } from '../constants/interview';
import SELECTION_STATUS from '@/models/ducks/selection-list/constants';

const MessageRecord = Record({
  application_id: '',
  application_source: '',
  body: '',
  created_at: '',
  customer_id: '',
  customer_read_status: '',
  from: 1,
  id: '',
  job_offer_id: '',
  member_id: '',
  member: new Map(),
  member_read_status: '',
  message_type: MESSAGE_TYPE.NORMAL,
  replied_status: '',
  customer_replied: false,
  scout_member_id: '',
  to: 2,
  title: '',
  update_at: '',
  job_offer_url: '',
  message_careersheet: null,
  message_files: [],
  job_offer: new Map(),
  selection: {
    id: 0,
    status: 0,
  },
  message_remind_histories: [],
  interview_schedule: {
    day: '',
    time: '',
  },
  interview_location: '',

  interview: {
    id: 0,
    room_id: '',
    interview_method: 0,
    start_datetime: '',
    canceled: undefined,
  },

  member_selected_interview_dates: null,

  customer_suggested_interview_dates: null,
  preferred_interview_datetime_list: [],
  preferred_interview_methods: [],
  preferred_interview_location: '',

  licenses: [],

  forced_member_selected_interview_dates: null,

  errors: {},
});

const MESSAGE_TEMPLATE_MAP = [
  { MESSAGE_TYPE: MESSAGE_TYPE.OFFER, TEMPLATE_TYPE: CUSTOMER_MESSAGE_TEMPLATE_TYPE.OFFER_DEFAULT },
  { MESSAGE_TYPE: MESSAGE_TYPE.REJECTION, TEMPLATE_TYPE: CUSTOMER_MESSAGE_TEMPLATE_TYPE.REJECTION_DEFAULT },
  { MESSAGE_TYPE: MESSAGE_TYPE.ARRANGEMENT_INTERVIEW, TEMPLATE_TYPE: CUSTOMER_MESSAGE_TEMPLATE_TYPE.ARRANGEMENT_INTERVIEW_DEFAULT },
  { MESSAGE_TYPE: MESSAGE_TYPE.CANCEL_INTERVIEW, TEMPLATE_TYPE: CUSTOMER_MESSAGE_TEMPLATE_TYPE.CANCEL_INTERVIEW_DEFAULT },
  { MESSAGE_TYPE: MESSAGE_TYPE.OFFLINE_INTERVIEW, TEMPLATE_TYPE: CUSTOMER_MESSAGE_TEMPLATE_TYPE.OFFLINE_INTERVIEW_DEFAULT },
];

export default class Message extends MessageRecord {
  update(key, value) {
    return this.set(key, value);
  }

  isBodyNoInput() {
    return this.body === '';
  }

  getDefaultTemplateId(messageTemplates) {
    const messageTemplateType = MESSAGE_TEMPLATE_MAP.find(tmpl => tmpl.MESSAGE_TYPE === this.message_type);

    if (!messageTemplateType) {
      return 0;
    }

    return messageTemplates.getTemplateId(messageTemplateType.TEMPLATE_TYPE);
  }

  getTitle() {
    // Off escape to prevent '/' becomes &#x2F;
    return i18next.t('models.constants.shared.models.message.title', { facility_name: this.job_offer.facility_name, job_category_name: this.job_offer.job_category_name, interpolation: { escapeValue: false } });
  }

  isOfflineInterviewTimePassed() {
    return this.interview && this.interview.interview_method === INTERVIEW_METHOD.OFFLINE_INTERVIEW && timezone.isPast(timezone.newDate(`${this.interview.start_datetime}`));
  }

  isOfflineInterviewValid() {
    return [
      this.interview,
      this.interview.interview_method === INTERVIEW_METHOD.OFFLINE_INTERVIEW,
      !this.isOfflineInterviewTimePassed(),
      !this.interview.canceled,
    ].every(v => v);
  }

  isInterview() {
    return [MESSAGE_TYPE.OFFLINE_INTERVIEW].includes(Number(this.message_type));
  }

  isOfflineInterview() {
    return Number(this.message_type) === MESSAGE_TYPE.OFFLINE_INTERVIEW;
  }

  isInterviewValid() {
    return [
      (this.isOfflineInterviewValid()),
      !this.isMemberUnavailable(),
      (this.selection && Number(this.selection.status) === SELECTION_STATUS.STATUS_MAP.INTERVIEW_SET_DONE.id),
    ].every(v => v);
  }

  isMemberUnavailable() {
    return [
      this.member.deleted,
      this.member.stopped,
    ].some(v => v);
  }

  isOfflineInterviewMessage() {
    return [
      this.message_type === MESSAGE_TYPE.OFFLINE_INTERVIEW,
      this.interview.interview_method === INTERVIEW_METHOD.OFFLINE_INTERVIEW,
    ].every(v => v);
  }

  isDisplayLicense() {
    return this.message_type === MESSAGE_TYPE.APPLICATION && this.licenses.length > 0;
  }

  isArrangementInterview() {
    return this.message_type === MESSAGE_TYPE.ARRANGEMENT_INTERVIEW;
  }
}
