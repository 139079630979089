import reducers from './reducers';
import * as visitedOperations from './operations';
import * as visitedTypes from './types';

export {
  visitedOperations,
  visitedTypes,
};

export default reducers;
