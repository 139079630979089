import reducers from './reducers';
import * as messageTemplateListOperations from './operations';
import * as messageTemplateListTypes from './types';

export {
  messageTemplateListOperations,
  messageTemplateListTypes,
};

export default reducers;
