import * as actions from './actions';

const check = () => {
  return dispatch => dispatch(actions.check());
};

const save = (page) => {
  return dispatch => dispatch(actions.save(page));
};

export {
  check,
  save,
};
