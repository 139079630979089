/* eslint-disable max-classes-per-file */
import React from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { Sticky } from 'react-sticky';
import { Link } from 'react-router-dom';

import { Flex, FlexItem } from 'jmds-flex';
import Headbar from '@/components/desktop/headbar';
import Header from '@/components/desktop/header';
import Sidebar from '@/components/desktop/sidebar';
import { SideNav, SideNavItem } from '@/components/desktop/side-nav';
import Main from '@/components/desktop/main';
import Logo from '@/components/desktop/logo';
import Icon from '@/components/desktop/icon';
import IconText from '@/components/desktop/icon-text';
import { CAN_SHOW_PAGE_WITHOUT_APPROVAL_PATH_LIST } from '@/router/constants';

const AppWrapper = (props) => {
  return (
    <div>{ props.children }</div>
  );
};

AppWrapper.propTypes = {
  children: PropTypes.node,
};

const AppHeaderLogo = () => {
  return (
    <div className="c-header__logo">
      <Logo href="/" />
    </div>
  );
};

const AppHeaderAccount = ({ id, name }) => {
  if (!id) {
    return null;
  }

  return (
    <div className="c-header-account">
      <span className="c-header-account__name">
        <span className="c-header-account__name-inner">
          { name }
        </span>
      </span>
    </div>
  );
};

AppHeaderAccount.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
};

class AppHeader extends React.Component {
  static propTypes = {
    customer: PropTypes.object,
    isOperator: PropTypes.bool, // eslint-disable-line
  }

  render() {
    return (
      <Headbar>
        <Header
          red={ this.props.isOperator }
        >
          <Flex>
            <FlexItem>
              <Flex>
                <FlexItem align="center" className="u-desktop-flex-row-s">
                  <AppHeaderLogo />
                </FlexItem>
                <FlexItem align="center" className="u-desktop-flex-row-s">
                  <AppHeaderAccount
                    id={ this.props.customer.id }
                    name={ this.props.customer.name }
                  />
                </FlexItem>
              </Flex>
            </FlexItem>

            {
              this.props.customer.id
                ? (
                  <FlexItem right align="center">
                    <Flex>
                      <FlexItem>
                        <Flex align="center">
                          <FlexItem className="u-desktop-flex-row-xxl">
                            <a
                              href="/faq/"
                              target="_blank"
                              className="c-global-nav-link"
                            >
                              <IconText
                                text={
                                  <span className="u-color-white u-fs-lh-medium-short">{ i18next.t('components.app_shell.faqs') }</span>
                                }
                              >
                                <Icon
                                  name="question"
                                  color="white"
                                  width="20"
                                  height="20"
                                />
                              </IconText>
                            </a>
                          </FlexItem>
                          <FlexItem className="u-desktop-flex-row-xxl">
                            <Link
                              to="/customers/edit/"
                              className="c-global-nav-link"
                            >
                              <IconText
                                text={
                                  <span className="u-color-white u-fs-lh-medium-short">{ i18next.t('components.app_shell.settings') }</span>
                                }
                              >
                                <Icon
                                  name="setting"
                                  color="white"
                                  width="20"
                                  height="20"
                                />
                              </IconText>
                            </Link>
                          </FlexItem>
                          <FlexItem className="u-desktop-flex-row-xxl">
                            <a href="/customers/sign_out" className="c-global-nav-link">{ i18next.t('components.app_shell.signout') }</a>
                          </FlexItem>
                        </Flex>
                      </FlexItem>
                    </Flex>
                  </FlexItem>
                )
                : null
            }
          </Flex>
        </Header>
      </Headbar>
    );
  }
}

const AppMain = (props) => {
  return (
    <div className="u-disp-table u-wd-100p">{ props.children }</div>
  );
};

AppMain.propTypes = {
  children: PropTypes.node,
};

class AppSidebar extends React.PureComponent {
  static propTypes = {
    sideNavBadge: PropTypes.shape({
      facilities: PropTypes.number,
      searches: PropTypes.number,
      selections: PropTypes.number,
      messages: PropTypes.number,
    }),
    items: PropTypes.arrayOf(PropTypes.oneOfType([
      PropTypes.shape({
        text: PropTypes.string,
        icon: PropTypes.string,
        href: PropTypes.string,
        count: PropTypes.string,
      }),
      PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string,
          icon: PropTypes.string,
          href: PropTypes.string,
          count: PropTypes.string,
        }),
      ),
    ])),
    className: PropTypes.string,
    others: PropTypes.node,
    customer: PropTypes.object,
  }

  static defaultProps = {
    sideNavBadge: {
      facilities: 0,
      searches: 0,
      selections: 0,
      messages: 0,
    },
  }

  render() {
    const {
      className,
      sideNavBadge,
    } = this.props;

    return (
      <Sidebar className={ className }>
        <Sticky topOffset={ -50 }>
          {
            ({ style }) => {
              const css = { ...style, top: '50px' };

              return (
                <div style={ css }>
                  <div className="o-sidebar__inner c-sidebar__inner">
                    {
                      this.props.items.map((prop, index) => {
                        if (Array.isArray(prop)) {
                          return (
                            <div className="u-p-20" key={ index }>
                              <div className="u-bdrds-3 u-bg-color-blue-100">
                                {
                                  prop.map(({ TEXT, HREF, ICON, NEW }, idx) => {
                                    return (
                                      <SideNavItem
                                        text={ i18next.t(TEXT) }
                                        href={ HREF }
                                        icon={ ICON }
                                        new={ NEW }
                                        width="16"
                                        height="16"
                                        key={ idx }
                                        className="u-mr-10 u-ml-10 u-pl-10 u-wd-auto"
                                      />
                                    );
                                  })
                                }
                              </div>
                            </div>
                          );
                        }

                        let disabled = false;
                        if (!this.props.customer.approval) {
                          if (!CAN_SHOW_PAGE_WITHOUT_APPROVAL_PATH_LIST.includes(prop.HREF)) {
                            disabled = true;
                          }
                        }

                        return (
                          <SideNav key={ index }>
                            <SideNavItem
                              text={ i18next.t(prop.TEXT) }
                              icon={ prop.ICON }
                              href={ prop.HREF }
                              new={ prop.NEW }
                              width="16"
                              height="16"
                              count={ sideNavBadge[`${ prop.COUNT }`] }
                              key={ index }
                              disabled={ disabled }
                            />
                          </SideNav>
                        );
                      })
                    }
                    { this.props.others }
                  </div>
                </div>
              );
            }
          }
        </Sticky>
      </Sidebar>
    );
  }
}

const AppSubSidebar = (props) => {
  return (
    <Sidebar className={ props.className }>
      <Sticky topOffset={ -50 }>
        {
          ({ style }) => {
            const css = { ...style, top: '50px' };

            return (
              <div style={ css }>
                <div className="o-sidebar__inner c-sidebar__inner">
                  <SideNav>
                    <SideNavItem
                      text={ i18next.t('components.app_shell.subside_bar.home') }
                      icon="home"
                      href="/"
                      width="16"
                      height="16"
                    />
                  </SideNav>
                  <div className="u-border-bottom-blue u-ml-20 u-pl-5 u-pt-15 u-pb-15 u-fs-lh-medium-short u-fw-bold">{ i18next.t('components.app_shell.settings') }</div>
                  <SideNav small>
                    <SideNavItem text={ i18next.t('components.app_shell.subside_bar.contract_info') } href="/customers/edit" />
                    <SideNavItem text={ i18next.t('components.app_shell.subside_bar.email') } href="/customers/email_edit/" />
                    <SideNavItem text={ i18next.t('components.app_shell.subside_bar.notifications') } href="/customers/notification_edit/" />
                    <SideNavItem text={ i18next.t('components.app_shell.subside_bar.password') } href="/customers/password/" />
                    <SideNavItem text={ i18next.t('components.app_shell.subside_bar.message_templates') } href="/customers/message_templates/" />
                  </SideNav>
                </div>
              </div>
            );
          }
        }
      </Sticky>
    </Sidebar>
  );
};

AppSubSidebar.propTypes = {
  className: PropTypes.string,
};

const AppContent = (props) => {
  const {
    singleColumn,
    className,
    children,
  } = props;

  return (
    <Main singleColumn={ singleColumn } withMobileMode={ this} className={ className }>
      { children }
    </Main>
  );
};

AppContent.propTypes = {
  singleColumn: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
};

export {
  AppWrapper,
  AppHeader,
  AppHeaderLogo,
  AppHeaderAccount,
  AppMain,
  AppSidebar,
  AppContent,
  AppSubSidebar,
};
