import * as actions from './actions';
import JobCategoryApi from '@/models/ducks/shared/api/job-category';

const get = () => {
  return dispatch => JobCategoryApi.all()
    .then((response) => {
      return dispatch(actions.get(response.data.job_categories));
    });
};

const getWithOpenedJobOffers = (prefId) => {
  return dispatch => JobCategoryApi.getWithJobOffers(prefId)
    .then((response) => {
      return dispatch(actions.get(response.data.job_categories));
    });
};

const getWithAllJobOffers = (prefId) => {
  return dispatch => JobCategoryApi.getWithAllJobOffers(prefId)
    .then((response) => {
      return dispatch(actions.get(response.data.job_categories));
    });
};

export {
  get,
  getWithOpenedJobOffers,
  getWithAllJobOffers,
};
