import * as types from './types';

export const initialState = {
  visible: false,
  isAlert: false,
  text: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SHOW:
      return {
        visible: true,
        isAlert: action.payload.isAlert,
        text: action.payload.text,
      };
    case types.HIDE:
      return initialState;
    default:
      return state;
  }
};
