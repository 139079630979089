import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Loader from '@/components/shared/loader';
import Overlay from '@/components/desktop/overlay';

export default class LoaderView extends React.Component {

  static propTypes = {
    isLoading: PropTypes.number,
  }

  static defaultProps = {
  }

  shouldComponentUpdate(nextProps) {
    return (
      this.props.isLoading !== nextProps.isLoading
    );
  }

  render() {
    const loaderViewClass = classNames({
      'c-loader-view': true,
      'u-is-hidden': this.props.isLoading <= 0,
    });

    return (
      <div className={ loaderViewClass }>
        <Loader className="c-loader-view__inner" />
        <Overlay className="c-loader-view__overlay" />
      </div>
    );
  }
}
