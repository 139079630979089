import reducers from './reducers';
import * as editingImageOperations from './operations';
import * as editingImageTypes from './types';

export {
  editingImageOperations,
  editingImageTypes,
};

export default reducers;
