import Error from './error.json';
import PasswordReset from './password-reset.json';
import Searches from './searches.json';

const Data = {};
Data.error = Error;
Data.password_reset = PasswordReset;
Data.searches = Searches;

export default Data;
