import * as types from './types';
import { List } from 'immutable';

export const initialState = List([]);

const update = (state, action) => {
  const isChecked = state.get(action.payload.index);
  return state.set(action.payload.index, !isChecked);
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.INIT:
      return List(Array(action.payload.size).fill(false));
    case types.UPDATE:
      return update(state, action);
    default:
      return state;
  }
};
