import * as types from './types';

const init = () => {
  return {
    type: types.INIT,
  };
};

const copy = (form) => {
  return {
    type: types.COPY,
    payload: {
      form,
    },
  };
};

const change = (key, value) => {
  return {
    type: types.CHANGE,
    payload: {
      key,
      value,
    },
  };
};

const changeConditionSelective = (key, value, prop) => {
  return {
    type: types.CHANGE_CONDITION_SELECTIVE,
    payload: {
      key,
      value,
      prop,
    },
  };
};

const addConditionDesiredArea = () => {
  return {
    type: types.ADD_CONDITION_DESIRED_AREA,
  };
};

const removeConditionDesiredArea = (index) => {
  return {
    type: types.REMOVE_CONDITION_DESIRED_AREA,
    payload: {
      index,
    },
  };
};

const addConditionDesiredJobCategory = () => {
  return {
    type: types.ADD_CONDITION_DESIRED_JOB_CATEGORY,
  };
};

const removeConditionDesiredJobCategory = (index) => {
  return {
    type: types.REMOVE_CONDITION_DESIRED_JOB_CATEGORY,
    payload: {
      index,
    },
  };
};

const changeConditionDesiredJobCategory = (key, value, index) => {
  return {
    type: types.CHANGE_CONDITION_DESIRED_JOB_CATEGORY,
    payload: {
      key,
      value,
      index,
    },
  };
};

const changeConditionDesiredAreaPrefectures = (prefectureId, index, cities) => {
  return {
    type: types.CHANGE_CONDITION_DESIRED_AREA_PREFECTURES,
    payload: {
      prefectureId,
      index,
      cities,
    },
  };
};

const toggleConditionDesiredAreaCityGroupsCheckAll = (allCityIds, isCheckedAll, index) => {
  return {
    type: types.TOGGLE_CONDITION_DESIRED_AREA_CITY_GROUPS_CHECK_ALL,
    payload: {
      allCityIds,
      isCheckedAll,
      index,
    },
  };
};

const changeConditionDesiredAreaCity = (value, index) => {
  return {
    type: types.CHANGE_CONDITION_DESIRED_AREA_CITY,
    payload: {
      value,
      index,
    },
  };
};

const correctConditionAgeRange = (type) => {
  return {
    type: types.CORRECT_CONDITION_AGE_RANGE,
    payload: {
      type,
    },
  };
};

const noop = () => {
  return {
    type: types.NOOP,
  };
};

const changeFromUrlQuery = (condition) => {
  return {
    type: types.CHANGE_FROM_URL_QUERY,
    payload: {
      condition,
    },
  };
};

const changeToScouted = (scoutedMember) => {
  return {
    type: types.CHANGE_TO_SCOUTED,
    payload: {
      scoutedMember,
    },
  };
};

const changeToRecommended = (condition) => {
  return {
    type: types.CHANGE_TO_RECOMMENDED,
    payload: {
      condition,
    },
  };
};

const changeToFavorite = (favorite) => {
  return {
    type: types.CHANGE_TO_FAVORITE,
    payload: {
      favorite,
    },
  };
};

export {
  init,
  copy,
  change,
  changeConditionSelective,
  addConditionDesiredArea,
  removeConditionDesiredArea,
  addConditionDesiredJobCategory,
  removeConditionDesiredJobCategory,
  changeConditionDesiredJobCategory,
  changeConditionDesiredAreaPrefectures,
  toggleConditionDesiredAreaCityGroupsCheckAll,
  changeConditionDesiredAreaCity,
  correctConditionAgeRange,
  noop,
  changeFromUrlQuery,
  changeToScouted,
  changeToRecommended,
  changeToFavorite,
};
