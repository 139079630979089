import { Record } from 'immutable';
import MODAL from '@/models/ducks/modal/constants';

const properties = Object.assign(...Object.keys(MODAL).map((item) => {
  return {
    [`${ item }`]: false,
  };
}));

const ModalRecord = Record(properties);

export default class Modal extends ModalRecord {
  update(key, value) {
    return this.set(key, value);
  }

  isVisible(ID) {
    return this.get(ID);
  }

  isSomeVisible() {
    return Object.values(this.toJS()).some(item => item);
  }

  isHidden(ID) {
    return !this.isVisible(ID);
  }

  isHiddenIds(ids) {
    return ids.map((id) => {
      return this.isHidden(id);
    })
    .every(isHidden => isHidden);
  }
}
