import { Record } from 'immutable';
import { PAGINATION } from '@/models/ducks/page/constants';

const properties = Object.assign(...Object.keys(PAGINATION).map((item) => {
  return {
    [`${ item }`]: 0,
  };
}));

const PageRecord = Record(properties);

export default class Page extends PageRecord {
  update(key, value) {
    return this.set(key, value);
  }

  getCurrent(ID) {
    return this.get(ID);
  }

  getOffset(ID, limit, total) {
    const isFirstPage = this.get(ID) <= 0;
    const offsetTo = (this.get(ID) * limit) + limit;
    const totalOrOffsetTo = total < offsetTo ? total : offsetTo;
     return {
      from: isFirstPage ? 1 : (this.get(ID) * limit) + 1,
      to: isFirstPage ? limit : totalOrOffsetTo,
    };
  }
}
