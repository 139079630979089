import * as types from './types';
import FacilitySearchForm from '@/models/ducks/shared/models/facility-search-form';

export const initialState = new FacilitySearchForm();

export default (state = initialState, action) => {
  switch (action.type) {
    case types.INIT:
      // Because mapEntries mutates immutable record in immutable v3, return new FacilitySearchForm instad of initialState.
      // https://github.com/immutable-js/immutable-js/issues/574
      return new FacilitySearchForm();
    case types.SET:
      return action.payload.form;
    case types.CHANGE_FIELD:
      return state.update(action.payload.key, action.payload.value);
    case types.UPDATE_FROM_URL_QUERY:
      return state.updateFromUrlQuery(action.payload.parsedQuery).resetPage();
    case types.CHANGE_PAGE:
      return state.setIn(['pagination', 'page'], action.payload.page);
    case types.RESET_PAGE:
      return state.resetPage();
    case types.NOOP:
    default:
      return state;
  }
};
