import axios from 'axios';

export default {
  create(facility) {
    return axios.post('/api/customers/facilities/', { facility });
  },

  update(facility) {
    return axios.put(`/api/customers/facilities/${facility.id}/`, { facility });
  },

  delete(facilityId) {
    return axios.delete(`/api/customers/facilities/${facilityId}/`);
  },

  show(id) {
    return axios.get(`/api/customers/facilities/${id}/`);
  },

  search(condition) {
    return axios.get('/api/customers/facilities/search/', {
      params: {
        name: condition.name,
        contact_name: condition.contact_name,
        facility_form_id: condition.facility_form_id,
        job_offer_existence: condition.job_offer_existence,
        job_offer_status: condition.job_offer_status ? condition.job_offer_status.toJS() : null,
        disapproved: condition.disapproved,
        employment_type: condition.employment_type,
        job_category_id: condition.job_category_id,
        facility_id: condition.facility_id,
        job_offer_id: condition.job_offer_id,
        pagination: condition.pagination,
        job_offer_type: condition.job_offer_type,
      },
    });
  },

  searchFacility(condition) {
    return axios.post('/api/customers/facilities/search_facility/', {
      name: condition.name,
      contact_name: condition.contact_name,
      facility_form_id: condition.facility_form_id,
      job_offer_existence: condition.job_offer_existence,
      facility_id: condition.facility_id,
      pagination: condition.pagination,
    });
  },

  createLabel(condition) {
    return axios.post('/api/customers/facilities/label/', condition);
  },

  existByName(name, id) {
    return axios.get('/api/customers/facilities/exist_by_name/', { params: { name, id } });
  },
};
