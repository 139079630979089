import i18next from 'i18next';
import qs from 'qs';
import deepRenameKeys from 'deep-rename-keys';
import * as actions from './actions';
import CityApi from '@/models/ducks/shared/api/city';
import findValueByKey from '@/utils/find-value-by-key';
import swapKeyWithValue from '@/utils/swap-key-with-value';
import { PARAMETER_ALIAS } from '@/models/ducks/customer-search-condition/constants';
import CustomerSearchCondition from '@/models/ducks/shared/models/customer-search-condition';

const init = () => {
  return dispatch => dispatch(actions.init());
};

const copy = (form) => {
  return dispatch => dispatch(actions.copy(form));
};

const changeConditionValue = (key, value) => {
  return dispatch => dispatch(actions.change(['condition', key], value));
};

const changeName = (value) => {
  return dispatch => dispatch(actions.change('name', value));
};

const changeConditionScoutedMemberJobOffer = (key, value) => {
  return dispatch => dispatch(actions.change(['condition', 'scouted_member_job_offer', key], value));
};

const changeConditionReceivedFavoriteJobOffer = (key, value) => {
  return dispatch => dispatch(actions.change(['condition', 'received_favorite_job_offer', key], value));
};

const supressUpperLimitChecks = (current) => {
  return (dispatch, getState) => {
    const { customerSearchConditionForm } = getState();

    if (CustomerSearchCondition.isReachedLimitChecks(customerSearchConditionForm.get('condition'))) {
      window.alert(i18next.t('models.operations.customer_search_condition_form.window_alert.max_checkboxes'));// eslint-disable-line no-alert
      return dispatch(actions.copy(current));
    }
    return dispatch(actions.noop());
  };
};

const changeConditionSelective = (key, value, prop) => {
  return (dispatch, getState) => {
    const { customerSearchConditionForm } = getState();

    return Promise.resolve()
      .then(() => dispatch(actions.changeConditionSelective(key, value, prop)))
      .then(() => dispatch(supressUpperLimitChecks(customerSearchConditionForm)));
  };
};

const addConditionDesiredArea = () => {
  return dispatch => dispatch(actions.addConditionDesiredArea());
};

const removeConditionDesiredArea = (index) => {
  return dispatch => dispatch(actions.removeConditionDesiredArea(index));
};

const addConditionDesiredJobCategory = () => {
  return dispatch => dispatch(actions.addConditionDesiredJobCategory());
};

const removeConditionDesiredJobCategory = (index) => {
  return dispatch => dispatch(actions.removeConditionDesiredJobCategory(index));
};

const changeConditionDesiredJobCategory = (key, value, index) => {
  return (dispatch, getState) => {
    const { customerSearchConditionForm } = getState();

    return Promise.resolve()
      .then(() => dispatch(actions.changeConditionDesiredJobCategory(key, value, index)))
      .then(() => dispatch(supressUpperLimitChecks(customerSearchConditionForm)));
  };
};

const changeConditionDesiredAreaPrefectures = (prefectureId, index) => {
  return dispatch => CityApi.search(prefectureId)
    .then((response) => {
      return dispatch(actions.changeConditionDesiredAreaPrefectures(prefectureId, index, response.data.cities));
    });
};

const toggleConditionDesiredAreaCityGroupsCheckAll = (allCityIds, isCheckedAll, index) => {
  return (dispatch, getState) => {
    const { customerSearchConditionForm } = getState();

    return Promise.resolve()
      .then(() => dispatch(actions.toggleConditionDesiredAreaCityGroupsCheckAll(allCityIds, isCheckedAll, index)))
      .then(() => dispatch(supressUpperLimitChecks(customerSearchConditionForm)));
  };
};

const changeConditionDesiredAreaCity = (value, index) => {
  return (dispatch, getState) => {
    const { customerSearchConditionForm } = getState();

    return Promise.resolve()
      .then(() => dispatch(actions.changeConditionDesiredAreaCity(value, index)))
      .then(() => dispatch(supressUpperLimitChecks(customerSearchConditionForm)));
  };
};

const changeConditionIgnoreScouted = () => {
  return (dispatch, getState) => {
    const currentStatus = getState().customerSearchConditionForm.getIn(['condition', 'ignore', 'scouted']);
    return dispatch(actions.change(['condition', 'ignore', 'scouted'], currentStatus));
  };
};

const changeSendable = (value) => {
  return dispatch => dispatch(actions.change('sendable', value));
};

const changeConditionPage = (value) => {
  return dispatch => dispatch(actions.change(['condition', 'pagination', 'page'], value));
};

const changeFromUrlQuery = (params) => {
  if (!params) {
    return dispatch => dispatch(actions.noop());
  }
  const query = params.split('?')[1];
  const parsed = qs.parse(query);
  const swappedAlias = swapKeyWithValue(PARAMETER_ALIAS);
  const renamedCondition = deepRenameKeys(parsed, (key) => {
    const newKey = findValueByKey(key, swappedAlias);

    return newKey || key;
  });

  return dispatch => dispatch(actions.changeFromUrlQuery(renamedCondition));
};

const changeToScouted = (scoutedMember) => {
  return dispatch => dispatch(actions.changeToScouted(scoutedMember));
};

const changeToRecommended = (condition) => {
  return dispatch => dispatch(actions.changeToRecommended(condition));
};

const changeToFavorite = (favorite) => {
  return dispatch => dispatch(actions.changeToFavorite(favorite));
};

export {
  init,
  copy,
  changeConditionValue,
  changeName,
  changeConditionScoutedMemberJobOffer,
  changeConditionReceivedFavoriteJobOffer,
  changeConditionSelective,
  addConditionDesiredArea,
  removeConditionDesiredArea,
  addConditionDesiredJobCategory,
  removeConditionDesiredJobCategory,
  changeConditionDesiredJobCategory,
  changeConditionDesiredAreaPrefectures,
  toggleConditionDesiredAreaCityGroupsCheckAll,
  changeConditionDesiredAreaCity,
  changeConditionIgnoreScouted,
  changeSendable,
  changeConditionPage,
  changeFromUrlQuery,
  changeToScouted,
  changeToRecommended,
  changeToFavorite,
};
