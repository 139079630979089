import * as types from './types';
import INQUIRY from '@/models/ducks/shared/constants/inquiry';

export const initialState = '';

export default (state = initialState, action) => {
  switch (action.type) {
    case types.TO_SELECTION:
      return '/customers/selections?status=1';
    case types.TO_FACILITIES:
      return '/customers/facilities?job_offer_status[0]=1&job_offer_status[1]=5&job_offer_status[2]=6';
    case types.TO_INQUIRY:
      return `/faq/?inquiry_type_value=${ INQUIRY.TYPE.SCOUT_OPTION }#contact-form`;
    case types.INIT:
      return initialState;
    default:
      return state;
  }
};
