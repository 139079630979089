import * as types from './types';
import NotificationSettingForm from './model';

export const initialState = new NotificationSettingForm();

export default (state = initialState, action) => {
  switch (action.type) {
    case types.INIT:
      return initialState;
    case types.CREATE_FROM_CUSTOMER:
      return NotificationSettingForm.convert(action.payload.customer);
    case types.CHANGE_FIELD:
      return state.update(action.payload.key, action.payload.value);
    default:
      return state;
  }
};
