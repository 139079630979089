import { put, call, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import * as entitiesActions from '@/entities/actions';
import * as actions from './actions';
import * as announcementsApi from '@/api/announcements';
import { normalizeApiResponse } from '@/entities/schemas';
import * as announcementsSchema from '@/entities/schemas/announcements';

export function* handleLoad(): SagaIterator {
  const response = yield call(announcementsApi.getAll);

  const normalized = normalizeApiResponse({ responseData: response.data.news, schema: announcementsSchema.announcementList });
  yield put(entitiesActions.merge(normalized.entities));
  yield put(actions.fetchAnnouncements({
    announcementIds: normalized.result,
  }));
}

export function* watchAnnouncements(): SagaIterator {
  yield takeEvery(actions.HANDLE_LOAD, handleLoad);
}
