export default {
  convertNullValue(value) {
    if (value === undefined || value === null) {
      return '';
    }
    return value;
  },

  getDefaultValue(value, defaultValue) {
    if (value === undefined || value === null) {
      return defaultValue;
    }
    return value;
  },

  isBlank(value) {
    return value === undefined || value === null || value === '';
  },

  to_hankaku(value) {
    let word = this.convertNullValue(value).trim();
    if (word === '') {
      return '';
    }

    word = word.replace(/[！＂＃＄％＆＇（）＊＋，－．／０-９：；＜＝＞？＠Ａ-Ｚ［＼］＾＿｀ａ-ｚ｛｜｝～]/g, (s) => {
      String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
    });
    return word.replace(/[−‐－ー―]/g, '-');
  },

  toInteger(value) {
    if (Number.isInteger(value)) {
      return value;
    }
    const tmpValue = this.convertNullValue(value);

    if (tmpValue.match(/^\d+$/)) {
      return parseInt(tmpValue, 10);
    }
    return null;
  },

  // 検索用: titleize, title_case
  // この関数を修正するとき、lib/util/text_util.rb のtitle_case関数も併せて修正する必要があるか確認すること（JobSeeker画面と表記を合わせるため）
  titleize(value) {
    const notTitleizeWords = ['AND', 'NICU', 'ER', 'ICU', 'AM', 'PM', 'A', 'AN', 'TO'];
    const words = value.split(' ');
    const titleCaseWords = words.map((word) => {
      if (word === word.toUpperCase()) {
        return word;
      }
      if (notTitleizeWords.includes(word.toUpperCase())) {
        return word;
      }
      if (word[0] === '(') {
        return word;
      }
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });
    return titleCaseWords.join(' ');
  },

  isInteger(value) {
    let newValue = value;
    newValue = this.toInteger(value);
    return newValue !== null && newValue > 0;
  },
};
