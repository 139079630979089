import { Record, Map } from 'immutable';

const properties = {
  desired_area: '',
  desired_job_category: '',
  employment_statuses: '',
  employment_types: '',
  favorite: '',
  received_favorite_job_offer_name: '',
  scout: '',
  scouted_member_job_offer_name: '',
  last_education: '',
  last_login: '',
  qualification: '',
  member_id: '',
  search_form: Map(),
  name: '',
  id: '',
};

const CustomerSearchConditionLabelRecord = Record(properties);
export default class CustomerSearchConditionLabel extends CustomerSearchConditionLabelRecord {
  update(key, value) {
    return this.set(key, value);
  }

  isEmpty() {
    return (
      !this.desired_area
      && !this.desired_job_category
      && !this.employment_statuses
      && !this.employment_types
      && !this.favorite
      && !this.last_education
      && !this.last_login
      && !this.qualification
      && !this.scout
      && !this.member_id
    );
  }
}
