import * as types from './types';

const init = () => {
  return {
    type: types.INIT,
  };
};

const set = (facility) => {
  return {
    type: types.SET,
    payload: {
      facility,
    },
  };
};

const create = (facility) => {
  return {
    type: types.CREATE,
    payload: {
      facility,
    },
  };
};

const setWithConvertedFacility = (facility) => {
  return {
    type: types.SET_WITH_CONVERTED_FACILITY,
    payload: {
      facility,
    },
  };
};

const initUnnecessaryFieldOnCopy = () => {
  return {
    type: types.INIT_UNNECESSARY_FIELD_ON_COPY,
  };
};

const copyCustomerInfo = (customer) => {
  return {
    type: types.COPY_CUSTOMER_INFO,
    payload: {
      customer,
    },
  };
};

const changeGeoLatLon = ({ lat, lon }) => {
  return {
    type: types.CHANGE_GEO_LAT_LON,
    payload: {
      lat,
      lon,
    },
  };
};

const changeAccess = (access) => {
  return {
    type: types.CHANGE_ACCESS,
    payload: {
      access,
    },
  };
};

const changeField = (key, value) => {
  return {
    type: types.CHANGE_FIELD,
    payload: {
      key,
      value,
    },
  };
};

const changeFeature = (feature, isChecked) => {
  return {
    type: types.CHANGE_FEATURE,
    payload: {
      feature,
      isChecked,
    },
  };
};

const noop = () => {
  return {
    type: types.NOOP,
  };
};

const fillAddress = (town) => {
  return {
    type: types.FILL_ADDRESS,
    payload: {
      town,
    },
  };
};

const addImages = (images) => {
  return {
    type: types.ADD_IMAGES,
    payload: {
      images,
    },
  };
};

export {
  init,
  set,
  create,
  initUnnecessaryFieldOnCopy,
  setWithConvertedFacility,
  copyCustomerInfo,
  changeGeoLatLon,
  changeAccess,
  changeField,
  changeFeature,
  noop,
  fillAddress,
  addImages,
};
