import * as types from './types';
import FacilitySearchLabel from './model';

export const initialState = new FacilitySearchLabel();

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET:
      return new FacilitySearchLabel(action.payload.label);
    default:
      return state;
  }
};
