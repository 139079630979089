const INIT = 'state/ducks/toggle/INIT';
const TOGGLE = 'state/ducks/toggle/TOGGLE';
const SHOW = 'state/ducks/toggle/SHOW';
const HIDE = 'state/ducks/toggle/HIDE';

export {
  INIT,
  TOGGLE,
  SHOW,
  HIDE,
};
