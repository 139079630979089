import * as types from './types';
import { List } from 'immutable';
import MessageGroup from './model';
import Message from '@/models/ducks/shared/models/message';
import READ_STATUS from '@/models/ducks/shared/constants/message-read-status';

export const initialState = new MessageGroup();

const searchMoreMessages = (state, messages) => {
  const newMessages = List(messages.map((message) => {
    return new Message(message);
  }));
  return state.messageGroups.concat(newMessages);
};

const readMessages = (state, id) => {
  return state.messageGroups.update(state.messageGroups.findIndex((item) => {
    return item.id === id;
  }), (item) => {
    return item.set('customer_read_status', READ_STATUS.READ);
  });
};

const updateItem = (state, action) => {
  const newMessageGroups = state.messageGroups.update(state.messageGroups.findIndex((item) => {
    return item.job_offer_id === action.payload.jobOfferId && item.member.id === action.payload.memberId;
  }), () => {
    return new Message(action.payload.messages[0]);
  });

  return state.update('messageGroups', newMessageGroups);
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SEARCH:
      return new MessageGroup({
        messageGroups: List(action.payload.messages.map((message) => {
          return new Message(message);
        })),
        page: action.payload.page,
        total: action.payload.total,
      });
    case types.SEARCH_MORE:
      return new MessageGroup({
        messageGroups: searchMoreMessages(state, action.payload.messages),
        page: action.payload.page,
        total: action.payload.total,
      });
    case types.MARK_AS_READ:
      return new MessageGroup({
        messageGroups: readMessages(state, action.payload.id),
        page: state.page,
        total: state.total,
      });
    case types.UPDATE_ITEM:
      return updateItem(state, action);
    case types.NOOP:
    default:
      return state;
  }
};
