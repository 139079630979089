import * as types from './types';

const get = (features) => {
  return {
    type: types.GET,
    payload: {
      features,
    },
  };
};

export {
  get,
};
