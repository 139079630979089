import React, { FC } from 'react';
import { Outlet, Location } from 'react-router-dom';
import { DispatchProps, StateProps } from './container';
import AppShell from '@/containers/app-shell';
import { useCheckToShowRoute } from './useCheckToShowRoute';

export type OwnProps = {
  location: Location;
};

type Props = DispatchProps & OwnProps & StateProps;

const AuthenticatedRoute: FC<Props> = (props) => {
  useCheckToShowRoute(props.location, props.checkAuth);

  return (
    <AppShell>
      <Outlet />
    </AppShell>
  );
};

export default AuthenticatedRoute;
