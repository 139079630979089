import { createAction, ActionType } from 'typesafe-actions';

export const INIT = 'SEARCHES/INIT';
export const SHOW_SCOUT_SENDING_FAILURE_CONFIRM = 'SEARCHES/SHOW_SCOUT_SENDING_FAILURE_CONFIRM';
export const HIDE_SCOUT_SENDING_FAILURE_CONFIRM = 'SEARCHES/HIDE_SCOUT_SENDING_FAILURE_CONFIRM';

export const init = createAction(INIT)();
export const showScoutSendingFailureConfirm = createAction(SHOW_SCOUT_SENDING_FAILURE_CONFIRM, (({ message }: { message?: string }) => ({
  message,
})))();
export const hideScoutSendingFailureConfirm = createAction(HIDE_SCOUT_SENDING_FAILURE_CONFIRM)();

export type AnnouncementsActionTypes =
  | ActionType<typeof init>
  | ActionType<typeof showScoutSendingFailureConfirm>
  | ActionType<typeof hideScoutSendingFailureConfirm>
