import { Record } from 'immutable';
import stringUtil from '@/utils/string';

const properties = {
  email: '',
  notice: '',
};

const PasswordResetRecord = Record(properties);
export default class PasswordResetForm extends PasswordResetRecord {
  update(key, value) {
    return this.set(key, stringUtil.convertNullValue(value));
  }
}
