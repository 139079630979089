import i18next from 'i18next';
import * as actions from './actions';
import JobOfferApi from '@/models/ducks/shared/api/job-offer';
import { EMPLOYMENT_TYPE } from '@/models/ducks/job-offer/constants';

const init = () => {
  return dispatch => dispatch(actions.init());
};

const checkSalaryLevel = (jobOffer) => {
  return dispatch => JobOfferApi.validateSalary(jobOffer)
    .then((response) => {
      return dispatch(actions.update(response.data.alerts));
    });
};

const checkUnitPriceUpdates = (jobOffer) => {
  return (dispatch) => {
    const items = jobOffer.job_offer_unit_prices
      .sortBy(item => item.qualification.id)
      .reduce((accumulator, current) => {

        if (current.price > current.unit_price.bottom) {
          const label = i18next.t('models.operations.job_offer_salary_caution.full_time_or_contract_employee');

          return [].concat.apply(accumulator, [`${ i18next.t('models.operations.job_offer_salary_caution.hiring_cost_has_been_changed',
            { current_qualification_name: current.qualification.name, label }) }`]);
        }

        return accumulator;
      }, []);

    return dispatch(actions.update(items));
  };
};

const checkSalaries = (jobOffer) => {
  return dispatch => Promise.resolve().then(() => {
    return dispatch(init());
  }).then(() => {
    return dispatch(checkSalaryLevel(jobOffer));
  }).then(() => {
    return dispatch(checkUnitPriceUpdates(jobOffer));
  });
};

export {
  checkSalaries,
  init,
};
