import * as types from './types';
import MessageTemplate from '@/models/ducks/shared/models/message-template';

export const initialState = new MessageTemplate();

export default (state = initialState, action) => {
  switch (action.type) {
    case types.INIT:
      return initialState;
    case types.SET:
      return new MessageTemplate(action.payload.messageTemplate);
    case types.CHANGE_FIELD:
      return state.update(action.payload.key, action.payload.value);
    default:
      return state;
  }
};
