const INIT = 'state/ducks/image-search-form/INIT';
const CHANGE_FIELD = 'state/ducks/image-search-form/CHANGE_FIELD';
const CHANGE_PAGE = 'state/ducks/image-search-form/CHANGE_PAGE';
const SET_SUGGESTION_FACILITY_ID = 'state/ducks/image-search-form/SET_SUGGESTION_FACILITY_ID';
const SET_SUGGESTION_JOB_OFFER_ID = 'state/ducks/image-search-form/SET_SUGGESTION_JOB_OFFER_ID';
const INIT_SUGGESTION = 'state/ducks/image-search-form/INIT_SUGGESTION';

export {
  INIT,
  CHANGE_FIELD,
  CHANGE_PAGE,
  SET_SUGGESTION_FACILITY_ID,
  SET_SUGGESTION_JOB_OFFER_ID,
  INIT_SUGGESTION,
};
