import { getType } from 'typesafe-actions';
import * as actions from './actions';
import { reduceWithImmer } from '@/reducer';

export const initialState = {
  new_email: '',
  notification: {
    text: '',
    isHidden: true,
    isAlert: false,
  },
};

export type EmailEditState = typeof initialState;

export const reducer = (state: EmailEditState = initialState, action: actions.EmailEditActionTypes): EmailEditState => {
  return reduceWithImmer(state, (draft) => {
    switch (action.type) {
      case getType(actions.showNotification):
        draft.notification.text = action.payload.message;
        draft.notification.isHidden = false;
        break;
      case getType(actions.hideNotification):
        draft.notification = initialState.notification;
        break;
      default:
        return draft;
    }
    return draft;
  });
};
