import * as types from './types';

const init = () => {
  return {
    type: types.INIT,
  };
};

const changeField = (key, value) => {
  return {
    type: types.CHANGE_FIELD,
    payload: {
      key,
      value,
    },
  };
};

const changeCheckboxField = (key, value, checked) => {
  return {
    type: types.CHANGE_CHECKBOX_FIELD,
    payload: {
      key,
      value,
      checked,
    },
  };
};

const clearField = (key) => {
  return {
    type: types.CLAER_FIELD,
    payload: {
      key,
    },
  };
};

const changeStatus = (status) => {
  return {
    type: types.CHANGE_STATUS,
    payload: {
      status,
    },
  };
};

const setSuggestionValue = (suggestion) => {
  return {
    type: types.SET_SUGGESTION_VALUE,
    payload: {
      suggestion,
    },
  };
};

const initSuggestionValue = () => {
  return {
    type: types.INIT_SUGGESTION_VALUE,
  };
};

const initSuggestion = () => {
  return {
    type: types.INIT_SUGGESTION,
  };
};

const initFacilityJobOffer = () => {
  return {
    type: types.INIT_FACILITY_JOB_OFFER,
  };
};

const createCache = () => {
  return {
    type: types.CREATE_CACHE,
  };
};

const restoreFromCache = () => {
  return {
    type: types.RESTORE_FROM_CACHE,
  };
};

export {
  init,
  changeField,
  changeCheckboxField,
  clearField,
  changeStatus,
  setSuggestionValue,
  initSuggestionValue,
  initSuggestion,
  initFacilityJobOffer,
  createCache,
  restoreFromCache,
};
