import BadgeApi from './api';
import * as actions from './actions';

const update = () => {
  return dispatch => BadgeApi.sideNav()
    .then((response) => {
      return dispatch(actions.update(response.data.badge));
    });
};

export {
  update,
};
