import * as types from './types';

export const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SHOW:
      return action.payload.member;
    case types.GET_ID:
      return {
        id: action.payload.id,
      };
    case types.DESTROY:
      return initialState;
    default:
      return state;
  }
};
