const INIT = 'state/ducks/message-send-form/INIT';
const UPDATE = 'state/ducks/message-send-form/UPDATE';
const RESET = 'state/ducks/message-send-form/RESET';
const SAVE = 'state/ducks/message-send-form/SAVE';
const FETCH_ERRORS = 'state/ducks/message-send-form/FETCH_ERRORS';
const UPDATE_FIELDS_BY_MESSAGE = 'state/ducks/message-send-form/UPDATE_FIELDS_BY_MESSAGE';
const UPDATE_INTERVIEW_SCHEDULE = 'state/ducks/message-send-form/UPDATE_INTERVIEW_SCHEDULE';
const INIT_INTERVIEW_SCHEDULE = 'state/ducks/message-send-form/INIT_INTERVIEW_SCHEDULE';
const UPDATE_PREFERRED_INTERVIEW_LIST = 'state/ducks/message-send-form/UPDATE_PREFERRED_INTERVIEW_LIST';

export {
  INIT,
  UPDATE,
  RESET,
  SAVE,
  FETCH_ERRORS,
  UPDATE_FIELDS_BY_MESSAGE,
  UPDATE_INTERVIEW_SCHEDULE,
  INIT_INTERVIEW_SCHEDULE,
  UPDATE_PREFERRED_INTERVIEW_LIST,
};
