import * as actions from './actions';

const create = () => {
  return dispatch => dispatch(actions.create());
};

const restore = (cache) => {
  return dispatch => dispatch(actions.restore(cache));
};

const updateField = (key, value) => {
  return dispatch => dispatch(actions.update(key, value));
};

const updateJobOffer = (key, value) => {
  return dispatch => dispatch(actions.update(['job_offer', key], value));
};

const updateMessageTypeField = (key, value) => {
  return dispatch => dispatch(actions.updateMessageType(key, value));
};

const updateSuggestByJobOfferId = (jobOfferId, suggest) => {
  const targetSuggest = suggest.find(s => (s.id * 1) === +jobOfferId);

  return dispatch => Promise.resolve()
    .then(() => {
      return dispatch(actions.update(['job_offer_id'], targetSuggest ? targetSuggest.id : null));
    })
    .then(() => {
      return dispatch(actions.update(['job_offer_name'], targetSuggest ? targetSuggest.name : ''));
    });
};

const clearJobOffer = () => {
  return dispatch => Promise.resolve()
    .then(() => {
      return dispatch(actions.update('job_offer_id', null));
    })
    .then(() => {
      return dispatch(actions.update('job_offer_name', ''));
    });
};

export {
  create,
  restore,
  updateField,
  updateJobOffer,
  clearJobOffer,
  updateMessageTypeField,
  updateSuggestByJobOfferId,
};
