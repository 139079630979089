import i18next from 'i18next';
import { SagaIterator } from 'redux-saga';
import { ActionType } from 'typesafe-actions';
import { put, call, takeEvery } from 'redux-saga/effects';
import * as actions from './actions';
import * as customersApi from '@/api/customers';
import TEXT from '@/models/ducks/shared/constants/text';

function* handleSubmit(action: ActionType<typeof actions.handleSubmit>): SagaIterator {
  const { email } = action.payload.values;

  try {
    yield call(customersApi.resetPassword, email);
    yield put(actions.showMessage(i18next.t(TEXT.PASSWORD_RESET_SUCCESS)));
  } catch (err) {
    yield put(actions.showMessage(i18next.t(TEXT.PASSWORD_RESET_FAILURE)));
  }
}

export function* watchResetForm(): SagaIterator {
  yield takeEvery(actions.HANDLE_SUBMIT, handleSubmit);
}
