import FacilityApi from '@/models/ducks/shared/api/facility';
import * as actions from './actions';

const get = (condition) => {
  return dispatch => FacilityApi.searchFacility(condition)
    .then((response) => {
      const { facilities, total, count } = response.data;

      return dispatch(actions.get({
        facilities,
        total,
        count,
      }));
    });
};

const getMore = (condition, page) => {
  const newCondition = condition.setIn(['pagination', 'page'], page);

  return dispatch => FacilityApi.searchFacility(newCondition)
    .then((response) => {
      if (!response.data.facilities.length) {
        return dispatch(actions.noop());
      }
      const { facilities } = response.data;

      return dispatch(actions.getMore({ facilities }));
    });
};

export {
  get,
  getMore,
};
