import * as actions from './actions';

const init = () => {
  return dispatch => dispatch(actions.init());
};

const set = (selection) => {
  return dispatch => dispatch(actions.set(selection));
};

export {
  init,
  set,
};
