
const INIT = 'state/ducks/guide/INIT';
const SAVE = 'state/ducks/guide/SAVE';
const SAVE_INDEX = 'state/ducks/guide/SAVE_INDEX';
const CHECK = 'state/ducks/guide/CHECK';
const RESET = 'state/ducks/guide/RESET';
const NOOP = 'state/ducks/guide/NOOP';

export {
  INIT,
  SAVE,
  SAVE_INDEX,
  CHECK,
  RESET,
  NOOP,
};
