import { Record, List } from 'immutable';
import ContactMean from '@/models/ducks/shared/models/contact-mean';
import SETTINGS from '@/models/ducks/shared/constants/settings';

const properties = {
  id: undefined,
  contact_type: undefined,
  staff_post: '',
  staff_position: '',
  staff_family_name: '',
  staff_first_name: '',
  contact_means: new List([
    new ContactMean({ media: SETTINGS.CONTACT_MEAN_MEDIAS.tel }),
    new ContactMean({ media: SETTINGS.CONTACT_MEAN_MEDIAS.email }),
  ]),
};

const ContactRecord = Record(properties);
export default class Contact extends ContactRecord {
  static convert(raw) {
    return new Contact(raw)
      .update('contact_means', Contact.fillEmptyContactMean(ContactMean.convertMultiple(raw.contact_means)));
  }

  // Ref: #5547
  static fillEmptyContactMean(items) {
    const contactMeans = items;

    if (contactMeans.length === properties.contact_means.size) {
      return contactMeans;
    }

    const medias = [SETTINGS.CONTACT_MEAN_MEDIAS.tel, SETTINGS.CONTACT_MEAN_MEDIAS.email];
    const existedMedias = contactMeans.map(mean => mean.media);

    for (let i = 0, max = medias.length; i < max; i += 1) {
      if (!medias.includes(existedMedias[i])) {
        contactMeans.splice(i, 0, properties.contact_means.get(i));
      }
    }

    return contactMeans;
  }

  static convertMultiple(contacts) {
    return contacts.map((contact) => {
      return Contact.convert(contact);
    });
  }

  update(key, value) {
    let val = value;
    if (key.includes('_id')) {
      val *= 1;
    }
    if (key === 'contact_means') {
      return this.setContactMeans(val);
    }
    return this.set(key, val);
  }

  setContactMeans(contactMeans) {
    const immutableContactMeans = contactMeans instanceof List ? contactMeans : new List(contactMeans);
    return this.set('contact_means', immutableContactMeans);
  }

  tel() {
    return this.contact_means.find((item) => {
      return item.isTel();
    }).data;
  }

  email() {
    return this.contact_means.find((item) => {
      return item.isEmail();
    }).data;
  }

  clearId() {
    let newModel = this;
    newModel = newModel.update('id', undefined);
    newModel.contact_means.forEach((contactMean, index) => {
      newModel = newModel.setIn(['contact_means', index, 'id'], undefined);
    });
    return newModel;
  }
}
