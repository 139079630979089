import { List } from 'immutable';
import * as types from './types';
import CustomerSearchCondition from '@/models/ducks/shared/models/customer-search-condition';

export const initialState = {
  searchConditions: new List(),
  total: 0,
  count: 0,
  limit: 10,
  total_match_count: 0,
};

const convertSearchConditionsToImmutable = (conditions) => {
  return List(conditions.map((condition) => {
    const model = new CustomerSearchCondition();

    return model.create(condition);
  }));
};

const get = (action) => {
  const { total, count, limit, conditions } = action.payload;

  return {
    total,
    count,
    limit,
    total_match_count: action.payload.total_match_count,
    searchConditions: convertSearchConditionsToImmutable(conditions),
  };
};

const getMore = (state, action) => {
  const { total, count, limit, conditions } = action.payload;

  return {
    total,
    count,
    limit,
    total_match_count: action.payload.total_match_count,
    searchConditions: state.searchConditions.concat(convertSearchConditionsToImmutable(conditions)),
  };
};

const update = (state, action) => {
  const index = state.searchConditions.findIndex((condition) => {
    return condition.id === action.payload.condition.id;
  });

  return {
    total: state.total,
    count: state.count,
    limit: state.limit,
    total_match_count: state.total_match_count,
    searchConditions: state.searchConditions.setIn([index], action.payload.condition),
  };
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET:
      return get(action);
    case types.GET_MORE:
      return getMore(state, action);
    case types.UPDATE:
      return update(state, action);
    default:
      return state;
  }
};
