import * as types from './types';

const init = () => {
  return {
    type: types.INIT,
  };
};

const set = (jobOfferStatusMap) => {
  return {
    type: types.SET,
    payload: {
      jobOfferStatusMap,
    },
  };
};

const get = (currentJobOfferStatus) => {
  return {
    type: types.GET,
    payload: {
      currentJobOfferStatus,
    },
  };
};

const noop = () => {
  return {
    type: types.NOOP,
  };
};

export {
  init,
  set,
  get,
  noop,
};
