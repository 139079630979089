import * as types from './types';

const init = () => {
  return {
    type: types.INIT,
  };
};

const changeField = (key, value) => {
  return {
    type: types.CHANGE_FIELD,
    payload: {
      key,
      value,
    },
  };
};

const changePage = (page) => {
  return {
    type: types.CHANGE_PAGE,
    payload: {
      page,
    },
  };
};

const setSuggestionFacilityId = (suggestion) => {
  return {
    type: types.SET_SUGGESTION_FACILITY_ID,
    payload: {
      suggestion,
    },
  };
};

const setSuggestionJobOfferId = (suggestion) => {
  return {
    type: types.SET_SUGGESTION_JOB_OFFER_ID,
    payload: {
      suggestion,
    },
  };
};

const initSuggestion = () => {
  return {
    type: types.INIT_SUGGESTION,
  };
};

export {
  init,
  changeField,
  changePage,
  setSuggestionFacilityId,
  setSuggestionJobOfferId,
  initSuggestion,
};
