import React, { FC, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { Outlet } from 'react-router-dom';
import { DispatchProps, StateProps } from './container';
import SETTINGS from '@/models/ducks/shared/constants/settings';

export type OwnProps = {
  location: Location;
};


type Props = StateProps & DispatchProps & OwnProps;

const scrollToTop = () => {
  setTimeout(() => {
    window.scrollTo(0, 1);
  }, 0);
};

const OnUpdateRouter: FC<Props> = (props) => {
  const [historyKey, setHistoryKey] = useState<string | null>(null);

  const isSameUrlState = () => {
    if (!window.history.state) {
      return false;
    }

    return historyKey === window.history.state.key;
  };

  const logPageView = () => {
    if (SETTINGS.GOOGLE_ANALYTICS.trackable && !isSameUrlState()) {
      let path = window.location.pathname;
      if (path.slice(-1) !== '/') {
        path += '/';
      }

      ReactGA.set({ page: path });
      ReactGA.send('pageview');
    }

    if (window.history.state) {
      setHistoryKey(window.history.state.key);
    }
  };

  useEffect(() => {
    scrollToTop();
    logPageView();
    // stateのversionが初期値ではない時に実行する
    // see: https://github.com/medley-inc/job-medley/issues/23718#issuecomment-1196237639
    if (props.version.get('version') !== '') {
      props.reloadIfNewVersionDetected();
    }
  }, [props.location.pathname, window.history.state?.key]);

  return (
    <Outlet />
  );
};

export default OnUpdateRouter;
