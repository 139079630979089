const INIT = 'state/ducks/selection-search-form/INIT';
const CHANGE_FIELD = 'state/ducks/selection-search-form/CHANGE_FIELD';
const CHANGE_CHECKBOX_FIELD = 'state/ducks/selection-search-form/CHANGE_CHECKBOX_FIELD';
const CLAER_FIELD = 'state/ducks/selection-search-form/CLAER_FIELD';
const CHANGE_STATUS = 'state/ducks/selection-search-form/CHANGE_STATUS';
const SET_SUGGESTION_VALUE = 'state/ducks/selection-search-form/SET_SUGGESTION_VALUE';
const INIT_SUGGESTION_VALUE = 'state/ducks/selection-search-form/INIT_SUGGESTION_VALUE';
const INIT_SUGGESTION = 'state/ducks/selection-search-form/INIT_SUGGESTION';
const INIT_FACILITY_JOB_OFFER = 'state/ducks/selection-search-form/INIT_FACILITY_JOB_OFFER';
const CREATE_CACHE = 'state/ducks/selection-search-form/CREATE_CACHE';
const RESTORE_FROM_CACHE = 'state/ducks/selection-search-form/RESTORE_FROM_CACHE';

export {
  INIT,
  CHANGE_FIELD,
  CHANGE_CHECKBOX_FIELD,
  CLAER_FIELD,
  CHANGE_STATUS,
  SET_SUGGESTION_VALUE,
  INIT_SUGGESTION_VALUE,
  INIT_SUGGESTION,
  INIT_FACILITY_JOB_OFFER,
  CREATE_CACHE,
  RESTORE_FROM_CACHE,
};
