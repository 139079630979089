const CUSTOMER_MESSAGE_TEMPLATE_TYPE = {
  USER: 0,
  SCOUT_DEFAULT: 1,
  REJECTION_DEFAULT: 2,
  OFFER_DEFAULT: 3,
  OFFLINE_INTERVIEW_DEFAULT: 5,
  ARRANGEMENT_INTERVIEW_DEFAULT: 7,
  CANCEL_INTERVIEW_DEFAULT: 8,
};

export {
  CUSTOMER_MESSAGE_TEMPLATE_TYPE,
};

export default {
  canDelete(templateType) {
    return templateType === this.USER;
  },
};
