import VersionApi from './api';
import Version from './model';
import * as actions from './actions';

const check = (version) => {
  return dispatch => dispatch(actions.check(version));
};

const reloadIfNewVersionDetected = () => {
  return (dispatch, getState) => VersionApi.getVersion()
    .then((response) => {
      const state = getState();
      if (state.version.version === '' || !state.version.version) {
        return dispatch(actions.save(new Version(response.data)));
      }
      if (state.version.version !== response.data.version) {
        window.location.reload(true);
      }
      return dispatch(actions.check(new Version(response.data)));
    });
};

const save = () => {
  return dispatch => VersionApi.getVersion()
    .then((response) => {
      return dispatch(actions.save(new Version(response.data)));
    });
};

export {
  check,
  save,
  reloadIfNewVersionDetected,
};
