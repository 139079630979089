import * as types from './types';
import Selection from '@/models/ducks/shared/models/selection';
import SELECTION_STATUS from '@/models/ducks/selection-list/constants';

export const initialState = new Selection();

export default (state = initialState, action) => {
  switch (action.type) {
    case types.INIT:
      return initialState;
    case types.CREATE:
      return action.payload.selection;
    case types.CHANGE:
      return state.update(action.payload.key, action.payload.value);
    case types.UPDATE_TO_HIRING_DONE:
      return state.merge({
        hired_at: state.scheduling_hired_at,
        status: SELECTION_STATUS.STATUS_MAP.HIRING_DONE.id,
      });
    default:
      return state;
  }
};
