import * as actions from './actions';

const init = () => {
  return dispatch => dispatch(actions.init());
};

const update = (image) => {
  return dispatch => dispatch(actions.update(image));
};

const refresh = (imageList) => {
  return dispatch => dispatch(actions.refresh(imageList));
};

export {
  init,
  update,
  refresh,
};
