import { Record } from 'immutable';
import Settings from '@/models/ducks/shared/constants/settings';

const properties = {
  id: undefined,
  contact_id: undefined,
  media: undefined,
  data: '',
  notification_setting_column: '',
};

const ContactMeanRecord = Record(properties);
export default class ContactMean extends ContactMeanRecord {
  static convert(raw) {
    return new ContactMean(raw);
  }

  static convertMultiple(contactMeans) {
    return contactMeans.map((contactMean) => {
      return ContactMean.convert(contactMean);
    });
  }

  update(key, value) {
    return this.set(key, value);
  }

  isTel() {
    return this.media === Settings.CONTACT_MEAN_MEDIAS.tel;
  }

  isEmail() {
    return this.media === Settings.CONTACT_MEAN_MEDIAS.email;
  }
}
