import * as actions from './actions';
import HomeApi from './api';

const create = (limit) => {
  return dispatch => HomeApi.recommendMembers(limit)
    .then((response) => {
      return dispatch(actions.create(response.data.members));
    });
};

export {
  create,
};
