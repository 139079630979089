import PrefectureApi from '@/models/ducks/shared/api/prefecture';
import * as actions from './actions';

const get = () => {
  return dispatch => PrefectureApi.all()
    .then((response) => {
      return dispatch(actions.get(response.data.prefectures));
    });
};

export {
  get,
};
