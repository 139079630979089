import { produce, Draft, Produced } from 'immer';

const reduceWithImmer = <S>(
  state: S,
  reduce: (draft: Draft<S>) => void | S,
): S | Produced<S, S> => {
  return produce(state, (draft) => {
    return reduce(draft);
  });
};


export {
  reduceWithImmer,
};
