import JobOfferApi from '@/models/ducks/shared/api/job-offer';
import FeatureApi from '@/models/ducks/shared/api/feature';
import JobOfferUnitPriceApi from '@/models/ducks/shared/api/job-offer-unit-price';
import * as actions from './actions';

const init = () => {
  return dispatch => dispatch(actions.init());
};

const create = (jobOffer) => {
  return dispatch => dispatch(actions.create(jobOffer));
};

const set = (jobOffer) => {
  return dispatch => dispatch(actions.set(jobOffer));
};

const get = (id) => {
  return dispatch => JobOfferApi.show(id)
    .then((response) => {
      return dispatch(actions.get(response.data.job_offer));
    });
};

const copy = (id) => {
  return dispatch => JobOfferApi.show(id)
    .then((response) => {
      return dispatch(actions.copy(response.data.job_offer));
    });
};

const changeField = (key, value) => {
  return dispatch => dispatch(actions.changeField(key, value));
};

const changeClosedReasons = (key, value) => {
  return dispatch => dispatch(actions.changeClosedReasons(key, value));
};

const changeSalaryValue = (salary, key, value) => {
  return dispatch => dispatch(actions.changeSalaryField(salary, key, value));
};

const changeSalaryStatus = (salary) => {
  return dispatch => dispatch(actions.changeSalaryStatus(salary));
};

const changeSalaryStatusCheck = (salary) => {
  return dispatch => dispatch(actions.changeSalaryStatusCheck(salary));
};

const changeAppealBody = (value) => {
  return dispatch => dispatch(actions.changeField('appeal_body', value));
};

const holdCurrentFeatures = (value) => {
  return dispatch => FeatureApi.search({ job_category_id: value })
    .then((response) => {
      return dispatch(actions.holdCurrentFeatures(response.data.features));
    });
};

const updateUnitPrices = (jobCategoryId) => {
  return dispatch => JobOfferUnitPriceApi.getUnitPrices(jobCategoryId)
    .then((response) => {
      return dispatch(actions.updateUnitPrices(response.data.job_offer_unit_prices));
    });
};

const changeFeature = (feature) => {
  return dispatch => dispatch(actions.changeFeature(feature));
};

const addImages = (images) => {
  return dispatch => dispatch(actions.addImages(images));
};

export {
  init,
  create,
  set,
  get,
  copy,
  changeField,
  changeClosedReasons,
  changeSalaryValue,
  changeSalaryStatus,
  changeSalaryStatusCheck,
  changeAppealBody,
  holdCurrentFeatures,
  updateUnitPrices,
  changeFeature,
  addImages,
};
