import * as types from './types';

export const initialState = {
  isMobile: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.CHECK:
      return state;
    case types.UPDATE:
      return {
        isMobile: action.payload.status,
      };
    default:
      return state;
  }
};
