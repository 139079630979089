const SHOW = 'state/ducks/message-thread/SHOW';
const ADD = 'state/ducks/message-thread/ADD';
const DESTROY = 'state/ducks/message-thread/DESTROY';
const NOOP = 'state/ducks/message-thread/NOOP';

export {
  SHOW,
  ADD,
  DESTROY,
  NOOP,
};
