import Operator from './model';
import * as types from './types';

export const initialState = new Operator();

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET:
      return new Operator(action.payload.operator);
    default:
      return state;
  }
};
