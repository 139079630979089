import * as actions from './actions';
import MessageTemplateApi from '@/models/ducks/shared/api/message-template';
import MessageApi from '@/models/ducks/shared/api/message';
import { updatePreferredInterviewDatetimeList } from './operations-typed';

const create = () => {
  return dispatch => dispatch(actions.create());
};

const updateField = (key, value) => {
  return dispatch => dispatch(actions.update(key, value));
};

const resetAfterSendMessage = () => {
  return dispatch => Promise.resolve()
    .then(() => {
      return dispatch(actions.resetField('body'));
    })
    .then(() => {
      return dispatch(actions.resetField('message_type'));
    });
};

const updateBodyFromTemplate = (templateId, params) => {
  return (dispatch) => {
    if (templateId < 1) {
      return dispatch(actions.update('body', ''));
    }
    return MessageTemplateApi.build(templateId, params)
      .then((response) => {
        return dispatch(actions.update('body', response.data.message_template.body));
      });
  };
};

const save = () => {
  return (dispatch, getState) => {
    const { messageSendForm } = getState();

    return MessageApi.create(messageSendForm)
      .then((response) => {
        return dispatch(actions.save(response.data.message));
      })
      .catch((err) => {
        // サーバサイドバリデーションエラーだった際には redux state に errors を格納
        // 上流でも catch しているため元のエラーは throw で上げておく
        if (Object.keys(err.response.data.errors).length) {
          dispatch(actions.fetchErrors(err.response.data.errors));
        }

        throw err;
      });
  };
};

const updateFieldsByMessage = (message, applicationId) => {
  return dispatch => dispatch(actions.updateFieldsByMessage(message, applicationId));
};

const updateInterviewSchedule = (name, value) => {
  return dispatch => dispatch(actions.updateInterviewSchedule(name, value));
};

const initInterviewSchedule = () => {
  return dispatch => dispatch(actions.initInterviewSchedule());
};

export {
  create,
  updateField,
  resetAfterSendMessage,
  updateBodyFromTemplate,
  save,
  updateFieldsByMessage,
  updateInterviewSchedule,
  initInterviewSchedule,
  updatePreferredInterviewDatetimeList,
};
