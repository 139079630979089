import * as types from './types';
import Message from '@/models/ducks/shared/models/message';
import { updatePreferredInterviewDatetimeList } from '@/models/ducks/message-send-form/reducer-fucntions';

export const initialState = new Message();

const reset = (state, action) => {
  const defaultValue = initialState.get(action.payload.key);
  return state.update(action.payload.key, defaultValue);
};

const updateFieldsByMessage = (state, action) => {
  const { message, applicationId } = action.payload;

  return state.merge({
    member_id: message.member.id,
    scout_member_id: message.scout_member_id,
    job_offer_id: message.job_offer_id,
    application_id: applicationId,
  });
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.INIT:
      return initialState;
    case types.UPDATE: {
      const newErrors = { ...state.errors };
      delete newErrors[`${action.payload.key}`];

      return state
        .update('errors', newErrors)
        .update(action.payload.key, action.payload.value);
    }
    case types.UPDATE_FIELDS_BY_MESSAGE:
      return updateFieldsByMessage(state, action);
    case types.RESET:
      return reset(state, action);
    case types.SAVE:
      return new Message({
        ...action.payload.form,
        interview_location: state.interview_location,
        preferred_interview_location: state.preferred_interview_location,
      });
    case types.FETCH_ERRORS:
      return state.update('errors', action.payload);
    case types.UPDATE_INTERVIEW_SCHEDULE: {
      const newErrors = { ...state.errors };
      delete newErrors.start_datetime;

      return state
        .update('errors', newErrors)
        .update('interview_schedule', {
          ...state.get('interview_schedule'),
          [`${action.payload.name}`]: action.payload.value,
        });
    }
    case types.INIT_INTERVIEW_SCHEDULE: {
      const newErrors = { ...state.errors };
      delete newErrors.start_datetime;

      return state
        .update('errors', newErrors)
        .update('interview_schedule', initialState.get('interview_schedule'));
    }
    case types.UPDATE_PREFERRED_INTERVIEW_LIST:
      return updatePreferredInterviewDatetimeList(state, action.payload);
    default:
      return state;
  }
};
