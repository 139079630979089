import * as actions from './actions';
import MessageTemplateApi from '@/models/ducks/shared/api/message-template';

const init = () => {
  return dispatch => dispatch(actions.init());
};

const build = (id, params) => {
  return dispatch => MessageTemplateApi.build(id, params)
    .then((response) => {
      return dispatch(actions.set(response.data.message_template));
    });
};

const set = (messageTemplate) => {
  return dispatch => dispatch(actions.set(messageTemplate));
};

const changeField = (key, value) => {
  return dispatch => dispatch(actions.changeField(key, value));
};

export {
  init,
  build,
  set,
  changeField,
};
