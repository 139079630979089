import axios from 'axios';

export default {
  showMember(id) {
    return axios
      .get(`/api/customers/members/${ id }`);
  },

  search(param) {
    const { condition } = param.toJS();

    return axios
      .post('/api/customers/members/search/', {
        id: param.id,
        desired_areas: condition.desired_areas,
        desired_job_categories: condition.desired_job_categories,
        employment_statuses: condition.employment_statuses,
        employment_types: condition.employment_types,
        favorite: condition.favorite,
        scout: condition.scout * 1,
        last_education_ids: condition.last_education_ids.map((id) => {
          return id * 1;
        }),
        last_login: condition.last_login,
        member_id: condition.member_id,
        member_qualification_ids: condition.member_qualification_ids,
        recommend: condition.recommend,
        received_favorite_job_offer_id: condition.received_favorite_job_offer_id,
        scouted_member_job_offer_id: condition.scouted_member_job_offer_id,
        customer_search_condition_id: condition.customer_search_condition_id || param.id,
        recommend_customer_search_condition_id: condition.recommend_customer_search_condition_id,
        pagination: condition.pagination,
      });
  },
};
