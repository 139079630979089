import axios from 'axios';
import 'blueimp-canvas-to-blob';
import loadImage from 'blueimp-load-image';
import * as Sentry from '@sentry/browser';
import { apiClient } from '@/utils/ApiClient';

export default {
  signIn(credential) {
    return new Promise((resolve, reject) => {
      axios.post('/api/customers/auth/sign_in', credential)
        .then((response) => {
          Sentry.setUser({
            id: response.data.customer.id,
          });

          return resolve(response);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },
  signOut() {
    return axios.get('/api/customers/auth/sign_out');
  },
  show() {
    return new Promise((resolve, reject) => {
      axios.get('/api/customers/customers')
        .then((response) => {
          return resolve(response);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },

  showByInvitationCode(code) {
    return axios.get(`/api/customers/customers/invitations/${code}/`);
  },

  updateWithInvitationCode(code, customer) {
    return axios.put(`/api/customers/customers/invitations/${code}/`, { customer });
  },

  loadImageData(localImages) {
    const imagePromiseList = [];
    localImages.forEach((localImage) => {
      const imagePromise = new Promise((resolve) => {
        loadImage(localImage.image.blob, (canvas) => {
          const imageData = canvas.toDataURL(localImage.image.blob.type);

          resolve({
            canvas,
            file: imageData,
            width: canvas.width,
            height: canvas.height,
            caption: localImage.caption,
            name: localImage.image.blob.name,
          });
        }, {
          canvas: true,
          orientation: true,
        });
      });
      imagePromiseList.push(imagePromise);
    });
    return Promise.all(imagePromiseList);
  },
  createImageData(loadedImages) {
    const imagePromiseList = [];
    loadedImages.forEach((loadedImage) => {
      const imagePromise = new Promise((resolve) => {
        loadedImage.canvas.toBlob((blob) => {
          resolve({
            blob,
            width: loadedImage.width,
            height: loadedImage.height,
            caption: loadedImage.caption,
            name: loadedImage.name,
          });
        });
      });
      imagePromiseList.push(imagePromise);
    });
    return Promise.all(imagePromiseList);
  },
  createFormData(customer, images) {
    const formData = new FormData();
    formData.append('customer[email]', customer.email);
    formData.append('customer[name]', customer.name);
    formData.append('customer[address_etc]', customer.address_etc);
    formData.append('customer[city_id]', customer.city_id);
    formData.append('customer[in_charge_family_name]', customer.in_charge_family_name);
    formData.append('customer[in_charge_first_name]', customer.in_charge_first_name);
    formData.append('customer[in_charge_position]', customer.in_charge_position);
    formData.append('customer[in_charge_post]', customer.in_charge_post);
    formData.append('customer[lead_id]', customer.lead_id);
    formData.append('customer[name]', customer.name);
    formData.append('customer[password]', customer.password);
    formData.append('customer[password_confirmation]', customer.password_confirmation);
    formData.append('customer[postal_code]', customer.postal_code);
    formData.append('customer[prefecture_id]', customer.prefecture_id);
    formData.append('customer[tel]', customer.tel);

    if (customer.same_as_tel) {
      formData.append('customer[mobile_tel]', customer.tel);
    } else {
      formData.append('customer[mobile_tel]', customer.mobile_tel);
    }

    images.forEach((image) => {
      formData.append('customer_image_files[]', image.blob, image.name);
      formData.append('customer_image_widths[]', image.width);
      formData.append('customer_image_heights[]', image.height);
      formData.append('customer_image_captions[]', image.caption);
    });
    return formData;
  },
  create(customer) {
    return new Promise((resolve, reject) => {
      this.loadImageData(customer.images) // call async loadimage
        .then((images) => {
          return this.createImageData(images); // call async canvas.toBlob
        })
        .then((images) => {
          const formData = this.createFormData(customer, images);
          axios
            .post('/api/customers/auth', formData, {
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
              },
            })
            .then((response) => {
              resolve(response);
            }).catch((err) => {
              return reject(err);
            });
        });
    });
  },
  update(customer) {
    return axios.put('/api/customers/auth', { customer });
  },

  updateEmail(email) {
    return apiClient.put('/api/customers/customers/update_email', { email });
  },
  updatePassword(currentPassword, password, passwordConfirmation) {
    return axios.put('/api/customers/customers/update_password', {
      current_password: currentPassword,
      password,
      password_confirmation: passwordConfirmation,
    });
  },
  resetPassword(email) {
    return axios.post('/api/customers/auth/password', {
      customer: {
        email,
      },
    });
  },

  editPassword(password, token) {
    return axios.put('/api/customers/auth/password', {
      customer: {
        password,
        reset_password_token: token,
      },
    });
  },
  existByEmail(email) {
    return axios.get('/api/customers/customers/exist_by_email', {
      params: {
        email,
      },
    });
  },
};
