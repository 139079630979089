import axios from 'axios';

export default {
  all() {
    return axios.get('/api/customers/job_categories/');
  },

  registered(jobOfferType) {
    return axios.get('/api/customers/job_categories/registered', {
      params: {
        job_offer_type: jobOfferType,
      },
    });
  },

  getWithJobOffers(prefId) {
    return axios.get('/api/customers/job_categories/with_job_offers', {
      params: {
        prefecture_id: prefId,
      },
    });
  },

  getWithAllJobOffers(prefId) {
    return axios.get('/api/customers/job_categories/with_all_job_offers', {
      params: {
        prefecture_id: prefId,
      },
    });
  },
};
