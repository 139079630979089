export default {
  NOT_SPECIFY: 0, // 指定なし,
  SCOUT: 1, // スカウト
  APPLICATION: 2, // 応募
  OFFER: 3, // 内定通知
  ACCEPTANCE: 4, // 内定承諾
  WITHDRAWAL: 5, // 内定辞退
  REJECTION: 6, // 不採用通知
  NORMAL: 7, // 通常返信
  REFUSE_SCOUT: 8, // スカウト辞退
  OFFLINE_INTERVIEW: 13, // 対面面接
  ARRANGEMENT_INTERVIEW: 14, // 面接の案内
  CANCEL_INTERVIEW: 15, // 面接のキャンセル
  REPLY_ARRANGEMENT_INTERVIEW: 16, // 面接日程の調整(求職者 → 顧客) @see: https://github.com/medley-inc/job-medley/issues/19908#issue-901645798

  MESSAGE_TYPES: [
    {
      id: 1,
      name: 'models.constants.shared.message_types.scout',
    },
    {
      id: 2,
      name: 'models.constants.shared.message_types.apply',
    },
    {
      id: 3,
      name: 'models.constants.shared.message_types.offer',
    },
    {
      id: 4,
      name: 'models.constants.shared.message_types.accept',
    },
    {
      id: 5,
      name: 'models.constants.shared.message_types.decline',
    },
    {
      id: 6,
      name: 'models.constants.shared.message_types.reject',
    },
    {
      id: 7,
      name: 'models.constants.shared.message_types.default',
    },
    {
      id: 8,
      name: 'models.constants.shared.message_types.decline_scout',
    },
    {
      id: 13,
      name: 'models.constants.shared.message_types.in_person_interview',
    },
    {
      id: 14,
      name: 'models.constants.shared.message_types.arrange_interview_from_customer',
    },
    {
      id: 15,
      name: 'models.constants.shared.message_types.cancel_interview',
    },
    {
      id: 16,
      name: 'models.constants.shared.message_types.arrange_interview_from_user',
    },
  ],
};
