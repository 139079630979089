const CLEAR = 'state/ducks/customer/CLEAR';
const CREATE = 'state/ducks/customer/CREATE';
const UPDATE_FIELD = 'state/ducks/customer/UPDATE_FIELD';
const LOAD_LEAD = 'state/ducks/customer/LOAD_READ';
const GET_CUSTOMER_BY_VERIFICATION_CODE = 'state/ducks/customer/GET_CUSTOMER_BY_VERIFICATION_CODE';
const FILL_ADDRESSES = 'state/ducks/customer/FILL_ADDRESSES';
const FILL_INVOICE_ADDRESSES = 'state/ducks/customer/FILL_INVOICE_ADDRESSES';
const NOOP = 'state/ducks/customer/NOOP';
const ADD_IMAGES = 'state/ducks/customer/ADD_IMAGES';
const DELETE_IMAGE = 'state/ducks/customer/DELETE_IMAGE';
const UPDATE_IMAGE = 'state/ducks/customer/UPDATE_IMAGE';
const SET_IMAGE_SIZE = 'state/ducks/customer/SET_IMAGE_SIZE';
const SORT_IMAGES = 'state/ducks/customer/SORT_IMAGES';
const SAVE = 'state/ducks/customer/SAVE';
const CLEAR_INVOICE = 'state/ducks/customer/CLEAR_INVOICE';

export {
  CLEAR,
  CREATE,
  UPDATE_FIELD,
  LOAD_LEAD,
  NOOP,
  FILL_ADDRESSES,
  FILL_INVOICE_ADDRESSES,
  ADD_IMAGES,
  DELETE_IMAGE,
  UPDATE_IMAGE,
  SET_IMAGE_SIZE,
  SORT_IMAGES,
  SAVE,
  CLEAR_INVOICE,
  GET_CUSTOMER_BY_VERIFICATION_CODE,
};
