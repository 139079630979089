import { List } from 'immutable';
import * as types from './types';
import ScoutMessageForm from './model';

export const initialState = new ScoutMessageForm();

const initJobOfferField = (state) => {
  return state
    .setIn(['messages', 0, 'job_offer', 'prefecture_id'], 0)
    .setIn(['messages', 0, 'job_offer', 'job_category_id'], 0)
    .setIn(['messages', 0, 'job_offer', 'keyword'], '');
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.INIT:
      return initialState;
    case types.CHANGE_FIELD:
      return state.setIn(['messages', 0, action.payload.key], action.payload.value);
    case types.CHANGE_JOB_OFFER_FIELD:
      return state.setIn(['messages', 0, 'job_offer', action.payload.key], action.payload.value);
    case types.INIT_JOB_OFFER_FIELD:
      return initJobOfferField(state);
    case types.CHANGE_MEMBER_IDS_FIELD:
      return state.set('member_ids', List(action.payload.memberIds));
    default:
      return state;
  }
};
