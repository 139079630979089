import React, { FC, useState, useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { DispatchProps } from './container';

export type OwnProps = {
  location: Location;
};

type Props = DispatchProps & OwnProps;

const CheckLogin: FC<Props> = (props) => {
  const [isLogin, setIsLogin] = useState(false);
  const [redirectPath, setRedirectPath] = useState('');

  useEffect(() => {
    const onCheckLogin = async () => {
      // 画面遷移した際はこのコンポーネントはマウントしたままなので、isLogin,redirectPathを初期化する
      setIsLogin(false);
      setRedirectPath('');

      const checkLoginResult = await props.checkLogin();
      if (checkLoginResult.isReload) {
        location.href = checkLoginResult.redirectPath;
        return;
      }
      setIsLogin(checkLoginResult.isLogin);
      setRedirectPath(checkLoginResult.redirectPath);
    };
    onCheckLogin();
  }, [props.location.pathname]);

  if (isLogin) {
    return <Navigate to={`${ redirectPath }`} />;
  }

  return <Outlet />;
};

export default CheckLogin;
