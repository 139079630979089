import * as types from './types';
import FacilityJobOfferList from '@/models/ducks/shared/models/facility-job-offers';
import { FACILITY_STATUS } from '@/models/ducks/facility/constants';

export const initialState = new FacilityJobOfferList();

const updateFacility = (state, action) => {
  const { facility } = action.payload;
  const newFacilities = state.facilities.update(state.facilities.findIndex((item) => {
    return item.id === facility.id;
  }), () => {
    return facility;
  });

  return state.update('facilities', newFacilities);
};

const updateJobOffer = (state, action) => {
  const { facilityJobOffer } = action.payload;
  const newJobOffer = facilityJobOffer.facilities.get(0).job_offers.get(0);
  const newJobOffers = state.facilities.get(action.payload.facilityIndex).job_offers
    .update(action.payload.jobOfferIndex, () => {
      return newJobOffer;
    });

  return state.updateJobOffers(action.payload.facilityIndex, newJobOffers);
};

const deleteJobOffer = (state, action) => {
  const { facilityIndex, jobOfferIndex } = action.payload;
  const newJobOffers = state.facilities.getIn([facilityIndex, 'job_offers']).delete(jobOfferIndex);

  return state.updateJobOffers(facilityIndex, newJobOffers);
};

const deleteFacility = (state, action) => {
  const { facilityId } = action.payload;
  const newFacilities = state.facilities.update(state.facilities.findIndex((facility) => {
    return facility.id === facilityId;
  }), (targetFacility) => {
    return targetFacility.set('status', FACILITY_STATUS.STATUS.DELETED);
  });

  return state.update('facilities', newFacilities);
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.INIT:
      return initialState;
    case types.GET:
      return FacilityJobOfferList.convert(action.payload.response);
    case types.UPDATE_FACILITY:
      return updateFacility(state, action);
    case types.UPDATE_JOB_OFFER:
      return updateJobOffer(state, action);
    case types.DELETE_JOB_OFFER:
      return deleteJobOffer(state, action);
    case types.DELETE_FACILITY:
      return deleteFacility(state, action);
    default:
      return state;
  }
};
