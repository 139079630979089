import Signin from './signin.json';
import Home from './home.json';

import Customers from './customers.json';
import FacilityList from './facility-list.json';
import Facility from './facility.json';

import JobOffer from './job_offer.json';

import Image from './image.json';
import ImageManager from './image-manager.json';

import Searches from './searches.json';

import Selections from './selections.json';

import Messages from './messages.json';
import MessageView from './message-view.json';

import Shared from './shared.json';

const Data = {};
Data.signin = Signin;
Data.home = Home;

Data.customers = Customers;

Data.facility_list = FacilityList;
Data.facility = Facility;

Data.job_offer = JobOffer;

Data.image = Image;
Data.image_manager = ImageManager;

Data.searches = Searches;

Data.selections = Selections;

Data.messages = Messages;
Data.message_view = MessageView;

Data.shared = Shared;

export default Data;
