import React from 'react';

import PropTypes from 'prop-types';

const Headbar = (props) => {
  return (
    <div className="o-headbar js-infinity-scroll-outer-el">
      { props.children }
    </div>
  );
};

Headbar.propTypes = {
  children: PropTypes.node,
};

export default Headbar;
