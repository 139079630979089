import * as types from './types';

const set = (image) => {
  return {
    type: types.SET,
    payload: {
      image,
    },
  };
};

const changeField = (key, value) => {
  return {
    type: types.CHANGE_FIELD,
    payload: {
      key,
      value,
    },
  };
};

export {
  set,
  changeField,
};
