import * as types from './types';
import NAV_TYPE from '@/models/ducks/shared/constants/member-search-nav-type';

export const initialState = {
  type: NAV_TYPE.ALL,
  id: 0,
  item: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.INIT:
      return initialState;
    case types.UPDATE:
      return {
        ...state,
        type: action.payload.type,
        id: action.payload.id,
        item: action.payload.condition,
      };
    default:
      return state;
  }
};
