import { getType } from 'typesafe-actions';
import * as actions from './actions';
import { reduceWithImmer } from '@/reducer';

export const initialState = {
  scoutSendingFailureConfirm: {
    isHidden: true,
    message: '',
  },
};

export type SearchesState = typeof initialState;

export const reducer = (state: SearchesState = initialState, action: actions.AnnouncementsActionTypes): SearchesState => {
  return reduceWithImmer(state, (draft) => {
    switch (action.type) {
      case getType(actions.init):
        return initialState;
      case getType(actions.showScoutSendingFailureConfirm):
        draft.scoutSendingFailureConfirm.isHidden = false;
        if (action.payload.message) {
          draft.scoutSendingFailureConfirm.message = action.payload.message;
        }
        break;
      case getType(actions.hideScoutSendingFailureConfirm):
        draft.scoutSendingFailureConfirm = initialState.scoutSendingFailureConfirm;
        break;
      default:
        return draft;
    }
    return draft;
  });
};
