import * as timezone from '@/utils/timezone';

function isPermanent(state) {
  return !!state.permanent;
}

function isCurrentMonth(state) {
  if (isPermanent(state)) {
    return true;
  }
  if (!state.promoted) {
    return false;
  }
  return timezone.isThisMonth(state.promoted);
}

export {
  isPermanent,
  isCurrentMonth,
};
