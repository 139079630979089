import * as types from './types';

const init = () => {
  return {
    type: types.INIT,
  };
};

const update = ({ type, condition, id }) => {
  return {
    type: types.UPDATE,
    payload: {
      type,
      condition,
      id,
    },
  };
};

export {
  init,
  update,
};
