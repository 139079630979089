import { Record, Map, List } from 'immutable';
import qs from 'qs';
import immutableHelper from '@/utils/immutable-helper';

const properties = {
  name: '',
  contact_name: '',
  facility_form_id: null,
  job_offer_existence: 0,
  pagination: Map({
    page: 1,
    limit: 20,
  }),
  facility_id: '',
};

const FacilitySearchFormRecord = Record(properties);

export default class FacilitySearchForm extends FacilitySearchFormRecord {
  update(key, value) {
    return this.set(key, value);
  }

  updateMap(model, query) {
    return model.mapEntries((entry) => {
      const key = entry[0];
      const value = entry[1];

      if (!immutableHelper.isImmutable(value)) {
        return query ? [key, query[key]] : [key, ''];
      }

      if (Map.isMap(value)) {
        return query ? this.updateMap(value, query[key]) : this.updateMap(value, {});
      }

      if (List.isList(value)) {
        return query ? [key, List(query[key])] : [key, List([])];
      }

      return entry;
    });
  }

  updateFromUrlQuery(query) {
    const model = this.mapEntries((entry) => {
      const key = entry[0];
      const value = entry[1];

      if (!immutableHelper.isImmutable(value)) {
        return [key, query[key]];
      }

      if (Map.isMap(value)) {
        return [key, this.updateMap(value, query[key])];
      }

      if (List.isList(value)) {
        return [key, List(query[key])];
      }

      return entry;
    });

    return model;
  }

  querify() {
    return qs.stringify(this.toJS(), {
      encodeValuesOnly: true,
    });
  }

  resetPage() {
    return this.set('pagination', properties.pagination);
  }
}
