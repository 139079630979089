import * as types from './types';
import FacilityJobOfferList from '@/models/ducks/shared/models/facility-job-offers';

export const initialState = new FacilityJobOfferList();

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET:
      return FacilityJobOfferList.convert(action.payload.response);
    default:
      return state;
  }
};
