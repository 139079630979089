import { configureStore, combineReducers } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { routerReducer } from 'react-router-redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { entities } from '@/entities/reducers';
import * as reducers from './ducks';
import { rootSaga } from '@/saga';
import * as announcementsReducer from '@/pages/Announcements/reducer';
import * as searchesReducer from '@/pages/Searches/reducer';
import * as resetFormReducer from '@/pages/ResetForm/reducer';
import * as emailEditReducer from '@/pages/EmailEdit/reducer';

const saga = createSagaMiddleware();

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [
    'deviceMode',
    'guide',
    'visited',
    'promoted',
    'initialized',
    'scoutSendingCounterInitialized',
  ],
};

export const rootReducer = persistReducer(persistConfig, combineReducers({
  ...reducers,
  routing: routerReducer,
  entities,
  announcements: announcementsReducer.reducer,
  searches: searchesReducer.reducer,
  resetForm: resetFormReducer.reducer,
  emailEdit: emailEditReducer.reducer,
}));

export default function appConfigureStore(initialState) {
  const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware => [
      ...getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: false, // TODO: 本来はimmutableCheckをtrueにしたいが、immutableでない扱いをしているreducerが存在してエラーになるため一旦falseにしている。後でこれをtrueに直す。
      }), saga],
    devTools: process.env.NODE_ENV === 'development',
    preloadedState: initialState,
  });

  saga.run(rootSaga);

  store.subscribe(() => {
  });

  return store;
}
