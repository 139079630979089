import qs from 'qs';
import * as actions from './actions';

const init = () => {
  return dispatch => dispatch(actions.init());
};

const changeField = (key, value) => {
  return dispatch => dispatch(actions.changeField(key, value));
};

const changeFromUrlQuery = (params) => {
  const query = params.split('?')[1];
  const parsed = qs.parse(query);

  return dispatch => dispatch(actions.changeFromUrlQuery(parsed));
};

const changePostingStatuses = (key, value) => {
  return dispatch => dispatch(actions.changePostingStatuses(key, value));
};

const changePage = (page) => {
  return dispatch => dispatch(actions.changePage(page));
};

const overwriteWithFacilitySearchForm = (facilitySearchForm) => {
  return dispatch => dispatch(actions.overwriteWithFacilitySearchForm(facilitySearchForm));
};

export {
  init,
  changeField,
  changeFromUrlQuery,
  changePostingStatuses,
  changePage,
  overwriteWithFacilitySearchForm,
};
