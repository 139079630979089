import { List } from 'immutable';
import * as types from './types';
import SelectionSearchForm from './model';
import FACILITY_JOB_OFFER_SUGGEST_TYPE from '@/models/ducks/shared/constants/facility-job-offer-suggest-type';

export const initialState = new SelectionSearchForm();

const setSuggestionValue = (state, action) => {
  const { suggestion } = action.payload;

  if (suggestion.type === FACILITY_JOB_OFFER_SUGGEST_TYPE.FACILITY) {
    return state.merge({
      facility_id: suggestion.id,
      facility_name: suggestion.name,
      job_offer_id: '',
      job_offer_name: '',
    });
  } else if (suggestion.type === FACILITY_JOB_OFFER_SUGGEST_TYPE.JOB_OFFER) {
    return state.merge({
      job_offer_id: suggestion.id,
      job_offer_name: suggestion.name,
      facility_id: '',
      facility_name: '',
    });
  }

  return state;
};

const updateCheckboxFields = (state, action) => {
  const fields = action.payload.checked
    ? state[action.payload.key].push(action.payload.value)
    : state[action.payload.key].delete(state[action.payload.key].findIndex((index) => {
      return index === action.payload.value;
    }));

  return state.update(action.payload.key, fields);
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.INIT:
      return initialState;
    case types.CHANGE_FIELD:
      return state.update(action.payload.key, action.payload.value);
    case types.CHANGE_CHECKBOX_FIELD:
      return updateCheckboxFields(state, action);
    case types.CLAER_FIELD:
      return state.update(action.payload.key, initialState[action.payload.key]);
    case types.CHANGE_STATUS:
      return state.update('selection_status_ids', List(action.payload.status));
    case types.SET_SUGGESTION_VALUE:
      return setSuggestionValue(state, action);
    case types.INIT_SUGGESTION_VALUE:
      return state.merge({
        facility_id: null,
        job_offer_id: null,
        job_offer_name: '',
      });
    case types.INIT_SUGGESTION:
      return state.clearSuggest();
    case types.INIT_FACILITY_JOB_OFFER:
      return state.clearFacilityOrJobOffer();
    case types.CREATE_CACHE:
      return state.createCache();
    case types.RESTORE_FROM_CACHE:
      return state.restoreFromCache();
    default:
      return state;
  }
};
