import { Record, List } from 'immutable';

const properties = {
  images: List([]),
};

const SelectedImagesRecord = Record(properties);

export default class SelectedImages extends SelectedImagesRecord {
  update(key, value) {
    return this.set(key, value);
  }
}
