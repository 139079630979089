import React, { ElementType } from 'react';
import { useRouteLocation, useRouteNavigate, useRouteParams } from '@/hooks/useRouter';

/**
 * クラスコンポーネントではhooksが使用できないため、ここでpropsでuseNavigate()、useLocation()、useRouteParams()を呼び出し、クラスコンポーネントに渡すHOC
 * 各コンポーネントをファンクショナルコンポーネント化した際にはこのHOCは必要なくなるため、削除する
 * see https://github.com/medley-inc/job-medley/issues/24354
 * @module withRouter
 * @param { ElementType } Component - コンポーネント。
 * @return { JSX.Element } - propsでuseNavigate()、useLocation()、useRouteParams()を渡したComponent
 */
export const withRouter = (Component: ElementType) => {
  return () => {
    const router = useRouteNavigate();
    const location = useRouteLocation();
    const params = useRouteParams();

    return (
      <>
        <Component router={ router } location={ location } params={ params } />
      </>
    );
  };
};
