import { ValueOf } from '@/types';

const TOGGLE = {
  MESSAGE_GROUP_SEARCH_LABEL: 'MESSAGE_GROUP_SEARCH_LABEL',
  SEARCHES_CONDITION_LABEL: 'SEARCHES_CONDITION_LABEL',
  SEARCHES_MULTI_CHECKS_ENABLED: 'SEARCHES_MULTI_CHECKS_ENABLED',
  MANUAL_CONDITIONS_EDIT_ENABLED: 'MANUAL_CONDITIONS_EDIT_ENABLED',
  RECOMMENDED_CONDITIONS_EDIT_ENABLED: 'RECOMMENDED_CONDITIONS_EDIT_ENABLED',
  SEARCH_CONDITION_DETAILED: 'SEARCH_CONDITION_DETAILED',
  SEARCHES_DROPDOWN_MENU: 'SEARCHES_DROPDOWN_MENU',
  SEARCH_CONDITION_ALL_QUALIFICATONS: 'SEARCH_CONDITION_ALL_QUALIFICATONS',
  INLINE_LOADER: 'INLINE_LOADER',
  SUGGEST_FETCH_LOADER: 'SUGGEST_FETCH_LOADER',
  MESSAGE_TEMPLATES_HELP: 'MESSAGE_TEMPLATES_HELP',
  SELECTION_APPLICATION_REQUEST_PROFILE: 'SELECTION_APPLICATION_REQUEST_PROFILE',
  SELECTION_STATUS_LINK_LIST: 'SELECTION_STATUS_LINK_LIST',
  CUSTOMER_CLAIM: 'CUSTOMER_CLAIM',
  FACILITY_CLAIM: 'FACILITY_CLAIM',
  CUSTOMER_CLAIM_IS_SAME_WITH_BASIC: 'CUSTOMER_CLAIM_IS_SAME_WITH_BASIC',
  FACILITY_CLAIM_IS_SAME_WITH_CUSTOMER: 'FACILITY_CLAIM_IS_SAME_WITH_CUSTOMER',
} as const;

export default TOGGLE;

export type ToggleKey = ValueOf<typeof TOGGLE>;
