import * as actions from './actions';
import NAV_TYPE from '@/models/ducks/shared/constants/member-search-nav-type';

const updateToAll = () => {
  return (dispatch) => {
    return dispatch(actions.init());
  };
};

const updateToManual = (condition) => {
  return (dispatch) => {
    return dispatch(actions.update({
      type: NAV_TYPE.MANUAL_CONDITION,
      id: condition.id,
      condition,
    }));
  };
};

const updateToRecommended = (condition) => {
  return (dispatch) => {
    return dispatch(actions.update({
      type: NAV_TYPE.RECOMMEND_CONDITION,
      id: condition.id,
      condition,
    }));
  };
};

const updateToFavoriteJobOffer = (condition) => {
  return (dispatch) => {
    return dispatch(actions.update({
      type: NAV_TYPE.FAVORITE_JOB_OFFER,
      condition,
      id: condition.job_offer_id,
    }));
  };
};

const updateToScoutedJobOffer = (condition) => {
  return (dispatch) => {
    return dispatch(actions.update({
      type: NAV_TYPE.SCOUNT_JOB_OFFER,
      condition,
      id: condition.job_offer_id,
    }));
  };
};

export {
  updateToAll,
  updateToManual,
  updateToRecommended,
  updateToFavoriteJobOffer,
  updateToScoutedJobOffer,
};
