import * as types from './types';

const update = (status) => {
  return {
    type: types.UPDATE,
    payload: {
      status,
    },
  };
};

const check = () => {
  return {
    type: types.CHECK,
  };
};

export {
  update,
  check,
};
