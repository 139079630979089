import axios from 'axios';

export default {
  all() {
    return axios.get('/api/customers/qualifications/', {
      params: {
        customer: 1,
      },
    });
  },

  jobCategorySearchCondition(id) {
    return axios.get(`/api/customers/qualifications/job_category_search_condition/${ id }`);
  },
};
