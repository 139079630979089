import * as types from './types';
import FacilityJobOfferSearchForm from '@/models/ducks/shared/models/facility-job-offer-search-form';

export const initialState = new FacilityJobOfferSearchForm();

const changePostingStatuses = (state, action) => {
  const { key, value } = action.payload;
  const statuses = value ?
    state.job_offer_status.push(key)
    :
    state.job_offer_status.delete(state.job_offer_status.findIndex((index) => {
      return index === key;
    }));

  return state.setIn(['job_offer_status'], statuses);
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.INIT:
      return initialState;
    case types.CHANGE_FIELD:
      return state.set(action.payload.key, action.payload.value);
    case types.CHANGE_FROM_URL_QUERY:
      return state.updateFromUrlQuery(action.payload.parsedQuery);
    case types.CHANGE_POSTING_STATUSES:
      return changePostingStatuses(state, action);
    case types.CHANGE_PAGE:
      return state.setIn(['pagination', 'page'], action.payload.page);
    case types.OVERWRITE_WITH_FACILITY_SEARCH_FORM:
      return new FacilityJobOfferSearchForm(action.payload.facilitySearchForm.set('pagination', initialState.get('pagination')));
    default:
      return state;
  }
};
