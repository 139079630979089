import * as actions from './actions';
import CustomerSearchConditionsApi from '@/models/ducks/shared/api/customer-search-conditions';

const create = (condition) => {
  return dispatch => CustomerSearchConditionsApi.createLabel(condition)
    .then((response) => {
      return dispatch(actions.create(response.data.label));
    });
};

const edit = (key, value) => {
  return dispatch => dispatch(actions.change(key, value));
};

const init = () => {
  return dispatch => dispatch(actions.init());
};

export {
  create,
  edit,
  init,
};
