import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default class Sidebar extends React.Component {

  static propTypes = {
    hidden: PropTypes.bool,
    children: PropTypes.node,
    className: PropTypes.string,
  }

  static defaultProps = {
    hidden: false,
  }

  shouldComponentUpdate(nextProps) {
    if (this.props.hidden && nextProps.hidden) {
      return false;
    }
    return true;
  }

  render() {
    const {
      hidden,
      className,
      children,
    } = this.props;

    const sidebarClass = classNames({
      'o-sidebar': true,
      'c-sidebar': true,
      'u-is-hidden': hidden,
      [`${ className }`]: className,
    });

    return (
      <nav className={ sidebarClass }>
        { children }
      </nav>
    );
  }
}
