export default {
  CATEGORY: {
    SIDE_NAV: 'SideNav',
    SCOUT_MEMBERS_SEARCH: 'ScoutMembersSearch',
  },

  TRIGGER_ID: {
    SAVED_CONDITION: 'SAVED_CONDITION',
    RECOMMENDED_CONDITION: 'RECOMMENDED_CONDITION',
    FAVORITE_MEMBERS: 'FAVORITE_MEMBERS',
    SCOUTED_MEMBERS: 'SCOUTED_MEMBERS',
    ALL: 'ALL',
    SEARCH_PANEL: 'SEARCH_PANEL',
    SEARCH_CONDITION_MODAL: 'SEARCH_CONDITION_MODAL',
  },
} as const;
