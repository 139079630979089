import * as types from './types';
import PasswordEditForm from './model';

export const initialState = new PasswordEditForm();

export default (state = initialState, action) => {
  switch (action.type) {
    case types.INIT:
      return initialState;
    case types.CHANGE_FIELD:
      return state.update(action.payload.key, action.payload.value);
    default:
      return state;
  }
};
