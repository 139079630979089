import reducers from './reducers';
import * as scoutTargetMemberListOperations from './operations';
import * as scoutTargetMemberListTypes from './types';

export {
  scoutTargetMemberListOperations,
  scoutTargetMemberListTypes,
};

export default reducers;
