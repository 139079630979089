import * as types from './types';

const init = ({ suggest }) => {
  return {
    type: types.INIT,
    payload: {
      suggest,
    },
  };
};

const get = ({ suggest }) => {
  return {
    type: types.GET,
    payload: {
      suggest,
    },
  };
};

const getMore = ({ suggest }) => {
  return {
    type: types.GET_MORE,
    payload: {
      suggest,
    },
  };
};

const initForScout = ({ suggest }) => {
  return {
    type: types.INIT_FOR_SCOUT,
    payload: {
      suggest,
    },
  };
};

const getForScout = ({ suggest }) => {
  return {
    type: types.GET_FOR_SCOUT,
    payload: {
      suggest,
    },
  };
};

const getMoreForScout = ({ suggest }) => {
  return {
    type: types.GET_MORE_FOR_SCOUT,
    payload: {
      suggest,
    },
  };
};

const clear = () => {
  return {
    type: types.CLEAR,
  };
};

const initWithFacility = ({ suggest, total }) => {
  return {
    type: types.INIT_WITH_FACILITY,
    payload: {
      suggest,
      total,
    },
  };
};

const getWithFacility = ({ suggest }) => {
  return {
    type: types.GET_WITH_FACILITY,
    payload: {
      suggest,
    },
  };
};

const getMoreWithFacility = ({ suggest }) => {
  return {
    type: types.GET_MORE_WITH_FACILITY,
    payload: {
      suggest,
    },
  };
};

export {
  init,
  get,
  getMore,
  initForScout,
  getForScout,
  getMoreForScout,
  clear,
  initWithFacility,
  getWithFacility,
  getMoreWithFacility,
};
