import * as actions from './actions';
import HomeApi from './api';

const get = () => {
  return dispatch => HomeApi.summary()
    .then((response) => {
      return dispatch(actions.get(response.data));
    });
};

export {
  get,
};
