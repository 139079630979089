import { fromJS, Record, List, Map } from 'immutable';
import qs from 'qs';
import deepRenameKeys from 'deep-rename-keys';
import { CONDITION_TYPE, PARAMETER_ALIAS } from '@/models/ducks/customer-search-condition/constants';
import immutableHelper from '@/utils/immutable-helper';
import findValueByKey from '@/utils/find-value-by-key';
import { scoutUtils } from '@/models/ducks/customer-search-condition/utils';

const properties = {
  id: undefined,
  name: '',
  sendable: true,
  match_count: undefined,
  condition_type: CONDITION_TYPE.MANUAL,
  condition: Map({
    count: '',
    desired_areas: List([
      Map({
        prefecture_id: '',
        cities: List(),
        city_id: '', // TODO: 市区町村が複数選択できない仕様だったときのkeyなので、削除して問題ないことを確認して削除する
        city_ids: List(),
      }),
    ]),
    desired_job_categories: List([
      Map({
        job_category_group_id: '',
        job_category_id: '',
        career_year: '',
      }),
    ]),
    employment_status: List(),
    employment_statuses: List(),
    employment_types: List(),
    last_education_ids: List([]),
    last_login: '0',
    member_id: '',
    member_qualification_ids: List([]),
    pagination: Map({
      page: 1,
      limit: 20,
    }),
    recommend: false, // 検索条件として永続化しない
    received_favorite_job_offer_id: undefined, // 検索条件として永続化しない
    received_favorite_job_offer_name: '', // 検索条件として永続化しない
    received_favorite_job_offer: Map({
      prefecture_id: 0,
      job_category_id: 0,
      keyword: '',
    }),
    favorite: false, // 検索条件として永続化しない
    scouted_member_job_offer_id: undefined, // 検索条件として永続化しない
    scouted_member_job_offer_name: '', // 検索条件として永続化しない
    scouted_member_job_offer: Map({
      prefecture_id: 0,
      job_category_id: 0,
      keyword: '',
    }),
    scout: '0', // 検索条件として永続化しない
    customer_search_condition_id: undefined, // 検索条件として永続化しない
    recommend_customer_search_condition_id: undefined, // 検索条件として永続化しない
  }),
};

const CustomerSearchConditionRecord = Record(properties);
export default class CustomerSearchCondition extends CustomerSearchConditionRecord {
  update(key, value) {
    if (Array.isArray(key)) {
      return this.setIn(key, value);
    }
    return this.set(key, value);
  }

  isManualCondition() {
    return this.condition_type === CONDITION_TYPE.MANUAL;
  }

  isSpecifiedDesiredArea() {
    const first = this.condition.getIn(['desired_areas', 0]);

    if (
      first
      && (Number(first.get('prefecture_id')) > 0 || first.get('city_ids').length > 0)
    ) {
      return true;
    }

    return false;
  }

  isSpecifiedDesiredJobCategories() {
    const first = this.condition.getIn(['desired_job_categories', 0]);

    if (
      first
      && (Number(first.get('job_category_id')) > 0 || Number(first.get('career_year')) > 0)
    ) {
      return true;
    }
    return false;
  }

  isChanged(isIncludeAreaAndJobCategory = false) {
    if (
      isIncludeAreaAndJobCategory
      && (this.isSpecifiedDesiredArea() || this.isSpecifiedDesiredJobCategories())
    ) {
      return true;
    }

    if (
      this.condition.getIn(['employment_statuses']).size >= 1
      || this.condition.getIn(['employment_types']).size >= 1
      || this.condition.get('favorite')
      || this.condition.getIn(['last_education_ids']).size >= 1
      || Number(this.condition.getIn(['last_login'])) >= 1
      || this.condition.getIn(['member_id'])
      || this.condition.getIn(['member_qualification_ids']).size >= 1
      || this.condition.getIn(['recommend'])
      || Number(this.condition.getIn(['scout'])) >= 1
    ) {
      return true;
    }
    return false;
  }

  create(model) {
    // TODO
    //  - ネストしたimmutableオブジェクトをいちいち全部immutableに変換せずに一括変換したい
    //  - いちいち型変換せずに最初っから型を合わせておきたい

    let desiredJobCategories;

    if (!model.condition.desired_job_categories.length) {
      desiredJobCategories = List([Map({
        job_category_group_id: '',
        job_category_id: '',
        career_year: '',
      })]);
    } else {
      desiredJobCategories = List(model.condition.desired_job_categories.map((jobCategory) => {
        return Map({
          job_category_group_id: jobCategory.job_category_group_id,
          job_category_id: jobCategory.job_category_id,
          career_year: jobCategory.career_year,
        });
      }));
    }

    const employmentStatuses = List(model.condition.employment_statuses.map((status) => {
      return `${ status }`;
    }));
    const employmentTypes = List(model.condition.employment_types.map((type) => {
      return `${ type }`;
    }));
    const lastEducationIds = List(model.condition.last_education_ids.map((id) => {
      return `${ id }`;
    }));
    const qualificationIds = List(model.condition.member_qualification_ids);

    let desiredAreas;

    if (!model.condition.desired_areas.length) {
      desiredAreas = List([
        Map({
          prefecture_id: '',
          cities: List(),
          city_id: '',
          city_ids: List(),
        }),
      ]);
    } else {
      desiredAreas = List(model.condition.desired_areas.map((area) => {
        return Map({
          prefecture_id: area.prefecture_id,
          cities: List(area.cities),
          city_id: area.city_id || '',
          city_ids: List(area.city_ids),
        });
      }));
    }

    const newModel = this
      .set('condition_type', model.condition_type)
      .set('name', model.name)
      .set('id', model.id)
      .set('match_count', model.match_count)
      .set('sendable', model.sendable)
      .setIn(['condition', 'desired_areas'], desiredAreas)
      .setIn(['condition', 'created_at'], model.condition.created_at)
      .setIn(['condition', 'customer'], model.condition.customer)
      .setIn(['condition', 'customer_search_condition_id'], model.condition.customer_search_condition_id)
      .setIn(['condition', 'desired_job_categories'], desiredJobCategories)
      .setIn(['condition', 'employment_statuses'], employmentStatuses)
      .setIn(['condition', 'employment_types'], employmentTypes)
      .setIn(['condition', 'favorite'], model.condition.favorite)
      .setIn(['condition', 'scout'], model.condition.scout)
      .setIn(['condition', 'last_education_ids'], lastEducationIds)
      .setIn(['condition', 'last_login'], `${ model.condition.last_login }`)
      .setIn(['condition', 'member_id'], `${ model.condition.member_id || '' }`)
      .setIn(['condition', 'member_qualification_ids'], qualificationIds)
      .setIn(['condition', 'received_favorite_job_offer_id'], model.condition.received_favorite_job_offer_id)
      .setIn(['condition', 'received_favorite_job_offer_name'], model.condition.received_favorite_job_offer_name)
      .setIn(['condition', 'scouted_member_job_offer_id'], model.condition.scouted_member_job_offer_id)
      .setIn(['condition', 'scouted_member_job_offer_name'], model.condition.scouted_member_job_offer_name);

    return newModel;
  }

  updateMap(model, query) {
    const newModel = model.mapEntries((entry) => {
      const key = entry[0];
      const value = entry[1];

      if (!immutableHelper.isImmutable(value)) {
        return query ? [key, query[key]] : [key, ''];
      }

      if (Map.isMap(value)) {
        return query ? this.updateMap(value, query[key]) : this.updateMap(value, {});
      }

      if (List.isList(value)) {
        return query ? [key, List(query[key])] : [key, List([])];
      }

      return entry;
    });

    return newModel;
  }

  updateList(model, query = []) {
    const size = query.length;
    let list;

    if (!model.size) {
      list = model.setSize(size);
    } else {
      const cache = model.get(0);
      list = model.clear();

      for (let i = 0; i < size; i += 1) {
        list = list.push(cache);
      }
    }

    const newModel = list.map((entry, index) => {
      if (!immutableHelper.isImmutable(entry)) {
        return query[index] || [];
      }

      if (Map.isMap(entry)) {
        return query ? this.updateMap(entry, query[index]) : this.updateMap(entry, {});
      }

      if (List.isList(entry)) {
        return query ? this.updateList(entry, query[index]) : this.updateList(entry, []);
      }

      return entry;
    });

    return newModel;
  }

  updateDesiredAreas(model, query = []) {
    return fromJS(query)
      .groupBy(q => q.get('prefecture_id'))
      .map((values) => {
        const item = values.first();
        const cityIds = values.map(v => Number(v.get('city_id'))).filter(v => v);

        return item.merge({
          city_ids: item.get('city_ids') ? item.get('city_ids').toArray().map(v => Number(v)) : cityIds,
          cities: List(),
        });
      })
      .toList();
  }

  updateFromUrlQuery(query) {
    const condition = this.get('condition').mapEntries((entry) => {
      const key = entry[0];
      const value = entry[1];

      if (!query[key]) {
        if (key === 'member_id' && query.memberId) {
          return [key, query.memberId];
        }
        return [key, properties.condition.get(key)];
      }

      if (!immutableHelper.isImmutable(value)) {
        const val = query[key] !== 'false' ? query[key] : false;

        return [key, val];
      }

      if (Map.isMap(value)) {
        return [key, this.updateMap(value, query[key])];
      }

      if (List.isList(value)) {
        if (key === 'desired_areas') {
          return [key, this.updateDesiredAreas(value, query[key])];
        }

        return [key, this.updateList(value, query[key])];
      }

      return entry;
    });

    const model = this.set('condition', condition);

    return model;
  }

  querifyCondition() {
    let desiredAreas;

    if (!this.condition.get('desired_areas').size) {
      desiredAreas = [
        {
          prefecture_id: '',
          cities: [],
          city_ids: [],
        },
      ];
    } else {
      desiredAreas = this.condition.get('desired_areas').map((area) => {
        const prefId = area.get('prefecture_id') * 1;
        const cityId = area.get('city_id') * 1;
        const cityIds = area.get('city_ids').toJS();
        const normalizeCityIds = (ids, id) => {
          if (!ids.length) {
            return Number(id) > 0 ? [id] : [];
          }

          return ids.filter(v => Number(v) > 0);
        };

        return {
          prefecture_id: prefId > 0 ? prefId : '',
          cities: [],
          city_ids: normalizeCityIds(cityIds, cityId),
        };
      });
    }

    let desiredJobCategories;

    if (!this.condition.get('desired_job_categories').size) {
      desiredJobCategories = [
        {
          job_category_group_id: '',
          job_category_id: '',
          career_year: '',
        },
      ];
    } else {
      desiredJobCategories = this.condition.get('desired_job_categories').map((jobCategory) => {
        return {
          job_category_group_id: jobCategory.get('job_category_group_id'),
          job_category_id: jobCategory.get('job_category_id'),
          career_year: jobCategory.get('career_year'),
        };
      });
    }

    const condition = this.condition
      .set('desired_areas', desiredAreas)
      .set('desired_job_categories', desiredJobCategories);

    const shapedCondition = Object.entries(condition.toJS()).reduce((item, [key, val]) => {
      if (val) {
        item[key] = val; // eslint-disable-line no-param-reassign
      }
      return item;
    }, {});

    const renamedCondition = deepRenameKeys(shapedCondition, (key) => {
      const newKey = findValueByKey(key, PARAMETER_ALIAS);

      return newKey || key;
    });

    return qs.stringify(renamedCondition, {
      encodeValuesOnly: true,
      skipNulls: true,
    });
  }

  clearConditionIndexed() {
    return this.set('id', properties.id)
      .set('name', properties.name)
      .set('match_count', properties.match_count)
      .set('sendable', properties.sendable)
      .set('condition_type', properties.condition_type);
  }

  format() {
    const lastEducationIds = this.condition.get('last_education_ids').map(i => i * 1);
    const scout = this.condition.get('scout') * 1;

    return this
      .setIn(['condition', 'last_education_ids'], lastEducationIds)
      .setIn(['condition', 'scout'], scout);
  }

  updateToScouted(scoutedMember) {
    return this
      .setIn(['condition', 'scout'], scoutUtils.SCOUTED)
      .setIn(['condition', 'scouted_member_job_offer_id'], scoutedMember.job_offer_id)
      .setIn(['condition', 'scouted_member_job_offer_name'], scoutedMember.title);
  }

  updateToFavorite(favorite) {
    return this
      .setIn(['condition', 'favorite'], true)
      .setIn(['condition', 'received_favorite_job_offer_id'], favorite.job_offer_id)
      .setIn(['condition', 'received_favorite_job_offer_name'], favorite.title);
  }

  updateToRecommend(condition) {
    return this.setIn(['condition', 'recommend_customer_search_condition_id'], condition.id)
      .setIn(['condition', 'desired_areas'], condition.condition.get('desired_areas'))
      .setIn(['condition', 'desired_job_categories'], condition.condition.get('desired_job_categories'));
  }

  getDesiredAreaPrefectureIds() {
    return this.getIn(['condition', 'desired_areas'])
      .map(v => Number(v.get('prefecture_id')))
      .filter(v => v);
  }

  isScoutedMemberJobOfferSpecified() {
    const condition = this.getIn(['condition']);

    if (
      condition.getIn(['scouted_member_job_offer_id']) > 0
      || condition.getIn(['scouted_member_job_offer', 'keyword'])
      || condition.getIn(['scouted_member_job_offer', 'prefecture_id']) > 0
      || condition.getIn(['scouted_member_job_offer', 'job_category_id']) > 0
    ) {
      return true;
    }

    return false;
  }

  isReceivedFavoriteJobOfferSpecified() {
    const condition = this.getIn(['condition']);

    if (
      condition.getIn(['received_favorite_job_offer_id']) > 0
      || condition.getIn(['received_favorite_job_offer', 'keyword'])
      || condition.getIn(['received_favorite_job_offer', 'prefecture_id']) > 0
      || condition.getIn(['received_favorite_job_offer', 'job_category_id']) > 0
    ) {
      return true;
    }

    return false;
  }

  static isReachedLimitChecks(condition) {
    if (!condition) {
      return false;
    }

    const checkedTotal = [
      condition.get('desired_areas').map((area) => {
        return area.get('city_ids');
      }).flatten().toArray().length,
      condition.get('member_qualification_ids').size,
      condition.get('employment_types').size,
      condition.get('last_education_ids').size,
      condition.get('employment_statuses').size,
      condition.get('favorite') ? 1 : 0,
    ].reduce((a, b) => (a + b), 0);

    return checkedTotal > 50;
  }
}
