import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default class Header extends React.Component {

  static propTypes = {
    red: PropTypes.bool,
    children: PropTypes.node,
  }

  render() {
    const headerClass = classNames({
      'c-header': true,
      'c-header--red': this.props.red,
    });

    return (
      <header className={ headerClass }>
        { this.props.children }
      </header>
    );
  }
}
