import { Record } from 'immutable';

const NoticeModalRecord = Record({
  hidden: true,
  title: '',
  body: '',
});

export default class NoticeModal extends NoticeModalRecord {
  update(key, value) {
    return this.set(key, value);
  }
}
