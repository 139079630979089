import * as types from './types';

export const initialState = {
  unread: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET:
      return action.payload.badge;
    default:
      return state;
  }
};
