import * as actions from './actions';
import MessageApi from '@/models/ducks/shared/api/message';

const countRemaining = () => {
  return dispatch => MessageApi.countRemainingScout()
    .then((response) => {
      return dispatch(actions.countRemaining(response));
    });
};

const subtract = (subtractionNum) => {
  return dispatch => dispatch(actions.subtract(subtractionNum));
};

export {
  countRemaining,
  subtract,
};
