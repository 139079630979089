import { Record, List, Map } from 'immutable';
import MESSAGE_TYPES from '@/models/ducks/shared/constants/message-type';

const properties = {
  member_ids: List(),
  messages: List([
    Map({
      subject: '',
      body: '',
      facility_id: '',
      job_offer: Map({
        prefecture_id: 0,
        job_category_id: 0,
        keyword: '',
      }),
      job_offer_id: '',
      job_offer_name: '',
      job_offer_salary: Map(),
      job_offer_salary_id: '',
      member_id: '',
      message_type: MESSAGE_TYPES.SCOUT,
    }),
  ]),
};

const ScoutMessageFormRecord = Record(properties);

export default class ScoutMessageForm extends ScoutMessageFormRecord {
  update(key, value) {
    return this.set(key, value);
  }

  hasJobOffer() {
    return this.getIn(['messages', 0, 'job_offer_id']) > 0;
  }

  getJobOfferId() {
    return this.getIn(['messages', 0, 'job_offer_id']);
  }

  isJobOfferSpecified() {
    const message = this.getIn(['messages', 0]);

    if (
      message.getIn(['job_offer_id']) > 0
      ||
      message.getIn(['job_offer', 'keyword'])
      ||
      message.getIn(['job_offer', 'prefecture_id']) > 0
      ||
      message.getIn(['job_offer', 'job_category_id']) > 0
    ) {
      return true;
    }

    return false;
  }
}
