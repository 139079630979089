import * as actions from './actions';

const init = () => {
  return dispatch => dispatch(actions.init());
};

const changeField = (key, value) => {
  return dispatch => dispatch(actions.changeField(key, value));
};

const changeCheckboxField = (key, value, checked) => {
  return dispatch => dispatch(actions.changeCheckboxField(key, value, checked));
};

const clearField = (key) => {
  return dispatch => dispatch(actions.clearField(key));
};

const changePage = (page) => {
  return dispatch => dispatch(actions.changeField(['pagination', 'page'], page));
};

const changeStatus = (status) => {
  return dispatch => dispatch(actions.changeStatus(status));
};

const setSuggestionValue = (suggestion) => {
  return dispatch => dispatch(actions.setSuggestionValue(suggestion));
};

const initSuggestionValue = () => {
  return dispatch => dispatch(actions.initSuggestionValue());
};

const initSuggestion = () => {
  return dispatch => dispatch(actions.initSuggestion());
};

const initFacilityJobOffer = () => {
  return dispatch => dispatch(actions.initFacilityJobOffer());
};

const createCache = () => {
  return dispatch => dispatch(actions.createCache());
};

const restoreFromCache = () => {
  return dispatch => dispatch(actions.restoreFromCache());
};

export {
  init,
  changePage,
  changeStatus,
  changeField,
  changeCheckboxField,
  clearField,
  setSuggestionValue,
  initSuggestionValue,
  initSuggestion,
  initFacilityJobOffer,
  createCache,
  restoreFromCache,
};
