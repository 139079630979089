import * as types from './types';

const get = (response) => {
  return {
    type: types.GET,
    payload: {
      total: response.data.total,
      count: response.data.count,
      limit: response.data.limit,
      total_match_count: response.data.total_match_count,
      conditions: response.data.conditions,
    },
  };
};

const getMore = (response) => {
  return {
    type: types.GET_MORE,
    payload: {
      total: response.data.total,
      count: response.data.count,
      limit: response.data.limit,
      total_match_count: response.data.total_match_count,
      conditions: response.data.conditions,
    },
  };
};

const update = (condition) => {
  return {
    type: types.UPDATE,
    payload: {
      condition,
    },
  };
};

export {
  get,
  getMore,
  update,
};
