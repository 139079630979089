import * as types from './types';
import NotificationModal from './model';

export const initialState = new NotificationModal();

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SHOW:
      return state.set('hidden', false);
    case types.HIDE:
      return state.set('hidden', true);
    case types.UPDATE_CONTENT:
      return state.merge({
        title: action.payload.title,
        body: action.payload.body,
      });
    default:
      return state;
  }
};
