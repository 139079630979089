import * as types from './types';

const getAll = (announcements) => {
  return {
    type: types.GET_ALL,
    payload: {
      announcements,
    },
  };
};

export {
  getAll,
};
