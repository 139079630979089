import * as types from './types';

export const initialState = {
  selection: 0,
  message: 0,
  facilities: 0,
  job_offer_opened: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET:
      return {
        ...state,
        ...action.payload.summary,
      };
    default:
      return state;
  }
};
