export const REPLACE_KEY = 'id';

export default {
  LAYOUT: {
    SINGLE_COLUMN: [
      '/customers/sign_in',
      '/customers/new',
      '/customers/reset_form',
      '/customers/invitations',
    ],
    SUB_SIDEBAR: [
      '/customers/edit',
      '/customers/email_edit',
      '/customers/notification_edit',
      '/customers/password',
      '/customers/message_templates',
    ],
  },
  ERROR: [
    '/customers/404',
    '/customers/500',
  ],
  SIDE_NAV_CATEGORY: {
    FACILITIES: {
      PARENT: '/customers/facilities',
      CHILDREN: [
        '/customers/job_offers',
      ],
    },
  },
  SIDE_NAV: {
    JOB_MEDLEY: [
      {
        TEXT: 'models.constants.shared.page.side_navigation.post_a_job',
        ICON: 'add',
        HREF: '/customers/post_job',
      },
      {
        TEXT: 'models.constants.shared.page.side_navigation.home',
        ICON: 'home',
        HREF: '/',
      },
      {
        TEXT: 'models.constants.shared.page.side_navigation.facilities',
        ICON: 'edit',
        HREF: '/customers/facilities',
      },
      {
        TEXT: 'models.constants.shared.page.side_navigation.images_managers',
        ICON: 'photo',
        HREF: '/customers/images_managers',
      },
      {
        TEXT: 'models.constants.shared.page.side_navigation.searches',
        ICON: 'roupe',
        HREF: '/customers/searches',
        COUNT: 'searches',
      },
      {
        TEXT: 'models.constants.shared.page.side_navigation.selections',
        ICON: 'folder',
        HREF: '/customers/selections',
        COUNT: 'selections',
      },
      {
        TEXT: 'models.constants.shared.page.side_navigation.messages',
        ICON: 'message',
        HREF: '/customers/messages',
        COUNT: 'messages',
      },
    ],
  },
};
