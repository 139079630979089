import React from 'react';
import PropTypes from 'prop-types';
import StaticHoc from './hoc';

class StaticComponent extends React.Component {
  static propTypes = {
    children: PropTypes.node,
  }

  static defaultProps = {
  }

  render() {
    return this.props.children;
  }
}

export default StaticHoc(StaticComponent);
