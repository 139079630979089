import * as types from './types';

const get = (response) => {
  return {
    type: types.GET,
    payload: {
      response,
    },
  };
};

const getMore = (response) => {
  return {
    type: types.GET_MORE,
    payload: {
      response,
    },
  };
};

const updateUnreadMemberCount = (jobOfferId, unreadMemberCount) => {
  return {
    type: types.UPDATE_UNREAD_MEMBER_COUNT,
    payload: {
      jobOfferId,
      unreadMemberCount,
    },
  };
};

export {
  get,
  getMore,
  updateUnreadMemberCount,
};
