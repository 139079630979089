import * as types from './types';

export const initialState = {
  home: [],
  facilities: [],
  customerImages: [],
  searches: [],
  selections: [],
};

const saveIndex = (state, action) => {
  const {
    page,
    date,
    index,
  } = action.payload;

  const newList = state[page].map((item, idx) => {
    return (idx <= index) ? date : item;
  });

  return {
    ...state,
    [page]: newList,
  };
};

const reset = (state, action) => {
  const { page } = action.payload;

  return {
    ...state,
    [page]: Array(state[page].length).fill(false),
  };
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.CHECK:
      return state;
    case types.INIT:
      return {
        ...state,
        [action.payload.page]: Array(action.payload.total).fill(false),
      };
    case types.SAVE:
      return {
        ...state,
        [action.payload.page]: true,
      };
    case types.SAVE_INDEX:
      return saveIndex(state, action);
    case types.RESET:
      return reset(state, action);
    case types.NOOP:
    default:
      return state;
  }
};
