import { Record, List } from 'immutable';
import MessageTemplate from '@/models/ducks/shared/models/message-template';
import { CUSTOMER_MESSAGE_TEMPLATE_TYPE } from '@/models/ducks/message-template/constants';

const properties = {
  messageTemplates: new List(),
  total: 0,
  count: 0,
  limit: 0,
};

const MessageTemplatesRecord = Record(properties);
export default class MessageTemplates extends MessageTemplatesRecord {
  static convert(raw) {
    let messageTemplates = new List();
    raw.message_templates.forEach((messageTemplate) => {
      messageTemplates = messageTemplates.push(new MessageTemplate(messageTemplate));
    });
    return new MessageTemplates({
      messageTemplates,
      total: raw.total,
      count: raw.count,
      limit: raw.limit,
    });
  }

  getTemplateId(templateType) {
    const template = this.messageTemplates.find(item => item.template_type === templateType);
    if (template) {
      return template.id;
    }
    return 0;
  }

  getScoutTemplates() {
    return this.messageTemplates.filter((item) => {
      return item.template_type === CUSTOMER_MESSAGE_TEMPLATE_TYPE.SCOUT_DEFAULT || item.template_type === CUSTOMER_MESSAGE_TEMPLATE_TYPE.USER;
    });
  }
}
