import * as types from './types';

const init = () => {
  return {
    type: types.INIT,
  };
};

const update = (image) => {
  return {
    type: types.UPDATE,
    payload: {
      image,
    },
  };
};

const refresh = (imageList) => {
  return {
    type: types.REFRESH,
    payload: {
      imageList,
    },
  };
};

export {
  init,
  update,
  refresh,
};
