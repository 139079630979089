import { Record } from 'immutable';

const properties = {
  job_offer_id: null,
  message: '',
  next_status_key: '',
};

const ManuscriptModificationRecord = Record(properties);

export default class ManuscriptModification extends ManuscriptModificationRecord {
  update(key, value) {
    return this.set(key, value);
  }
}
