import * as types from './types';
import { updatePreferredInterviewDatetimeList } from './actions-typed';

const create = () => {
  return {
    type: types.INIT,
  };
};

const update = (key, value) => {
  return {
    type: types.UPDATE,
    payload: {
      key,
      value,
    },
  };
};

const resetField = (key) => {
  return {
    type: types.RESET,
    payload: {
      key,
    },
  };
};

const save = (form) => {
  return {
    type: types.SAVE,
    payload: {
      form,
    },
  };
};

const updateFieldsByMessage = (message, applicationId) => {
  return {
    type: types.UPDATE_FIELDS_BY_MESSAGE,
    payload: {
      message,
      applicationId,
    },
  };
};

const updateInterviewSchedule = (name, value) => {
  return {
    type: types.UPDATE_INTERVIEW_SCHEDULE,
    payload: {
      name,
      value,
    },
  };
};

const initInterviewSchedule = () => {
  return {
    type: types.INIT_INTERVIEW_SCHEDULE,
  };
};

const fetchErrors = (errors) => {
  return {
    type: types.FETCH_ERRORS,
    payload: errors,
  };
};

export {
  create,
  update,
  resetField,
  save,
  updateFieldsByMessage,
  updateInterviewSchedule,
  initInterviewSchedule,
  fetchErrors,
  updatePreferredInterviewDatetimeList,
};
