import * as types from './types';

const init = () => {
  return {
    type: types.INIT,
  };
};

const set = (condition) => {
  return {
    type: types.SET,
    payload: {
      condition,
    },
  };
};

export {
  init,
  set,
};
