import * as types from './types';

export const initialState = 0;

export default (state = initialState, action) => {
  switch (action.type) {
    case types.INIT:
      return initialState;
    case types.SHOW:
      return (state < 0) ? 1 : state + 1;
    case types.HIDE:
      return (state === 0) ? 0 : state - 1;
    default:
      return state;
  }
};
