import * as types from './types';

const init = () => {
  return {
    type: types.INIT,
  };
};

const set = (messageTemplate) => {
  return {
    type: types.SET,
    payload: {
      messageTemplate,
    },
  };
};

const changeField = (key, value) => {
  return {
    type: types.CHANGE_FIELD,
    payload: {
      key,
      value,
    },
  };
};

export {
  init,
  set,
  changeField,
};
