import * as types from './types';

const change = (key, value) => {
  return {
    type: types.CHANGE,
    payload: {
      key,
      value,
    },
  };
};

export {
  change,
};
