import i18next from 'i18next';
import axios from 'axios';
import CustomerApi from '@/models/ducks/shared/api/customer';
import store from '@/models/store';

export default {
  // ログイン無効のalertを複数出ないようにする
  alertRendered: false,
  // レスポンスエラー処理を処理するかどうか
  processResponseError: true,

  addResponseErrorInterceptor(router) {
    return axios.interceptors.response.use(null, (error) => {
      if (this.processResponseError === false) {
        return Promise.reject(error);
      }

      if (error.response.status === 500) {
        router('/customers/500/');
      } else if (error.response.status === 401
        && this.unauthorizedNeedRedirect() === true
      ) {
        if (!this.alertRendered && error.response.data && error.response.data.error_code === 'NOT_LOGIN_CUSTOMER') {
          /* eslint-disable */
          window.alert(i18next.t('utils.interceptor.have_been_signed_out'));
          /* eslint-enable */

          this.alertRendered = true;
        }

        router('/customers/sign_out/');

        if (error.response.data && error.response.data.error_code === 'MAINTENANCE') {
          CustomerApi.signOut().then(() => {
            window.location.reload();
          });
        }
      }
      return Promise.reject(error);
    });
  },

  disableResponseErrorInterceptor() {
    this.processResponseError = false;
  },

  enableResponseErrorInterceptor() {
    this.processResponseError = true;
  },

  addRequestInterceptor() {
    return axios.interceptors.request.use((config) => {
      const newConfig = config;
      const { customer } = store.getState();
      const csrfElement = document.querySelector('[name="csrf-token"]');

      if (customer.id > 0) {
        newConfig.headers['X-Customer-ID'] = customer.id;
        newConfig.headers['X-Customer-Email'] = customer.email;
      }

      if (csrfElement && csrfElement.content) {
        newConfig.headers['X-CSRF-Token'] = csrfElement.content;
      }

      if (newConfig.url[newConfig.url.length - 1] !== '/') {
        newConfig.url += '/';
      }

      return newConfig;
    });
  },

  unauthorizedNeedRedirect() {
    let redirect = true;
    [
      '/customers/sign_in',
      '/customers/new',
      '/customers/500',
      '/customers/404',
      '/customers/reset_form',
      '/api/customers/auth/password/edit',
      '/customers/invitations',
    ].forEach((url) => {
      if (window.location.pathname.includes(url) && redirect === true) {
        redirect = false;
      }
    });
    return redirect;
  },
};
