import i18next from 'i18next';
import { Record } from 'immutable';
import { CUSTOMER_MESSAGE_TEMPLATE_TYPE } from '@/models/ducks/message-template/constants';

const properties = {
  id: '',
  subject: '',
  body: '',
  updated_at: '',
  template_type: CUSTOMER_MESSAGE_TEMPLATE_TYPE.USER,
};

const MessageTemplateRecord = Record(properties);
export default class MessageTemplate extends MessageTemplateRecord {
  update(key, value) {
    return this.set(key, value);
  }

  getSubject() {
    if (this.template_type === CUSTOMER_MESSAGE_TEMPLATE_TYPE.USER) {
      return this.subject;
    }
    return `${this.subject} (${ i18next.t('common.default') })`;
  }
}
