import { Record, List } from 'immutable';

const properties = {
  members: List(),
};

const HomeRecommendMembersRecord = Record(properties);
export default class HomeRecommendMembers extends HomeRecommendMembersRecord {
  static convert(members) {
    return new HomeRecommendMembers().set('members', new List(members));
  }
}
