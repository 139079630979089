import * as types from './types';
import { STATUS } from './constants';

export const initialState = STATUS.ALL;

export default (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE:
      return action.payload.status;
    case types.INIT:
      return initialState;
    default:
      return state;
  }
};
