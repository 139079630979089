import axios from 'axios';
import imageUploader from '@/utils/image-uploader';

export default {
  search(params) {
    return axios
      .post('/api/customers/customer_images/search', {
        job_offer_id: params.job_offer_id,
        facility_id: params.facility_id,
        caption: params.caption,
        filter_type: params.filter_type,
        ignore_ids: params.ignore_ids,
        pagination: {
          limit: params.pagination.get('limit') || 20,
          page: params.pagination.get('page') || 1,
        },
      });
  },

  create(file) {
    return new Promise((resolve) => {
      imageUploader.createImgData(file)
        .then((img) => {
          return imageUploader.createFormData({
            img,
            file,
            blobItems: [
              {
                name: 'customer_image[caption]',
                value: '',
              },
              {
                name: 'customer_image[width]',
                value: img.width,
              },
              {
                name: 'customer_image[height]',
                value: img.height,
              },
            ],
          });
        })
        .then((formData) => {
          axios
            .post('/api/customers/customer_images/create', formData, {
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
              },
            })
            .then((response) => {
              resolve(response);
            });
        });
    });
  },

  update(attr) {
    return axios.post('/api/customers/customer_images/update', attr);
  },

  delete(attr) {
    return axios.post('/api/customers/customer_images/delete', {
      customer_image_id: attr.id,
    });
  },
};
