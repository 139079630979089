import reducers from './reducers';
import * as editingMessageTemplateOperations from './operations';
import * as editingMessageTemplateTypes from './types';

export {
  editingMessageTemplateOperations,
  editingMessageTemplateTypes,
};

export default reducers;
