import AnnouncementApi from './api';
import * as actions from './actions';

const getAll = () => {
  return dispatch => AnnouncementApi.getAnnouncements()
    .then((response) => {
      return dispatch(actions.getAll(response.data.news));
    });
};

export {
  getAll,
};
