import { List } from 'immutable';
import * as types from './types';

export const initialState = {
  scoutedMembers: new List(),
  total: 0,
  count: 0,
  limit: 10,
};

const get = (action) => {
  const { total, count, limit } = action.payload.response.data;
  const scoutedMembers = List(action.payload.response.data.scouted_members);

  return {
    total,
    count,
    limit,
    scoutedMembers,
  };
};

const getMore = (state, action) => {
  const { total, count, limit } = action.payload.response.data;
  const scoutedMembers = List(action.payload.response.data.scouted_members);

  return {
    total,
    count,
    limit,
    scoutedMembers: state.scoutedMembers.concat(scoutedMembers),
  };
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET:
      return get(action);
    case types.GET_MORE:
      return getMore(state, action);
    default:
      return state;
  }
};
