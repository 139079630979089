import reducers from './reducers';
import * as announcementOperations from './operations';
import * as announcementTypes from './types';

export {
  announcementOperations,
  announcementTypes,
};

export default reducers;
