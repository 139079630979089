import axios from 'axios';

export default {
  all(page, limit) {
    return axios.get('/api/customers/message_templates/', {
      params: {
        pagination: { page, limit },
      },
    });
  },

  build(id, params = { jobOfferId: '', jobOfferSalaryId: '', memberId: '' }) {
    return axios
      .get(`/api/customers/message_templates/${ +id }/build/`, {
        params: {
          job_offer_id: params.jobOfferId,
          job_offer_salary_id: params.jobOfferSalaryId,
          member_id: params.memberId,
        },
      });
  },

  update(messageTemplate) {
    return axios.put(`/api/customers/message_templates/${ messageTemplate.id }`, messageTemplate);
  },

  create(messageTemplate) {
    return axios.post('/api/customers/message_templates', messageTemplate);
  },

  delete(messageTemplate) {
    return axios.delete(`/api/customers/message_templates/${ messageTemplate.id }`);
  },
};
