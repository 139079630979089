import * as types from './types';

export const initialState = '';

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET:
      return `/job_offer/${ action.payload.id }/confirmation/`;
    default:
      return state;
  }
};
