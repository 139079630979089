import { Record } from 'immutable';

const properties = {
  search_condition_member_match_mail: true,
  job_offer_member_match_mail: true,
  publication_request: true,
  published_notification: true,
  stopped_publication_notification: true,
};

const NotificationEditRecord = Record(properties);
export default class NotificationEdit extends NotificationEditRecord {
  static convert(customer) {
    return new NotificationEdit({
      job_offer_member_match_mail: customer.isDesireEmail('customer_job_offer_member_match_mail'),
      publication_request: customer.isDesireEmail('customer_publication_request'),
      published_notification: customer.isDesireEmail('customer_published_notification'),
      stopped_publication_notification: customer.isDesireEmail('customer_stopped_publication_notification'),
      search_condition_member_match_mail: customer.isDesireEmail('customer_search_condition_member_match_mail'),
    });
  }

  update(key, value) {
    return this.set(key, value);
  }

  getNotification() {
    const ignoreEmail = [];
    if (this.search_condition_member_match_mail === false) {
      ignoreEmail.push('customer_search_condition_member_match_mail');
    }
    if (this.job_offer_member_match_mail === false) {
      ignoreEmail.push('customer_job_offer_member_match_mail');
    }
    if (this.publication_request === false) {
      ignoreEmail.push('customer_publication_request');
    }
    if (this.published_notification === false) {
      ignoreEmail.push('customer_published_notification');
    }
    if (this.stopped_publication_notification === false) {
      ignoreEmail.push('customer_stopped_publication_notification');
    }
    return JSON.stringify({ ignore_emails: ignoreEmail });
  }
}
