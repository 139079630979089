import * as types from './types';

const change = (status) => {
  return {
    type: types.CHANGE,
    payload: {
      status,
    },
  };
};

const init = () => {
  return {
    type: types.INIT,
  };
};

export {
  change,
  init,
};
