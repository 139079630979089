import { Record } from 'immutable';

const VersionRecord = Record({
  version: '',
});

export default class Version extends VersionRecord {
  update(key, value) {
    return this.set(key, value);
  }
}
