import reducers from './reducers';
import * as passwordEditFormOperations from './operations';
import * as passwordEditFormTypes from './types';

export {
  passwordEditFormOperations,
  passwordEditFormTypes,
};

export default reducers;
