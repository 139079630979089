import { Record, List, Map } from 'immutable';
import qs from 'qs';
import immutableHelper from '@/utils/immutable-helper';
import { JOB_OFFER_EXISTENCE } from '@/models/ducks/job-offer/constants';

const properties = {
  name: '',
  contact_name: '',
  facility_form_id: null,
  job_offer_existence: JOB_OFFER_EXISTENCE.JOB_OFFER_EXISTENCE_UNSPECIFIED,
  job_category_id: '',
  employment_type: '',
  job_offer_status: List(),
  facility_id: '',
  job_offer_id: '',
  disapproved: false,
  pagination: Map({
    page: 1,
    limit: 5,
  }),
};

const FacilityJobOfferSearchFormRecord = Record(properties);
export default class FacilityJobOfferSearchForm extends FacilityJobOfferSearchFormRecord {
  initialize() {
    return new FacilityJobOfferSearchForm();
  }

  update(key, value) {
    return this.set(key, value);
  }

  updateMap(model, query) {
    return model.mapEntries((entry) => {
      const key = entry[0];
      const value = entry[1];

      if (!immutableHelper.isImmutable(value)) {
        return query ? [key, query[key]] : [key, ''];
      }

      if (Map.isMap(value)) {
        return query ? this.updateMap(value, query[key]) : this.updateMap(value, {});
      }

      if (List.isList(value)) {
        return query ? [key, List(query[key])] : [key, List([])];
      }

      return entry;
    });
  }

  updateFromUrlQuery(query) {
    const model = this.mapEntries((entry) => {
      const key = entry[0];
      const value = entry[1];

      if (key === 'disapproved') {
        return [key, value === 'true'];
      }

      if (!immutableHelper.isImmutable(value)) {
        return [key, query[key]];
      }

      if (Map.isMap(value)) {
        return [key, this.updateMap(value, query[key])];
      }

      if (List.isList(value)) {
        return [key, List(query[key])];
      }

      return entry;
    });

    return model;
  }

  querify() {
    return qs.stringify(this.toJS(), {
      encodeValuesOnly: true,
    });
  }

  resetPage() {
    return this.set('pagination', properties.pagination);
  }
}
