import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import connect from './container';
import { AppWrapper, AppHeader, AppMain, AppSidebar, AppContent, AppSubSidebar } from '@/components/desktop/app-shell';
import SiteInfo from '@/components/desktop/site-info';
import routerHelper from '@/utils/router';
import PAGE from '@/models/ducks/shared/constants/page';

class Desktop extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    location: PropTypes.object,
    customer: PropTypes.shape(),
    isOperator: PropTypes.bool,
    sideNavBadge: PropTypes.shape(),
  }

  isSingleColumnPage() {
    if (PAGE.ERROR.includes(routerHelper.removeTrailingSlash(this.props.location.pathname))) {
      return !this.isLoggedIn();
    }

    return PAGE.LAYOUT.SINGLE_COLUMN.includes(routerHelper.removeTrailingSlash(this.props.location.pathname));
  }

  isLoggedIn() {
    const { customer, isOperator } = this.props;
    return customer && (customer.approval || (customer.id && isOperator));
  }

  isWithSubSidebar() {
    const path = routerHelper.removeTrailingSlash(this.props.location.pathname);
    return PAGE.LAYOUT.SUB_SIDEBAR.some(pattern => path.indexOf(pattern) === 0);
  }

  renderSidebar() {
    if (this.isSingleColumnPage()) {
      return null;
    }

    if (this.isWithSubSidebar(this.props.location.pathname)) {
      return (
        <AppSubSidebar />
      );
    }

    return (
      <AppSidebar
        sideNavBadge={ this.props.sideNavBadge }
        items={ PAGE.SIDE_NAV.JOB_MEDLEY }
        others={ <SiteInfo /> }
        customer={ this.props.customer }
      />
    );
  }

  render() {
    return (
      <div>
        <Helmet>
          <meta name="viewport" content="width=device-width,initial-scale=1" />
        </Helmet>

        <AppWrapper>
          <AppHeader
            customer={ this.props.customer }
            isOperator={ this.props.isOperator }
          />
          <AppMain>
            { this.renderSidebar() }

            <AppContent singleColumn={ this.isSingleColumnPage() }>
              { this.props.children }
            </AppContent>
          </AppMain>
        </AppWrapper>
      </div>
    );
  }
}

export default connect(Desktop);
