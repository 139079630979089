import ReactGA from 'react-ga4';
import * as actions from './actions';
import MemberApi from '@/models/ducks/shared/api/member';
import browser from '@/utils/browser';
import GOOGLE_ANALYTICS from '@/models/ducks/shared/constants/google-analytics';

const init = () => {
  return dispatch => dispatch(actions.init());
};

const get = (condition, router, location, triggerId = 'OTHER') => {
  const query = condition.querifyCondition();

  if (!browser.isIE9()) {
    router(`${ location.pathname }?${ query }`);
  }
  ReactGA.event({
    category: GOOGLE_ANALYTICS.CATEGORY.SCOUT_MEMBERS_SEARCH,
    action: `Search by ${triggerId}`,
    label: `${query}`,
  });

  return dispatch => MemberApi.search(condition)
    .then((response) => {
      return dispatch(actions.get(response));
    });
};

const getMore = (condition) => {
  return dispatch => MemberApi.search(condition)
    .then((response) => {
      return dispatch(actions.getMore(response));
    });
};

const markAsNew = (unreadMemberIds) => {
  return dispatch => dispatch(actions.markAsNew(unreadMemberIds));
};

const markAsScouted = (scoutedMemberIds) => {
  return dispatch => dispatch(actions.markAsScouted(scoutedMemberIds));
};

export {
  init,
  get,
  getMore,
  markAsNew,
  markAsScouted,
};
