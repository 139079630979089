import { List } from 'immutable';
import * as types from './types';
import Selection from '@/models/ducks/shared/models/selection';
import MESSAGE_TYPE from '@/models/ducks/shared/constants/message-type';
import SELECTION_STATUS from '@/models/ducks/selection-list/constants';
import selectionHelper from '@/utils/selection';

export const initialState = {
  selections: List(),
  count: 0,
  total: 0,
  limit: 0,
};

function getSelectionStatusFromMessageType(messageType, currentStatus) {
  if (Number(messageType) === MESSAGE_TYPE.REJECTION) {
    return SELECTION_STATUS.STATUS_MAP.REJECTION.id;
  }
  if (Number(messageType) === MESSAGE_TYPE.OFFER) {
    return SELECTION_STATUS.STATUS_MAP.OFFER.id;
  }
  return currentStatus;
}

const get = (action) => {
  const selections = List(action.payload.selections.items.map((selection) => {
    return new Selection(selection);
  }));

  return {
    selections,
    total: action.payload.total,
    count: action.payload.count,
    limit: action.payload.limit,
  };
};

const getMore = (state, action) => {
  const newItems = List(action.payload.selections.items.map((selection) => {
    return new Selection(selection);
  }));

  return {
    ...state,
    selections: state.selections.concat(newItems),
    count: action.payload.count,
    total: action.payload.total,
    limit: action.payload.limit,
  };
};

const toggle = (state, action) => {
  const newModel = state.selections.update(state.selections.findIndex((item) => {
    return item.get('id') === action.payload.id;
  }), (item) => {
    return item.merge({
      [action.payload.key]: !item[action.payload.key],
    });
  });

  return {
    ...state,
    selections: newModel,
  };
};

const syncStatusWithMessageType = (state, action) => {
  const { selectedSelection, messageType } = action.payload;
  const selection = selectedSelection.set('status', getSelectionStatusFromMessageType(messageType, selectedSelection.status));

  const newStateSelections = state.selections.update(state.selections.findIndex((item) => {
    return item.get('id') === selection.get('id');
  }), (item) => {
    return item.merge(selection);
  });

  return {
    selections: newStateSelections,
    count: state.count,
    total: state.total,
    limit: state.limit,
  };
};

const update = (state, action) => {
  const selection = new Selection(action.payload.selection);
  const newStateSelections = state.selections.update(state.selections.findIndex((item) => {
    return item.get('id') === selection.get('id');
  }), (item) => {
    return item.merge(new Selection({
      ...action.payload.selection,
    }));
  });

  return {
    selections: newStateSelections,
    count: state.count,
    total: state.total,
    limit: state.limit,
  };
};

const updateStatus = (state, action) => {
  const selection = new Selection(action.payload.selection);
  const newStateSelections = state.selections.update(state.selections.findIndex((item) => {
    return item.get('id') === selection.get('id');
  }), (item) => {
    const isApplicationStatusAppliable = selectionHelper.isApplicationStatusAppliable(Number(selection.status)) || selectionHelper.isStatusHiringDone(Number(selection.status));

    return item.merge({
      status: selection.status,
      scheduling_hired_at: isApplicationStatusAppliable ? selection.scheduling_hired_at : '',
      hiring_note: isApplicationStatusAppliable ? selection.hiring_note : '',
      job_offer_salary: isApplicationStatusAppliable ? selection.job_offer_salary : '',
      qualification_id: isApplicationStatusAppliable ? selection.qualification_id : '',
      hired_at: action.payload.selection.hired_at,
      reason: '',
    });
  });

  return {
    selections: newStateSelections,
    count: state.count,
    total: state.total,
    limit: state.limit,
  };
};

const requestApplication = (state, action) => {
  const selection = new Selection(action.payload.selection);
  const newStateSelections = state.selections.update(state.selections.findIndex((item) => {
    return item.get('id') === selection.get('id');
  }), (item) => {
    return item.merge({
      status: selection.status,
      hired_at: selection.hired_at,
      hiring_note: selection.hiring_note,
      job_offer_salary: selection.job_offer_salary,
      qualification_id: selection.qualification_id,
    });
  });

  return {
    selections: newStateSelections,
    count: state.count,
    total: state.total,
    limit: state.limit,
  };
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.INIT:
      return initialState;
    case types.GET:
      return get(action);
    case types.GET_MORE:
      return getMore(state, action);
    case types.TOGGLE:
      return toggle(state, action);
    case types.SYNC_STATUS_WITH_MESSAGE_TYPE:
      return syncStatusWithMessageType(state, action);
    case types.UPDATE:
      return update(state, action);
    case types.UPDATE_STATUS:
      return updateStatus(state, action);
    case types.REQUEST_APPLICATION:
      return requestApplication(state, action);
    case types.NOOP:
    default:
      return state;
  }
};
