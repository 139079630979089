import reducers from './reducers';
import * as emailFormOperations from './operations';
import * as emailFormTypes from './types';

export {
  emailFormOperations,
  emailFormTypes,
};

export default reducers;
