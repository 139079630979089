export default {
  isAndroid() {
    return !!navigator.userAgent.match(/Android/i);
  },

  isiOS() {
    return !!navigator.userAgent.match(/iPhone|iPad|iPod/i);
  },

  isWindowsPhone() {
    return !!navigator.userAgent.match(/IEMobile/i);
  },

  isBlackBerry() {
    return !!navigator.userAgent.match(/BlackBerry/i);
  },

  isOperaMini() {
    return !!navigator.userAgent.match(/Opera Mini/i);
  },

  isMobile() {
    return (
      this.isAndroid()
      ||
      this.isiOS()
      ||
      this.isWindowsPhone()
      ||
      this.isBlackBerry()
      ||
      this.isOperaMini()
    );
  },
};
