import * as actions from './actions';
import FacilityApi from '@/models/ducks/shared/api/facility';
import JobOfferApi from '@/models/ducks/shared/api/job-offer';
import { JOB_OFFER_STATUS } from '@/models/ducks/job-offer/constants';
import FacilityJobOfferSearchForm from '@/models/ducks/shared/models/facility-job-offer-search-form';
import FacilityJobOfferList from '@/models/ducks/shared/models/facility-job-offers';
import browser from '@/utils/browser';

const init = () => {
  return dispatch => dispatch(actions.init());
};

const get = (condition, router, location) => {
  const query = condition.querify();

  if (!browser.isIE9()) {
    router(`${ location.pathname }?${ query }`);
  }

  return dispatch => FacilityApi.search(condition)
    .then((response) => {
      return dispatch(actions.get(response));
    });
};

const updateFacility = (facility) => {
  return dispatch => dispatch(actions.updateFacility(facility));
};

const updateJobOffer = (jobOffer) => {
  const condition = new FacilityJobOfferSearchForm({ job_offer_id: jobOffer.id });

  return (dispatch, getState) => FacilityApi.search(condition)
    .then((response) => {
      const { facilityJobOfferList } = getState();
      const facilityIndex = facilityJobOfferList.facilities.findIndex(item => item.id === jobOffer.facility_id);
      const jobOfferIndex = facilityJobOfferList.facilities.get(facilityIndex).job_offers.findIndex(item => item.id === jobOffer.id);
      const facilityJobOffer = FacilityJobOfferList.convert(response);

      return dispatch(actions.updateJobOffer(facilityIndex, jobOfferIndex, facilityJobOffer));
    });
};

const updateJobOfferStatus = (jobOffer, nextStatusKey, message) => {
  return (dispatch, getState) => JobOfferApi.changeStatus(jobOffer, nextStatusKey, message)
    .then((response) => {

      // @see: https://github.com/medley-inc/job-medley/issues/19554
      if (response.data.error_code) {
        return response.data;
      }

      const redirect_path = response.data.redirect_path;
      if (redirect_path) {
        window.location.href = redirect_path;
      }

      const { facilityJobOfferList } = getState();
      const facilityIndex = facilityJobOfferList.facilities.findIndex(item => item.id === jobOffer.facility_id);
      const jobOfferIndex = facilityJobOfferList.facilities.get(facilityIndex).job_offers.findIndex(item => item.id === jobOffer.id);

      if (response.data.job_offer.status === JOB_OFFER_STATUS.STATUS.DELETED) {
        return dispatch(actions.deleteJobOffer(facilityIndex, jobOfferIndex));
      }
      return dispatch(updateJobOffer(jobOffer));
    });
};

const deleteFacility = (facilityId) => {
  return dispatch => FacilityApi.delete(facilityId)
    .then(() => {
      return dispatch(actions.deleteFacility(facilityId));
    });
};

export {
  init,
  get,
  updateFacility,
  updateJobOfferStatus,
  deleteFacility,
};
