import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class Loader extends React.Component {

  static propTypes = {
    isHidden: PropTypes.bool,
    isStatic: PropTypes.bool,
    isRelative: PropTypes.bool,
    shadow: PropTypes.bool,
    className: PropTypes.string,
    t: PropTypes.func,
  }

  static defaultProps = {
    shadow: true,
  }

  shouldComponentUpdate(nextProps) {
    if (this.props.isHidden && nextProps.isHidden) {
      return false;
    }
    return (
      this.props.isHidden !== nextProps.isHidden || this.props.className !== nextProps.className
    );
  }

  render() {
    const {
      isStatic,
      shadow,
      isHidden,
      className,
      isRelative,
      t,
    } = this.props;
    const loaderClass = classNames({
      'c-loader': true,
      'c-loader--static': isStatic,
      'c-loader--relative': isRelative,
      'c-loader--shadow': shadow,
      'u-is-hidden': isHidden,
      [`${ className }`]: className,
    });

    return (
      <div className={ loaderClass }>
        <div className="c-loader__inner">
          <ul className="c-loader__list">
            <li className="c-loader__item" />
            <li className="c-loader__item" />
            <li className="c-loader__item" />
          </ul>
        </div>
        <span className="c-loader__text">{ t('common.loading') }</span>
      </div>
    );
  }
}

export default withTranslation()(Loader);
