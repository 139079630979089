const INIT = 'state/ducks/facility-job-offer-list/INIT';
const GET = 'state/ducks/facility-job-offer-list/GET';
const UPDATE_FACILITY = 'state/ducks/facility-job-offer-list/UPDATE_FACILITY';
const UPDATE_JOB_OFFER = 'state/ducks/facility-job-offer-list/UPDATE_JOB_OFFER';
const DELETE_JOB_OFFER = 'state/ducks/facility-job-offer-list/DELETE_JOB_OFFER';
const DELETE_FACILITY = 'state/ducks/facility-job-offer-list/DELETE_FACILITY';

export {
  INIT,
  GET,
  UPDATE_FACILITY,
  UPDATE_JOB_OFFER,
  DELETE_JOB_OFFER,
  DELETE_FACILITY,
};
