const INIT = 'state/ducks/facility-search-form/INIT';
const SET = 'state/ducks/facility-search-form/SET';
const CHANGE_FIELD = 'state/ducks/facility-search-form/CHANGE_FIELD';
const NOOP = 'state/ducks/facility-search-form/NOOP';
const CHANGE_PAGE = 'state/ducks/facility-search-form/CHANGE_PAGE';
const RESET_PAGE = 'state/ducks/facility-search-form/RESET_PAGE';
const UPDATE_FROM_URL_QUERY = 'state/ducks/facility-search-form/UPDATE_FROM_URL_QUERY';

export {
  INIT,
  SET,
  CHANGE_FIELD,
  NOOP,
  CHANGE_PAGE,
  RESET_PAGE,
  UPDATE_FROM_URL_QUERY,
};
