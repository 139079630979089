import { Location } from 'react-router-dom';
import { useCheckAuth } from './useCheckAuth';

type checkAuth = ({ toPath, query, loginRedirectUrl }: {
  toPath: string;
  query: string;
  loginRedirectUrl: string;
}) => Promise<{
  isAuthenticated: boolean;
  redirectPath: string;
  isReload: boolean;
  canShowWithoutAuthenticated: boolean;
  loginRedirectUrl: string;
}>

const checkToShowRoute = ({
  isAuthenticated,
  redirectPath,
  canShowWithoutAuthenticated,
}: {
  isAuthenticated: boolean;
  redirectPath: string;
  canShowWithoutAuthenticated: boolean;
}) => {
  // ページへアクセスする際のパターンとしては以下の4種類

  // 1 ログイン後、アクセス権限を持ったページへアクセス
  const isShowAuthenticated = (isAuthenticated && !redirectPath);

  // 2 未ログインでログインしなくても表示できるページ(sign_inや掲載申込画面など)へアクセス
  const isShowWithoutAuthenticated = (!isAuthenticated && !redirectPath && canShowWithoutAuthenticated);

  // 3 未ログインでログインしないと表示できないページへアクセス
  const isUnauthenticatedAndRedirect = !isAuthenticated && !!redirectPath && !canShowWithoutAuthenticated;

  // 4 ログイン中には表示させたくないページへアクセス(sign_in画面など)
  const isAuthenticateAndRedirect = isAuthenticated && !!redirectPath;

  // 上記4種類のパターンの組み合わせで表示するかリダイレクトするかを判断する

  const isShowRoute = isShowAuthenticated || isShowWithoutAuthenticated;

  const isRedirect = isUnauthenticatedAndRedirect || isAuthenticateAndRedirect;

  return {
    isShowRoute,
    isRedirect,
  };
};

export const useCheckToShowRoute = (location: Location, checkAuth: checkAuth) => {
  const {
    isAuthenticated,
    redirectPath,
    canShowWithoutAuthenticated,
  } = useCheckAuth(location, checkAuth);

  const { isShowRoute, isRedirect } = checkToShowRoute({ isAuthenticated, redirectPath, canShowWithoutAuthenticated });

  return {
    isShowRoute,
    isRedirect,
    redirectPath,
  };
};
