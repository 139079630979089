import * as types from './types';

const update = (items) => {
  return {
    type: types.UPDATE,
    payload: {
      items,
    },
  };
};

const init = () => {
  return {
    type: types.INIT,
  };
};

export {
  update,
  init,
};
