const INIT = 'state/ducks/member-list/INIT';
const GET = 'state/ducks/member-list/GET';
const GET_MORE = 'state/ducks/member-list/GET_MORE';
const MARK_AS_NEW = 'state/ducks/member-list/MARK_AS_NEW';
const MARK_AS_SCOUTED = 'state/ducks/member-list/MARK_AS_SCOUTED';

export {
  INIT,
  GET,
  GET_MORE,
  MARK_AS_NEW,
  MARK_AS_SCOUTED,
};
