import reducers from './reducers';
import * as leavingPageAlertOperations from './operations';
import * as leavingPageAlertTypes from './types';

export {
  leavingPageAlertOperations,
  leavingPageAlertTypes,
};

export default reducers;
