const CONDITION_TYPE = {
  MANUAL: 1,
  JOB_OFFER_PREFECTURE: 2,
};

const EMPLOYMENT_STATUSES = [
  {
    id: 1,
    name: 'models.constants.customer_search_condition.employment_status.in_employment',
  },
  {
    id: 2,
    name: 'models.constants.customer_search_condition.employment_status.resigning',
  },
  {
    id: 3,
    name: 'models.constants.customer_search_condition.employment_status.in_school',
  },
];

const LAST_LOGIN = [
  {
    id: 0,
    name: 'common.unspecified',
  },
  {
    id: 1,
    name: 'models.constants.customer_search_condition.last_login.three_days_ago',
  },
  {
    id: 2,
    name: 'models.constants.customer_search_condition.last_login.a_month_ago',
  },
];

const PARAMETER_ALIAS = {
  desired_areas: 'da',
  prefecture_id: 'pid',
  city_ids: 'cid',
  desired_job_categories: 'djc',
  job_category_group_id: 'jcgid',
  job_category_id: 'jcid',
  career_year: 'cy',
  employment_types: 'et',
  employment_statuses: 'es',
  last_education_ids: 'leid',
  last_login: 'lg',
  member_qualification_ids: 'mqid',
};

const SCHOOL_TYPES = [
  {
    id: 1,
    name: 'models.constants.customer_search_condition.school_types.high_school',
  },
  {
    id: 2,
    name: 'models.constants.customer_search_condition.school_types.technical_college',
  },
  {
    id: 3,
    name: 'models.constants.customer_search_condition.school_types.junior_college',
  },
  {
    id: 4,
    name: 'models.constants.customer_search_condition.school_types.university',
  },
  {
    id: 5,
    name: 'models.constants.customer_search_condition.school_types.graduate_school_master',
  },
  {
    id: 6,
    name: 'models.constants.customer_search_condition.school_types.graduate_school_doctorate',
  },
  {
    id: 7,
    name: 'models.constants.customer_search_condition.school_types.vocational_school',
  },
  {
    id: 8,
    name: 'models.constants.customer_search_condition.school_types.others',
  },
];

export {
  CONDITION_TYPE,
  EMPLOYMENT_STATUSES,
  LAST_LOGIN,
  PARAMETER_ALIAS,
  SCHOOL_TYPES,
};
