import React from 'react';
import PropTypes from 'prop-types';
import SVGInline from 'react-svg-inline';
import classNames from 'classnames';

import AccessTime from 'raw-loader!../../../../img/access_time.svg';
import Analytics from 'raw-loader!../../../../img/analytics.svg';
import Arrow from 'raw-loader!../../../../img/arrow.svg';
import Blank from 'raw-loader!../../../../img/blank.svg';
import Checked from 'raw-loader!../../../../img/checked.svg';
import Close from 'raw-loader!../../../../img/close.svg';
import Collapse from 'raw-loader!../../../../img/collapse.svg';
import Done from 'raw-loader!../../../../img/done.svg';
import Edit from 'raw-loader!../../../../img/edit.svg';
import Expand from 'raw-loader!../../../../img/expand.svg';
import Facility from 'raw-loader!../../../../img/facility.svg';
import Folder from 'raw-loader!../../../../img/folder.svg';
import Group from 'raw-loader!../../../../img/group.svg';
import Add from 'raw-loader!../../../../img/add.svg';
import Home from 'raw-loader!../../../../img/home.svg';
import LinkBottom from 'raw-loader!../../../../img/link-bottom.svg';
import LinkLeft from 'raw-loader!../../../../img/link-left.svg';
import LinkRight from 'raw-loader!../../../../img/link-right.svg';
import LinkTop from 'raw-loader!../../../../img/link-top.svg';
import List from 'raw-loader!../../../../img/list.svg';
import Logo from 'raw-loader!../../../../img/logo.svg';
import Mail from 'raw-loader!../../../../img/mail.svg';
import Message from 'raw-loader!../../../../img/message.svg';
import OpenNew from 'raw-loader!../../../../img/open-new.svg';
import Phone from 'raw-loader!../../../../img/phone.svg';
import PhotoImage from 'raw-loader!../../../../img/photo-image.svg';
import Photo from 'raw-loader!../../../../img/photo.svg';
import Pr from 'raw-loader!../../../../img/pr.svg';
import Print from 'raw-loader!../../../../img/print.svg';
import Question from 'raw-loader!../../../../img/question.svg';
import Return from 'raw-loader!../../../../img/return.svg';
import Roupe from 'raw-loader!../../../../img/roupe.svg';
import SelectTriangle from 'raw-loader!../../../../img/select-triangle.svg';
import Setting from 'raw-loader!../../../../img/setting.svg';
import Site from 'raw-loader!../../../../img/site.svg';
import Voice from 'raw-loader!../../../../img/voice.svg';
import Video from 'raw-loader!../../../../img/video.svg';
import Play from 'raw-loader!../../../../img/play.svg';
import PlayDots from 'raw-loader!../../../../img/icon-playDots.svg';
import Calendar from 'raw-loader!../../../../img/calender.svg';
import File from 'raw-loader!../../../../img/file.svg';
import Send from 'raw-loader!../../../../img/send.svg';
import Badge from 'raw-loader!../../../../img/badge.svg';

const svgs = {
  'access-time': AccessTime,
  analytics: Analytics,
  arrow: Arrow,
  blank: Blank,
  checked: Checked,
  close: Close,
  collapse: Collapse,
  done: Done,
  edit: Edit,
  expand: Expand,
  facility: Facility,
  folder: Folder,
  group: Group,
  add: Add,
  home: Home,
  'link-bottom': LinkBottom,
  'link-eft': LinkLeft,
  'link-right': LinkRight,
  'link-top': LinkTop,
  list: List,
  logo: Logo,
  mail: Mail,
  message: Message,
  'open-new': OpenNew,
  phone: Phone,
  'photo-image': PhotoImage,
  photo: Photo,
  pr: Pr,
  print: Print,
  question: Question,
  return: Return,
  roupe: Roupe,
  'select-triangle': SelectTriangle,
  setting: Setting,
  site: Site,
  voice: Voice,
  video: Video,
  play: Play,
  'play-dots': PlayDots,
  calendar: Calendar,
  file: File,
  send: Send,
  badge: Badge,
};

const getSvgbyName = (name) => {
  return svgs[name];
};

export default class Icon extends React.Component {

  static propTypes = {
    name: PropTypes.string.isRequired,
    color: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    hoverable: PropTypes.bool,
    transparent: PropTypes.bool,
  }

  static defaultProps = {
    name: '',
    color: '',
    width: '',
    height: '',
  }

  shouldComponentUpdate(nextProps) {
    return (
      this.props.name !== nextProps.name || this.props.color !== nextProps.color
    );
  }

  render() {
    const {
      name,
      color,
      width,
      height,
      hoverable,
      transparent,
    } = this.props;

    if (!name) {
      return null;
    }

    const iconClass = classNames({
      'c-icon': true,
      'c-icon--white': color === 'white',
      'c-icon--grey': color === 'grey',
      'c-icon--light-grey': color === 'light-grey',
      'c-icon--blue': color === 'blue',
      'c-icon--hoverable': hoverable,
      'c-icon--transparent': transparent,
    });

    const svg = getSvgbyName(name);

    return (
      <SVGInline
        svg={ svg }
        width={ `${ width }px` }
        height={ `${ height }px` }
        className={ iconClass }
        cleanup
        ref={ (c) => {
          this.icon = c;
        } }
      />
    );
  }
}