import reducers from './reducers';
import * as loaderOperations from './operations';
import * as loaderTypes from './types';

export {
  loaderOperations,
  loaderTypes,
};

export default reducers;
