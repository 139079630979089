import { ValueOf } from '@/types';

const MODAL = {
  DELETE_CONFIRM: 'DELETE_CONFIRM',
  FACILITY_COPY: 'FACILITY_COPY',
  IMAGE_SELECT_MODAL: 'IMAGE_SELECT_MODAL',
  JOB_OFFER_COPY: 'JOB_OFFER_COPY',
  JOB_OFFER_CONFIRM_NOTICE: 'JOB_OFFER_CONFIRM_NOTICE',
  JOB_OFFER_UNIT_PRICE: 'JOB_OFFER_UNIT_PRICE',
  JOB_OFFER_UNIT_PRICE_NOTICE: 'JOB_OFFER_UNIT_PRICE_NOTICE',
  QUESTION_SELECTION: 'QUESTION_SELECTION',
  THEMES: 'THEMES',
  MESSAGE_DETAIL: 'MESSAGE_DETAIL',
  MESSAGE_SEARCH_FORM: 'MESSAGE_SEARCH_FORM',
  MESSAGE_SEARCH_JOB_OFFER_SELECTOR: 'MESSAGE_SEARCH_JOB_OFFER_SELECTOR',
  MESSAGE_HELP: 'MESSAGE_HELP',
  DELETED_JOB_OFFER: 'DELETED_JOB_OFFER',
  PROFILE: 'PROFILE',
  PROFILE_HELP: 'PROFILE_HELP',
  ANNOUNCEMENT_DETAIL: 'ANNOUNCEMENT_DETAIL',
  SEARCHES_HELP: 'SEARCHES_HELP',
  SEARCHES_MANUAL_CONDITIONS: 'SEARCHES_MANUAL_CONDITIONS',
  SEARCHES_RECOMMENDED_CONDITIONS: 'SEARCHES_RECOMMENDED_CONDITIONS',
  SEARCHES_RECEIVED_FAVORITES: 'SEARCHES_RECEIVED_FAVORITES',
  CUSTOMER_SEARCH_CONDITION: 'CUSTOMER_SEARCH_CONDITION',
  SCOUT_SENDING_FORM: 'SCOUT_SENDING_FORM',
  SEARCHES_SCOUTED_MEMBERS: 'SEARCHES_SCOUTED_MEMBERS',
  CUSTOMER_SEARCH_CONDITION_SAVE_MODAL: 'CUSTOMER_SEARCH_CONDITION_SAVE_MODAL',
  MANUAL_SEARCH_CONDITION_DELETE_CONFIRM: 'MANUAL_SEARCH_CONDITION_DELETE_CONFIRM',
  SCOUT_SENDING_JOB_OFFER_SELECTOR: 'SCOUT_SENDING_JOB_OFFER_SELECTOR',
  SCOUT_SENDING_JOB_OFFER_HISTORY: 'SCOUT_SENDING_JOB_OFFER_HISTORY',
  RECEIVED_FAVORITE_JOB_OFFER_SELECTOR: 'RECEIVED_FAVORITE_JOB_OFFER_SELECTOR',
  SCOUTED_MEMBER_JOB_OFFER_SELECTOR: 'SCOUTED_MEMBER_JOB_OFFER_SELECTOR',
  SCOUT_UPPER_LIMIT_CONFIRM: 'SCOUT_UPPER_LIMIT_CONFIRM',
  SEARCH_CONDITION_EDIT_HELP: 'SEARCH_CONDITION_EDIT_HELP',
  SEARCH_CONDITION_FOR_RECOMMEND_EDIT_HELP: 'SEARCH_CONDITION_FOR_RECOMMEND_EDIT_HELP',
  UNREGISTERED_JOB_OFFERS: 'UNREGISTERED_JOB_OFFERS',
  TRANSITION_TO_DESKTOP_CONFIRM: 'TRANSITION_TO_DESKTOP_CONFIRM',
  SCOUT_COUNTER_INITIALIZATION_NOTIFICATION: 'SCOUT_COUNTER_INITIALIZATION_NOTIFICATION',
  MESSAGE_TEMPLATES_HELP: 'MESSAGE_TEMPLATES_HELP',
  SELECTION_APPLICATION_HIRED_AT: 'SELECTION_APPLICATION_HIRED_AT',
  SELECTION_APPLICATION_SCHEDULING_HIRED_AT: 'SELECTION_APPLICATION_SCHEDULING_HIRED_AT',
  SELECTION_STATUS_UPDATE_REQUEST: 'SELECTION_STATUS_UPDATE_REQUEST',
  SELECTION_HIRING_SET_DONE_CONFIRM: 'SELECTION_HIRING_SET_DONE_CONFIRM',
  SELECTION_HIRING_DONE_CONFIRM: 'SELECTION_HIRING_DONE_CONFIRM',
  SELECTION_APPLICATION_PAST_SCHEDULING_HIRED_DATE_CONFIRM: 'SELECTION_APPLICATION_PAST_SCHEDULING_HIRED_DATE_CONFIRM',
  SELECTION_REJECTION_CONFIRM: 'SELECTION_REJECTION_CONFIRM',
  SELECTION_LEAD_TO_SEND_INTERVIEW_GUIDE_MESSAGE_DIALOG: 'SELECTION_LEAD_TO_SEND_INTERVIEW_GUIDE_MESSAGE_DIALOG',
  MESSAGE_SIDE_COVER: 'MESSAGE_SIDE_COVER',
  MESSAGE_TEMPLATE_DELETE_CONFIRM: 'MESSAGE_TEMPLATE_DELETE_CONFIRM',
  MESSAGE_TEMPLATE_EDITOR: 'MESSAGE_TEMPLATE_EDITOR',
  HOME_ANNOUNCEMENT: 'HOME_ANNOUNCEMENT',
  IMAGE_MANAGER_EDITOR: 'IMAGE_MANAGER_EDITOR',
  RECRUIT_COST: 'RECRUIT_COST',
  SELECTION_SEARCH_FORM: 'SELECTION_SEARCH_FORM',
  SELECTIONS_HELP: 'SELECTIONS_HELP',
  SELECTION_FACILITY_JOB_OFFER_SELECTOR: 'SELECTION_FACILITY_JOB_OFFER_SELECTOR',
  SELECTION_STATUS_CHOICE_LIST: 'SELECTION_STATUS_CHOICE_LIST',
  FACILITY_SEARCH_FORM: 'FACILITY_SEARCH_FORM',
  JOB_OFFER_APPLY_PUBLISH_NOTICE: 'JOB_OFFER_APPLY_PUBLISH_NOTICE',
  JOB_OFFER_APPLY_FAILED_NOTICE: 'JOB_OFFER_APPLY_FAILED_NOTICE',
  MANUSCRIPT_REQUEST_COMPLETE: 'MANUSCRIPT_REQUEST_COMPLETE',
  JOB_OFFER_CLOSE: 'JOB_OFFER_CLOSE',
  JOB_OFFER_DELETE: 'JOB_OFFER_DELETE',
  FACILITY_DELETE_CONFIRM: 'FACILITY_DELETE_CONFIRM',
  JOB_OFFER_PREVIEW_FRAME: 'JOB_OFFER_PREVIEW_FRAME',
  IMAGE_HELP: 'IMAGE_HELP',
  IMAGE_CAUTION: 'IMAGE_CAUTION',
  CONFIRM_ATTACHED_FILE: 'CONFIRM_ATTACHED_FILE',
} as const;

export type ModalKey = ValueOf<typeof MODAL>;

export default MODAL;
