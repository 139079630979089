import axios from 'axios';
import { CONDITION_TYPE } from '@/models/ducks/customer-search-condition/constants';

export default {
  searchManual(pagination) {
    return axios.post('/api/customers/customer_search_conditions/search_manual', {
      pagination,
    });
  },

  searchRecommend(pagination) {
    return axios.post('/api/customers/customer_search_conditions/search_recommend', {
      pagination,
    });
  },

  destroy(condition) {
    return axios.delete(`/api/customers/customer_search_conditions/${condition.id}/`);
  },

  save(condition, sendable) {
    return axios.post('/api/customers/customer_search_conditions/', {
      id: null,
      name: condition.name,
      condition: condition.condition,
      condition_type: CONDITION_TYPE.MANUAL,
      sendable,
    });
  },

  update(condition) {
    return axios.put(`/api/customers/customer_search_conditions/${condition.id}/`, {
      customer_search_condition: condition,
      name: condition.name,
    });
  },

  updateWithoutCondition(condition) {
    return axios.put(`/api/customers/customer_search_conditions/update_without_condition/${condition.id}/`, { customer_search_condition: condition });
  },

  markRead(condition) {
    const id = condition.get('id');

    return axios.put(`/api/customers/customer_search_conditions/mark_read/${ id }/`);
  },

  createLabel(param) {
    const condition = param.toJS();

    return axios.post('/api/customers/customer_search_conditions/label', {
      desired_areas: condition.desired_areas,
      desired_job_categories: condition.desired_job_categories,
      employment_statuses: condition.employment_statuses.map((status) => {
        return status * 1;
      }),
      employment_types: condition.employment_types.map((type) => {
        return type * 1;
      }),
      last_login: condition.last_login,
      last_education_ids: condition.last_education_ids.map((id) => {
        return id * 1;
      }),
      member_qualification_ids: condition.member_qualification_ids,
      recommend: condition.recommend,
      favorite: condition.favorite,
      received_favorite_job_offer_name: condition.received_favorite_job_offer_name,
      received_favorite_job_offer_id: condition.received_favorite_job_offer_id,
      scout: condition.scout * 1,
      scouted_member_job_offer_name: condition.scouted_member_job_offer_name,
      scouted_member_job_offer_id: condition.scouted_member_job_offer_id,
      member_id: condition.member_id,
    });
  },

  exist_by_name(name, customerSearchConditionId) {
    return axios.get('/api/customers/customer_search_conditions/exist_by_name/', {
      params: {
        name,
        customer_search_condition_id: customerSearchConditionId,
      },
    });
  },
};
