import * as actions from './actions';
import MemberApi from '@/models/ducks/shared/api/member';

const show = (id) => {
  return dispatch => MemberApi.showMember(id)
    .then((response) => {
      return dispatch(actions.show(response.data.member));
    });
};

const getId = (id) => {
  return dispatch => dispatch(actions.getId(id));
};

const destroy = () => {
  return dispatch => dispatch(actions.destroy());
};

export {
  show,
  getId,
  destroy,
};
