import { List } from 'immutable';
import * as types from './types';

export const initialState = new List();

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET:
      return List(action.payload.jobOffers);
    default:
      return state;
  }
};
