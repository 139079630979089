import * as actions from './actions';
import FACILITY_JOB_OFFER_SUGGEST_TYPE from '@/models/ducks/shared/constants/facility-job-offer-suggest-type';

const init = () => {
  return dispatch => dispatch(actions.init());
};

const changeField = (key, value) => {
  return dispatch => dispatch(actions.changeField(key, value));
};

const changePage = (page) => {
  return dispatch => dispatch(actions.changePage(page));
};

const setSuggestionId = (suggestion) => {
  if (suggestion.type === FACILITY_JOB_OFFER_SUGGEST_TYPE.FACILITY) {
    return dispatch => dispatch(actions.setSuggestionFacilityId(suggestion));
  }
  return dispatch => dispatch(actions.setSuggestionJobOfferId(suggestion));
};

const initSuggestion = () => {
  return dispatch => dispatch(actions.initSuggestion());
};

export {
  init,
  changeField,
  changePage,
  setSuggestionId,
  initSuggestion,
};
