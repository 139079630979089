import { connect } from 'react-redux';
import { deviceModeOperations } from '@/models/ducks/device-mode';
import browserHelper from '@/utils/browser';
import { getUrlQueryParameter } from '@/utils/get-url-query-parameter';

export const mapStateToProps = state => ({
  customer: state.customer,
  sideNavBadge: state.sideNavBadge,
  operator: state.operator,
  isLoading: state.loader,
});

export const mapDispatchToProps = dispatch => ({
  onInit: async ({ router, location }) => {
    const parsedQuery = getUrlQueryParameter(location.search);

    if (parsedQuery.from === 'desktop') {
      await dispatch(deviceModeOperations.update(true));
      await router(window.location.pathname);

      if (browserHelper.isSafari()) {
        await window.location.reload(true);
      }
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps);
