import * as types from './types';

const noop = () => {
  return {
    type: types.NOOP,
  };
};

const activate = () => {
  return {
    type: types.ACTIVATE,
  };
};

const inactivate = () => {
  return {
    type: types.INACTIVATE,
  };
};

export {
  noop,
  activate,
  inactivate,
};
