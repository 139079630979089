import React from 'react';

const StaticHoc = Component => class extends React.Component {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    return (
      <Component { ...this.props } />
    );
  }
};

export default StaticHoc;
