import PAGE from '@/models/ducks/shared/constants/page';

export default {
  reloadOnActive(nextProps, currentProps, callback) {
    if (
      nextProps.location.key !== currentProps.location.key
      &&
      nextProps.location.search.includes('activelink=true')
    ) {
      nextProps.router(nextProps.location.pathname, { replace: true });
      callback();
    }
  },

  getInfo(to) {
    const { pathname } = location;
    const href = this.isSlashEnd(to) ? to.slice(0, -1) : to;
    const path = this.isSlashEnd(pathname) ? pathname.slice(0, -1) : pathname;

    return {
      href,
      path,
    };
  },

  isSlashEnd(link) {
    const size = link.length;

    return (
      size > 1
      && link[size - 1] === '/'
    );
  },

  isSearchesPages(pathname) {
    return pathname.includes('/customers/searches');
  },

  isActiveLink(to) {
    const { href, path } = this.getInfo(to);

    return path === href;
  },

  // サイドメニューの構成を見直すまでの間、下層ページにいる場合にどこの画面にいるのかを示す情報として、下層ページを含めてメニューのアクティブ状態を判定する
  isActiveCategory(to) {
    const { href, path } = this.getInfo(to);

    if (href === '/') {
      return false;
    }

    if (href === PAGE.SIDE_NAV_CATEGORY.FACILITIES.PARENT) {
      return (
        PAGE.SIDE_NAV_CATEGORY.FACILITIES.CHILDREN.some(url => path.includes(url))
        || path.includes(href)
      );
    }

    return path.includes(href);
  },

  isFromQueryProfile(memberId, activeInParamQuery) {
    if (!memberId) {
      return false;
    }
    return !!memberId && activeInParamQuery === 'profile';
  },

  isFromQueryScout(memberId, activeInParamQuery) {
    if (!memberId) {
      return false;
    }
    return !!memberId && activeInParamQuery === 'scout';
  },

  isMessageSpecified(jobOfferId, memberId) {
    return jobOfferId && memberId;
  },

  isMobileOptimized() {
    const { pathname } = window.location;
    const path = this.isSlashEnd(pathname) ? pathname.slice(0, -1) : pathname;

    return [
      '/customers/new',
      '/customers/messages',
      '/customers/messages/detail',
      '/customers/searches',
      '/customers/selections',
      '/customers/announcements',
      '/customers/sign_in',
      '/customers/reset_form',
      '/api/customers/auth/password/edit',
      '/',
    ].includes(path);
  },

  removeTrailingSlash(url) {
    if (!this.isSlashEnd(url)) {
      return url;
    }

    return url.slice(0, -1);
  },

  generateLinkProps(to) {
    const isActiveLink = this.isActiveLink(to);
    return isActiveLink ? '?activelink=true' : '';
  },
};
