import * as actions from './actions';

const show = ({ targetEl, targetEllipsis, content }) => {
  return dispatch => dispatch(actions.show({ targetEl, targetEllipsis, content }));
};

const hide = () => {
  return dispatch => dispatch(actions.hide());
};

export {
  show,
  hide,
};
