import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { RootState } from '@/state';
import store from '@/models/store';
import { customerOperations } from '@/models/ducks/customer';
import { URL_PATH } from '@/router/constants';
import { withRouter } from '@/router/hocs/withRouter';
import CheckLogin from '.';

export const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => ({
  checkLogin: async () => {
    try {
      await dispatch(customerOperations.getCurrentCustomer());
    } catch {
      return {
        isLogin: false,
        isReload: false,
        redirectPath: '',
      };
    }


    const { customer }: any = store.getState();

    if (!customer.approval) {
      return {
        isLogin: true,
        isReload: true,
        redirectPath: URL_PATH.PROVISIONAL,
      };
    }

    return {
      isLogin: true,
      isReload: true,
      redirectPath: URL_PATH.HOME,
    };
  },
});

export type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default withRouter(connect(() => ({}), mapDispatchToProps)(CheckLogin));
