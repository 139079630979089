import JobOfferApi from '@/models/ducks/shared/api/job-offer';
import * as actions from './actions';

const getPublished = () => {
  return dispatch => JobOfferApi.published()
    .then((response) => {
      return dispatch(actions.get(response.data.data.job_offers));
    });
};

export {
  getPublished,
};
