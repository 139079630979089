import FacilityApi from '@/models/ducks/shared/api/facility';
import * as actions from './actions';

const create = (condition) => {
  return dispatch => FacilityApi.createLabel(condition)
    .then((response) => {
      return dispatch(actions.set(response.data.label));
    });
};

export {
  create,
};
