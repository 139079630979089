import * as types from './types';

const set = (label) => {
  return {
    type: types.SET,
    payload: {
      label,
    },
  };
};

export {
  set,
};
