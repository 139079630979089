const INIT = 'state/ducks/city-display-list/INIT';
const SYNC_SIZE = 'state/ducks/city-display-list/SYNC_SIZE';
const SYNC = 'state/ducks/city-display-list/SYNC';
const TOGGLE = 'state/ducks/city-display-list/TOGGLE';
const UPDATE_ID = 'state/ducks/city-display-list/UPDATE_ID';

export {
  INIT,
  SYNC_SIZE,
  SYNC,
  TOGGLE,
  UPDATE_ID,
};
