import { Record } from 'immutable';

const properties = {
  id: undefined,
  job_offer_id: undefined,
  qualification_id: '',
  qualification: {},
  employment_type: '',
  unit_price_id: '',
  unit_price: {},
  price: '',
};

const JobOfferUnitPriceRecord = Record(properties);
export default class JobOfferUnitPrice extends JobOfferUnitPriceRecord {
  update(key, value) {
    return this.set(key, value);
  }
}

