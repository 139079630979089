import * as types from './types';

const show = (member) => {
  return {
    type: types.SHOW,
    payload: {
      member,
    },
  };
};

const getId = (id) => {
  return {
    type: types.GET_ID,
    payload: {
      id,
    },
  };
};

const destroy = () => {
  return {
    type: types.DESTROY,
  };
};

export {
  show,
  destroy,
  getId,
};
