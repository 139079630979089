import * as types from './types';

const init = () => {
  return {
    type: types.INIT,
  };
};

const create = (jobOffer) => {
  return {
    type: types.CREATE,
    payload: {
      jobOffer,
    },
  };
};

const set = (jobOffer) => {
  return {
    type: types.SET,
    payload: {
      jobOffer,
    },
  };
};

const get = (jobOffer) => {
  return {
    type: types.GET,
    payload: {
      jobOffer,
    },
  };
};

const changeField = (key, value) => {
  return {
    type: types.CHANGE_FIELD,
    payload: {
      key,
      value,
    },
  };
};

const changeClosedReasons = (key, value) => {
  return {
    type: types.CHANGE_CLOSED_REASONS,
    payload: {
      key,
      value,
    },
  };
};

const copy = (copyingJobOffer) => {
  return {
    type: types.COPY,
    payload: {
      copyingJobOffer,
    },
  };
};

const changeSalaryField = (salary, key, value) => {
  return {
    type: types.CHANGE_SALARY_FIELD,
    payload: {
      salary,
      key,
      value,
    },
  };
};

const changeSalaryStatus = (salary) => {
  return {
    type: types.CHANGE_SALARY_STATUS,
    payload: {
      salary,
    },
  };
};

const changeSalaryStatusCheck = (salary) => {
  return {
    type: types.CHANGE_SALARY_STATUS_CHECK,
    payload: {
      salary,
    },
  };
};

const holdCurrentFeatures = (features) => {
  return {
    type: types.HOLD_CURRENT_FEATURES,
    payload: {
      features,
    },
  };
};

const updateUnitPrices = (unitPrices) => {
  return {
    type: types.UPDATE_UNIT_PRICES,
    payload: {
      unitPrices,
    },
  };
};

const changeFeature = (feature) => {
  return {
    type: types.CHANGE_FEATURE,
    payload: {
      feature,
    },
  };
};

const addImages = (images) => {
  return {
    type: types.ADD_IMAGES,
    payload: {
      images,
    },
  };
};

export {
  init,
  create,
  set,
  get,
  changeField,
  changeClosedReasons,
  copy,
  changeSalaryField,
  changeSalaryStatus,
  changeSalaryStatusCheck,
  holdCurrentFeatures,
  updateUnitPrices,
  changeFeature,
  addImages,
};
