import * as types from './types';

const init = () => {
  return {
    type: types.INIT,
  };
};

const set = (selection) => {
  return {
    type: types.SET,
    payload: {
      selection,
    },
  };
};

export {
  init,
  set,
};
