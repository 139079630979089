import { Record } from 'immutable';
import String from '@/utils/string';

const properties = {
  password: '',
  password_new: '',
  is_reset_alert_visible: false,
};

const PasswordEditRecord = Record(properties);
export default class PasswordEdit extends PasswordEditRecord {
  update(key, value) {
    return this.set(key, String.convertNullValue(value));
  }
}
