import Customers from './customers.json';
import CustomerSearchCondition from './customer-search-condition.json';
import Facility from './facility.json';
import ManuscriptModification from './manuscript-modification.json';
import PasswordEdit from './password-edit.json';
import ResetForm from './reset-form.json';
import Shared from './shared.json';

const Data = {};
Data.customers = Customers;
Data.customer_search_condition = CustomerSearchCondition;
Data.facility = Facility;
Data.manuscript_modification = ManuscriptModification;
Data.password_edit = PasswordEdit;
Data.reset_form = ResetForm;
Data.shared = Shared;

export default Data;
