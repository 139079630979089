import * as actions from './actions';
import BadgeApi from './api';

const get = () => {
  return dispatch => BadgeApi.selections()
    .then((response) => {
      return dispatch(actions.get(response.data.badge));
    });
};

export {
  get,
};
