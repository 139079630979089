import * as types from './types';
import Facility from '@/models/ducks/shared/models/facility';
import Image from '@/models/ducks/shared/models/image';

export const initialState = new Facility();

const initUnnecessaryFieldOnCopy = (state) => {
  const contacts = state.contacts.map(item => item.clearId());

  return state.merge({
    id: initialState.id,
    status: initialState.status,
  }).setContacts(contacts);
};

const copyCustomerInfo = (state, action) => {
  const { customer } = action.payload;

  return state.merge({
    postal_code: customer.postal_code,
    prefecture_id: customer.prefecture_id,
    city_id: customer.city_id,
    address_etc: customer.address_etc,
  });
};

const changeGeoLatLon = (state, action) => {
  const { lat, lon } = action.payload;

  return state.merge({
    lat: Number(lat),
    lon: Number(lon),
  });
};

const changeAccess = (state, action) => {
  return state.set('access', action.payload.access);
};

const changeFeature = (state, action) => {
  const index = state.features.findIndex((item) => {
    return item.id === action.payload.feature.id;
  });

  const newFeatures = state.features.setIn([index], {
    ...action.payload.feature,
    checked: action.payload.isChecked,
  });

  return state.update('features', newFeatures);
};

const fillAddress = (state, action) => {
  const { town } = action.payload;

  return state.merge({
    prefecture_id: town.prefecture_id,
    city_id: town.city_id,
  });
};

const addImages = (state, action) => {
  const { images } = action.payload;
  const newImages = images.reduce((accumulator, current) => {
    return accumulator.push(new Image(current));
  }, state.customer_images);

  return state.update('customer_images', newImages);
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.INIT:
      return initialState;
    case types.SET:
      return action.payload.facility;
    case types.CREATE:
      return new Facility(action.payload.facility);
    case types.SET_WITH_CONVERTED_FACILITY:
      return Facility.convert(action.payload.facility);
    case types.INIT_UNNECESSARY_FIELD_ON_COPY:
      return initUnnecessaryFieldOnCopy(state);
    case types.COPY_CUSTOMER_INFO:
      return copyCustomerInfo(state, action);
    case types.CHANGE_GEO_LAT_LON:
      return changeGeoLatLon(state, action);
    case types.CHANGE_ACCESS:
      return changeAccess(state, action);
    case types.CHANGE_FIELD:
      return state.update(action.payload.key, action.payload.value);
    case types.CHANGE_FEATURE:
      return changeFeature(state, action);
    case types.FILL_ADDRESS:
      return fillAddress(state, action);
    case types.ADD_IMAGES:
      return addImages(state, action);
    case types.NOOP:
    default:
      return state;
  }
};
