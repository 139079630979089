import { Record } from 'immutable';

const properties = {
  facility_or_job_offer: '',
  member_name_or_id: '',
  note: '',
};

const SelectionSearchLabelRecord = Record(properties);

export default class SelectionSearchLabel extends SelectionSearchLabelRecord {
  update(key, value) {
    return this.set(key, value);
  }

  isEdited() {
    return [
      this.facility_or_job_offer,
      this.member_name_or_id,
      this.note,
    ].some(v => v);
  }

  connected() {
    return [
      `${ this.get('facility_or_job_offer') }`,
      `${ this.get('member_name_or_id') }`,
      `${ this.get('note') }`,
    ].filter(v => v).join(',');
  }
}
