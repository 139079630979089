import FacilityFormApi from './api';
import * as actions from './actions';

const init = () => {
  return dispatch => dispatch(actions.init());
};

const get = () => {
  return dispatch => FacilityFormApi.all()
    .then((response) => {
      return dispatch(actions.get(response.data.facility_forms));
    });
};

export {
  init,
  get,
};
