import { createAction, ActionType } from 'typesafe-actions';

export const INIT = 'ANNOUNCEMENTS/INIT';
export const HANDLE_LOAD = 'ANNOUNCEMENTS/HANDLE_LOAD';
export const FETCH_ANNOUNCEMENTS = 'ANNOUNCEMENTS/FETCH_ANNOUNCEMENTS';
export const SHOW_ANNOUNCEMENT_DETAIL = 'ANNOUNCEMENTS/SHOW_ANNOUNCEMENT_DETAIL';
export const HIDE_ANNOUNCEMENT_DETAIL = 'ANNOUNCEMENTS/HIDE_ANNOUNCEMENT_DETAIL';

export const init = createAction(INIT)();
export const handleLoad = createAction(HANDLE_LOAD)();
export const fetchAnnouncements = createAction(FETCH_ANNOUNCEMENTS, ({ announcementIds }: { announcementIds: number[] }) => ({
  announcementIds,
}))();
export const showAnnouncementDetail = createAction(SHOW_ANNOUNCEMENT_DETAIL, ({ id }: { id: number }) => ({
  id,
}))();
export const hideAnnouncementDetail = createAction(HIDE_ANNOUNCEMENT_DETAIL)();

export type AnnouncementsActionTypes =
  ActionType<typeof init> |
  ActionType<typeof handleLoad> |
  ActionType<typeof fetchAnnouncements> |
  ActionType<typeof showAnnouncementDetail> |
  ActionType<typeof hideAnnouncementDetail>;
