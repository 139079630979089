import { Record, List } from 'immutable';

const properties = {
  keyword: '',
  ignore_deleted_member: '',
  message_types: List(),
  message_type_ids: List(),
  job_offer_name: '',
  member: '',
};

const MessageGroupSearchLabelRecord = Record(properties);

export default class MessageGroupSearchLabel extends MessageGroupSearchLabelRecord {
  update(key, value) {
    return this.set(key, value);
  }

  isUnspecified() {
    return (
      !this.keyword
      &&
      !this.ignore_deleted_member
      &&
      !this.message_types.length
      &&
      !this.job_offer_name
      &&
      !this.member
    );
  }

  isUnspecifiedFromInput() {
    return !(
      !!this.get('member')
      ||
      !!this.get('keyword')
      ||
      !!this.get('message_types').length
      ||
      !!this.get('ignore_deleted_member')
    );
  }
}
