import * as types from './types';

const init = () => {
  return {
    type: types.INIT,
  };
};

const changeField = (key, value) => {
  return {
    type: types.CHANGE_FIELD,
    payload: {
      key,
      value,
    },
  };
};

const changeFromUrlQuery = (parsedQuery) => {
  return {
    type: types.CHANGE_FROM_URL_QUERY,
    payload: {
      parsedQuery,
    },
  };
};

const changePostingStatuses = (key, value) => {
  return {
    type: types.CHANGE_POSTING_STATUSES,
    payload: {
      key,
      value,
    },
  };
};

const changePage = (page) => {
  return {
    type: types.CHANGE_PAGE,
    payload: {
      page,
    },
  };
};

const overwriteWithFacilitySearchForm = (facilitySearchForm) => {
  return {
    type: types.OVERWRITE_WITH_FACILITY_SEARCH_FORM,
    payload: {
      facilitySearchForm,
    },
  };
};

export {
  init,
  changeField,
  changeFromUrlQuery,
  changePostingStatuses,
  changePage,
  overwriteWithFacilitySearchForm,
};
