import HomeRecommendedMembers from './model';
import * as types from './types';

export const initialState = new HomeRecommendedMembers();

export default (state = initialState, action) => {
  switch (action.type) {
    case types.CREATE:
      return HomeRecommendedMembers.convert(action.payload.members);
    default:
      return state;
  }
};
