import * as types from './types';

const show = ({ messages, page, total }) => {
  return {
    type: types.SHOW,
    payload: {
      messages,
      page,
      total,
    },
  };
};

const destroy = () => {
  return {
    type: types.DESTROY,
  };
};

const add = (item) => {
  return {
    type: types.ADD,
    payload: {
      item,
    },
  };
};

const noop = () => {
  return {
    type: types.NOOP,
  };
};

export {
  show,
  destroy,
  add,
  noop,
};
