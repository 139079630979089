import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Overlay = (props) => {
  const onClick = (e) => {
    e.preventDefault();
    props.onClick(e);
  };

  const overlayClass = classNames({
    'c-overlay': true,
    'c-overlay--transparent': props.transparent,
    'u-is-hidden': props.isHidden,
    [`${ props.className }`]: props.className,
  });

  /* eslint-disable jsx-a11y/click-events-have-key-events */
  return (
    <div className={ overlayClass } onClick={ onClick } />
  );
  /* eslint-enable jsx-a11y/click-events-have-key-events */
};

Overlay.propTypes = {
  isHidden: PropTypes.bool,
  onClick: PropTypes.func,
  transparent: PropTypes.bool,
  className: PropTypes.string,
};

Overlay.defaultProps = {
  onClick() {},
};

export default Overlay;
