import reducers from './reducers';
import * as operatorOperations from './operations';
import * as operatorTypes from './types';

export {
  operatorOperations,
  operatorTypes,
};

export default reducers;
