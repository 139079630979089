import JobCategoryApi from '@/models/ducks/shared/api/job-category';
import * as actions from './actions';

const get = (jobOfferType) => {
  return dispatch => JobCategoryApi.registered(jobOfferType)
    .then((response) => {
      return dispatch(actions.get(response.data.job_categories));
    });
};

export {
  get,
};
