import * as actions from './actions';
import MessageTemplateApi from '@/models/ducks/shared/api/message-template';

const get = (page = 1, limit = 100) => {
  return dispatch => MessageTemplateApi.all(page, limit)
    .then((response) => {
      return dispatch(actions.get(response.data));
    });
};

export {
  get,
};
