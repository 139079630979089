import { Record } from 'immutable';

const properties = {
  email: '',
  new_email: '',
};

const EmailEditRecord = Record(properties);
export default class EmailEdit extends EmailEditRecord {
  static convert(customer) {
    const model = new EmailEdit({ email: customer.email });
    return model;
  }

  update(key, value) {
    return this.set(key, value);
  }
}
