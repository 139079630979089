import { arrayMove } from 'react-sortable-hoc';
import { Iterable, List } from 'immutable';

export default {
  moveListItem(list, oldIndex, newIndex) {
    let items = list.toArray();
    items = arrayMove(items, oldIndex, newIndex);
    return new List(items);
  },

  // http://stackoverflow.com/questions/31907470/how-to-check-if-object-is-immutable
  isImmutable(obj) {
    return Iterable.isIterable(obj);
  },
};
