import { Record, List } from 'immutable';
import NAV_TYPE from '@/models/ducks/shared/constants/member-search-nav-type';

const properties = {
  type: NAV_TYPE.ALL,
  id: 0,
  memberIds: new List(),
};

const UnreadMembersRecord = Record(properties);
export default
class UnreadMembers extends UnreadMembersRecord {

  removeMemberIds(removeMemberIds) {
    let newMemberIds = this.memberIds;
    removeMemberIds.forEach((memberId) => {
      const index = newMemberIds.findIndex((id) => {
        return id === memberId;
      });

      if (index > -1) {
        newMemberIds = newMemberIds.delete(index);
      }
    });
    return this.set('memberIds', newMemberIds);
  }
}
