const INIT = 'state/ducks/scout-message-form/INIT';
const CHANGE_FIELD = 'state/ducks/scout-message-form/CHANGE_FIELD';
const CHANGE_JOB_OFFER_FIELD = 'state/ducks/scout-message-form/CHANGE_JOB_OFFER_FIELD';
const INIT_JOB_OFFER_FIELD = 'state/ducks/scout-message-form/INIT_JOB_OFFER_FIELD';
const CHANGE_MEMBER_IDS_FIELD = 'state/ducks/scout-message-form/CHANGE_MEMBER_IDS_FIELD';

export {
  INIT,
  CHANGE_FIELD,
  CHANGE_JOB_OFFER_FIELD,
  INIT_JOB_OFFER_FIELD,
  CHANGE_MEMBER_IDS_FIELD,
};
