import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/browser';
import { RootState } from '@/state';
import store from '@/models/store';
import { customerOperations } from '@/models/ducks/customer';
import { operatorOperations } from '@/models/ducks/operator';
import { sideNavBadgeOperations } from '@/models/ducks/side-nav-badge';
import { CAN_SHOW_PAGE_WITHOUT_AUTHENTICATED_PATH_LIST, CAN_SHOW_PAGE_WITHOUT_APPROVAL_PATH_LIST, PDF_PATH, SIDE_NAV_BLACK_LIST, URL_PATH } from '@/router/constants';
import { REPLACE_KEY } from '@/models/ducks/shared/constants/page';
import { withRouter } from '@/router/hocs/withRouter';
import AuthenticatedRoute from '.';
import { CustomerState } from '@/models/ducks';

export type StateProps = {
  customer: CustomerState;
}

export const mapStateToProps = (state: RootState): StateProps => ({
  customer: state.customer,
});

export const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => ({
  checkAuth: async ({ toPath, query, loginRedirectUrl }: { toPath: string; query: string; loginRedirectUrl: string }) => {
    // customerとしての承認、利用規約の同意の3つを持ってログイン認証と見なす
    let isApprovedAsCustomer = false;
    let isRuleAgreed = false;

    try {
      await Promise
    .all([
      dispatch(operatorOperations.get()),
      dispatch(customerOperations.getCurrentCustomer()),
    ]);
    } catch (err) {
      Sentry.setUser(null);
      // 未ログインでログインしなくても表示できるページへのアクセス
      // loginRedirectUrlはログイン前に最後に開いたページのURL(ログインリダイレクトさせるページ)を保持するが、
      // CAN_SHOW_PAGE_WITHOUT_AUTHENTICATED_PATH_LISTの画面にはログインリダイレクトさせたくないため、loginRedirectUrlを更新しない
      if (CAN_SHOW_PAGE_WITHOUT_AUTHENTICATED_PATH_LIST.some((path: string) => toPath.indexOf(path) > -1)) {
        return {
          isAuthenticated: isApprovedAsCustomer && isRuleAgreed,
          redirectPath: '',
          isReload: false,
          canShowWithoutAuthenticated: true,
          loginRedirectUrl,
        };
      }

      // ユーザー編集ページ → ログイン期限切れ → ログイン → ユーザー編集ページ
      // 上記のようにログイン後にログイン前に開いていたページに遷移できるようにloginRedirectUrlにログイン前に開いていたパスを設定する
      return {
        isAuthenticated: isApprovedAsCustomer && isRuleAgreed,
        redirectPath: URL_PATH.SIGN_IN,
        isReload: false,
        canShowWithoutAuthenticated: false,
        loginRedirectUrl: `${ toPath === '/' ? '' : `${ toPath }${ query }` }`,
      };
    }

    const state: any = store.getState();
    const { customer, operator } = state;
    // CAN_SHOW_PAGE_WITHOUT_APPROVAL_PATH_LISTの画面は本登録済み顧客のみアクセス可能なので、本登録前の顧客の場合はリダイレクトさせる
    if (!CAN_SHOW_PAGE_WITHOUT_APPROVAL_PATH_LIST.some((path: string) => toPath.indexOf(path) > -1)) {
      if (!customer.approval && !operator.operator) {
        return {
          isAuthenticated: isApprovedAsCustomer && isRuleAgreed,
          redirectPath: URL_PATH.FACILITIES,
          isReload: true,
          canShowWithoutAuthenticated: false,
          loginRedirectUrl: '',
        };
      }
    }

    // customerとして承認はされている
    isApprovedAsCustomer = true;

    // 利用規約画面は無条件に表示する。利用規約画面側でどこにリダイレクトするのかをハンドリングする
    if (toPath.includes(URL_PATH.CONFIRM_RULE)) {
      return {
        isAuthenticated: isApprovedAsCustomer && isRuleAgreed,
        redirectPath: '',
        isReload: false,
        canShowWithoutAuthenticated: true,
        loginRedirectUrl,
      };
    }

    // 利用規約に同意している
    // これ以降はisApprovedAsCustomer、isRuleAgreed全てtrueとなりログインが認められる
    isRuleAgreed = true;

    // NOTE: 動的なIDを、固定の文字列に変換して、PATHを特定している
    // 変換前: '/customers/official_hiring_pages/1/job_offers/1/edit'
    // 変換後: '/customers/official_hiring_pages/id/job_offers/id/edit'
    const replacedToPath = toPath.replace(/[0-9]+/g, REPLACE_KEY);
    const isBlackListPath = SIDE_NAV_BLACK_LIST.some(path => replacedToPath.includes(path));
    if (!isBlackListPath) {
      dispatch(sideNavBadgeOperations.update());
    }

    if (toPath.includes(URL_PATH.SIGN_IN)) {
      return {
        isAuthenticated: isApprovedAsCustomer && isRuleAgreed,
        redirectPath: URL_PATH.HOME,
        isReload: false,
        canShowWithoutAuthenticated: false,
        loginRedirectUrl: '',
      };
    }

    if (
      toPath.includes(PDF_PATH.PRICE_LIST)
      || toPath.includes(PDF_PATH.PRICE_LIST_OLD)
      || toPath.includes(PDF_PATH.USERS_MANUAL_PC)
      || toPath.includes(PDF_PATH.USERS_MANUAL_SP)
    ) {
      return {
        isAuthenticated: isApprovedAsCustomer && isRuleAgreed,
        redirectPath: toPath.replace('/files', '/pdf'),
        isReload: false,
        canShowWithoutAuthenticated: false,
        loginRedirectUrl: '',
      };
    }

    if (loginRedirectUrl !== '') {
      return {
        isAuthenticated: isApprovedAsCustomer && isRuleAgreed,
        redirectPath: loginRedirectUrl,
        isReload: false,
        canShowWithoutAuthenticated: false,
        loginRedirectUrl: '',
      };
    }

    return {
      isAuthenticated: isApprovedAsCustomer && isRuleAgreed,
      redirectPath: '',
      isReload: false,
      canShowWithoutAuthenticated: false,
      loginRedirectUrl: '',
    };
  },
});
export type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AuthenticatedRoute));
