import FacilityApi from '@/models/ducks/shared/api/facility';
import * as actions from './actions';

const search = (condition) => {
  return dispatch => FacilityApi.search(condition)
    .then((response) => {
      return dispatch(actions.get(response));
    });
};

export {
  search,
};
