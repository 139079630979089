// Skip
export default {
  IGNORE_ERRORS: [
    'Cannot read property \'_avast_submit\' of undefined',
    'Unable to get property \'_currentElement\' of undefined or null reference',
    'Unable to get property \'getHostNode\' of undefined or null reference',
    '未定義または NULL 参照のプロパティ \'_currentElement\' は取得できません',
    '未定義または NULL 参照のプロパティ \'getHostNode\' は取得できません',
    'Maximum call stack size exceeded',
    'スタック領域が不足しています。',
    'この操作を完了するのに十分な記憶域がありません。',
    'Permission denied to access property "target"',
    'クリップボードのデータ形式が無効です',
    'Minified exception occurred; use the non-minified dev environment for the full error message and additional helpful warnings.',
    'Attempted to update component `FluxContainer(t)` that has already been unmounted (or failed to mount).',
    'Unexpected string literal "); parts.push(k); parts.push(". Expected \')\' to end an argument list.',
    'window.onorientationchange is not a function. (In \'window.onorientationchange()\', \'window.onorientationchange\' is null)',
    '\'console\' は定義されていません。',
  ],
};
