import * as types from './types';

export const initialState = {
  facilities: 0,
  searches: 0,
  selections: 0,
  messages: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE:
      return {
        ...state,
        ...action.payload.badge,
      };
    default:
      return state;
  }
};
