export default {
  FACILITY_FORMS: window.Settings.facility_forms,
  FEATURE_CATEGORIES: window.Settings.feature_categories,
  CUSTOMERS: window.Settings.customers,
  CONTACT_MEAN_MEDIAS: window.Settings.contact_mean_medias,
  CONTACT_TYPES: window.Settings.contact_types,
  JOB_OFFER_STATUS_MAP: window.Settings.status_map,
  JOB_MEDLEY_URL: window.Settings.job_medley_url,
  GOOGLE_MAP_API_KEY: window.Settings.api_key,
  GOOGLE_MAP_ID: window.Settings.google_map_id,
  GOOGLE_ANALYTICS: window.Settings.google_analytics,
  GOOGLE_TAG_MANAGER: window.Settings.google_tag_manager,
  ASSET_HOST: window.Settings.asset_host,
  DFP: window.Settings.dfp,
  SENTRY_DSN: window.Settings.sentry_dsn,
  SUPPORT_TIME: window.Settings.medley.support_time,
  MEDLEY_TEL: window.Settings.medley.tel,
  SUPPORT_TEL: window.Settings.medley.support_tel,
  SUPPORT_MAIL: window.Settings.medley.support_mail,
  TIMEZONE_FORMATTED_OFFSET: window.Settings.timezone_formatted_offset,
} as const;
