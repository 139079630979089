const INIT = 'state/ducks/message-template/INIT';
const BUILD = 'state/ducks/message-template/BUILD';
const SET = 'state/ducks/message-template/SET';
const CHANGE_FIELD = 'state/ducks/message-template/CHANGE_FIELD';

export {
  INIT,
  BUILD,
  SET,
  CHANGE_FIELD,
};
