import * as types from './types';
import SelectionSearchLabel from './model';

export const initialState = new SelectionSearchLabel();

export default (state = initialState, action) => {
  switch (action.type) {
    case types.INIT:
      return initialState;
    case types.GET:
      return new SelectionSearchLabel(action.payload.label);
    default:
      return state;
  }
};
